//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { stockPage } from '@/api/stock';
import BaseHeader from "../components/BaseHeader.vue";
import BaseTable from "../components/BaseTable.vue";
export default {
  name: 'XyTable',
  components: {
    BaseHeader: BaseHeader,
    BaseTable: BaseTable
  },
  filters: {
    statusFilter: function statusFilter(status, type) {
      var map = {
        // STP: { type: 'info', title: '停用' },
        USE: {
          type: 'success',
          title: '使用'
        }
      };
      return map[status] ? map[status][type] : status;
    },
    typeFilter: function typeFilter(status, type) {
      var map = {
        YAO: {
          type: 'info',
          title: '药品类'
        },
        PDU: {
          type: 'success',
          title: '物资类'
        }
      };
      return map[status] ? map[status][type] : status;
    },
    percFilter: function percFilter(status, type) {
      var map = {
        NO: {
          type: 'info',
          title: '否'
        },
        YES: {
          type: 'success',
          title: '是'
        }
      };
      return map[status] ? map[status][type] : status;
    }
  },
  props: {
    stoStock: {
      type: String
    },
    height: {
      required: true
    }
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      temp: {},
      actions: [// {title:'增加',icon:'el-icon-plus', type:'success',enable:function () {
        //     return true
        //   }},
        // {title:'修改',icon:'el-icon-edit', type:'primary',enable:function () {
        //     return true
        //   }},
        // {title:'刷新',icon:'el-icon-refresh', type:'info'},
        // {title:'确定',icon:'el-icon-folder-opened', type:'warning'}
      ],
      columns: [{
        title: '物品编号',
        field: 'stoZptcode',
        searchable: true,
        width: '100px'
      }, {
        title: '仓库名称',
        field: 'stoStockName',
        searchable: false,
        width: '100px'
      }, {
        title: '仓库名称',
        field: 'stoStock',
        notColumn: true,
        searchable: true,
        width: '100px',
        searchList: this.$store.state.common.storageList
      }, {
        title: '物品名称',
        field: 'zptName',
        searchable: true,
        width: '150px'
      }, {
        title: '拼音码查询',
        field: 'zptOldid',
        searchable: true,
        width: '100px'
      }, {
        title: '规格',
        field: 'zptOrder',
        width: '80px'
      }, {
        title: '价格',
        field: 'zptPducomsAmt',
        width: '80px'
      }, {
        title: '可用库存',
        field: 'stoStnum',
        width: '100px'
      }, {
        title: '实际库存',
        field: 'stoNum',
        width: '100px'
      }, {
        title: '单位',
        field: 'zptUnitName',
        width: '80px'
      }],
      listQuery: {
        stoStock: this.stoStock,
        gteStoNum: 0.01,
        pageNum: 1,
        pageSize: 50
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleEnable: function handleEnable() {
      return this.$refs.table ? this.$refs.table.multipleSelection == 0 : false;
    },
    handleAction: function handleAction(index) {
      switch (index) {
        case 0:
          this.handleAdd();
          break;

        case 1:
          this.handleSave();
          break;

        case 2:
          this.handleSearch();
          break;

        case 3:
          this.handleDone();
          break;
      }
    },
    handleSearch: function handleSearch() {
      this.listQuery.pageNum = 1;
      this.list = [];
      this.$setTableLoading(true);
      this.getList();
    },
    handleDone: function handleDone() {
      this.$emit('done', this.temp);
    },
    getList: function getList() {
      var _this = this;

      this.$setTableLoading(true);
      this.listQuery.stoStock = this.listQuery.stoStock || this.stoStock;
      stockPage(this.listQuery).then(function (response) {
        if (response.data && response.data.list) {
          _this.list = response.data.list;
          _this.total = response.data.total;

          _this.$setTableLoading(false);

          _this.temp = _this.list[0];
        }
      });
    },
    handleClick: function handleClick(row) {
      this.temp = row;
    },
    handleDblclick: function handleDblclick(row) {
      this.$emit('done', row);
    }
  }
};