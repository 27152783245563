import Layout from '@/layout';
var wzRoute = {
  path: '/wz',
  component: Layout,
  meta: {
    title: '外帐',
    icon: 'clipboard',
    keepAlive: true,
    roles: ['admin']
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/wz/revenue/index');
    },
    name: 'Wz',
    meta: {
      title: '营收报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'put',
    component: function component() {
      return import('@/views/wz/stock/put');
    },
    name: 'WzStockPut',
    meta: {
      title: '库存入库',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'out',
    component: function component() {
      return import('@/views/wz/stock/out');
    },
    name: 'WzStockOut',
    meta: {
      title: '库存出库',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'report',
    component: function component() {
      return import('@/views/wz/stock/report');
    },
    name: 'WzStockReport',
    meta: {
      title: '库存报表',
      keepAlive: true,
      roles: ['admin']
    }
  }]
};
console.log(process.env);
export default process.env.NODE_ENV === 'production' ? [] : wzRoute;