import request from '@/utils/request'; // 获取报单列表

export function callList(data) {
  return request({
    url: '/manager/call/list',
    method: 'post',
    data: data
  });
} // 新增报单

export function callAdd(data) {
  return request({
    url: '/manager/call/add',
    method: 'post',
    data: data
  });
} // 删除报单

export function callDelete(data) {
  return request({
    url: '/manager/call/delete',
    method: 'get',
    params: data
  });
} // 获取报单详情

export function callDetial(data) {
  return request({
    url: '/manager/call/getdetial',
    method: 'post',
    data: data
  });
} // 修改报单

export function callUpdate(data) {
  return request({
    url: '/manager/call/update',
    method: 'post',
    data: data
  });
} // 获取上门意向

export function getIntens(data) {
  return request({
    url: '/manager/call/getIntens',
    method: 'get',
    params: data
  });
} // 获取信息来源

export function getcaltype(data) {
  return request({
    url: '/manager/call/getcaltype',
    method: 'get',
    params: data
  });
} // 获取预约列表

export function fucList(data) {
  return request({
    url: '/manager/fuc/list',
    method: 'post',
    data: data
  });
} // 新增预约

export function addFuc(data) {
  return request({
    url: '/manager/fuc/add',
    method: 'post',
    data: data
  });
} // 更新预约

export function updateFuc(data) {
  return request({
    url: '/manager/fuc/update',
    method: 'post',
    data: data
  });
} // 取消预约

export function canFuc(data) {
  return request({
    url: '/manager/fuc/can',
    method: 'get',
    params: data
  });
} // 获取预约类型列表

export function fucType(data) {
  return request({
    url: '/manager/fuc/type',
    method: 'post',
    data: data
  });
} // 获取回访列表

export function rvList(data) {
  return request({
    url: '/manager/rv/list',
    method: 'post',
    data: data
  });
} // 获取回访类型列表

export function rvTypes(data) {
  return request({
    url: '/manager/rv/types',
    method: 'get',
    params: data
  });
} // 医院列表

export function eareList(data) {
  return request({
    url: '/manager/eare/page',
    method: 'post',
    data: data
  });
} // 医院列表

export function eareData(data) {
  return request({
    url: '/manager/eare/list',
    method: 'post',
    data: data
  });
} // 切换医院列表

export function getMangerEare() {
  return request({
    url: '/manager/user/getMangerEare',
    method: 'get'
  });
} // 切换医院

export function switchEare(params) {
  return request({
    url: '/manager/user/switchEare',
    method: 'get',
    params: params
  });
} // 获取医生时刻预约表

export function docfuch(data) {
  return request({
    url: '/manager/fuc/docfuch',
    method: 'post',
    params: data
  });
} // 获取分院预约表

export function earfuch(data) {
  return request({
    url: '/manager/fuc/earfuch',
    method: 'post',
    params: data
  });
} // 获取医生排班明细列表

export function fucStatReport(data) {
  return request({
    url: '/manager/docshift/list',
    method: 'post',
    data: data
  });
} // 获取分院预汇总

export function fucStatReportSum(data) {
  return request({
    url: '/manager/fuc/fucStatReport ',
    method: 'post',
    data: data
  });
} // 科室列表

export function departmentPage(data) {
  return request({
    url: '/manager/department/page',
    method: 'post',
    data: data
  });
} // 获取医生排班明细列表

export function docshiftList(data) {
  return request({
    url: '/manager/docshift/list',
    method: 'post',
    data: data
  });
} // 获取职员排班汇总表

export function docshiftAll(data) {
  return request({
    url: '/manager/docshift/all',
    method: 'get',
    params: data
  });
} // 获取排班单号

export function getShiftCode(data) {
  return request({
    url: '/manager/docshift/getShiftCode',
    method: 'get',
    params: data
  });
} // 新增医生排班

export function docshiftAdd(data) {
  return request({
    url: '/manager/docshift/add',
    method: 'post',
    data: data
  });
} // 新增医生排班(批量)

export function docshiftAdds(data) {
  return request({
    url: '/manager/docshift/adds',
    method: 'post',
    data: data
  });
} // 修改医生排班

export function docshiftUpdate(data) {
  return request({
    url: '/manager/docshift/update',
    method: 'post',
    data: data
  });
} // 删除医生排班

export function deleteShift(data) {
  return request({
    url: '/manager/docshift/deleteShift',
    method: 'get',
    params: data
  });
} // 获取客户的预约项目列表

export function pduList(data) {
  return request({
    url: '/manager/fuc/pduList',
    method: 'get',
    params: data
  });
} // 获取当前院的预约点配置情况

export function fucConfigList(data) {
  return request({
    url: '/manager/fuc/config',
    method: 'get',
    params: data
  });
} // 获取当前院的预约点配置情况

export function fucAddOrUpdateConfig(data) {
  return request({
    url: '/manager/fuc/addOrUpdateConfig',
    method: 'post',
    data: data
  });
} // 批量新增员工的预约时间段配置

export function addEmpConfigs(data) {
  return request({
    url: '/manager/fuc/addEmpConfigs',
    method: 'post',
    data: data
  });
} // 批量新增分院预约时间段配置

export function addConfigs(data) {
  return request({
    url: '/manager/fuc/addConfigs',
    method: 'post',
    data: data
  });
} // 获取当前院的预约点配置情况

export function fucDelconfig(data) {
  return request({
    url: '/manager/fuc/delconfig',
    method: 'get',
    params: data
  });
} // 获取员工预约点配置情况

export function fucEmpConfigList(data) {
  return request({
    url: '/manager/fuc/empconfig',
    method: 'get',
    params: data
  });
} // 新增修改员工预约情况

export function fucAddOrUpdateEmpConfig(data) {
  return request({
    url: '/manager/fuc/addOrUpdateEmpConfig',
    method: 'post',
    data: data
  });
} // 删除员工预约

export function fucDelEmpconfig(data) {
  return request({
    url: '/manager/fuc/delempconfig',
    method: 'get',
    params: data
  });
} // 配置模板下拉框列表

export function configTemplateList(data) {
  return request({
    url: '/manager/configTemplate/list',
    method: 'post',
    params: data
  });
} // 介绍人电话

export function getByMobile(data) {
  return request({
    url: '/manager/customer/getByMobile',
    method: 'get',
    params: data
  });
} // 预约界面--预约顾客统计

export function getCtmstat(data) {
  return request({
    url: '/manager/fuc/ctmstat',
    method: 'post',
    data: data
  });
} // 获取排班状态以及默认的排班时间

export function getShiftStatus(data) {
  return request({
    url: '/manager/docshift/getShiftStatus',
    method: 'get',
    params: data
  });
} // 新增Or修改排班状态

export function addOrUpdateShiftStatus(data) {
  return request({
    url: '/manager/docshift/addOrUpdateShiftStatus',
    method: 'post',
    data: data
  });
} // 删除获取排班状态

export function delShiftStatus(data) {
  return request({
    url: '/manager/docshift/delShiftStatus',
    method: 'get',
    params: data
  });
} //  预约统计

export function fucReport(data) {
  return request({
    url: '/manager/fuc/fucReport',
    method: 'post',
    data: data
  });
} // 预约统计导出

export function fucReportExport(data) {
  return request({
    url: '/manager/fuc/fucReportExport ',
    method: 'post',
    data: data
  });
} // 预约导出

export function fucListExport(data) {
  return request({
    url: '/manager/fuc/listExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 上门意向二级

export function simpleCalType(data) {
  return request({
    url: '/manager/call/getSimpleCalType',
    method: 'get',
    params: data
  });
} // 上传图片

export function uploadFiles(data) {
  return request({
    url: '/manger/upload/files',
    method: 'data',
    data: data
  });
} // 上传 base64 图片

export function uploadBase64(data) {
  return request({
    url: '/manger/upload/uploadBase64',
    method: 'post',
    data: data
  });
}