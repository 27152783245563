var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-container",
        style: { height: _vm.height + "px", overflow: "scroll" },
      },
      [
        _c(
          "search-form",
          {
            attrs: { columns: _vm.columns, listQuery: _vm.listQuery },
            on: { search: _vm.handleRefresh },
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "danger" },
                on: { click: _vm.handleRefresh },
              },
              [_vm._v("\n        刷新\n      ")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "success" },
                on: { click: _vm.handleSure },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-container",
          { staticClass: "fixed_table" },
          [
            _c(
              "el-main",
              [
                _c("base-table", {
                  ref: "table",
                  attrs: {
                    index: "",
                    temp: _vm.customer,
                    list: _vm.list,
                    total: _vm.total,
                    height: _vm.height - 120,
                    "list-query": _vm.listQuery,
                    columns: _vm.columns,
                    pagination: "",
                  },
                  on: { click: _vm.handleClick, pagination: _vm.getList },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }