var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.globalLoading,
          expression: "globalLoading",
        },
      ],
      staticClass: "app-wrapper",
      class: _vm.classObj,
    },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar", {
                attrs: { outList: _vm.outList },
                on: { logout: _vm.websocketclose },
              }),
              _vm._v(" "),
              _vm.needTagsView ? _c("tags-view") : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("app-main"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.outDept.showTip
        ? _c("el-alert", {
            staticClass: "position_top_arert",
            attrs: {
              effect: "dark",
              "show-icon": "",
              title: _vm.outDept.tip,
              type: "warning",
            },
            on: {
              close: function ($event) {
                _vm.outDept.showTip = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.checkDialog
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "处方药审核",
                visible: _vm.checkDialog,
                top: "50px",
                "close-on-click-modal": false,
                center: "",
                width: "90%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.checkDialog = $event
                },
              },
            },
            [_c("Check")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }