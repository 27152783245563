import request from '@/utils/request';
import exportRequest from '@/utils/exportRequest'; // 获取顾客列表

export function customerList(data, stillStates) {
  return request({
    url: '/manager/customer/list',
    method: 'post',
    data: data,
    isTrim: true,
    stillStates: stillStates
  });
} // 获取顾客列表

export function customerComlist(data, stillStates) {
  return request({
    url: '/manager/customer/comlist',
    method: 'post',
    data: data,
    isTrim: true,
    stillStates: stillStates
  });
} // 获取顾客列表 合计

export function queryCustomsSum(data) {
  return request({
    url: '/manager/customer/queryCustomsSum',
    method: 'post',
    data: data
  });
} // 客户资料保存

export function customerSave(data) {
  return request({
    url: '/manager/customer/save',
    method: 'post',
    data: data
  });
} // 根据名字查找顾客（前缀匹配）

export function getByName(data) {
  return request({
    url: '/manager/customer/getByName',
    method: 'post',
    data: data
  });
} // 顾客已消费列表

export function pageConsumed(data) {
  return request({
    url: '/manager/ctmPay/pageConsumed',
    method: 'post',
    data: data
  });
} // 顾客已订购项目列表

export function pageCustomerpay(data) {
  return request({
    url: '/manager/ctmPay/pageCustomerpay',
    method: 'post',
    data: data
  });
} // 消费项目历史表

export function ctmzptDPage(data) {
  return request({
    url: '/manager/ctmZpt/ctmzptDPage',
    method: 'post',
    data: data
  });
} // 消费项目历史表

export function queryAllConsumptionList(data) {
  return request({
    url: '/manager/ctmPay/queryAllConsumptionList',
    method: 'post',
    data: data
  });
} //查询消费列表回访弹出界面数据接口

export function queryRvAllConsumptionList(data) {
  return request({
    url: '/manager/ctmPay/rv/queryAllConsumptionList',
    method: 'post',
    data: data
  });
} // 消费项目 合计

export function queryConsumptionSum(data) {
  return request({
    url: '/manager/ctmPay/queryConsumptionSum',
    method: 'post',
    params: data
  });
} // 修改脱毛转化

export function updateTmzhlabel(data) {
  return request({
    url: '/manager/ctmCallPdu/updateTmzhlabel',
    method: 'get',
    params: data
  });
} // 客服已消费列表

export function queryPayConsumeList(data) {
  return request({
    url: '/manager/ctmPay/queryPayConsumeList',
    method: 'post',
    data: data
  });
} // 当前咨询项目

export function listCurrentPdu(data) {
  return request({
    url: '/manager/ctmCallPdu/listCurrentPdu',
    method: 'post',
    data: data
  });
} // 客户订购列表分页

export function pageVo(data) {
  return request({
    url: '/manager/ctmZpt/pageVo',
    method: 'post',
    data: data
  });
} // 客户满意度调查表-查询

export function adviceMemo(data) {
  return request({
    url: '/manager/advice/adviceMemo',
    method: 'post',
    params: data
  });
} // 投诉处理-查询

export function tsList(data) {
  return request({
    url: '/manager/toushu/tsList',
    method: 'post',
    data: data
  });
} // 投诉处理-查询

export function tsListExport(data) {
  return request({
    url: '/manager/toushu/tsListExport',
    responseType: 'blob',
    method: 'post',
    data: data
  });
} // 投诉处理-增加

export function tsListAdd(data) {
  return request({
    url: '/manager/toushurv/tsListAdd',
    method: 'post',
    data: data
  });
} // 投诉处理-更新(通过ID)

export function tsListUpd(data) {
  return request({
    url: '/manager/toushurv/tsListUpd',
    method: 'post',
    data: data
  });
}
export function tsListAddOrUpdate(data) {
  return request({
    url: '/manager/toushu/tsListAddOrUpdate',
    method: 'post',
    data: data
  });
} // 投诉处理-删除(通过ID)

export function tsListDel(data) {
  return request({
    url: '/manager/toushu/tsListDel',
    method: 'get',
    params: data
  });
} // 客户满意度-调查服务岗位接口

export function waitersGet(data) {
  return request({
    url: '/manager/advice/waitersGet',
    method: 'post',
    params: data
  });
} // 客户信息来源统计表

export function informationGet(data) {
  return request({
    url: '/manager/advice/informationGet',
    method: 'post',
    data: data
  });
} // 获取满意度调查项

export function getSatisfset(data) {
  return request({
    url: '/manager/satisfset/getSatisfset',
    method: 'post',
    data: data
  });
} // 客户满意度调查表-增加

export function adviceMemoAdd(data) {
  return request({
    url: '/manager/advice/adviceMemoAdd',
    method: 'post',
    data: data
  });
} // 客户满意度调查表-更新

export function adviceMemoUpd(data) {
  return request({
    url: '/manager/advice/adviceMemoUpd',
    method: 'post',
    data: data
  });
} // 客户满意度调查表-删除

export function adviceMemoDel(data) {
  return request({
    url: '/manager/advice/adviceMemoDel',
    method: 'post',
    data: data
  });
} // 客户积分情况列表

export function intergetPage(data) {
  return request({
    url: '/manager/interget/page',
    method: 'post',
    data: data
  });
} // 客户积分情况使用列表

export function integralPage(data) {
  return request({
    url: '/manager/integral/page',
    method: 'post',
    data: data
  });
} // 根据顾客主键ID查找顾客

export function getCustomerDetailByCode(data) {
  return request({
    url: "/manager/customer/getByCode",
    method: 'get',
    params: data
  });
} // 根据顾客主键ID查找顾客

export function getCustomerDetail(data) {
  return request({
    url: "/manager/customer/".concat(data.id),
    method: 'get'
  });
} // 新增客户照片

export function addPhoto(data) {
  return request({
    url: '/manager/customer/addPhoto',
    method: 'post',
    data: data
  });
} // 删除客户照片

export function delPhoto(data) {
  return request({
    url: '/manager/customer/delPhoto',
    method: 'post',
    params: data
  });
} // 获取顾客的相片列表

export function getPhotos(data) {
  return request({
    url: '/manager/customer/getPhotos',
    method: 'get',
    params: data
  });
} // 清空介绍人

export function clearnJs(data) {
  return request({
    url: '/manager/customer/clearnJs',
    method: 'get',
    params: data
  });
} // 根据code查找顾客

export function getByCode(data) {
  return request({
    url: '/manager/customer/getByCode',
    method: 'get',
    params: data
  });
} // 顾客消费明细表

export function queryConsumptionList(data) {
  return request({
    url: '/manager/ctmPay/queryConsumptionList',
    method: 'post',
    data: data
  });
} // 顾客消耗明细表

export function queryConsumeList(data) {
  return request({
    url: '/manager/ctmPay/queryConsumeList',
    method: 'post',
    data: data
  });
} // 获取顾客上门次数

export function getSmTimes(data) {
  return request({
    url: '/manager/customer/getSmTimes',
    method: 'get',
    params: data
  });
} // 搜索附近的客户

export function getNear(data) {
  return request({
    url: '/manager/customer/getNear',
    method: 'get',
    params: data
  });
} // 搜索附近的客户导出

export function getNearlistExport(data) {
  return exportRequest({
    url: '/manager/customer/getNearlistExport',
    method: 'get',
    params: data
  });
} // 获取顾客重要程度列表

export function customerImportantLevel() {
  return request({
    url: '/manager/customerImportantLevel/list',
    method: 'get'
  });
} // 黄金客户保有

export function queryGoldCustomerKeepReport(data) {
  return request({
    url: '/manager/ctmPay/report/queryGoldCustomerKeepReport',
    method: 'post',
    data: data
  });
} // 老带新

export function queryLdxReport(data) {
  return request({
    url: '/manager/ctmPay/report/queryLdxReport',
    method: 'post',
    data: data
  });
} // 新客留存

export function queryNewCustomerKeepReport(data) {
  return request({
    url: '/manager/ctmPay/report/queryNewCustomerKeepReport',
    method: 'post',
    data: data
  });
}
export function queryNewCustomerKeepReportExport(data) {
  return exportRequest({
    url: '/manager/ctmPay/report/queryNewCustomerKeepReportExport',
    method: 'post',
    data: data
  });
}
export function queryOldCustomerKeepReportExport(data) {
  return exportRequest({
    url: '/manager/ctmPay/report/queryOldCustomerKeepReportExport',
    method: 'post',
    data: data
  });
}
export function queryGoldCustomerKeepReportExport(data) {
  return exportRequest({
    url: '/manager/ctmPay/report/queryGoldCustomerKeepReportExport',
    method: 'post',
    data: data
  });
}
export function queryPyjsReportExport(data) {
  return exportRequest({
    url: '/manager/ctmPay/report/queryPyjsReportExport',
    method: 'post',
    data: data
  });
}
export function queryLdxReportExport(data) {
  return exportRequest({
    url: '/manager/ctmPay/report/queryLdxReportExport',
    method: 'post',
    data: data
  });
} // 老客保有

export function queryOldCustomerKeepReport(data) {
  return request({
    url: '/manager/ctmPay/report/queryOldCustomerKeepReport',
    method: 'post',
    data: data
  });
} // 朋友介绍

export function queryPyjsReport(data) {
  return request({
    url: '/manager/ctmPay/report/queryPyjsReport',
    method: 'post',
    data: data
  });
} // 价值分析列表

export function valueAnalysisList(data) {
  return request({
    url: '/manager/customer/valueAnalysisList',
    method: 'post',
    data: data
  });
} // 价值分析客户列表

export function valueAnalysisCustomerList(data) {
  return request({
    url: '/manager/customer/valueAnalysisCustomerList',
    method: 'post',
    data: data
  });
} // 赠送记录

export function giveflow(data) {
  return request({
    url: '/manager/giveflow/queryPage',
    method: 'post',
    data: data
  });
} // 客户RFM数据列表

export function getRfmList(data) {
  return request({
    url: "/manager/customer/rfmList/".concat(data.customerId),
    method: 'get',
    params: data
  });
} // 客户RFM折线图

export function getMyScore(data) {
  return request({
    url: "/manager/rfm/getMyScore",
    method: 'get',
    params: data
  });
} // 价值分析比较

export function cvaCompare(data) {
  return request({
    url: 'manager/customer/cvaCompare',
    method: 'post',
    data: data
  });
} // 价值分析比较客户列表

export function cvaCompareCustomerList(data) {
  return request({
    url: '/manager/customer/cvaCompareCustomerList',
    method: 'post',
    data: data
  });
} // 价值分析统计

export function cvaStatistics(data) {
  return request({
    url: '/manager/customer/cvaStatistics',
    method: 'post',
    data: data
  });
} // 价值分析统计客户列表

export function cvaStatisticsCustomerList(data) {
  return request({
    url: '/manager/customer/cvaStatisticsCustomerList',
    method: 'post',
    data: data
  });
} // 获取积分类型列表

export function integralTypes(data) {
  return request({
    url: '/manager/integral/types',
    method: 'post',
    data: data
  });
} // 获取积分清单

export function intergetList(data) {
  return request({
    url: '/manager/interget/getList',
    method: 'post',
    data: data
  });
} // 券额记录

export function queryUsecoupPage(data) {
  return request({
    url: '/manager/usecoup/queryUsecoupPage',
    method: 'post',
    data: data
  });
} // 券额记录 导出

export function getListExport(data) {
  return request({
    url: '/manager/interget/getListExport',
    method: 'post',
    // responseType: 'blob',
    data: data
  });
} // 券额,明细记录-导出

export function coupList(data) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'getList';
  return request({
    url: "/manager/coup/".concat(type),
    method: 'post',
    responseType: type !== 'getList' ? 'blob' : null,
    data: data
  });
}
export function bePartner(data) {
  return request({
    url: '/manager/customer/setHh',
    method: 'get',
    params: data
  });
}
export function queryConsumeProduct(data) {
  return request({
    url: '/manager/ctmPay/queryConsumeProduct',
    method: 'get',
    params: data
  });
}
export function addOrUpdateCustomerPhoto(data) {
  return request({
    url: '/manager/customer/photo/addOrUpdate',
    method: 'post',
    data: data
  });
}
export function getPhotoTypeList(data) {
  return request({
    url: '/manager/customer/photo/getTypeList',
    method: 'get',
    params: data
  });
}
export function getPhotoByTypeId(data) {
  return request({
    url: '/manager/customer/photo/getByTypeId',
    method: 'get',
    params: data
  });
}
export function getCusFqStatus(data) {
  return request({
    url: '/manager/cusFqStatus/getList',
    method: 'get',
    params: data
  });
}
export function addOrUpdatePhotoCustomer(data) {
  return request({
    url: '/manager/customer/photo/addOrUpdatePhoto',
    method: 'post',
    data: data
  });
}
export function delCustomerPhoto(data) {
  return request({
    url: '/manager/customer/photo/delPhoto',
    method: 'post',
    params: data
  });
}
export function setHeadImg(data) {
  return request({
    url: '/manager/customer/photo/setHeadImg',
    method: 'get',
    params: data
  });
} // 集团客户

export function customerAllSearch(data) {
  return request({
    url: '/manager/customer/allSearch',
    method: 'post',
    data: data
  });
} // 集团客户-分页

export function customerallSearchPage(data) {
  return request({
    url: 'manager/customer/allSearchPage',
    method: 'post',
    data: data
  });
} // 集团客户-删除

export function deleteCustomer(data) {
  return request({
    url: '/manager/customer/deleteCustomer',
    method: 'get',
    params: data
  });
} // 顾客合并

export function customerMerger(data) {
  return request({
    url: '/manager/customer/merger',
    method: 'get',
    params: data
  });
} // 客户列表导出

export function customerListExport(data) {
  return request({
    url: '/manager/customer/listExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 合伙人删除

export function customerCancelHh(data) {
  return request({
    url: '/manager/customer/cancelHh',
    method: 'get',
    params: data
  });
} // 取消合伙人

export function cancelRelHh(data) {
  return request({
    url: '/manager/customer/cancelRelHh',
    method: 'get',
    params: data
  });
} // 合伙人删除

export function getLast(data) {
  return request({
    url: '/manager/op/getLast',
    method: 'get',
    params: data
  });
} // 激活

export function activUpdate(data) {
  return request({
    url: '/manager/customer/activ/update',
    method: 'get',
    params: data
  });
} // 批量更新

export function activBatch(data) {
  return request({
    url: '/manager/customer/activ/batch',
    method: 'post',
    data: data
  });
} // 关联人列表

export function hhRelList(data) {
  return request({
    url: '/manager/customer/hhRelList',
    method: 'post',
    data: data
  });
} // 关联人列表导出

export function hhRelListExport(data) {
  return request({
    url: '/manager/customer/hhRelListExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 批量回访

export function batchAddByCusList(data, str) {
  return request({
    url: "/manager/rv/".concat(str),
    method: 'post',
    data: data
  });
} // 批量回访

export function batchAddByList(data, str) {
  return request({
    url: "/manager/ctmPay/rv/batchAddByList",
    method: 'post',
    data: data
  });
} // 批量短信

export function batchSendCus(data, str) {
  return request({
    url: "/manager/sms/batchSendCus",
    method: 'post',
    data: data
  });
} // 余额差异明细

export function getCtmDisPage(data) {
  return request({
    url: "/manager/ctmPay/getCtmDisPage",
    method: 'post',
    data: data
  });
} // 余额差异明细 汇总

export function getCtmDisSum(data) {
  return request({
    url: "/manager/ctmPay/getCtmDisSum",
    method: 'post',
    data: data
  });
}