import "core-js/modules/es6.function.name";
import _toConsumableArray from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.array.find-index";
import { employeeSimpleList } from '@/api/employee';
import { eareData, getIntens, departmentPage, fucType, simpleCalType } from '@/api';
import { paymentList as _paymentList } from '@/api/cash';
import { waitersGet, customerImportantLevel } from '@/api/customer';
import { rvTypes } from '@/api/returnVisit';
import { vendorPage, storagePage } from '@/api/stock';
import { getCusGrade } from '@/api/rfm';
import { positionPage, labelPage, symptoms, customerLevelList, customerLevelListManual, activConfigList } from '@/api/manage';
import { getEmployees, getEareList, getItensList, setEmployees, getSymptomList, getRvtypeList, getVendorList, getStorageList, getWaiterList, getDepartmentList, getDepartmentEmployList, getPaymentList, getPostionList, getFucTypeList, getServices, setServices, getZptPdulabel, setZptPdulabel, getZptPdulabel2, getDepartmentListAll, getCaltypeList1, setCaltypeList1, getSimpleCalType, setSimpleCalType, getCustomerLevel, getCustomerLevelManual, getCustomerImportant, getPreProjectList, setPreProjectList } from '@/utils/common';
import { getTreeList } from "@/api/consult";
import { getEarId } from '@/utils/auth';
import { sortListUseRemoveUpper } from '@/utils';
var state = {
  employees: [],
  employeesAll: [],
  eareList: [],
  departmentList: [],
  departmentListAll: [],
  departmentEmployList: [],
  paymentList: [],
  intensList: [],
  intensListLevel2: [],
  fucTypeList: [],
  rvTypes: [],
  storageList: [],
  vendorList: [],
  waiterList: [],
  positionList: [],
  services: [],
  symptomListChi: [],
  symptomList: [],
  zptPdulabel2List: [],
  zptPdulabelList: [],
  simpleCalType: [],
  caltypeList: [],
  businessInfo: {},
  customerLevel: [],
  customerLevelManual: [],
  customerImportant: [],
  activeList: [],
  vipLevelList: [],
  earId: null,
  mangerEareId: null,
  mangerEareFlag: false,
  preProjectList: []
};
var mutations = {
  SET_EARID: function SET_EARID(state, earId) {
    state.earId = earId;
  },
  SET_EMPLOYEES: function SET_EMPLOYEES(state, employees) {
    state.employees = employees;
  },
  SET_EMPLOYEES_ALL: function SET_EMPLOYEES_ALL(state, employeesAll) {
    state.employeesAll = employeesAll;
  },
  SET_CALTYPELIST: function SET_CALTYPELIST(state, caltypeList) {
    state.caltypeList = caltypeList;
  },
  SET_LEVELLIST: function SET_LEVELLIST(state, customerLevel) {
    state.customerLevel = customerLevel;
  },
  SET_LEVELLIST_MANUAL: function SET_LEVELLIST_MANUAL(state, customerLevelManual) {
    state.customerLevelManual = customerLevelManual;
  },
  SET_IMPORTANT: function SET_IMPORTANT(state, customerImportant) {
    state.customerImportant = customerImportant;
  },
  SET_SIMPLECALTYPE: function SET_SIMPLECALTYPE(state, simpleCalType) {
    state.simpleCalType = simpleCalType;
  },
  SET_BUSINESS_INFO: function SET_BUSINESS_INFO(state, data) {
    state.businessInfo[data.key] = data.value;
  },
  SET_SERVICES: function SET_SERVICES(state, services) {
    state.services = services;
  },
  SET_EARELIST: function SET_EARELIST(state, eareList) {
    state.eareList = eareList;
  },
  SET_PAYMENTLIST: function SET_PAYMENTLIST(state, paymentList) {
    state.paymentList = paymentList;
  },
  SET_DEPARTMENTLIST: function SET_DEPARTMENTLIST(state, departmentList) {
    state.departmentList = departmentList;
  },
  SET_DEPARTMENTLISTAll: function SET_DEPARTMENTLISTAll(state, departmentListAll) {
    state.departmentListAll = departmentListAll;
  },
  SET_DEPARTMENTEMPLOYLIST: function SET_DEPARTMENTEMPLOYLIST(state, departmentEmployList) {
    state.departmentEmployList = departmentEmployList;
  },
  SET_INTENSLIST: function SET_INTENSLIST(state, intensList) {
    state.intensList = intensList;
  },
  SET_RVTYPELIST: function SET_RVTYPELIST(state, rvTypes) {
    state.rvTypes = rvTypes;
  },
  SET_STORAGELIST: function SET_STORAGELIST(state, storageList) {
    state.storageList = storageList;
  },
  SET_VENDORLIST: function SET_VENDORLIST(state, vendorList) {
    state.vendorList = vendorList;
  },
  SET_WAITERLIST: function SET_WAITERLIST(state, waiterList) {
    state.waiterList = waiterList;
  },
  SET_POSITIONLIST: function SET_POSITIONLIST(state, positionList) {
    state.positionList = positionList;
  },
  SET_FUCTYPELIST: function SET_FUCTYPELIST(state, fucTypeList) {
    state.fucTypeList = fucTypeList;
  },
  SET_SYMPTOMLIST_CHI: function SET_SYMPTOMLIST_CHI(state, symptomList) {
    state.symptomListChi = symptomList;
  },
  SET_SYMPTOMLIST: function SET_SYMPTOMLIST(state, symptomList) {
    state.symptomList = symptomList;
  },
  SET_INTENSLIST_LEVEL2: function SET_INTENSLIST_LEVEL2(state, list) {
    state.intensListLevel2 = list;
  },
  // zptPdulabelList
  SET_ZPT_PDULABEL: function SET_ZPT_PDULABEL(state, list) {
    state.zptPdulabelList = list;
  },
  SET_ZPT_PDULABEL2: function SET_ZPT_PDULABEL2(state, list) {
    state.zptPdulabel2List = list;
  },
  SET_ACTIVE_list: function SET_ACTIVE_list(state, activeList) {
    state.activeList = activeList;
  },
  SET_VIPLEVEL_LIST: function SET_VIPLEVEL_LIST(state, vipLevelList) {
    state.vipLevelList = vipLevelList;
  },
  SET_MANGER_EAR_ID: function SET_MANGER_EAR_ID(state, id) {
    state.mangerEareId = id;
  },
  SET_MANGER_EAR_FLAG: function SET_MANGER_EAR_FLAG(state, bol) {
    state.mangerEareFlag = bol;
  },
  SET_PREPROJECTLIST: function SET_PREPROJECTLIST(state, preProjectList) {
    state.preProjectList = preProjectList;
  }
};
var actions = {
  // user login
  businessInfo: function businessInfo(_ref, data) {
    var commit = _ref.commit;
    commit('SET_BUSINESS_INFO', data);
  },
  // user login
  employeeList: function employeeList(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      // var data = { earId: getEarId() }
      employeeSimpleList({
        isAllStatus: 'Y'
      }).then(function (response) {
        var data = response.data;
        var list = data.list;
        var employees = list.map(function (v) {
          return {
            title: v.empName + '--' + v.empCode + '--' + v.earName,
            field: v.empCode,
            postTypes: v.postTypes,
            empStatus: v.empStatus,
            disabled: v.empStatus !== 'CFM',
            earName: v.earName,
            earId: v.earId,
            pstName: v.pstName,
            empDptCode: v.empDptCode
          };
        });
        var index = employees.findIndex(function (v) {
          return v.field === 'Y000155';
        });
        commit('SET_EMPLOYEES', index === -1 ? [{
          title: '内部消费--Y000155--集团',
          field: 'Y000155',
          postTypes: [],
          empStatus: 'CFM',
          disabled: false,
          earName: '集团',
          earId: '000'
        }].concat(_toConsumableArray(employees)) : employees);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
      employeeSimpleList({
        isAllEmp: 'Y',
        isAllStatus: 'Y'
      }).then(function (response) {
        var data = response.data;
        var list = data.list;
        var employees = [];
        employees = list.map(function (v) {
          return {
            title: v.empName + '--' + v.empCode + '--' + v.earName,
            field: v.empCode,
            postTypes: v.postTypes,
            empStatus: v.empStatus,
            earName: v.earName,
            earId: v.earId,
            pstName: v.pstName,
            empDptCode: v.empDptCode
          };
        });
        commit('SET_EMPLOYEES_ALL', employees);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // mangerEareId({ commit }, data) {
  //   commit('SET_MANGER_EAR_ID', data);
  // },
  simpleCalTypeList: function simpleCalTypeList(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      // var data = { earId: getEarId() }
      simpleCalType().then(function (response) {
        var data = response.data;
        var caltypeList = [];
        data = data.map(function (v) {
          return {
            title: v.eptName,
            field: v.id,
            eptCode: v.eptCode,
            disabled: v.eptStatus === 'STP',
            eptStatus: v.eptStatus
          };
        });
        caltypeList = [].concat(_toConsumableArray(data.filter(function (v) {
          return v.eptStatus !== 'STP';
        })), _toConsumableArray(data.filter(function (v) {
          return v.eptStatus === 'STP';
        })));
        setCaltypeList1(caltypeList);
        commit('SET_CALTYPELIST', caltypeList);
        setSimpleCalType(data);
        commit('SET_SIMPLECALTYPE', response.data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getVipLevelList: function getVipLevelList(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      getCusGrade().then(function (response) {
        var data = response.data;
        var list = data.map(function (v) {
          return {
            title: v.levelName,
            field: v.id
          };
        }); // setDepartmentList(departmentList)

        commit('SET_VIPLEVEL_LIST', list);
        resolve();
      });
    });
  },
  departmentList: function departmentList(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000
      };
      departmentPage(data).then(function (response) {
        var data = response.data;
        var list = data.list;
        var departmentList = [];

        for (var i in list) {
          departmentList.push({
            title: list[i].dptName,
            field: list[i].dptCode
          });
        } // setDepartmentList(departmentList)


        commit('SET_DEPARTMENTLIST', departmentList);
        resolve();
      });
    });
  },
  levelList: function levelList(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve) {
      customerLevelList().then(function (response) {
        var data = response.data;
        var list = data; // console.log('list', list);

        var levelList = [];

        for (var i in list) {
          levelList.push({
            title: list[i].name,
            field: list[i].level
          });
        } // setCustomerLevel(levelList)


        commit('SET_LEVELLIST', levelList);
        resolve();
      });
    });
  },
  levelListManual: function levelListManual(_ref7) {
    var commit = _ref7.commit;
    return new Promise(function (resolve) {
      customerLevelListManual().then(function (response) {
        var data = response.data;
        var list = data; // setCustomerLevelManual(list)

        commit('SET_LEVELLIST_MANUAL', list);
        resolve();
      });
    });
  },
  getActiveList: function getActiveList(_ref8) {
    var commit = _ref8.commit;
    return new Promise(function (resolve) {
      activConfigList({
        activStatus: 'USE'
      }).then(function (response) {
        if (response && response.data) {
          var list = response.data.map(function (v) {
            return {
              title: v.activName,
              field: v.id
            };
          });
          commit('SET_ACTIVE_list', list);
          resolve();
        }
      });
    });
  },
  importantList: function importantList(_ref9) {
    var commit = _ref9.commit;
    return new Promise(function (resolve) {
      customerImportantLevel().then(function (response) {
        var data = response.data;
        var list = data; // setCustomerImportant(list)

        commit('SET_IMPORTANT', list);
        resolve();
      });
    });
  },
  departmentListAll: function departmentListAll(_ref10) {
    var commit = _ref10.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000,
        dptStatus: 'All'
      };
      departmentPage(data).then(function (response) {
        var data = response.data;
        var list = data.list;
        var departmentList = [].concat(_toConsumableArray(list.filter(function (v) {
          return v.dptStatus !== 'STP';
        }).map(function (k) {
          return {
            title: k.dptName,
            field: k.dptCode,
            status: k.dptStatus,
            disabled: k.dptStatus === 'STP'
          };
        })), _toConsumableArray(list.filter(function (v) {
          return v.dptStatus === 'STP';
        }).map(function (k) {
          return {
            title: k.dptName,
            field: k.dptCode,
            status: k.dptStatus,
            disabled: k.dptStatus === 'STP'
          };
        }))); // setDepartmentListAll(departmentList)

        commit('SET_DEPARTMENTLISTAll', departmentList);
        resolve();
      });
    });
  },
  departmentEmployList: function departmentEmployList(_ref11) {
    var commit = _ref11.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000,
        isProjectDpt: 0,
        dptStatus: 'All'
      };
      departmentPage(data).then(function (response) {
        var data = response.data;
        var list = data.list.map(function (v) {
          return {
            title: v.dptName,
            field: v.dptCode,
            status: v.dptStatus,
            disabled: v.dptStatus !== 'USE',
            dptStatus: v.dptStatus
          };
        });
        var departmentEmployList = sortListUseRemoveUpper(list, 'dptStatus'); // setDepartmentEmployList(departmentEmployList)

        commit('SET_DEPARTMENTEMPLOYLIST', departmentEmployList);
        resolve();
      });
    });
  },
  symptomList: function symptomList(_ref12) {
    var commit = _ref12.commit;
    return new Promise(function (resolve) {
      var data = {
        level: 2,
        status: 'all'
      };
      symptoms(data).then(function (response) {
        var data = response.data;
        var list = data.list;
        var symptomListChi = [];

        for (var i in list) {
          symptomListChi.push({
            title: list[i].symptom,
            status: list[i].status,
            disabled: list[i].status !== 'USE',
            field: list[i].symptom,
            id: list[i].id,
            pId: list[i].parentId
          });
        } // setSymptomList(symptomListChi)


        commit('SET_SYMPTOMLIST_CHI', symptomListChi);
      });
      var data2 = {
        level: 1,
        status: 'all'
      };
      symptoms(data2).then(function (response) {
        var data = response.data;
        var list = data.list;
        var symptomList = [];

        for (var i in list) {
          symptomList.push({
            title: list[i].symptom,
            field: list[i].id,
            disabled: list[i].status !== 'USE',
            status: list[i].status
          });
        } // setSymptomList(symptomList)


        commit('SET_SYMPTOMLIST', symptomList);
      });
      resolve();
    });
  },
  paymentList: function paymentList(_ref13) {
    var commit = _ref13.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000
      };

      _paymentList(data).then(function (response) {
        var data = response.data;
        var list = data;
        var paymentList = [];

        for (var i in list) {
          if (i < 2) {
            paymentList.push({
              title: list[i].payName,
              field: list[i].payCode
            });
          }
        } // setPaymentList(paymentList)


        commit('SET_PAYMENTLIST', paymentList);
        resolve();
      });
    });
  },
  eareList: function eareList(_ref14) {
    var commit = _ref14.commit;
    return new Promise(function (resolve) {
      // var data = { earId: getEarId() };
      eareData({
        isAll: 1
      }).then(function (response) {
        var data = response.data;
        var list = data;
        var eareList = [];

        for (var i in list) {
          eareList.push({
            title: list[i].earName,
            field: list[i].earCode
          });
        } // setEareList(eareList)


        commit('SET_EARELIST', eareList);
        resolve();
      });
    });
  },
  intensList: function intensList(_ref15, name) {
    var commit = _ref15.commit;
    console.log('name', name);
    return new Promise(function (resolve) {
      var data = {
        earId: getEarId(),
        status: 'all'
      };
      if (name !== undefined) data.parentNames = name;
      getIntens(data).then(function (response) {
        var data = response.data;
        var list = data.map(function (v) {
          return {
            title: v.intentionDes,
            field: v.intentionDes,
            desc: v.referenceSymptom,
            parentId: v.parentId,
            parentIntentionDes: v.parentIntentionDes,
            status: v.status,
            disabled: v.status === 'STP'
          };
        });
        var intensList = sortListUseRemoveUpper(list, 'status');

        if (name !== undefined) {
          commit('SET_INTENSLIST_LEVEL2', intensList);
        } else {
          // setItensList(intensList)
          commit('SET_INTENSLIST', intensList);
        }

        resolve();
      });
    });
  },
  rvtypeList: function rvtypeList(_ref16) {
    var commit = _ref16.commit;
    return new Promise(function (resolve) {
      var data = {
        earId: getEarId(),
        status: 'all'
      };
      rvTypes(data).then(function (response) {
        var data = response.data;
        var list = data;
        var rvTypes = [].concat(_toConsumableArray(list.filter(function (v) {
          return v.rvtStatus === 'USE';
        }).map(function (k) {
          return {
            title: k.rvtType,
            field: k.rvtType,
            status: k.rvtStatus,
            disabled: k.rvtStatus !== 'USE'
          };
        })), _toConsumableArray(list.filter(function (v) {
          return v.rvtStatus !== 'USE';
        }).map(function (k) {
          return {
            title: k.rvtType,
            field: k.rvtType,
            status: k.rvtStatus,
            disabled: k.rvtStatus !== 'USE'
          };
        }))); // for (const i in list) {
        //   rvTypes.push({ title: list[i].rvtType, field: list[i].rvtType });
        // }
        // setRvtypeList(rvTypes)

        commit('SET_RVTYPELIST', rvTypes);
        resolve();
      });
    });
  },
  vendorList: function vendorList(_ref17) {
    var commit = _ref17.commit;
    return new Promise(function (resolve) {
      var data = {
        earId: getEarId(),
        pageNum: 1,
        pageSize: 1000,
        cmStatus: '所有'
      };
      vendorPage(data).then(function (response) {
        var data = response.data;
        var list = data.list;
        var vendorList = list.map(function (v) {
          return {
            title: v.cmName,
            field: v.cmCode,
            disabled: v.cmStatus === '停用'
          };
        }); // for (const i in list) {
        //   vendorList.push({ title: list[i].cmName, field: list[i].cmCode });
        // }
        // setVendorList(vendorList)

        commit('SET_VENDORLIST', vendorList);
        resolve();
      });
    });
  },
  waitersList: function waitersList(_ref18) {
    var commit = _ref18.commit;
    return new Promise(function (resolve) {
      var data = {
        earId: getEarId(),
        pageNum: 1,
        pageSize: 1000
      };
      waitersGet(data).then(function (response) {
        var data = response.data;
        var list = data;
        var waitersList = [];

        for (var i in list) {
          waitersList.push({
            title: list[i].name,
            field: list[i].nameId
          });
        } // setWaiterList(waitersList)


        commit('SET_WAITERLIST', waitersList);
        resolve();
      });
    });
  },
  storageList: function storageList(_ref19) {
    var commit = _ref19.commit;
    return new Promise(function (resolve) {
      var data = {
        earId: getEarId(),
        pageNum: 1,
        pageSize: 1000,
        strName: ''
      };
      storagePage(data).then(function (response) {
        var data = response.data;
        var list = data.list;
        var storageList = [];

        for (var i in list) {
          storageList.push({
            title: list[i].strName,
            field: list[i].strCode
          });
        } // setStorageList(storageList)


        commit('SET_STORAGELIST', storageList);
        resolve();
      });
    });
  },
  positionList: function positionList(_ref20) {
    var commit = _ref20.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000,
        isProjectDpt: 0
      };
      positionPage(data).then(function (response) {
        var data = response.data;
        var list = data.list;
        var positionList = [];

        for (var i in list) {
          positionList.push({
            title: list[i].pstName,
            field: list[i].pstCode,
            depCode: list[i].dptCode
          });
        } // setPostionList(positionList)


        commit('SET_POSITIONLIST', positionList);
        resolve();
      });
    });
  },
  zptPdulabelList: function zptPdulabelList(_ref21) {
    var commit = _ref21.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000,
        labelType: 1
      };
      labelPage(data).then(function (response) {
        var data = response.data;

        if (data.list) {
          var list = [];

          for (var i in data.list) {
            list.push({
              title: data.list[i].labelName,
              field: data.list[i].id
            });
          }

          setZptPdulabel(list);
          commit('SET_ZPT_PDULABEL', list);
        }

        resolve();
      });
    });
  },
  zptPdulabel2List: function zptPdulabel2List(_ref22) {
    var commit = _ref22.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000,
        labelType: 2
      };
      labelPage(data).then(function (response) {
        var data = response.data;

        if (data.list) {
          var list = [];

          for (var i in data.list) {
            list.push({
              title: data.list[i].labelName,
              field: data.list[i].id
            });
          } // setZptPdulabel2(list)


          commit('SET_ZPT_PDULABEL2', list);
        }

        resolve();
      });
    });
  },
  preProjectList: function preProjectList(_ref23) {
    var commit = _ref23.commit;
    return new Promise(function (resolve) {
      getTreeList().then(function (response) {
        var data = response.data;
        var list = data;
        commit('SET_PREPROJECTLIST', list);
        resolve();
      });
    });
  },
  fucTypeList: function fucTypeList(_ref24) {
    var commit = _ref24.commit;
    return new Promise(function (resolve) {
      var data = {
        pageNum: 1,
        pageSize: 1000
      };
      fucType(data).then(function (response) {
        var data = response.data;
        var list = data.map(function (v) {
          return {
            title: v.ftyName,
            field: v.ftyCode,
            status: v.ftyStatus,
            disabled: v.ftyStatus !== 'USE'
          };
        });
        var fucTypeList = sortListUseRemoveUpper(list, 'status'); // setFucTypeList(fucTypeList)

        commit('SET_FUCTYPELIST', fucTypeList);
        resolve();
      });
    });
  },
  fucServices: function fucServices(_ref25, data) {
    var commit = _ref25.commit;
    data = data || [];
    commit('SET_SERVICES', data);
    setServices(data);
  },
  getCommon: function getCommon(_ref26) {
    var commit = _ref26.commit;
    return new Promise(function (resolve) {
      commit('SET_EMPLOYEES', getEmployees());
      commit('SET_CALTYPELIST', getCaltypeList1());
      commit('SET_LEVELLIST', getCustomerLevel());
      commit('SET_IMPORTANT', getCustomerImportant());
      commit('SET_LEVELLIST_MANUAL', getCustomerLevelManual());
      commit('SET_SIMPLECALTYPE', getSimpleCalType());
      commit('SET_EARELIST', getEareList());
      commit('SET_DEPARTMENTLIST', getDepartmentList());
      commit('SET_DEPARTMENTLISTAll', getDepartmentListAll());
      commit('SET_DEPARTMENTEMPLOYLIST', getDepartmentEmployList());
      commit('SET_PAYMENTLIST', getPaymentList());
      commit('SET_STORAGELIST', getStorageList());
      commit('SET_INTENSLIST', getItensList());
      commit('SET_RVTYPELIST', getRvtypeList());
      commit('SET_VENDORLIST', getVendorList());
      commit('SET_WAITERLIST', getWaiterList());
      commit('SET_POSITIONLIST', getPostionList());
      commit('SET_FUCTYPELIST', getFucTypeList());
      commit('SET_SYMPTOMLIST', getSymptomList());
      commit('SET_SERVICES', getServices());
      commit('SET_ZPT_PDULABEL', getZptPdulabel());
      commit('SET_ZPT_PDULABEL2', getZptPdulabel2());
      commit('SET_PREPROJECTLIST', getPreProjectList());
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};