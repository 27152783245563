export var KcColumn = [{
  title: '物料组名',
  field: 'zptName',
  searchable: true,
  width: '150px'
}, {
  title: '物料组号',
  field: 'stoZptcode',
  searchable: true,
  width: '150px'
}, {
  title: '仓库名称',
  field: 'stoStockName',
  searchable: false,
  width: '150px'
}, // {title:'仓库名称',field:'stoStock',notColumn: true,searchable:true,width:'100px',  searchList:this.$store.state.common.storageList},
// { title: '拼音码查询', field: 'zptOldid', searchable: true, width: '100px' },
{
  title: '规格',
  field: 'zptOrder',
  width: '80px'
}, {
  title: '单位',
  field: 'zptUnitName',
  width: '80px'
}, // { title: '零售价', field: 'zptPrice', width: '80px' },
{
  title: '可用库存',
  field: 'stoStnum',
  width: '100px'
}, {
  title: '实际库存',
  field: 'stoNum',
  width: '100px'
}];