var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.update
    ? _c(
        "div",
        { staticStyle: { display: "inline" } },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: _vm.placement ? _vm.placement : "bottom",
                width: "400",
                trigger: _vm.trigger ? _vm.trigger : "click",
              },
              on: {
                show: function ($event) {
                  return _vm.$emit("show")
                },
              },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c("el-button", { attrs: { type: "text", size: "medium" } }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c("base-table", {
                ref: "table",
                attrs: {
                  index: "",
                  temp: _vm.customer,
                  list: _vm.list,
                  total: _vm.total,
                  height: 200,
                  "list-query": _vm.listQuery,
                  columns: _vm.columns,
                },
                on: { dblclick: _vm.handleDblclick },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "5px" },
                  attrs: { slot: "reference", type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("show")
                    },
                  },
                  slot: "reference",
                },
                [_vm._v("选择")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }