import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scrollTo } from '@/utils/scroll-to';
export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: function _default() {
        return [15, 30, 50, 100];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      allSelect: true
    };
  },
  computed: {
    currentPage: {
      get: function get() {
        return this.page;
      },
      set: function set(val) {
        this.$emit('update:page', val);
      }
    },
    pageSize: {
      get: function get() {
        return this.limit;
      },
      set: function set(val) {
        this.$emit('update:limit', val);
      }
    }
  },
  methods: {
    handleSizeChange: function handleSizeChange(val) {
      this.$emit('pagination', {
        page: this.currentPage,
        limit: val
      });

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit('pagination', {
        page: val,
        limit: this.pageSize
      });

      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    }
  }
};