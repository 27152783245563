var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.update
    ? _c(
        "el-popover",
        {
          ref: "popoverSH",
          attrs: {
            "popper-class": "auto_popover",
            placement: "bottom",
            trigger: "click",
          },
          on: {
            show: function ($event) {
              _vm.visibNormal = true
            },
            hide: _vm.hideFun,
          },
          model: {
            value: _vm.visibNormal,
            callback: function ($$v) {
              _vm.visibNormal = $$v
            },
            expression: "visibNormal",
          },
        },
        [
          _c("div", { staticClass: "closeDiv", on: { click: _vm.closeFun } }, [
            _vm._v("X"),
          ]),
          _vm._v(" "),
          _c(
            "el-checkbox-group",
            {
              nativeOn: {
                change: function ($event) {
                  return (function (e) {
                    return _vm.clickitem(e, 3)
                  })($event)
                },
              },
              model: {
                value: _vm.checkList_3,
                callback: function ($$v) {
                  _vm.checkList_3 = $$v
                },
                expression: "checkList_3",
              },
            },
            _vm._l(_vm.arrayList[0], function (item, index) {
              return _c(
                "el-dropdown-item",
                { key: index },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "auto_popover",
                        placement: "left",
                        trigger: "hover",
                        offset: "20",
                      },
                      on: {
                        show: function ($event) {
                          return _vm.$refs.popoverSH.doShow()
                        },
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          nativeOn: {
                            change: function ($event) {
                              return (function (e) {
                                return _vm.clickitem(e, 4)
                              })($event)
                            },
                          },
                          model: {
                            value: _vm.checkList_4,
                            callback: function ($$v) {
                              _vm.checkList_4 = $$v
                            },
                            expression: "checkList_4",
                          },
                        },
                        _vm._l(item.children, function (item4, index4) {
                          return _c(
                            "el-dropdown-item",
                            { key: index4 },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  key: item4.label,
                                  attrs: { label: item4.label },
                                },
                                [_vm._v(" " + _vm._s(item4.label))]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-checkbox",
                        {
                          key: item.label,
                          attrs: { slot: "reference", label: item.label },
                          slot: "reference",
                        },
                        [_vm._v("\n          " + _vm._s(item.label))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drop_position" },
            [
              _c(
                "el-checkbox-group",
                {
                  nativeOn: {
                    change: function ($event) {
                      return (function (e) {
                        return _vm.clickitem(e, 1)
                      })($event)
                    },
                  },
                  model: {
                    value: _vm.checkList_1,
                    callback: function ($$v) {
                      _vm.checkList_1 = $$v
                    },
                    expression: "checkList_1",
                  },
                },
                _vm._l(_vm.arrayList[1], function (item2, index2) {
                  return _c(
                    "el-dropdown-item",
                    { key: index2 },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            "popper-class": "auto_popover",
                            placement: "right",
                            trigger: "hover",
                            offset: "20",
                          },
                          on: {
                            show: function ($event) {
                              return _vm.$refs.popoverSH.doShow()
                            },
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              nativeOn: {
                                change: function ($event) {
                                  return (function (e) {
                                    return _vm.clickitem(e, 2)
                                  })($event)
                                },
                              },
                              model: {
                                value: _vm.checkList_2,
                                callback: function ($$v) {
                                  _vm.checkList_2 = $$v
                                },
                                expression: "checkList_2",
                              },
                            },
                            _vm._l(item2.children, function (item3, index3) {
                              return _c(
                                "el-dropdown-item",
                                { key: index3 },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      key: item3.label,
                                      attrs: { label: item3.label },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item3.label)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-checkbox",
                            {
                              key: item2.label,
                              attrs: { slot: "reference", label: item2.label },
                              slot: "reference",
                            },
                            [_vm._v("\n            " + _vm._s(item2.label))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "reference" }, slot: "reference" },
            [
              _c("el-input", {
                style: _vm.inputStyle
                  ? _vm.inputStyle
                  : _vm.showNull
                  ? " width: 210px"
                  : "width: 250px",
                attrs: { readonly: "", clearable: "" },
                on: {
                  input: function ($event) {
                    return _vm.$emit("selectChange", _vm.inputData)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm.selectList.length
                      ? {
                          key: "prepend",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "el-cascader__tags tag_left" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-tag el-tag--info el-tag--mini el-tag--light",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectList && _vm.selectList[0]
                                          )
                                        ),
                                      ]),
                                      _c("i", {
                                        staticClass:
                                          "el-tag__close el-icon-close",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.catList(
                                              _vm.selectList[
                                                _vm.selectList.length - 1
                                              ]
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.selectList.length > 1
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-tag el-tag--info el-tag--mini el-tag--light",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "+ " +
                                                _vm._s(
                                                  _vm.selectList.length - 1
                                                )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm.selectList.length
                      ? {
                          key: "suffix",
                          fn: function () {
                            return [
                              _c("span", { staticClass: "el-input__suffix" }, [
                                _c(
                                  "span",
                                  { staticClass: "el-input__suffix-inner" },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-input__icon el-icon-circle-close",
                                      on: { click: _vm.clearFun },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.inputData,
                  callback: function ($$v) {
                    _vm.inputData = $$v
                  },
                  expression: "inputData",
                },
              }),
              _vm._v(" "),
              _vm.showNull
                ? _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.ctmNullChange },
                      model: {
                        value: _vm.cusClsNull,
                        callback: function ($$v) {
                          _vm.cusClsNull = $$v
                        },
                        expression: "cusClsNull",
                      },
                    },
                    [_vm._v("空")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }