import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapActions } from 'vuex';
import HeadEditItem from "./headEditItem.vue";
import CheckItem from "./checkBox";
import ExportForm from "./exportDialog";
import GDialog from "./gDialog";
import { exportFile } from '@/utils/file';
import { formatTime } from '@/utils/moment';
import moment from 'moment';
export default {
  name: 'BaseHeader',
  components: {
    HeadEditItem: HeadEditItem,
    CheckItem: CheckItem,
    GDialog: GDialog,
    ExportForm: ExportForm
  },
  props: {
    // 顶部按钮集合
    actions: {
      required: true,
      type: Array
    },
    // 用来控制按钮加载状态
    loading: {
      type: Boolean
    },
    // 是否开启辅助菜单
    extra: {
      type: Object
    },
    columns: {
      required: true,
      type: Array
    },
    selectColumns: {
      required: true,
      type: Array
    },
    temp: {
      required: true,
      type: Object
    },
    listQuery: {
      required: true,
      type: Object
    },
    excel: {
      type: Boolean
    },
    isSearch: {
      type: Boolean,
      default: true
    },
    isFilter: {
      type: Boolean,
      default: true
    },
    searchStyle: {
      type: Object
    },
    asideWidth: {
      type: String
    },
    taskBtn: {
      type: Boolean
    },
    showCheckBox: {
      type: Boolean
    },
    revenueBtnShow: {
      type: Boolean,
      default: true
    },
    showNormalCol: {
      type: Boolean
    },
    showDateExport: {
      type: Boolean
    },
    exportMessage: {
      type: Object
    },
    oprateExport: {
      type: Boolean
    },
    timeControl: {
      type: Object
    },
    // 营收header
    formRevene: {},
    showTaskBtn: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  data: function data() {
    return {
      height: '70px',
      allSelect: true,
      curIndex: 0,
      filter: false,
      len: 0,
      selectList: [],
      columnsSortList: [],
      listQueryCopy: JSON.parse(JSON.stringify(this.listQuery)),
      visible: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['earId', 'employees', 'rightWidth', 'globalLoading', 'searchStatus', 'roleId', 'empCode', 'userInfo'])), {}, {
    // 显示的字段集合
    showColumns: function showColumns() {
      var that = this;
      return this.selectColumns.filter(function (column) {
        that.$set(column, 'visible', true);
        return !column.notColumn;
      });
    },
    // 当前显示的字段集合
    activeColumns: function activeColumns() {
      return this.selectColumns.filter(function (column) {
        return column.visible;
      });
    },
    // 可搜索的字段集合
    searchColumns: function searchColumns() {
      return this.columns.filter(function (column) {
        return column.searchable;
      });
    }
  }),
  created: function created() {
    var width = document.body.clientWidth;
    var len = 8; // 根据屏幕宽度计算高度

    if (width >= 1920) {
      len = 12;
    } else if (width >= 1200) {
      len = 9;
    } else if (width >= 992) {
      len = 7;
    }

    this.len = len; // this.getList()

    this.height = this.actions && this.actions.length > len ? '70px' : '60px'; // 判断是否全选

    this.allSelect = this.activeColumns.length === this.showColumns.length; // if(this.sortcolumns){
    //   this.columns.forEach(v=>this.columnsSortList.push(v))
    //   this.columnsSortList.sort((a, b) => {
    //     if(a.sort == null){
    //       a.sort = -1
    //     }
    //     if(b.sort == null){
    //       b.sort = -1
    //     }
    //     return b.sort - a.sort
    //
    //   });
    // }
  },
  methods: _objectSpread(_objectSpread({}, mapActions('settings', ['toggleSearchGether', 'toggleSearchRedius'])), {}, {
    getValue: function getValue(val, eve) {
      // return eve
      console.log(val, eve);
    },
    searchClick: function searchClick() {
      var _this = this;

      if (!this.searchStyle) {
        return;
      }

      setTimeout(function () {
        var popovers = document.getElementsByClassName('el-popover');

        for (var i in popovers) {
          if (popovers[i].style && popovers[i].style.width == '1100px') {
            popovers[i].style.width = _this.searchStyle.width;
            popovers[i].style.maxHeight = _this.searchStyle.maxHeight;
            popovers[i].style.overflowY = _this.searchStyle.overflowY;
            popovers[i].style.overflowX = _this.searchStyle.overflowX;
          }
        }
      }, 100);
    },
    change: function change() {
      this.$set(this.listQuery, 'beginDate', this.listQuery.beginDate);
    },
    allSelectChange: function allSelectChange(val) {
      this.selectColumns.forEach(function (column) {
        column.visible = val;
      });
    },
    handleCommand: function handleCommand(command) {
      this.$emit('extra', command);
    },
    handleExcel: function handleExcel(command) {
      var _this2 = this;

      if (this.oprateExport) {
        this.$emit('excel', command);
      } else if (this.taskBtn) {
        this.visible = true;
      } else {
        var exportFun = this.exportMessage.fun;
        var exportType = this.exportMessage.exportType;
        var query = Object.assign({}, command === '1' ? this.exportMessage.query : this.exportMessage.dateQuery);

        if (this.timeControl && this.roleId != 1 && this.userInfo.depCode !== '000D71') {
          if (this.timeFun(query)) {
            return;
          }
        } // if (this.timeControl) {
        //   if (this.timeFun(query)) {
        //     return;
        //   }
        // }


        exportFun(query, exportType).then(function (res) {
          if (res.type == 'application/json') {
            var reader = new FileReader();
            reader.readAsText(res, 'utf-8');

            reader.onload = function (e) {
              var readerres = reader.result;
              var parseObj = {};
              parseObj = JSON.parse(readerres);
              var $this = _this2; //this指向问题，如果要将reader.onload=function(e)/reader.onload=e=>{}结果给外界使用必须改变this指向问题

              _this2.$message({
                type: 'error',
                message: parseObj.message,
                duration: 1500
              });
            };
          } else {
            exportFile(res, "".concat(_this2.exportMessage.exportTaskName).concat(formatTime('YYYYMMDDHHmmss'), ".xlsx"));
          }
        });
      }
    },
    timeFun: function timeFun(query) {
      console.log('query', query);
      var newCompareDate = moment().startOf('month').format('YYYY-MM-DD');
      var time = this.timeControl.time;
      var compareDate1 = moment(query[this.timeControl.begin]).diff(moment(newCompareDate), 'months');
      var compareDate2 = moment(query[this.timeControl.end]).diff(moment(newCompareDate), 'months');

      if (time && time === 'day') {
        newCompareDate = moment().startOf('day').format('YYYY-MM-DD');
        compareDate1 = moment(query[this.timeControl.begin]).diff(moment(newCompareDate), 'days');
        compareDate2 = moment(query[this.timeControl.end]).diff(moment(newCompareDate), 'days');
        console.log('day', newCompareDate);
      } else {
        newCompareDate = moment().startOf('month').format('YYYY-MM-DD');
      }

      var flag = false;
      var flag2 = false;

      if (time) {
        if (time === 'day') {
          flag2 = compareDate1 <= -7 || compareDate2 >= 8;
        } else {
          flag2 = compareDate1 < -1 || compareDate2 > 1;
        }
      } else {
        flag = compareDate1 !== 0 || compareDate2 !== 0;
      }

      return false; // return true;

      if (this.timeControl.noAuth) {
        //不做前端时间校验
        return false;
      } else if (flag2) {
        this.$message({
          type: 'warning',
          message: "\u60A8\u53EA\u80FD\u5BFC\u51FA".concat(time === 'day' ? '近一个星期' : '近两个月', "\u6570\u636E!")
        });
      } else if (flag) {
        this.$message({
          type: 'warning',
          message: "\u60A8\u53EA\u80FD\u5BFC\u51FA\u5F53\u6708\u6570\u636E!"
        });
      } else {
        return false;
      }

      return true;
    },
    exportTable: function exportTable() {
      var _this3 = this;

      var exportFun = this.exportMessage.fun;
      var query = Object.assign({}, this.exportMessage.query);
      var test = /[&,，？?]/g; // 匹配特殊字符替换

      query.exportTaskName = this.exportMessage.exportTaskName.replace(test, '');
      console.log(query.exportTaskName);

      if (this.timeControl && this.roleId != 1 && this.userInfo.depCode !== '000D71') {
        if (this.timeFun(query)) {
          return;
        }
      } // return;


      exportFun(query).then(function (res) {
        var reader = new FileReader();
        reader.readAsText(res, 'utf-8');

        reader.onload = function (e) {
          var readerres = reader.result;
          var parseObj = {};
          parseObj = JSON.parse(readerres);
          var $this = _this3; //this指向问题，如果要将reader.onload=function(e)/reader.onload=e=>{}结果给外界使用必须改变this指向问题

          if (parseObj.code == 200) {
            _this3.$message({
              type: 'success',
              message: '导出任务创建成功，请稍后下载',
              duration: 1500
            });
          } else {
            _this3.$message({
              type: 'error',
              message: parseObj.message,
              duration: 1500
            });
          }
        };

        _this3.visible = false;
      });
    },
    handleAction: function handleAction(index) {
      this.curIndex = index;
      this.$emit('action', index);
    },
    handleClick: function handleClick(row) {
      this.$emit('click', row);
    },
    handleSearch: function handleSearch() {
      var e = this.$refs.HeadItem[this.$refs.HeadItem.length - 1];

      if (e.$refs.autoSelect) {
        e.$refs.autoSelect.visibNormal = false;
      }

      this.filter = false;
      this.$emit('search');
    },
    handleClear: function handleClear() {
      for (var v in this.listQuery) {
        this.listQuery[v] = this.listQueryCopy[v];
      }
    }
  })
};