var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "table-container", style: { height: _vm.height + "px" } },
      [
        _c("base-table", {
          ref: "table",
          attrs: {
            temp: _vm.temp,
            list: _vm.list,
            height: _vm.height - 15,
            "list-query": _vm.listQuery,
            columns: _vm.columns,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }