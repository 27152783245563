var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      style: { height: _vm.height + "px", overflow: "scroll" },
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { staticClass: "tabs-header" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleTabClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "生日提醒(3)", name: "1" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "报单提醒(5)", name: "2" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "预约提醒(10)", name: "3" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "客户回访(1)", name: "4" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "库存不足(2)", name: "5" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "产品过期(4)", name: "6" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "欠款提醒(1)", name: "7" },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "项目到期(0)", name: "8" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "1",
                  expression: "activeName == '1'",
                },
              ],
            },
            [
              _c(
                "el-main",
                [
                  _c("base-table", {
                    ref: "table",
                    attrs: {
                      temp: _vm.customer,
                      list: _vm.list1,
                      total: _vm.total1,
                      height: _vm.height - 150,
                      "list-query": _vm.listQuery1,
                      columns: _vm.columns1,
                      pagination: "",
                    },
                    on: { pagination: _vm.getList1, click: _vm.handleClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "2",
                  expression: "activeName == '2'",
                },
              ],
            },
            [
              _c(
                "el-main",
                [
                  _c("base-table", {
                    ref: "table",
                    attrs: {
                      temp: _vm.customer,
                      list: _vm.list2,
                      total: _vm.total2,
                      height: _vm.height - 150,
                      "list-query": _vm.listQuery2,
                      columns: _vm.columns2,
                      pagination: "",
                    },
                    on: { pagination: _vm.getList2, click: _vm.handleClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName == "3",
                  expression: "activeName == '3'",
                },
              ],
            },
            [
              _c(
                "el-main",
                [
                  _c("base-table", {
                    ref: "table",
                    attrs: {
                      temp: _vm.customer,
                      list: _vm.list3,
                      total: _vm.total3,
                      height: _vm.height - 150,
                      "list-query": _vm.listQuery3,
                      columns: _vm.columns3,
                      pagination: "",
                    },
                    on: { pagination: _vm.getList3, click: _vm.handleClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }