import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import axios from 'axios'; // import Vue from 'vue'

import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getToken } from '@/utils/auth';
import { isNaN } from '@/utils'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout

}); // 对保存接口进行过滤

var filterUrls = ['/manager/call/update', '/manager/rv/update', '/manager/department/addOrUpdate', '/manager/reception/callInfo/update', '/manager/customer/save', '/manager/customer/allSearch', '/manager/b2c/product/pageAddOrUpdate', '/manager/user/roleAddEmpMenu', '/manager/report/eare/zptPdulabelReportToJdEmpReport', '/manager/report/eare/zptPdulabelReportToCtmExpoler', '/manager/report/eare/zptPdulabelReportToAge', 'manager/report/eare/zptPdulabelReportToNurseEmpReport', '/manager/report/eare/zptPdulabelReportToBeauEmpReport', '/manager/cashmanage/updateZpInfo', '/manager/product/addOrUpdate', '/manager/project/auth/saveOrUpdate', '/manager/project/auth/batchSaveOrUpdate', '/manager/ctmZpt/ctmZptUpdate', '/manager/ctmPay/updateRemark', '/manager/employee/addOrUpdate', '/manager/eare/add', '/manager/rfm/getCusRfmList', '/manager/ctmCallPdu/updateTmzhlabel', '/manager/customer/list', '/manager/rv/list', '/manager/rv/batchDel']; // request interceptor

service.interceptors.request.use(function (config) {
  // 白名单接口中是否含有当前接口
  var hasFilter = filterUrls.filter(function (v) {
    if (config.url.indexOf(v) > -1) {
      return v;
    }
  });

  if (!config.isFilterWhite) {
    // post 请求清除
    for (var i in config.data) {
      // 是字符串去除空格
      if (typeof config.data[i] === 'string') {
        config.data[i] = config.data[i].trim();
      } // isTrim : 是否去除 ''
      // stillStates: isTrim为 true时  不delete的属性列表


      if (config.data[i] === '' && (hasFilter.length === 0 || config.isTrim) || config.data[i] === null || isNaN(config.data[i]) || config.data[i] === undefined) {
        if (!(config.stillStates && config.stillStates.includes(i))) delete config.data[i];
      }
    } // get 请求清除


    for (var _i in config.params) {
      // 是字符串去除空格
      if (typeof config.params[_i] === 'string') {
        config.params[_i] = config.params[_i].trim();
      }

      if (config.params[_i] === '' && hasFilter.length === 0 || config.params[_i] === null || isNaN(config.params[_i]) || config.params[_i] === undefined) {
        delete config.params[_i];
      }
    }
  } // do something before request is sent


  if (!config.noToken) {
    config.headers['token'] = getToken();
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  // console.log('response.config.url', response.config.url);
  var url = response.config.url.split(response.config.baseURL)[1]; // console.log('url', url);

  var res = response.data; // 文件流

  if (res.message === undefined && res.code === undefined) {
    return res;
  } // if the custom code is not 20000, it is judged as an error.


  if (res.code !== 200 && url != '/manager/cus/reception/register') {
    var elMessage = document.getElementsByClassName('el-message--error');

    if (elMessage.length === 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
        onClose: function onClose() {
          if (res.code === 401) {
            store.dispatch('settings/changeSetting', {
              key: 'lockScreen',
              value: false
            });
            store.dispatch('user/logout');
            router.push('/login');
          }

          if (res.code === 201) {
            store.dispatch('settings/changeSetting', {
              key: 'lockScreen',
              value: false
            });
            store.dispatch('user/logout');
            router.push('/login');
          }
        }
      });
    } else {
      if (res.code === 401) {
        store.dispatch('settings/changeSetting', {
          key: 'lockScreen',
          value: false
        });
        store.dispatch('user/logout');
        router.push('/login');
      }

      if (res.code === 201) {
        store.dispatch('settings/changeSetting', {
          key: 'lockScreen',
          value: false
        });
        store.dispatch('user/logout');
        router.push('/login');
      }
    } // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;


    if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }

    return Promise.reject(new Error(res.message || 'Error')).catch(function (res) {
      console.log('catch data::', res);
    });
  } else {
    return res;
  }
}, function (error) {
  var elMessage = document.getElementsByClassName('el-message--error');

  if (elMessage.length === 0) {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
  }

  return Promise.reject(error);
});
export default service;