import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import PrintJs from 'print-js';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'ComsumePrint',
  props: {
    customer: {
      type: Object
    },
    projectList: {
      type: Array
    }
  },
  data: function data() {
    return {
      printTime: moment().format('YYYY-MM-DD HH:mm')
    };
  },
  computed: _objectSpread({}, mapGetters(['userName', 'eareName'])),
  methods: {
    print: function print() {
      this.printTime = moment().format('YYYY-MM-DD HH:mm');
      setTimeout(function () {
        PrintJs({
          printable: 'comsume_print_box',
          //打印区域id
          type: 'html',
          //打印类型是html
          scanStyles: false,
          targetStyles: ['*'],
          style: '@page{size:auto; margin: 0;}' + '@media print { @page {size: 80mm 297mm portrait } }' + '  @page :first {  margin-top: 10mm;  }'
        });
      }, 500);
    }
  }
};