import request from '@/utils/request'; // 业务收款单

export function findCollectMoney(data) {
  return request({
    url: '/manager/cashmanage/findCollectMoney',
    method: 'get',
    params: data
  });
} // 业务收款单 - 分页

export function findCollectPage(data) {
  return request({
    url: '/manager/cashmanage/findCollectPage',
    method: 'post',
    data: data
  });
} // 查询现有选购项目

export function findZptList(data) {
  return request({
    url: '/manager/cashmanage/findCardList',
    method: 'get',
    params: data
  });
} // 总和

export function findCardSum(data) {
  return request({
    url: '/manager/cashmanage/findCardSum',
    method: 'get',
    params: data
  });
} // 编辑收费项目

export function updateZpHxm(data) {
  return request({
    url: '/manager/cashmanage/updateZpHxm',
    method: 'get',
    params: data
  });
} // 查询现有项目

export function findZptTList(data) {
  return request({
    url: '/manager/cashmanage/findZptList',
    method: 'get',
    params: data
  });
} // 查询现有选购项目

export function findZptDList(data) {
  return request({
    url: '/manager/cashmanage/findZptDList',
    method: 'get',
    params: data
  });
} // 预存-定金

export function preDeposit(data) {
  return request({
    url: '/manager/cashmanage/preDeposit',
    method: 'post',
    data: data
  });
} // 预存-购券

export function buyCoupon(data) {
  return request({
    url: '/manager/cashmanage/buyCoupon',
    method: 'post',
    data: data
  });
} // 币种设置列表

export function paymentList(data) {
  return request({
    url: '/manager/payment/getList',
    method: 'post',
    data: data
  });
} // 币种设置列表新

export function getPaymentList(data) {
  return request({
    url: '/manager/payment/getPaymentList',
    method: 'post',
    data: data
  });
} // 退款-客服退款单

export function refund(data) {
  return request({
    url: '/manager/cashmanage/refund',
    method: 'post',
    data: data
  });
} // 退款-客服定金转增

export function refundDepositGift(data) {
  return request({
    url: '/manager/cashmanage/refundDepositGift',
    method: 'post',
    data: data
  });
} // 退单

export function refundOrder(data) {
  return request({
    url: '/manager/cashmanage/refundOrder',
    method: 'get',
    params: data
  });
} // 消单

export function cancelOrder(data) {
  return request({
    url: '/manager/cashmanage/cancelOrder',
    method: 'get',
    params: data
  });
} // 退药

export function refundDrug(data) {
  return request({
    url: '/manager/cashmanage/refundDrug',
    method: 'get',
    params: data
  });
} // 历史-查询消费项目

export function hisotryPidNoZptdList(data) {
  return request({
    url: '/manager/cashmanage/hisotryPidNoZptdList',
    method: 'get',
    params: data
  });
} // 开单

export function openOrder(data) {
  return request({
    url: '/manager/cashmanage/openOrder',
    method: 'get',
    params: data
  });
} // 科室消费开单

export function comsumPpenOrder(data) {
  return request({
    url: '/manager/comsume/openOrder',
    method: 'get',
    params: data
  });
} // 项目列表

export function projectPage(data) {
  return request({
    url: '/manager/project/page',
    method: 'post',
    data: data
  });
} // 加入购物车项目

export function addCard(data) {
  return request({
    url: '/manager/cashmanage/addCard',
    method: 'get',
    params: data
  });
} // 零购

export function findTimeSaleVo(data) {
  return request({
    url: '/manager/cashmanage/findTimeSaleVo',
    method: 'get',
    params: data
  });
} // 积分

export function giftPoints(data) {
  return request({
    url: '/manager/cashmanage/giftPoints',
    method: 'get',
    params: data
  });
} // 项目转赠

export function giveAway(data) {
  return request({
    url: '/manager/cashmanage/giveAway',
    method: 'get',
    params: data
  });
} // 结算

export function settle(data) {
  return request({
    url: '/manager/cashmanage/settle',
    method: 'post',
    data: data
  });
} // 获取项目优惠信息

export function activitySettle(data) {
  return request({
    url: '/manager/cashmanage/activity/settle',
    method: 'post',
    data: data
  });
} // 应收财务登记-新增

export function addDisacc(data) {
  return request({
    url: '/manager/cashmanage/addDisacc',
    method: 'post',
    data: data
  });
} // 应收财务登记-编辑

export function updateDisacc(data) {
  return request({
    url: '/manager/cashmanage/updateDisacc',
    method: 'post',
    data: data
  });
} // 应收财务登记-删除

export function delDisacc(data) {
  return request({
    url: '/manager/cashmanage/delDisacc',
    method: 'post',
    data: data
  });
} // 调整价格

export function adjustPrice(data) {
  return request({
    url: '/manager/cashmanage/adjustPrice',
    method: 'post',
    data: data
  });
} // 更换用户卡号

export function changeUserCard(data) {
  return request({
    url: '/manager/cashmanage/changeUserCard',
    method: 'post',
    data: data
  });
} // 客户资料查询-现有项目资料

export function findPayNowProject(data) {
  return request({
    url: '/manager/cashmanage/findPayNowProject',
    method: 'get',
    params: data
  });
} // 客户资料查询-历史咨询情况

export function findCallInfoList(data) {
  return request({
    url: '/manager/cashmanage/findCallInfoList',
    method: 'get',
    params: data
  });
} // 客户资料查询-日结消费记录

export function dailySettlement(data) {
  return request({
    url: '/manager/cashmanage/dailySettlement',
    method: 'get',
    params: data
  });
} // 客户资料查询-日结消费记录

export function getDayPayList(data) {
  return request({
    url: '/manager/cashmanage/getDayPayList',
    method: 'get',
    params: data
  });
} // 客户资料查询-优惠券情况

export function getUserCoupDetail(data) {
  return request({
    url: '/manager/cashmanage/getUserCoupDetail',
    method: 'get',
    params: data
  });
} // 客户资料查询-优惠券列表

export function getUserCoups(data) {
  return request({
    url: '/manager/cashmanage/getCoups',
    method: 'get',
    params: data
  });
} // 新增收银项目

export function addZtp(data) {
  return request({
    url: '/manager/cashmanage/addZtp',
    method: 'post',
    data: data
  });
} // 新增购物车项目

export function addCtmZptd(data) {
  return request({
    url: '/manager/cashmanage/addCtmZptd',
    method: 'post',
    data: data
  });
} // 删除购物车项目

export function moveCard(data) {
  return request({
    url: '/manager/cashmanage/moveCard',
    method: 'get',
    params: data
  });
} // 套餐查詢

export function queryPdutoList(data) {
  return request({
    url: '/manager/pdutol/queryPdutoList',
    method: 'post',
    data: data
  });
} // 获取套餐下项目

export function findPdutoDetByPthCode(data) {
  return request({
    url: '/manager/pdutol/findPdutoDetByPthCode',
    method: 'get',
    params: data
  });
} // 获取结算消费金额

export function getReceivablesPrice(data) {
  return request({
    url: '/manager/cashmanage/getReceivablesPrice',
    method: 'post',
    data: data
  });
} // 查询客户信息

export function getCtmIdByCode(data) {
  return request({
    url: '/manager/cashmanage/getCtmIdByCode',
    method: 'get',
    params: data
  });
} // 新增日常收支登记

export function saveAccountItem(data) {
  return request({
    url: '/manager/cashmanage/saveAccountItem',
    method: 'POST',
    data: data
  });
} // 编辑日常收支登记

export function updateAccountItem(data) {
  return request({
    url: '/manager/cashmanage/updateAccountItem',
    method: 'POST',
    data: data
  });
} // 日常收支登记列表

export function dayacc(data) {
  return request({
    url: '/manager/dayacc/getPage',
    method: 'POST',
    data: data
  });
} //

export function depositRefund(data) {
  return request({
    url: '/manager/cashmanage/depositRefund',
    method: 'POST',
    data: data
  });
} //  项目转定金

export function projectExchangeDeposit(data) {
  return request({
    url: '/manager/cashmanage/projectExchangeDeposit',
    method: 'get',
    params: data
  });
} // 客户消费明细

export function paymentHistory(data) {
  return request({
    url: '/manager/cashmanage/paymentHistory',
    method: 'GET',
    params: data
  });
} // 客户还款

export function payBackMoney(data) {
  return request({
    url: '/manager/cashmanage/payBackMoney',
    method: 'GET',
    params: data
  });
} // 积分兑换

export function integralExchange(data) {
  return request({
    url: '/manager/cashmanage/integralExchange',
    method: 'post',
    data: data
  });
} // 积分兑换-记录

export function getIntegralExchanage(data) {
  return request({
    url: '/manager/cashmanage/getIntegralExchanage',
    method: 'get',
    params: data
  });
} // 赠送

export function giveExchange(data) {
  return request({
    url: '/manager/cashmanage/giveExchange',
    method: 'post',
    data: data
  });
} // 赠送 项目

export function sysGiveProject(data) {
  return request({
    url: '/manager/ctmZpt/sysGiveProject',
    method: 'post',
    data: data
  });
} // 批量 赠送 项目

export function sysGiveProjectVerifyBatch(data) {
  return request({
    url: '/manager/ctmZpt/sysGiveProjectVerifyBatch',
    method: 'post',
    data: data
  });
} // 赠送 项目 删除

export function refundGiveProject(data) {
  return request({
    url: '/manager/ctmZpt/refundGiveProject',
    method: 'get',
    params: data
  });
} // 赠送-记录

export function getGiveExchanage(data) {
  return request({
    url: '/manager/cashmanage/getGiveExchanage',
    method: 'get',
    params: data
  });
} // 应收财务登记-清讫

export function accountSettled(data) {
  return request({
    url: '/manager/cashmanage/accountSettled',
    method: 'get',
    params: data
  });
} // 获取分摊明细

export function pdutolShare(data) {
  return request({
    url: '/manager/pdutol/share',
    method: 'get',
    params: data
  });
} // 查询项目明细

export function refundInfo(data) {
  return request({
    url: '/manager/cashmanage/refundInfo',
    method: 'get',
    params: data
  });
} // 删除收银项目

export function deleteZtp(data) {
  return request({
    url: '/manager/cashmanage/deleteZtps',
    method: 'get',
    params: data
  });
} // 存款记录

export function customeraccount(data) {
  return request({
    url: '/manager/customeraccount/page',
    method: 'post',
    data: data
  });
} // 老券兑换成新券

export function old2new(params) {
  return request({
    url: '/manager/coup/old2new',
    method: 'get',
    params: params
  });
} // 打印

export function printSettle(data) {
  return request({
    url: '/manager/cashmanage/printSettle',
    method: 'get',
    params: data
  });
} // 打印

export function findCustomerList(data) {
  return request({
    url: '/manager/cashmanage/findCustomerList',
    method: 'get',
    params: data
  });
} // 日常收支汇总

export function getSumAccount(data) {
  return request({
    url: '/manager/dayacc/getSumAccount',
    method: 'post',
    data: data
  });
} // 退券

export function rtdCoup(data) {
  return request({
    url: '/manager/cashmanage/rtdCoup',
    method: 'post',
    params: data
  });
} // 退券余额

export function getCoupBalanceSum(data) {
  return request({
    url: '/manager/coup/getCoupBalanceSum',
    method: 'get',
    params: data
  });
} // 退券余额

export function getCoupByCtmCode(data) {
  return request({
    url: '/manager/coup/getCoupByCtmCode',
    method: 'get',
    params: data
  });
} // 日常收支-删除

export function deleteAccountItem(data) {
  return request({
    url: '/manager/cashmanage/deleteAccountItem',
    method: 'post',
    params: data
  });
} // 日常收支-删除

export function getDisaccById(data) {
  return request({
    url: '/manager/cashmanage/getDisaccById',
    method: 'post',
    params: data
  });
} // 项目传定金收支

export function projectExchangeDepositNew(data) {
  return request({
    url: '/manager/cashmanage/projectExchangeDepositNew',
    method: 'post',
    data: data
  });
} // 还款打印

export function printPayBackMoney(data) {
  return request({
    url: '/manager/cashmanage/printPayBackMoney',
    method: 'get',
    params: data
  });
} // 购券详情

export function getBuyCoupDeatil(data) {
  return request({
    url: '/manager/usecoup/getBuyCoupDeatil',
    method: 'post',
    params: data
  });
} // 项目转定金打印

export function printProjectExchangeDeposit(data) {
  return request({
    url: '/manager/cashmanage/printProjectExchangeDeposit',
    method: 'get',
    params: data
  });
} // 开票记录

export function billPage(data) {
  return request({
    url: '/bill/page',
    method: 'post',
    data: data
  });
} // 开票记录明细

export function billItem(data) {
  return request({
    url: '/bill/item',
    method: 'post',
    data: data
  });
} // 应收审核记录

export function getRevenueAccountById(data) {
  return request({
    url: '/manager/cashmanage/getRevenueAccountById',
    method: 'get',
    params: data
  });
} // 顾客消费信息查询

export function billPayDetail(data) {
  return request({
    url: '/bill/payDetail',
    method: 'post',
    data: data
  });
} //开票

export function billOpen(data) {
  return request({
    url: '/bill/open',
    method: 'post',
    data: data
  });
} //外账营收明细表报

export function billReportItem(data) {
  return request({
    url: '/bill/report/item',
    method: 'post',
    data: data
  });
} //外账营收汇总表报

export function billReportCollect(data) {
  return request({
    url: '/bill/report/collect',
    method: 'post',
    data: data
  });
} //外账科室营收统计表报

export function billReportDept(data) {
  return request({
    url: '/bill/report/dept',
    method: 'post',
    data: data
  });
} // 保存平台

export function cashmanageUpdateZpInfo(data) {
  return request({
    url: '/manager/cashmanage/updateZpInfo',
    method: 'post',
    data: data
  });
} // 发票恢复

export function billRecovery(data) {
  return request({
    url: '/bill/recovery',
    method: 'post',
    params: data
  });
} // 外帐-营收明细导出

export function billItemExcel(data) {
  return request({
    url: '/bill/report/itemExcel',
    method: 'post',
    data: data
  });
} // 外帐-营收汇总导出

export function billCollectExcel(data) {
  return request({
    url: '/bill/report/collectExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 外帐-科室营收统计导出

export function billDeptExcel(data) {
  return request({
    url: '/bill/report/deptExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 修改截止时间

export function ctmZptUpdate(data) {
  return request({
    url: '/manager/ctmZpt/ctmZptUpdate',
    method: 'post',
    data: data
  });
} // 修改截止时间

export function verifExpire(data) {
  return request({
    url: '/manager/ctmZpt/verifExpire',
    method: 'post',
    data: data
  });
} // 修改截止时间-历史列表

export function getCtpFnsdateList(data) {
  return request({
    url: '/manager/op/getCtpFnsdateList',
    method: 'get',
    params: data
  });
} // 消费业绩重算

export function consumeReAchievement(data) {
  return request({
    url: '/manager/ctmPay/consumeReAchievement',
    method: 'post',
    data: data
  });
} // 划扣业绩重算

export function hkReAchievement(data) {
  return request({
    url: '/manager/ctmPay/hkReAchievement',
    method: 'post',
    data: data
  });
} //  退券接口 入参和操作退券

export function getRtdCoupDeatil(data) {
  return request({
    url: '/manager/usecoup/getRtdCoupDeatil',
    method: 'post',
    params: data
  });
} //  交易备注

export function updateRemark(data) {
  return request({
    url: '/manager/ctmPay/updateRemark',
    method: 'post',
    data: data
  });
} //  清理积分

export function xfcusdel(data) {
  return request({
    url: '/manager/interget/xfcusdel',
    method: 'post',
    data: data
  });
}