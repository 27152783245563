var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "mini",
          attrs: {
            inline: true,
            model: _vm.customer,
            size: "mini",
            "label-width": "85px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户卡号:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.pidCtmcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "pidCtmcode", $$v)
                  },
                  expression: "customer.pidCtmcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.pidCtmname,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "pidCtmname", $$v)
                  },
                  expression: "customer.pidCtmname",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "table-container",
          style: { height: _vm.height + "px" },
        },
        [
          _c("edit-table", {
            ref: "table",
            attrs: {
              temp: _vm.temp,
              list: _vm.list,
              height: _vm.height,
              "list-query": _vm.listQuery,
              columns: _vm.columns,
            },
            on: { click: _vm.handleClick },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottomBtn" },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleDone },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }