import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import HeadEditItem from "./headEditItem.vue";
export default {
  components: {
    HeadEditItem: HeadEditItem
  },
  props: {
    columns: {
      required: true,
      type: Array
    },
    listQuery: {
      required: true,
      type: Object
    },
    enabled: {
      type: Boolean
    },
    rules: {
      type: Object
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['earId', 'employees', 'rightWidth', 'globalLoading'])), {}, {
    // 可搜索的字段集合
    searchColumns: function searchColumns() {
      console.log('searchForm');
      return this.columns.filter(function (column) {
        return column.formSearch;
      });
    }
  }),
  data: function data() {
    return {
      listQueryCopy: JSON.parse(JSON.stringify(this.listQuery))
    };
  },
  methods: {
    handleClear: function handleClear() {
      for (var v in this.listQuery) {
        this.listQuery[v] = this.listQueryCopy[v];
      }
    },
    handleSearch: function handleSearch() {
      this.$emit('search');
    }
  }
};