var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-container", style: { height: _vm.height + "px" } },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { attrs: { height: "auto" } },
            [
              _c("base-header", {
                attrs: {
                  actions: _vm.actions,
                  temp: _vm.customer,
                  "list-query": _vm.listQuery,
                  filter: _vm.filter,
                  selectColumns: _vm.columns1,
                  columns: _vm.columns1,
                },
                on: {
                  action: _vm.handleAction,
                  search: _vm.handleRefresh,
                  clear: _vm.handleClear,
                  filter: _vm.handleFilter,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-main",
            [
              _c("base-table", {
                ref: "table1",
                attrs: {
                  index: "",
                  temp: _vm.customer,
                  list: _vm.list1,
                  total: _vm.total,
                  height: (_vm.height - 140) / 2,
                  "list-loading": _vm.listLoading,
                  "list-query": _vm.listQuery,
                  columns: _vm.columns1,
                  pagination: "",
                },
                on: {
                  click: _vm.handleClick,
                  dblclick: _vm.handleDblclick,
                  pagination: _vm.handleSearch1,
                },
              }),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "text", size: "medium" } }, [
                _vm._v("开票明细"),
              ]),
              _vm._v(" "),
              _c("base-table", {
                ref: "table2",
                attrs: {
                  index: "",
                  temp: _vm.customer2,
                  list: _vm.list2,
                  total: _vm.total2,
                  height: (_vm.height - 240) / 2,
                  "list-loading": _vm.listLoading,
                  "list-query": _vm.listQuery2,
                  columns: _vm.columns2,
                  pagination: "",
                },
                on: {
                  click: _vm.handleClick2,
                  dblclick: _vm.handleDblclick,
                  pagination: _vm.handleSearch2,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "开取发票登记信息",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "50px",
                width: "90%",
                center: "",
              },
              on: {
                close: function ($event) {
                  return _vm.getList1()
                },
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("dj-table", {
                ref: "customer",
                attrs: {
                  customer: {},
                  "ear-id": _vm.earId,
                  "ctf-zd-doc-list": _vm.employees,
                  height: _vm.innerHeight - 160,
                },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible1 = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }