import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import BaseTable from '../components/BaseTable.vue';
import { mapGetters, mapActions } from 'vuex';
import VxeTable from "./VxeTable.vue";
export default {
  name: 'TablePopover',
  components: {
    VxeTable: VxeTable
  },
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: String
    },
    title: {
      type: String
    },
    positionType: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    code: {
      type: [String]
    },
    selectFun: {
      type: Function
    },
    width: {
      type: String
    }
  },
  computed: _objectSpread({}, mapGetters(['earId', 'employees', 'positionTypeStatus'])),
  data: function data() {
    return {
      list: [],
      name: null,
      customer: {},
      total: 0,
      proDisVisible: false,
      selectRow: null,
      copyValue: null,
      update: false,
      columns: [{
        title: '员工姓名',
        field: 'empName',
        searchable: true,
        width: '80px',
        formatter: function formatter(value, list, row) {
          if (row.empStatus === 'CFM') {
            return '<span>' + row.empName + '</span>';
          }

          return '<span style="color: #ff4949;">' + row.empName + '</span>';
        }
      }, {
        title: '员工编号',
        field: 'empCode',
        searchable: true,
        width: '110px',
        formatter: function formatter(value, list, row) {
          if (row.empStatus === 'CFM') {
            return '<span>' + row.empCode + '</span>';
          }

          return '<span style="color: #ff4949;">' + row.empCode + '</span>';
        }
      }, {
        title: '分院',
        field: 'earName',
        searchable: true,
        width: '110px',
        formatter: function formatter(value, list, row) {
          if (row.empStatus === 'CFM') {
            return '<span>' + row.earName + '</span>';
          }

          return '<span style="color: #ff4949;">' + row.earName + '</span>';
        }
      }]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(newValue) {
        this.name = newValue ? newValue : '';
      }
    },
    name: function name() {
      this.getList('inputGetList');
    }
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.update = true;
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions('app', ['toggleEmpStauts'])), {}, {
    handleDblclick: function handleDblclick(row) {
      if (this.selectFun && !this.selectFun(row.empCode, 'cpyEmpcode1')) {
        return;
      }

      this.name = row.empName;
      this.toggleEmpStauts(false);
      this.selectRow = row;
      this.proDisVisible = false;
      this.$emit('select', row);
    },
    inputFun: function inputFun(e) {
      if (!e) {
        this.$emit('select', {
          empName: '',
          empCode: ''
        });
      }
    },
    getList: function getList(input) {
      var list = this.positionType === 'kf' ? JSON.parse(JSON.stringify(this.getEmployeeTypeList('kf', true))) : this.getEmployeeTypeList(this.positionType, true);
      list.forEach(function (v, index) {
        v.empName = v.title.split('--')[0];
        v.empStatusName = v.empStatus === 'CFM' ? '在职' : '离职';
        v.empCode = v.field;
        v.id = index;
      });

      if (this.name) {
        this.list = this.filterData(list, input);
        return;
      }

      this.list = list;
      this.total = this.list.length;
    },
    proShow: function proShow() {
      this.getList();
      this.selectRow = null;
    },
    proHide: function proHide() {
      if (this.list.length === 1) {
        this.$emit('select', this.list[0]);
        this.toggleEmpStauts(false);
      } else if (!this.name) {
        this.toggleEmpStauts(false);
        console.log('select');
        this.$emit('select', {
          empName: '',
          empCode: ''
        });
      } else if (this.name && this.selectRow === null || this.name && this.selectRow && this.selectRow.empCode === '') {
        this.toggleEmpStauts(true);
        var row = {
          empName: this.name,
          empCode: ''
        };
        this.$emit('select', row);
      }
    },
    filterData: function filterData(list, input) {
      var _this2 = this;

      var arr = [];
      var firStr = this.name.indexOf('Y');

      if (firStr == 0) {
        arr = list.filter(function (v) {
          return v.empCode.indexOf(_this2.name) > -1;
        });
      } else {
        arr = list.filter(function (v) {
          if (_this2.code && !input) {
            return _this2.code === v.empCode;
          } else {
            return v.empName.indexOf(_this2.name) > -1;
          }
        });
      }

      return arr;
    }
  })
};