var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.update
    ? _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            width: "400",
            trigger: "click",
            disabled: _vm.disabled,
          },
          on: { hide: _vm.proHide, show: _vm.proShow },
          model: {
            value: _vm.proDisVisible,
            callback: function ($$v) {
              _vm.proDisVisible = $$v
            },
            expression: "proDisVisible",
          },
        },
        [
          _c("el-button", { attrs: { type: "text", size: "medium" } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c("VxeTable", {
            ref: "table",
            attrs: {
              temp: _vm.customer,
              list: _vm.list,
              total: _vm.total,
              height: 200,
              "list-query": {},
              columns: _vm.columns,
              scroll: { x: 4, y: 5 },
              scrollTopLastLocation: false,
              pagination: false,
              showIndex: false,
              autoResize: true,
              copyText: false,
            },
            on: { dbclick: _vm.handleDblclick },
          }),
          _vm._v(" "),
          _vm._t("reference"),
          _vm._v(" "),
          _c("el-input", {
            ref: "inputRef",
            style: { width: _vm.width },
            attrs: {
              slot: "reference",
              placeholder: "",
              disabled: _vm.disabled,
            },
            on: { input: _vm.inputFun },
            slot: "reference",
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }