/*
 * @Author: yangchao
 * @Date: 2021-08-24 10:44:13
 * @LastEditors: yangchao
 * @LastEditTime: 2021-11-29 17:21:08
 * @Description: 全局route
 */
import Layout from '@/layout';
import AppMain from '@/layout/components/AppMain';
var globalRoute = {
  path: '/global',
  component: Layout,
  children: [{
    path: 'shift',
    component: function component() {
      return import('@/views/callFuc/shift');
    },
    name: 'Shift',
    hidden: true,
    meta: {
      title: '职员排班管理',
      icon: 'clipboard',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'askHistory',
    component: function component() {
      return import('@/views/consult/askHistory');
    },
    name: 'askHistory',
    hidden: true,
    meta: {
      title: '过往咨询列表',
      roles: ['root', 'admin', 'operator']
    }
  }, {
    path: 'consumerList',
    component: function component() {
      return import('@/views/comsume/consumerList');
    },
    name: 'ConsumerList',
    hidden: true,
    meta: {
      title: '客户消耗列表',
      roles: ['root', 'admin', 'operator']
    }
  }, {
    path: 'consumer/done',
    component: function component() {
      return import('@/views/comsume/comsumerDoneList');
    },
    name: 'ComsumerDoneList',
    hidden: true,
    meta: {
      title: '客户消费列表',
      roles: ['root', 'admin', 'operator']
    }
  }, {
    path: 'customer/comlist',
    component: function component() {
      return import('@/views/customer/index');
    },
    name: 'CustomerComlist',
    hidden: true,
    meta: {
      title: '公共池顾客',
      roles: ['root', 'admin', 'operator']
    }
  }, {
    path: 'consumer/back',
    component: function component() {
      return import('@/views/comsume/backList');
    },
    name: 'ComsumerBack',
    hidden: true,
    meta: {
      title: '客户退划扣列表',
      roles: ['root', 'admin', 'operator']
    }
  }, {
    path: 'system',
    redirect: '/global/system/consult',
    name: 'CustomerSum',
    hidden: true,
    component: AppMain,
    meta: {
      title: '客户汇总',
      roles: ['root', 'admin', 'posadmin', 'dean']
    },
    children: [{
      path: 'consult',
      component: function component() {
        return import('@/views/system/customerSum');
      },
      name: 'CustomerSumConsult',
      meta: {
        title: '客户汇总-所属顾问',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'referral',
      component: function component() {
        return import('@/views/system/customerSum');
      },
      name: 'CustomerSumRefConsult',
      meta: {
        title: '客户汇总-推荐顾问',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'ear',
      component: function component() {
        return import('@/views/system/customerSum');
      },
      name: 'CustomerSumEar',
      meta: {
        title: '客户汇总-交易分院',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'tjcodePtReport',
      component: function component() {
        return import('@/views/system/ptReport');
      },
      name: 'tjcodePtReport',
      meta: {
        title: '套餐统计表-推荐顾问',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'earIdPtReport',
      component: function component() {
        return import('@/views/system/ptReport');
      },
      name: 'earIdPtReport',
      meta: {
        title: '套餐统计表-交易分院',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'ctmEmpcode1ZtReport',
      component: function component() {
        return import('@/views/system/ztConvertReport');
      },
      name: 'ctmEmpcode1ZtReport',
      meta: {
        title: '引流/转化数据表-所属顾问',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'ctmSource3ZtReport',
      component: function component() {
        return import('@/views/system/ztConvertReport');
      },
      name: 'ctmSource3ZtReport',
      meta: {
        title: '引流/转化数据表-所属护理师',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'ctmSource4ZtReport',
      component: function component() {
        return import('@/views/system/ztConvertReport');
      },
      name: 'ctmSource4ZtReport',
      meta: {
        title: '引流/转化数据表-所属美容师',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }, {
      path: 'getcuremprfmReport',
      component: function component() {
        return import('@/views/system/getcuremprfmReport');
      },
      name: 'getcuremprfmReport',
      meta: {
        title: '客户贡献表-所属顾问',
        roles: ['root', 'admin', 'posadmin', 'dean']
      }
    }]
  }]
};
export default globalRoute;