import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { pageForGroup, outStockPage } from '@/api/stock';
import BaseHeader from "../components/BaseHeader.vue";
import BaseTable from "../components/BaseTable.vue";
import { KcColumn } from '@/views/components/BaseColumn';
export default {
  name: 'XyTable',
  components: {
    BaseHeader: BaseHeader,
    BaseTable: BaseTable
  },
  filters: {
    statusFilter: function statusFilter(status, type) {
      var map = {
        STP: {
          type: 'info',
          title: '停用'
        },
        USE: {
          type: 'success',
          title: '使用'
        }
      };
      return map[status] ? map[status][type] : status;
    },
    typeFilter: function typeFilter(status, type) {
      var map = {
        YAO: {
          type: 'info',
          title: '药品类'
        },
        PDU: {
          type: 'success',
          title: '物资类'
        }
      };
      return map[status] ? map[status][type] : status;
    },
    percFilter: function percFilter(status, type) {
      var map = {
        NO: {
          type: 'info',
          title: '否'
        },
        YES: {
          type: 'success',
          title: '是'
        }
      };
      return map[status] ? map[status][type] : status;
    }
  },
  props: {
    stoStock: {
      type: String
    },
    height: {
      required: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    justShow: {
      type: Boolean,
      default: false
    },
    storageId: {
      type: [String, Number],
      default: ''
    },
    earId: {
      type: [String, Number],
      default: ''
    },
    activeName: {
      type: String
    },
    zptJtcode: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      list: [],
      total: 0,
      temp: {},
      actions: [],
      columns: KcColumn,
      listQuery: {
        stoStock: this.stoStock,
        gteStoNum: 1,
        pageNum: 1,
        pageSize: 50
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleEnable: function handleEnable() {
      return this.$refs.table ? this.$refs.table.multipleSelection == 0 : false;
    },
    handleAction: function handleAction(index) {
      switch (index) {
        case 0:
          this.handleAdd();
          break;

        case 1:
          this.handleSave();
          break;

        case 2:
          this.handleSearch();
          break;

        case 3:
          this.handleDone();
          break;
      }
    },
    handleSearch: function handleSearch() {
      this.listQuery.pageNum = 1;
      this.list = [];
      this.$setTableLoading(true);
      this.getList();
    },
    handleDone: function handleDone() {
      this.$emit('done', this.temp);
    },
    getList: function getList() {
      var _this = this;

      this.$setTableLoading(true);
      this.listQuery.stoStock = this.stoStock;
      this.listQuery.zptJtcode = this.zptJtcode ? 'N' : null;
      pageForGroup(this.listQuery).then(function (response) {
        if (response.data && response.data.list) {
          _this.list = response.data.list;
          _this.total = response.data.total;

          _this.$setTableLoading(false);

          _this.temp = _this.list[0];
        }
      });
    },
    handleClick: function handleClick(row) {
      this.temp = row;
    },
    handleDblclick: function handleDblclick(row) {
      this.$emit('done', row);
    }
  }
};