import _objectDestructuringEmpty from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectDestructuringEmpty.js";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import clip from '@/utils/clipboard'; // use clipboard directly

import { mapGetters } from 'vuex';
import VxeItemText from "./VxeItemText";
export default {
  name: 'BaseTable',
  components: {
    Pagination: Pagination,
    VxeItemText: VxeItemText
  },
  props: {
    temp: {
      required: true,
      type: Object
    },
    // 排序
    sortable: {
      type: Boolean
    },
    // 宽 自适应
    fit: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 排序字段
    sortProp: {
      type: String
    },
    // 开启多选
    checkbox: {
      type: Boolean
    },
    // 是否需要序号
    index: {
      type: Boolean
    },
    // 自定义序号函数
    indexMethod: {
      type: Function
    },
    // 自定义 checkbox是否可选
    selectInit: {
      type: Function
    },
    // 初始化 checkbox是否可选
    autoSelectCheckbox: {
      type: Function
    },
    // 字段集合
    columns: {
      required: true,
      type: Array
    },
    //每页数量区间
    pageSizes: {
      type: Array,
      default: function _default() {
        [15, 30, 50, 100];
      }
    },
    // 是否隐藏表头
    hideHeader: {
      type: Boolean
    },
    // 是否显示合计
    showSummary: {
      type: Boolean
    },
    rowKey: {
      type: String
    },
    treeProps: {
      type: Object
    },
    cellStyle: {
      type: Function
    },
    topSelect: {
      type: Boolean
    },
    expandAll: {
      type: Boolean,
      default: true
    },
    // 不高亮选择行
    notHighLight: {
      type: Boolean,
      default: false
    },
    // 自定义行列合并函数
    arraySpanMethod: {
      type: Function
    },
    // 自定义行列合并表头函数
    arrayCellStyle: {
      type: Function
    },
    // 自定义合计行函数
    summaryMethod: {
      type: Function
    },
    rowClassName: {},
    list: {
      required: true
    },
    // 列表查询对象
    listQuery: {
      required: true,
      type: Object
    },
    // 是否开启分页
    pagination: {
      // required: true,
      type: Boolean
    },
    total: {
      //required: false,
      type: Number
    },
    height: {
      type: [Number, String]
    },
    maxHeight: {
      type: Number
    },
    allCheck: {
      type: Boolean
    },
    checkSelect: {
      type: Boolean
    },
    listLoading: {
      type: Boolean
    },
    // 是否滚动到指定位置
    scrollTopLastLocation: {
      type: Boolean,
      default: true
    },
    // 清除存储行缓存数据
    clearLocalStorage: {
      type: Boolean
    },
    // 存储行数据
    localStorageCurrentKey: {
      type: String
    },
    // 存储行数据的唯一键
    defaultRowKey: {
      type: String,
      default: 'id'
    },
    selectList: {
      type: Array
    },
    checkType: {
      type: String
    },
    copyText: {
      type: Boolean
    },
    showSum: {
      type: Boolean
    },
    tableClass: {
      type: String
    },
    updateCol: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    listKey: {
      type: Number
    },
    idName: {
      type: String
    },
    rfmTable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      row: {},
      scrollTop: 0,
      updateTable: true,
      multipleSelection: [],
      curCell: {},
      // rowClassName: '',
      rowStyle: {
        height: this.$store.state.settings.tableHeight + 'px'
      }
    };
  },
  watch: {
    clearLocalStorage: function clearLocalStorage(val) {
      if (val === true) {
        localStorage.removeItem("".concat(this.localStorageCurrentKey, "_currentRow"));
      }
    },
    listKey: function listKey(val) {
      if (val) {
        this.fnUpdate();
      }
    },
    columns: {
      handler: function handler(val) {
        if (this.updateCol) {
          var hasNotUpdate = val.filter(function (v) {
            return v.notUpdateTable;
          });

          if (hasNotUpdate.length === 0) {
            this.fnUpdate();
          }
        }
      },
      deep: true
    },
    list: function list(value) {
      var _this = this;

      if (value && value.length > 0 && !this.notHighLight) {
        this.$nextTick(function () {
          _this.$refs.table && _this.$refs.table.bodyWrapper && _this.scrollTopLastLocation && (_this.$refs.table.bodyWrapper.scrollTop = _this.scrollTop);

          if (_this.$refs.table && _this.$refs.table.data && _this.$refs.table.data.length > 0 && (_this.selectList || _this.autoSelectCheckbox)) {
            _this.$refs.table.clearSelection();

            _this.$refs.table.data.forEach(function (item, index) {
              if (_this.autoSelectCheckbox) {
                if (_this.autoSelectCheckbox(item)) {
                  _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                }
              }

              _this.selectList && _this.selectList.forEach(function (item2) {
                if (!_this.checkType) {
                  if (item.zptCode === item2.zptCode) {
                    _this.$set(item, 'productNum', item2.productNum);

                    _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                  }
                } else if (_this.checkType === 'gpCheck') {
                  if (item.ctmCode === item2.ctmCode) {
                    _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                  }
                } else if (_this.checkType === 'lgCheck') {
                  if (item.stoZptcode === item2.stoZptcode) {
                    _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                  }
                } else if (_this.checkType === 'rvCheck') {
                  if (item.id === item2.id) {
                    _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                  }
                }
              });
            });
          }
        });

        if (this.allCheck) {
          this.fnSetCurrentRow(true);
          this.$nextTick(function () {
            for (var i = 0; i < value.length; i++) {
              _this.$refs.table.toggleRowSelection(value[i], true);
            }
          });
        } else {
          this.fnSetCurrentRow(false);
        }
      }
    },
    'listQuery.pageNum': {
      handler: function handler(val) {
        if (val > 1 && this.$refs.table) {
          this.$refs.table.bodyWrapper.scrollTop = 0;
        }
      },
      immediate: true
    },
    temp: function temp(val) {
      if (!val.id) {
        this.$refs.table.setCurrentRow();
      }
    },
    '$store.state.settings': {
      handler: function handler(val) {
        // console.log('val', val);
        this.rowStyle.height = val.tableHeight + 'px';
      },
      immediate: true,
      deep: true
    }
  },
  computed: _objectSpread({
    activeColumns: function activeColumns() {
      return this.columns && this.columns.filter(function (column) {
        return column.visible && !column.notColumn;
      });
    }
  }, mapGetters(['tableLoading'])),
  mounted: function mounted() {
    var _this2 = this;

    var that = this; // console.log('this', this);
    // console.log(this.listQuery.pageNum);

    this.columns && this.columns.forEach(function (item) {
      _this2.$set(item, 'visible', true);
    });
    this.rowStyle.height = this.$store.state.settings.tableHeight + 'px';

    window.onresize = function () {
      return function () {
        that.$emit('update:height', window.innerHeight - 260);
      }();
    };
  },
  methods: {
    fnSetCurrentRow: function fnSetCurrentRow(bool) {
      var _this3 = this;

      // 判断是否含有存储当前选择行
      if (!this.clearLocalStorage && this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
        // 获得存储的数据
        var currentRow = JSON.parse(localStorage.getItem("".concat(this.localStorageCurrentKey, "_currentRow"))); // 找到当前数据在查询出来数据的位置

        var data = [];

        if (currentRow && currentRow[this.defaultRowKey]) {
          data = this.list.filter(function (v) {
            return v[_this3.defaultRowKey] === currentRow[_this3.defaultRowKey];
          });
        } // 当缓存数据在表格数据里


        if (data.length > 0) {
          this.handleClick(data[0]); // 设置数据的高亮

          this.$refs.table.setCurrentRow(data[0]);
        } else {
          // 判断是否设置第一条数据高亮
          if (bool) {
            this.$refs.table.setCurrentRow(this.list[0]);
          }
        }
      } else {
        // 判断是否设置第一条数据高亮
        if (bool) {
          this.$refs.table.setCurrentRow(this.list[0]);
        }
      }
    },
    fnUpdate: function fnUpdate() {
      var _this4 = this;

      console.log('updateCol');
      this.updateTable = false;
      this.$nextTick(function () {
        _this4.updateTable = true;

        _this4.$nextTick(function () {
          _this4.fnSetCurrentRow(false);
        });
      });
    },
    handleClose: function handleClose(row) {
      this.$emit('close', row);
    },
    // 监听多选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('selected', this.multipleSelection.length);
      this.$emit('multipleSelection', val);
    },
    // 分页点击页码跳转
    getList: function getList() {
      this.listQuery.pageSize = this.listQuery.pageSize;
      this.$emit('pagination');
    },
    cellClick: function cellClick(row, column, cell) {
      // console.log('column', column);
      this.curCell = {
        row: row.index,
        field: column.property
      }; //  console.log('curCell', this.curCell);

      this.$emit('cell-click', row, column, cell);
    },
    // 行单击事件
    handleClick: function handleClick(row, column) {
      // 通用的缓存数据key
      if (this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
        localStorage.setItem("".concat(this.localStorageCurrentKey, "_currentRow"), JSON.stringify(row));
      } // 滚动到指定位置


      if (this.scrollTopLastLocation && column) {
        this.scrollTop = this.$refs.table.bodyWrapper.scrollTop;
      } // if(row != this.row){


      this.row = row; // console.log('row-Table',row);

      this.$emit('click', row, column); // }
    },
    cellClass: function cellClass(row) {
      // console.log(row)
      if (this.topSelect) {
        if (row.columnIndex === 0) {
          return 'DisableSelection';
        } else {
          return 'DisableSelection';
        }
      } else {
        return 'table-header';
      }
    },
    // table 禁选
    selectCheckbox: function selectCheckbox(row) {
      if (this.checkSelect && row.verifyState != 1 || row.isBill === '已开票') {
        return false; //不可勾选
      } else {
        return true; //可勾选
      }
    },
    select: function select(selection, row) {
      if (selection.length > 1 && row.onlySelect) {
        var del_row = selection.shift();
        this.$refs.table.toggleRowSelection(del_row, false);
      }

      this.$emit('selectItem', {
        selection: selection,
        row: row
      });
    },
    selectAll: function selectAll(selection) {
      // console.log(selection);
      if (selection.length > 1 && selection[0].onlySelect) {
        this.$refs.table.clearSelection();
      }

      this.$emit('allSelect', selection);
    },
    // 行双击事件
    handleDblclick: function handleDblclick(row, column) {
      // 通用的缓存数据key
      // if (this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
      //   localStorage.setItem(`${this.localStorageCurrentKey}_currentRow`, JSON.stringify(row));
      // }
      if (this.rfmTable) {
        this.$emit('dblclick', {
          row: row,
          column: column
        });
      } else {
        this.$emit('dblclick', row);
      }
    },
    // 单元格双击事件
    handleCellDblClick: function handleCellDblClick(row, column, cell, event) {
      if (this.copyText) {
        console.log('window.getSelection().toString()', window.getSelection().focusNode.data);
        clip(window.getSelection().focusNode.data, event, column);
      }

      this.$emit('cell-dblclick', row, column);
    },
    // 选择行
    setCurrentRow: function setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },
    toggleRowSelection: function toggleRowSelection(row) {
      this.$refs.table.toggleRowSelection(row);
    },
    // 给行、字段添加序号
    tableRowClassName: function tableRowClassName(_ref) {//注意这里是解构

      _objectDestructuringEmpty(_ref);
    },
    tableCellClassName: function tableCellClassName(_ref2) {
      var row = _ref2.row,
          column = _ref2.column,
          rowIndex = _ref2.rowIndex,
          columnIndex = _ref2.columnIndex;
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex - 1;
    },
    getCanEdit: function getCanEdit(column, row) {
      if (column.canEdit === true) {
        return true;
      }

      if (column.canEdit && column.canEdit(row)) {
        return true;
      }

      return false;
    }
  }
};