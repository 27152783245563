var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b({ staticClass: "scrollbar" }, "div", _vm.$attrs, false),
    [
      _c(
        "div",
        {
          ref: "wrap",
          class: [
            _vm.wrapClass,
            "scrollbar__wrap",
            _vm.native ? "" : "scrollbar__wrap--hidden-default",
          ],
          on: { scroll: _vm.handleScroll, wheel: _vm.handleWheel },
        },
        [
          _c(
            _vm.tag,
            {
              ref: "resize",
              tag: "component",
              class: ["scrollbar__view", _vm.viewClass],
              style: "height:" + _vm.viewH + "px;position:relative",
            },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.native
        ? [
            _c("bar", {
              attrs: {
                vertical: true,
                move: _vm.moveY,
                boxHeigth: _vm.boxHeigth,
                viewH: _vm.viewH,
                sizeHeight: _vm.sizeHeight,
                size: _vm.sizeHeight,
                stateForm: _vm.state,
              },
              on: {
                onScroll: function ($event) {
                  return _vm.$emit("onScroll", $event)
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }