import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { queryDisaccList, delDisacc, queryDisaccListExport } from '@/api/stock';
import BaseHeader from "../components/BaseHeader.vue";
import BaseTable from "../components/BaseTable.vue";
import WfForm from "./wf-form";
import QkTable from "./qk-table";
import { mapGetters } from 'vuex';
import { paymentHistory, payBackMoney, accountSettled, getDisaccById, printPayBackMoney, getRevenueAccountById } from '@/api/cash';
import { printPdf } from '@/utils/iframePrintPDF';
import { mapEarList } from '@/utils/index';
export default {
  name: 'YsForm',
  components: {
    BaseHeader: BaseHeader,
    BaseTable: BaseTable,
    WfForm: WfForm,
    QkTable: QkTable
  },
  props: {
    height: {
      required: true
    },
    ctmCode: {
      type: String
    },
    earId: {
      required: true,
      type: String
    },
    checkItem: {
      type: Object
    },
    approvalInfo: {
      type: Object
    }
  },
  filters: {
    statusFilter: function statusFilter(status, type) {
      var map = {
        NEW: {
          type: 'primary',
          title: '新单'
        },
        CFM: {
          type: 'warning',
          title: '未还款'
        },
        FNS: {
          type: 'success',
          title: '还款完成'
        },
        AST: {
          type: 'danger',
          title: '清讫'
        },
        RTD: {
          type: 'info',
          title: '退款'
        }
      };
      return map[status][type];
    },
    payFilter: function payFilter(status, type) {
      var map = {
        A: {
          type: 'info',
          title: '现金'
        },
        B: {
          type: 'warning',
          title: '卡'
        },
        E: {
          type: 'warning',
          title: '卡'
        }
      };
      return map[status][type];
    }
  },
  computed: _objectSpread({}, mapGetters(['employees', 'rightWidth', 'empCode', 'eareList'])),
  watch: {// "customer.dacStatus": {
    //   handler (val) {
    //     console.log('dacStatus -> ', val);
    //     if (val == 'FNS') {
    //       this.payAllDisabled = true
    //     } else {
    //       this.payAllDisabled = false
    //     }
    //   },
    //    deep: true,
    //   immediate: true
    // }
  },
  data: function data() {
    return {
      customer: {},
      activeName: '1',
      rules: {
        cpyRmamt: [{
          required: true,
          message: '请输入还款金额',
          trigger: 'blur'
        }],
        cpyCardamt1: [{
          required: true,
          message: '请输入卡1金额',
          trigger: 'blur'
        }],
        cpyCardamt2: [{
          required: true,
          message: '请输入卡2金额',
          trigger: 'blur'
        }]
      },
      actions: [{
        title: '刷新',
        icon: 'el-icon-refresh',
        type: ''
      }, // {title:'增加',icon:'el-icon-plus', type:'success'},
      // {title:'编辑',icon:'el-icon-edit', type:'primary'},
      // { title: '删除', icon: 'el-icon-delete', type: 'danger', enable: this.getEnable },
      // {title:'审核',icon:'el-icon-shopping-cart-full', type:'primary'},
      {
        title: '还款',
        icon: 'el-icon-coin',
        type: 'primary',
        enable: this.payDisabled
      }, {
        title: '明细',
        icon: 'el-icon-trophy',
        type: 'success'
      } //  {title:'清讫',icon:'el-icon-edit', type:'warning', enable:this.payDisabled2},
      // {title:'高级',icon:'el-icon-bell', type:'warning'},
      // {title:'保存',icon:'el-icon-folder-opened', type:'warning'}
      ],
      curCell: {},
      list1: [],
      list2: [],
      form: {
        cpyRmamt: 0,
        cpyCardamt1: 0,
        cpyCardamt2: 0
      },
      customer2: {},
      exportMessage: {
        exportTaskName: '应付未付款导出'
      },
      dialogVisible1: false,
      dialogVisible2: false,
      payAllDisabled: false,
      dialogVisibleQk: false,
      print: true,
      total: 0,
      total2: 0,
      temp: {},
      rvTypes: [],
      columns1: [],
      columns2: [],
      payTypeList: [{
        title: '现金支付',
        field: 'A'
      }, {
        title: '刷卡支付',
        field: 'B'
      }],
      statusList: [// {field: 'NEW', title: '新单'},
      {
        field: 'CFM',
        title: '未还款'
      }, {
        field: 'FNS',
        title: '还款完成'
      }, // {field: 'AST', title: '清讫'},
      {
        field: 'RTD',
        title: '退款'
      }],
      listQuery: {
        pageNum: 1,
        pageSize: 50,
        earId: ''
      },
      listQuery2: {
        pageNum: 1,
        pageSize: 100
      }
    };
  },
  created: function created() {
    var _this = this;

    this.listQuery.earId = this.listQuery.earId || this.earId;
    this.columns1 = [// {title:'营业开始日期',field:'startDate',operate:'date',notColumn:true,searchable:true,width:'150px'},
    // {title:'营业结束日期',field:'endDate',operate:'date',notColumn:true,searchable:true,width:'150px'},
    {
      title: '交易分院',
      field: 'earId',
      width: '80px',
      searchable: true,
      searchList: mapEarList(this.eareList, this.earId, function () {
        return true;
      }),
      formatter: this.getColumnValueBaseTable
    }, {
      title: '单据状态',
      field: 'dacStatus',
      width: '80px',
      searchable: true,
      searchList: this.statusList,
      filter: this.$options.filters.statusFilter
    }, {
      title: '账务编号',
      field: 'dacCode',
      searchable: false,
      width: '120px'
    }, // {title:'营业日期',field:'dacDate',searchable:false,width:'95px'},
    {
      title: '客户卡号',
      field: 'dacCtmcode',
      width: '120px',
      searchable: true
    }, {
      title: '客户姓名',
      field: 'dacCtmName',
      notColumn: true,
      width: '80px',
      searchable: true
    }, {
      title: '客户姓名',
      field: 'dacCtmname',
      width: '80px',
      searchable: false
    }, {
      title: '客户类别',
      field: 'ctmBirthday',
      width: '80px'
    }, {
      title: '产品编号',
      field: 'dacZptcode',
      width: '120px'
    }, {
      title: '产品名称',
      field: 'dacZptname',
      width: '120px'
    }, {
      title: '售出数量',
      field: 'dacPaynum',
      width: '80px'
    }, {
      title: '应收总额',
      field: 'dacAccount',
      width: '80px'
    }, // {title:'本单已付',field:'dacPayamt',width:'80px'},
    {
      title: '本单未付',
      field: 'dacDisacc',
      searchable: false,
      width: '120px'
    }, {
      title: '累计还款',
      field: 'dacPaytol',
      width: '120px'
    }, {
      title: '尚欠金额',
      field: 'dacDisacc2',
      width: '120px'
    }, {
      title: '所属销售单',
      field: 'dacPidno',
      width: '120px'
    }, {
      title: '销售发票号',
      field: 'dacCtmid',
      width: '120px'
    }, {
      title: '推荐顾问',
      field: 'tjEmpName',
      width: '120px'
    }, {
      title: '助推/二级咨询',
      field: 'ztEmpName',
      width: '120px'
    }, // {title:'医生',field:'ctfSjTime',width:'120px'},
    {
      title: '变动时间',
      field: 'updateTime',
      width: '120px'
    }, {
      title: '操作员',
      field: 'dacOptername',
      width: '120px'
    }, // {title:'主管工号',field:'dacOpter',width:'120px'},
    {
      title: '说明',
      field: 'dacRemark',
      width: '120px'
    }];
    this.columns2 = [// {title:'账单状态',field:'acStatus',width:'80px',filter:this.$options.filters.statusFilter},
    {
      title: '账务编号',
      field: 'acType',
      searchable: true,
      width: '120px'
    }, {
      title: '营业日期',
      field: 'revenueDate',
      searchable: true,
      width: '95px'
    }, {
      title: '发生时间',
      field: 'acTime',
      width: '120px'
    }, {
      title: '账务科目',
      field: 'acName',
      width: '80px'
    }, {
      title: '账务明细',
      field: 'acDetail',
      width: '180px'
    }, {
      title: '还款金额',
      field: 'acAmtStr',
      width: '120px'
    }, {
      title: '现金',
      field: 'dacRmamt',
      width: '120px'
    }, {
      title: '卡1',
      field: 'dacCardamt1',
      width: '120px'
    }, {
      title: '卡2',
      field: 'dacCardamt2',
      width: '120px'
    }, {
      title: '还款方式',
      field: 'payType',
      width: '120px',
      filter: this.$options.filters.payFilter
    }, {
      title: '卡类型',
      field: 'cardName',
      width: '120px'
    }, {
      title: '操作人',
      field: 'acOpterName',
      width: '120px'
    }, // {title:'审核人',field:'dacPaynum',width:'80px'},
    // {title:'审核时间',field:'dacPayamt',width:'80px'},
    {
      title: '对应应收账编号',
      field: 'acCode',
      width: '120px'
    }, {
      title: '对应销售发票号',
      field: 'salesNo',
      searchable: true,
      width: '120px'
    }, {
      title: '对应产品',
      field: 'dacZptname',
      width: '120px'
    }, {
      title: '备注',
      field: 'dacRemark',
      width: '120px'
    }]; //this.listQuery.startDate = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss')
    // this.$set(this.listQuery,'startDate',moment().subtract(1,'year').format('YYYY-MM-DD HH:mm:ss'))
    // this.$set(this.listQuery,'endDate',moment().format('YYYY-MM-DD HH:mm:ss'))
    // this.listQuery.endDate = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')

    if (this.checkItem) {
      this.actions = [{
        title: '提交审核',
        icon: 'el-icon-check',
        type: 'danger',
        enable: function enable() {
          return _this.customer.verifyState == '4';
        }
      }, this.getlist2()];
    } else {
      this.getlist1();
    }
  },
  methods: {
    handleAction: function handleAction(index) {
      if (this.checkItem) {
        this.$emit('sureCheck');
      } else {
        switch (index) {
          case 0:
            this.handleRefresh();
            break;
          // case 1:
          //   this.customer = {dacStatus: 'NEW', dacOpter: this.empCode}
          //   this.dialogVisible1 = true
          //   break;
          // case 2:
          //   this.dialogVisible1 = true
          //   break;
          // case 1:
          //   this.handleDel();
          //   break;

          case 1:
            this.handleSave();
            break;

          case 2:
            this.dialogVisibleQk = true; // this.handleShitf()

            break;
        }
      }
    },
    handleShitf: function handleShitf() {
      var _this2 = this;

      this.$confirm('清讫操作需要审核,确定要清讫吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // this.customer.dacStatus	= 'FNS'
        accountSettled({
          zsbDisaccId: _this2.customer.id
        }).then(function (res) {
          if (res.code == 200) {
            _this2.$message({
              type: 'success',
              message: '提交审核成功!',
              onClose: function onClose() {
                _this2.getlist1();
              }
            });
          }
        });
      });
    },
    handleSave: function handleSave() {
      this.dialogVisible2 = true; // this.$confirm('确定要还款吗?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.customer.dacStatus	= 'FNS'
      //   updateDisacc(this.customer).then( res => {
      //     console.log(res);
      //   })
      // })
    },
    handleDel: function handleDel() {
      var _this3 = this;

      if (!this.customer2 || !this.customer2.id || this.customer2.acAmt < 0) {
        this.$alert('请选择一条还款记录', '提示', {
          confirmButtonText: '确定',
          type: 'error',
          callback: function callback() {}
        });
        return;
      }

      this.$confirm(' 还款删除需要审核,确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delDisacc({
          id: _this3.customer2.id
        }).then(function (response) {
          _this3.$setTableLoading(false);

          if (response.code == 200) {
            _this3.$message({
              type: 'success',
              message: '提交审核成功!',
              onClose: function onClose() {
                _this3.getlist1();

                _this3.getlist2();
              }
            });
          }
        });
      }).catch(function () {});
    },
    setClear: function setClear() {
      this.$refs.form.resetFields();
    },
    handleCancel: function handleCancel() {
      this.dialogVisible2 = false;
    },
    getEnable: function getEnable() {
      return this.customer2.id ? false : true;
    },
    payDisabled: function payDisabled() {
      return this.customer.dacStatus == 'FNS' || this.customer.dacStatus == 'RTD' ? true : false;
    },
    payDisabled2: function payDisabled2() {
      return this.customer.dacStatus == 'RTD' ? true : false;
    },
    handleDone2: function handleDone2(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.form.zsbDisaccId = _this4.customer.id;
          payBackMoney(_this4.form).then(function (res) {
            if (res && res.code == 200) {
              //  this.getlist1()
              _this4.$refs.form.resetFields();

              _this4.$message({
                type: 'success',
                message: '还款成功!',
                onClose: function onClose() {
                  _this4.getlist2();

                  _this4.getlist1();

                  _this4.dialogVisible2 = false;

                  if (_this4.print) {
                    _this4.printAgain(res.data.dacCode);
                  }
                }
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    printAgain: function printAgain(code) {
      var dacCode = code ? code : this.customer2.acCode;
      var earId = code ? null : this.customer2.earId;
      printPayBackMoney({
        dacCode: dacCode,
        earId: earId
      }).then(function (res) {
        if (res.code == 200) {
          printPdf(res.data);
        }
      });
    },
    handleDone: function handleDone() {
      this.dialogVisible1 = false;
      this.handleRefresh();
    },
    handleSearch1: function handleSearch1() {
      this.getlist1();
    },
    handleSearch2: function handleSearch2() {
      this.getlist2();
    },
    getlist1: function getlist1() {
      var _this5 = this;

      this.list1Loading = true; // this.listQuery.earId == '000' ? (this.listQuery.earId = null) : null;

      if (this.checkItem) {
        var params = JSON.parse(this.checkItem.requestParams);
        console.log('checkItem', this.checkItem); // this.listQuery.dacCtmcode = this.checkItem.ctpCtmcode

        getDisaccById({
          id: params.id
        }).then(function (response) {
          _this5.list1Loading = false;
          console.log('response', response);

          if (response.data) {
            var list1 = [];
            list1.push(response.data);
            list1.forEach(function (item) {
              if (item.dacStatus == 'RTD') {
                item.dacDisacc2 = 0;
              } else {
                item.dacDisacc2 = item.dacDisacc - item.dacPaytol;
                Math.ceil(item.dacDisacc2 * 100) / 100.;
                item.dacDisacc2 = item.dacDisacc2.toFixed(2);
              }
            });
            _this5.list1 = list1;
            _this5.total = response.data.total;

            if (_this5.list1.length > 0) {
              _this5.customer = JSON.parse(JSON.stringify(_this5.list1[0]));
            }
          }
        });
      } else {
        this.exportMessage.fun = queryDisaccListExport;
        this.exportMessage.query = this.listQuery;
        queryDisaccList(this.listQuery).then(function (response) {
          _this5.list1Loading = false;

          if (response.data && response.data.list) {
            var list1 = response.data.list;
            list1.forEach(function (item) {
              if (item.dacStatus == 'RTD') {
                item.dacDisacc2 = 0;
              } else {
                item.dacDisacc2 = item.dacDisacc - item.dacPaytol;
                Math.ceil(item.dacDisacc2 * 100) / 100.;
                item.dacDisacc2 = item.dacDisacc2.toFixed(2);
              }
            });
            _this5.list1 = list1;
            _this5.total = response.data.total;

            if (_this5.list1.length > 0) {
              _this5.customer = JSON.parse(JSON.stringify(_this5.list1[0]));
            }
          }
        });
      } // this.listQuery.startDate = moment( this.listQuery.startDate).format('YYYY-MM-DD HH:mm:ss')
      // this.listQuery.endDate = moment( this.listQuery.endDate).format('YYYY-MM-DD HH:mm:ss')

    },
    getlist2: function getlist2() {
      var _this6 = this;

      var params = this.checkItem ? JSON.parse(this.checkItem.requestParams) : {};
      this.list1Loading = true;
      this.listQuery2.ctmCode = this.customer.dacCtmcode || this.checkItem.dacCtmcode;
      this.listQuery2.cpyId = this.customer.dacCtmid || JSON.parse(this.checkItem.requestParams).id;
      console.log('1111'); // if (this.customer.dacCtmcode) {

      if (params.id) {
        getRevenueAccountById({
          id: params.id
        }).then(function (response) {
          _this6.list1Loading = false;
          console.log('response', response);

          if (response.data) {
            var list2 = [];
            list2.push(response.data);
            list2.forEach(function (item) {
              if (item.dacStatus == 'RTD') {
                item.dacDisacc2 = 0;
              } else {
                item.dacDisacc2 = item.dacDisacc - item.dacPaytol;
              }
            });
            _this6.list2 = list2;
            _this6.total = response.data.total;

            if (_this6.list2.length > 0) {// this.customer = JSON.parse(JSON.stringify(this.list1[0]))
            }
          }
        });
      } else {
        paymentHistory(this.listQuery2).then(function (response) {
          // console.log(response);
          _this6.list1Loading = false;

          if (response.data && response.data.items) {
            var list2 = response.data.items;
            _this6.list2 = list2; // console.log(this.list1)

            _this6.total2 = response.data.total; // if (this.list2.length > 0) this.customer2 = JSON.parse(JSON.stringify(this.list2[0])); 拒绝默认选择一条
          }
        });
      } // }

    },
    handleSure: function handleSure() {
      this.$emit('select', this.customer);
    },
    handleRefresh: function handleRefresh() {
      this.listQuery.pageNum = 1;
      this.list1 = [];
      this.list2 = [];
      this.getlist1();
    },
    handleClick: function handleClick(row) {
      this.customer = row;
      this.customer2 = {};
      this.getlist2();
    },
    handleClick2: function handleClick2(row) {
      this.customer2 = row;
    },
    handleDblclick: function handleDblclick(row) {
      this.customer = row;
      this.dialogVisible1 = true;
    }
  }
};