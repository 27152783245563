import _defineProperty from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
import { on, off } from '@/utils/domEvent';
var BAR_MAP = {
  vertical: {
    offset: 'offsetHeight',
    scroll: 'scrollTop',
    scrollSize: 'scrollHeight',
    size: 'height',
    key: 'vertical',
    axis: 'Y',
    client: 'clientY',
    direction: 'top'
  },
  horizontal: {
    offset: 'offsetWidth',
    scroll: 'scrollLeft',
    scrollSize: 'scrollWidth',
    size: 'width',
    key: 'horizontal',
    axis: 'X',
    client: 'clientX',
    direction: 'left'
  }
};
export default {
  name: 'bar',
  props: {
    move: {},
    size: {},
    vertical: {},
    boxHeigth: {},
    sizeHeight: {},
    stateForm: {},
    viewH: {}
  },
  data: function data() {
    return {
      bar: {},
      offsetTop: 0,
      state: {
        X: 0,
        staval: 0
      }
    };
  },
  computed: {
    getStyle: function getStyle() {
      var radio = this.size;
      var clientSize = this.boxHeigth;
      var size = 0;

      if (radio > 100) {
        size = Number.POSITIVE_INFINITY;
      }

      if (radio > 50) {
        size = radio * clientSize / 100;
      }

      size = radio * clientSize;
      var SCROLLBAR_MAX_SIZE = clientSize / 3;
      return _defineProperty({
        transform: "translate".concat(this.bar.axis, "(").concat(this.state.staval, "px)")
      }, this.bar.size, Math.floor(Math.min(SCROLLBAR_MAX_SIZE, Math.max(size, SCROLLBAR_MAX_SIZE))) + 'px');
    },
    totalSteps: function totalSteps() {
      return Math.floor(this.boxHeigth - this.$refs.thumbRef.offsetHeight - 4);
    },
    trackStyle: function trackStyle() {
      return {
        height: '170px'
      };
    }
  },
  created: function created() {
    this.bar = BAR_MAP[this.vertical ? 'vertical' : 'horizontal'];
  },
  watch: {
    stateForm: {
      deep: true,
      handler: function handler(value) {
        this.state.staval = Math.ceil(value.scrollOffset / (this.viewH - this.boxHeigth) * this.totalSteps);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    handleMousedown: function handleMousedown(e) {
      e.stopPropagation();
      this.state.X = e.target.offsetHeight - (e.clientY - e.target.getBoundingClientRect().top);
      this.state.isDragging = true;
      this.attachEvent();
    },
    mouseup: function mouseup() {
      this.state.isDragging = false;
      this.state.X = 0;
      this.detachEvents();
    },
    thumMouseMove: function thumMouseMove(e) {
      var _this = this;

      e.stopPropagation();

      if (this.state.isDragging) {
        var prevPage = this.state.X;
        var offset = (this.$refs.trackRef.getBoundingClientRect().top - e.clientY) * -1;
        var thunbClickPosition = this.$refs.thumbRef.offsetHeight - prevPage;
        var distance = offset - thunbClickPosition;
        setTimeout(function () {
          _this.$emit('onScroll', {
            distance: distance,
            totalSteps: _this.totalSteps
          });
        }, 16); // this.attachEvent();
      }
    },
    attachEvent: function attachEvent() {
      on(window, 'mousemove', this.thumMouseMove);
      on(window, 'mouseup', this.mouseup);
    },
    detachEvents: function detachEvents() {
      off(window, 'mousemove', this.thumMouseMove);
      off(window, 'mouseup', this.mouseup);
    }
  }
};