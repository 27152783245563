import { render, staticRenderFns } from "./ck-form.vue?vue&type=template&id=1f3bf458&"
import script from "./ck-form.vue?vue&type=script&lang=js&"
export * from "./ck-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f3bf458')) {
      api.createRecord('1f3bf458', component.options)
    } else {
      api.reload('1f3bf458', component.options)
    }
    module.hot.accept("./ck-form.vue?vue&type=template&id=1f3bf458&", function () {
      api.rerender('1f3bf458', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/check/ck-form.vue"
export default component.exports