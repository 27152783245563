import "vxe-table/lib/footer/style";
import _Footer from "vxe-table/lib/footer";
import "vxe-table/lib/tooltip/style";
import _Tooltip from "vxe-table/lib/tooltip";
import "vxe-table/lib/table/style";
import _Table from "vxe-table/lib/table";
import "vxe-table/lib/keyboard/style";
import _Keyboard from "vxe-table/lib/keyboard";
import "vxe-table/lib/edit/style";
import _Edit from "vxe-table/lib/edit";
import "vxe-table/lib/colgroup/style";
import _colgroup from "vxe-table/lib/colgroup";
import "vxe-table/lib/header/style";
import _Header from "vxe-table/lib/header";
import "vxe-table/lib/column/style";
import _Column from "vxe-table/lib/column";
import "vxe-table/lib/v-x-e-table/style";
import _VXETable from "vxe-table/lib/v-x-e-table";
import "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/core-js/modules/es6.promise.js";
import "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/core-js/modules/es6.object.assign.js";
import "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/core-js/modules/es7.promise.finally.js";

/*
 * @Author: yangchao
 * @Date: 2020-08-11 09:19:11
 * @LastEditors: yangchao
 * @LastEditTime: 2021-12-22 16:16:30
 * @Description:
 */
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import MyDirectives from "./directive/directives";
import dialogDirectives from "./directive/el-drag-dialog";
import asideDrag from "./directive/aside-drag";
import tableScroll from "./directive/table-scroll";
import focus from "./directive/el-focus";
import globalMixin from "./mixins/global";
import jquery from 'jquery';
import 'print-js/dist/print.css'; // import { global } from '@/utils/global.js';
// 引入vxetable 依赖 测试

import XEUtils from 'xe-utils';
import VXETablePluginElement from 'vxe-table-plugin-element';
import 'vxe-table-plugin-element/dist/style.css'; // VXETable.use(VXETablePluginElement);

import zhCNLocat from 'vxe-table/lib/locale/lang/zh-CN';

_VXETable.setup({
  i18n: function i18n(key, args) {
    return XEUtils.toFormatString(XEUtils.get(zhCNLocat, key), args);
  }
});

_VXETable.use(VXETablePluginElement);

Vue.use(_Column);
Vue.use(_Header);
Vue.use(_colgroup);
Vue.use(_Edit);
Vue.use(_Keyboard);
Vue.use(_Table);
Vue.use(_Tooltip);
Vue.use(_Footer); // import 'default-passive-events'

/**
 * 去除 由element-ui 引起的 chrome 警报:
 * [Violation] Added non-passive event listener to a scroll-blocking
 * 'mousewheel' event. Consider marking event handler as 'passive'
 * to make the page more responsive.
 */

window.jquery = window.$ = jquery;
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */

Vue.mixin(globalMixin);
Vue.use(MyDirectives);
Vue.use(dialogDirectives);
Vue.use(asideDrag);
Vue.use(tableScroll);
Vue.use(focus);
console.log('cwd', process.cwd());
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size

});
Vue.config.productionTip = false; // Vue.config.silent = true

new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}); // window.addEventListener('visibilitychange', function() {
//   if (
//     document.hidden == false &&
//     localStorage.getItem('userName') &&
//     store.getters.empCode != localStorage.getItem('userName').toUpperCase()
//   ) {
//     let userAgent = navigator.userAgent;
//     if (userAgent.indexOf('Firefox') != -1 || userAgent.indexOf('Chrome') != -1) {
//       window.location.href = 'about:blank';
//       window.close();
//     } else {
//       window.opener = null;
//       window.open('', '_self');
//       window.close();
//     }
//   }
//   //不覆盖的话aaa永远都是我们设的初始值
// });