var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.mangerEareList.length !== 0
            ? _c(
                "el-select",
                {
                  staticClass: "manger_eare_select",
                  attrs: { placeholder: "", size: "mini" },
                  on: { change: _vm.earChange },
                  model: {
                    value: _vm.mangerEareId,
                    callback: function ($$v) {
                      _vm.mangerEareId = $$v
                    },
                    expression: "mangerEareId",
                  },
                },
                _vm._l(_vm.mangerEareList, function (item) {
                  return _c("el-option", {
                    key: item.earId,
                    attrs: { label: item.earName, value: item.earId },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.device !== "mobile"
            ? [
                _c("div", { staticClass: "right-menu-item hover-effect" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.userInfo.belongEareName) +
                      " " +
                      _vm._s(
                        _vm.userInfo.dptName ? _vm.userInfo.dptName + "-" : ""
                      ) +
                      _vm._s(_vm.userInfo.posName) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "right-menu-item hover-effect",
                    on: { click: _vm.openLock },
                  },
                  [_c("svg-icon", { attrs: { "icon-class": "lock" } })],
                  1
                ),
                _vm._v(" "),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-badge",
            { attrs: { value: _vm.mesNum, type: "warning" } },
            [
              _c("router-link", { attrs: { to: "/message/index" } }, [
                _c("i", {
                  class: { "el-icon-bell": true, iconAnimate: _vm.mesNum > 0 },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("../../assets/images/avatar.png") },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "12px",
                      "margin-left": "5px",
                      "margin-top": "-5px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.userName))]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "system",
                      staticStyle: { width: "100%" },
                      attrs: { mode: "vertical", router: "", collapse: "" },
                    },
                    [
                      _c(
                        "el-submenu",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { index: "2" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("后台管理"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-menu-item",
                            { attrs: { index: "2-1", route: "/manage/index" } },
                            [_vm._v("基础信息管理")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-menu-item",
                            {
                              attrs: {
                                index: "2-2",
                                route: "/manage/information",
                              },
                            },
                            [_vm._v("后台信息管理")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.dialogVisibleKp = true
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("发票"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.drawer = true
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("布局设定"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.dialogVisible3 = true
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("修改密码"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "append-to-body": "",
            "with-header": false,
            visible: _vm.drawer,
            size: "250px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [_c("settings")],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "系统提醒管理",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
                top: "50px",
                width: "70%",
                center: "",
                "append-to-body": "",
                "destroy-on-close": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("messag", {
                ref: "dialog1",
                attrs: {
                  "ear-id": _vm.earId,
                  employees: _vm.employees,
                  height: _vm.innerHeight - 160,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "屏幕保护",
                "append-to-body": "",
                visible: _vm.dialogVisible2,
                width: "420px",
                top: (_vm.innerHeight - 260) / 2 + "px",
                "close-on-click-modal": false,
                "show-close": false,
                "close-on-press-escape": false,
                "validate-on-rule-change": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "screenForm",
                  attrs: {
                    size: "mini",
                    inline: true,
                    model: _vm.screenForm,
                    rules: _vm.rules,
                    "label-width": "150px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "20px",
                      },
                      attrs: { label: "操作员密码:", prop: "password" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputPsd",
                        staticStyle: { width: "150px" },
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          "validate-event": _vm.screenForm.password.length == 0,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$refs.screenForm.clearValidate()
                          },
                        },
                        model: {
                          value: _vm.screenForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.screenForm, "password", $$v)
                          },
                          expression: "screenForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "warning" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("重新登录")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        disabled: _vm.screenForm.password.length == 0,
                      },
                      on: {
                        click: function ($event) {
                          _vm.screenForm.password = ""
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handlePassword },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible3
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "修改密码",
                "append-to-body": "",
                visible: _vm.dialogVisible3,
                width: "50%",
                top: (_vm.innerHeight - 260) / 2 + "px",
                "close-on-click-modal": false,
                "show-close": false,
                "close-on-press-escape": false,
                "validate-on-rule-change": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible3 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "request",
                  attrs: {
                    size: "mini",
                    inline: true,
                    model: _vm.request,
                    rules: _vm.rules2,
                    "label-width": "150px",
                    "label-position": "right",
                  },
                },
                [
                  false
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            "margin-top": "20px",
                            "margin-bottom": "20px",
                          },
                          attrs: { label: "用户名:", prop: "username" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "170px" },
                            attrs: { type: "text", autocomplete: "off" },
                            model: {
                              value: _vm.request.userName,
                              callback: function ($$v) {
                                _vm.$set(_vm.request, "userName", $$v)
                              },
                              expression: "request.userName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "20px",
                      },
                      attrs: { label: "原密码:", prop: "password" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputPsd",
                        staticStyle: { width: "170px" },
                        attrs: { type: "text", autocomplete: "off" },
                        model: {
                          value: _vm.request.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "password", $$v)
                          },
                          expression: "request.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "20px",
                      },
                      attrs: { label: "新密码:", prop: "newPassword" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputPsd",
                        staticStyle: { width: "170px" },
                        attrs: { type: "text", autocomplete: "off" },
                        model: {
                          value: _vm.request.newPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "newPassword", $$v)
                          },
                          expression: "request.newPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-bottom": "20px",
                      },
                      attrs: { label: "确认密码:", prop: "newPasswordAgain" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "inputPsd",
                        staticStyle: { width: "170px" },
                        attrs: { type: "text", autocomplete: "off" },
                        model: {
                          value: _vm.request.newPasswordAgain,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "newPasswordAgain", $$v)
                          },
                          expression: "request.newPasswordAgain",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.sureChange },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.resetDialog },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisibleKp
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户消费发票管理",
                visible: _vm.dialogVisibleKp,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "50px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibleKp = $event
                },
                close: function ($event) {
                  return _vm.handleTabClick({ name: _vm.activeName })
                },
              },
            },
            [
              _c("kp-table", {
                ref: "customer",
                attrs: {
                  customer: {},
                  "ear-id": _vm.earId,
                  "ctf-zd-doc-list": _vm.employees,
                  height: _vm.innerHeight - 160,
                },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible1 = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }