import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { tempList } from '@/api/manage';
import BaseTable from "./BaseTable.vue";
export default {
  name: 'TablePopover',
  components: {
    BaseTable: BaseTable
  },
  data: function data() {
    return {
      list: [],
      customer: {},
      columns: [{
        title: '内容(双击选择)',
        field: 'name',
        searchable: true,
        width: '320px'
      }],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 100
      },
      visible: false,
      update: false
    };
  },
  props: {
    type: {
      required: true,
      type: Number
    },
    title: {
      type: String
    },
    placement: {
      type: String
    },
    trigger: {
      type: String
    }
  },
  created: function created() {
    var _this = this;

    this.$nextTick(function () {
      _this.update = true;
    });
    this.getList();
  },
  methods: {
    handleDblclick: function handleDblclick(row) {
      this.visible = false;
      this.$emit('select', row);
    },
    getList: function getList() {
      var _this2 = this;

      // this.$setTableLoading(true)
      this.listQuery.type = this.type;
      tempList(this.listQuery).then(function (response) {
        if (response && response.data) {
          console.log('tempresponse', response);
          var list = response.data.list;
          _this2.list = list;
          _this2.total = response.data.total;

          if (_this2.list.length > 0) {
            _this2.customer = JSON.parse(JSON.stringify(_this2.list[0]));
          }
        }
      });
    }
  }
};