var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-container",
        style: { height: _vm.height + "px", overflow: "scroll" },
      },
      [
        _c(
          "el-container",
          [
            _c(
              "el-header",
              { attrs: { height: "auto" } },
              [
                _c("base-header", {
                  attrs: {
                    actions: _vm.actions,
                    temp: _vm.customer,
                    "list-query": _vm.listQuery,
                    selectColumns: _vm.columns,
                    columns: _vm.columns1,
                  },
                  on: { action: _vm.handleAction, search: _vm.handleRefresh },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                _c("VxeTable", {
                  ref: "table",
                  attrs: {
                    index: "",
                    temp: _vm.customer,
                    list: _vm.list,
                    total: _vm.total,
                    height: _vm.height - 120,
                    "list-query": _vm.listQuery,
                    columns: _vm.columns,
                    pagination: "",
                  },
                  on: {
                    click: _vm.handleClick,
                    dbclick: _vm.handleDblclick,
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }