var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      style: { height: _vm.height + "px", overflow: "scroll" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "mini",
          attrs: {
            inline: true,
            model: _vm.temp,
            rules: _vm.rules,
            size: "mini",
            "label-width": "90px",
            "label-position": "right",
            "show-message": false,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-button", { attrs: { type: "text" } }, [
                    _vm._v("应收未付款明细："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账单编号:", prop: "dacCode" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacCode", $$v)
                          },
                          expression: "temp.dacCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "销售发票:", prop: "dacCtmid" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacCtmid,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacCtmid", $$v)
                          },
                          expression: "temp.dacCtmid",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible2 = true
                            },
                          },
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "本单借款:" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacDisacc,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacDisacc", $$v)
                          },
                          expression: "temp.dacDisacc",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "属消费单:", prop: "dacPidno" } },
                    [
                      _c("el-input", {
                        ref: "mobile",
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacPidno,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacPidno", $$v)
                          },
                          expression: "temp.dacPidno",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营业日期:" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "110px" },
                        attrs: {
                          type: "date",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          clearable: false,
                        },
                        model: {
                          value: _vm.temp.dacDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacDate", $$v)
                          },
                          expression: "temp.dacDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发生时间:" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          disabled: "",
                          type: "datetime",
                          format: "yyyy-MM-dd HH:mm",
                          "value-format": "yyyy-MM-dd HH:mm",
                          clearable: false,
                        },
                        model: {
                          value: _vm.temp.dacTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacTime", $$v)
                          },
                          expression: "temp.dacTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "付款产品:", prop: "dacZptcode" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacZptcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacZptcode", $$v)
                          },
                          expression: "temp.dacZptcode",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible2 = true
                            },
                          },
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称:", prop: "dacZptname" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacZptname,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacZptname", $$v)
                          },
                          expression: "temp.dacZptname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "购买数量:", prop: "dacPaynum" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: {
                          disabled: "",
                          oninput: "value=value.replace(/[^0-9]/g,'')",
                        },
                        model: {
                          value: _vm.temp.dacPaynum,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacPaynum", $$v)
                          },
                          expression: "temp.dacPaynum",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "应付总额:", prop: "dacAccount" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacAccount", $$v)
                          },
                          expression: "temp.dacAccount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-button", { attrs: { type: "text" } }, [
                    _vm._v("本单未付款确认："),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户卡号:", prop: "dacCtmcode" } },
                    [
                      _c("el-input", {
                        ref: "mobile",
                        staticStyle: { width: "110px" },
                        attrs: { disabled: _vm.temp.dacCtmcode != undefined },
                        model: {
                          value: _vm.temp.dacCtmcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacCtmcode", $$v)
                          },
                          expression: "temp.dacCtmcode",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible1 = true
                            },
                          },
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户姓名:", prop: "dacCtmname" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacCtmname,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacCtmname", $$v)
                          },
                          expression: "temp.dacCtmname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "已付总额:", prop: "dacPayamt" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacPayamt,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacPayamt", $$v)
                          },
                          expression: "temp.dacPayamt",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "已清还款:", prop: "dacPaytol" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "110px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.temp.dacPaytol,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacPaytol", $$v)
                          },
                          expression: "temp.dacPaytol",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账单状态:" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "110px" },
                          attrs: { filterable: "", disabled: "" },
                          model: {
                            value: _vm.temp.dacStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "dacStatus", $$v)
                            },
                            expression: "temp.dacStatus",
                          },
                        },
                        _vm._l(_vm.statusList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.field },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作员:" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.temp.dacOpter,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "dacOpter", $$v)
                            },
                            expression: "temp.dacOpter",
                          },
                        },
                        _vm._l(
                          _vm.getEmployeeTypeList(),
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.title, value: item.field },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目科室:" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          staticStyle: { width: "110px" },
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.temp.dacDept,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "dacDept", $$v)
                            },
                            expression: "temp.dacDept",
                          },
                        },
                        _vm._l(_vm.departmentList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.field },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注: (Ctrl+Enter拆行)",
                        "label-width": "185px",
                      },
                    },
                    [
                      _c("el-input", {
                        ref: "mobile",
                        staticStyle: { width: "220px" },
                        attrs: { rows: 3, type: "textarea" },
                        model: {
                          value: _vm.temp.dacRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "dacRemark", $$v)
                          },
                          expression: "temp.dacRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottomBtn" },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleDone },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户列表",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "100px",
                width: "80%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("kh-table", {
                ref: "customer",
                attrs: {
                  "ear-id": _vm.earId,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  height: _vm.innerHeight - 250,
                },
                on: { select: _vm.handleSelect1 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "消费发票查询",
                visible: _vm.dialogVisible2,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "100px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
              },
            },
            [
              _c("tk-tablet", {
                ref: "customer",
                attrs: {
                  "ctm-code": _vm.temp.dacCtmcode,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  height: _vm.innerHeight - 250,
                },
                on: {
                  select: _vm.handleSelect2,
                  cancel: function ($event) {
                    _vm.dialogVisible2 = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }