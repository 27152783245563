/**
 * @description[printPdf 打印 pdf]
 * @author yangchao
 * @param {String} data [pdf 文档流]
 * @returns { null } [没有返回]
 */
import PrintJs from 'print-js';
export var printPdf = function printPdf(data) {
  // PrintJs({ printable: data, properties: ['name', 'email', 'phone'], type: 'pdf', base64: true });
  var win = window.open();
  var pdf = 'data:application/pdf;base64,' + data;
  win.document.write('<iframe src="' + pdf + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen&gt;&lt;/iframe>');
};