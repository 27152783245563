export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
        icon = _context$props.icon,
        title = _context$props.title;
    var vnodes = [];

    if (icon) {
      vnodes.push(h("svg-icon", {
        "attrs": {
          "icon-class": icon
        }
      }));
    }

    if (title) {
      vnodes.push(h("span", {
        "slot": "title"
      }, [title]));
    }

    return vnodes;
  }
};