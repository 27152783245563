var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "mini select-block date-block",
          attrs: {
            "show-message": false,
            rules: _vm.rules,
            model: _vm.customer,
            size: "mini",
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票单号:" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbCode", $$v)
                          },
                          expression: "listQuery.ctbCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "抬头:" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbInfo", $$v)
                          },
                          expression: "listQuery.ctbInfo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户卡号:" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "", placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbCtmcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbCtmcode", $$v)
                          },
                          expression: "listQuery.ctbCtmcode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "", placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbCtmname,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbCtmname", $$v)
                          },
                          expression: "listQuery.ctbCtmname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "门诊状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "",
                          },
                          model: {
                            value: _vm.listQuery.ctbMenstate,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "ctbMenstate", $$v)
                            },
                            expression: "listQuery.ctbMenstate",
                          },
                        },
                        _vm._l(_vm.ctbMenstateList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.field },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票代码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbTaxcode,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbTaxcode", $$v)
                          },
                          expression: "listQuery.ctbTaxcode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票号码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbTaxid,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbTaxid", $$v)
                          },
                          expression: "listQuery.ctbTaxid",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          placeholder: "",
                        },
                        model: {
                          value: _vm.listQuery.ctmDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctmDate", $$v)
                          },
                          expression: "listQuery.ctmDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开票时间" } },
                    [
                      _c("el-time-picker", {
                        attrs: {
                          "picker-options": {
                            selectableRange: "00:00:00 - 23:59:59",
                          },
                          placeholder: "",
                          format: "HH:mm:ss",
                          "value-format": "HH:mm:ss",
                        },
                        model: {
                          value: _vm.listQuery.ctmCrndate,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctmCrndate", $$v)
                          },
                          expression: "listQuery.ctmCrndate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "说明" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.listQuery.ctbRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "ctbRemark", $$v)
                          },
                          expression: "listQuery.ctbRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-row"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "app-container",
          style: {
            height: _vm.innerHeight / 2 + 100 + "px",
            overflow: "hidden",
          },
        },
        [
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("edit-table", {
                    ref: "table",
                    attrs: {
                      temp: {},
                      list: _vm.list,
                      total: _vm.total,
                      scrollTopLastLocation: true,
                      height: _vm.innerHeight / 2,
                      "list-loading": _vm.listLoading,
                      "list-query": _vm.listQuery,
                      columns: _vm.columns,
                      indexFixed: true,
                      copyText: false,
                    },
                    on: { click: _vm.handleClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right", margin: "20px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "success" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v("开票")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger" },
                  on: { click: _vm.del },
                },
                [_vm._v("删除")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.getListForReassign },
                },
                [_vm._v("确定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "warning" },
                  on: { click: _vm.clean },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户消费信息查询",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "40px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("xf-table", {
                ref: "customer",
                attrs: {
                  customer: {},
                  "ear-id": _vm.earId,
                  "ctf-zd-doc-list": _vm.employees,
                  height: _vm.innerHeight - 160,
                },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }