import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.array.find-index";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ScrollBar from "../ScrollBar/index";
export default {
  name: 'VxeSelect',
  components: {
    ScrollBar: ScrollBar
  },
  props: {
    list: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    showNull: {
      type: String,
      default: null
    },
    query: {},
    field: {},
    selectStyle: {}
  },
  data: function data() {
    return {
      input: '',
      visible: false,
      hoverIndex: null,
      clist: [],
      // 页面展示数据
      viewH: 0,
      // 外部box高度
      itemH: 34,
      // 单项高度
      cusClsNull: false,
      showNum: '',
      //可视化高度一次能装几个列表
      offsetY: 0,
      // 动态偏移量
      selectItem: null,
      selectIndex: 0,
      scrollTop: 0,
      startIndex: 0,
      endIndex: 0,
      boxHeigth: 170,
      filterList: [],
      stateMap: {
        scrollOffset: 0,
        isScrollIng: false,
        updateRequested: false
      }
    };
  },
  created: function created() {
    this.viewH = this.itemH * this.list.length;
    this.filterList = JSON.parse(JSON.stringify(this.list));
  },
  watch: {
    visible: {
      immediate: true,
      handler: function handler(value) {
        if (value) {
          this.getSelectItemSetList();
        }
      }
    },
    query: {
      deep: true,
      immediate: true,
      handler: function handler(value) {
        var _this = this;

        if (!value[this.field]) {
          this.selectItem = null;
          this.input = '';
        } else {
          this.$nextTick(function () {
            _this.selectItem = value[_this.field];

            var selectIndex = _this.filterList.findIndex(function (v) {
              return v.field === _this.selectItem;
            });

            if (selectIndex !== -1) {
              _this.input = _this.filterList[selectIndex].title;
            }
          });
        }
      }
    },
    list: {
      immediate: true,
      handler: function handler(value) {
        if (value) {
          this.filterList = JSON.parse(JSON.stringify(this.list)); // this.getSelectItemSetList();
        }
      }
    }
  },
  mounted: function mounted() {},
  computed: {},
  methods: {
    ctmNullChange: function ctmNullChange(e) {
      if (e) {
        // this.clearFun();
        this.query[this.showNull] = e;
        return;
      }

      this.query[this.showNull] = null;
    },
    handleWheel: function handleWheel(e) {
      var _this2 = this;

      e.preventDefault();
      var atStartEdge = this.stateMap.scrollOffset <= 0;
      var atEndEdge = this.stateMap.scrollOffset >= this.viewH;
      var offset = 0;

      var hasReachedEdge = function hasReachedEdge(offset) {
        var edgeReached = offset < 0 && atStartEdge || offset > 0 && atEndEdge;
        return edgeReached;
      };

      var newOffset = e.deltaY;
      if (hasReachedEdge(offset) && hasReachedEdge(offset + newOffset)) return;
      offset += newOffset;
      var times = setTimeout(function () {
        _this2.scrollBoxTo(_this2.stateMap.scrollOffset + offset, _this2.viewH - _this2.boxHeigth);

        clearTimeout(times);
        offset = 0;
      }, 16);
    },
    vexItemHover: function vexItemHover(e) {
      this.hoverIndex = e;
    },
    getItemStyle: function getItemStyle(item) {
      return {
        position: 'absolute',
        left: 0,
        top: Number(item.index) * this.itemH + 'px',
        height: this.itemH,
        width: '100%',
        color: item.disabled ? 'red' : null
      };
    },
    getSelectItemSetList: function getSelectItemSetList() {
      var _this3 = this;

      var selectIndex = this.filterList.findIndex(function (v) {
        return v.field === _this3.selectItem;
      });
      var offset = selectIndex !== -1 ? this.itemH * (selectIndex + 1) - this.boxHeigth : this.stateMap.scrollOffset;
      this.scrollBoxTo(offset);
    },
    clearInput: function clearInput() {
      this.selectItem = null;
      this.input = null;
      this.query[this.field] = null; // this.$emit('vxeSelect', null);
    },
    inputFun: function inputFun(e) {
      if (!e) {
        this.filterList = JSON.parse(JSON.stringify(this.list));
      }

      this.filterList = this.list.filter(function (v) {
        return v.title.includes(e);
      });
      this.stateMap.scrollOffset = 0;
      this.itemsToRender();
    },
    vxeItemSelect: function vxeItemSelect(e) {
      this.stateMap.updateRequested = false;
      this.selectItem = e.field;
      this.input = e.title;
      this.query[this.field] = e.field; // this.$emit('vxeSelect', e.field);
    },
    thumDragFilterList: function thumDragFilterList(_ref) {
      var distance = _ref.distance,
          totalSteps = _ref.totalSteps;
      var offset = (this.viewH - this.boxHeigth) / totalSteps * distance;
      this.scrollBoxTo(Math.min(offset, this.viewH - this.boxHeigth));
    },
    scrollBoxTo: function scrollBoxTo(offset) {
      var _this4 = this;

      offset = Math.max(offset, 0);
      this.stateMap.scrollOffset = offset;
      this.stateMap.isScrollIng = true;
      this.itemsToRender();
      this.$nextTick(function () {
        _this4.stateMap.isScrollIng = false;
      });
    },
    itemsToRender: function itemsToRender() {
      this.stateMap.updateRequested = true;
      var _this$stateMap = this.stateMap,
          scrollOffset = _this$stateMap.scrollOffset,
          isScrollIng = _this$stateMap.isScrollIng;
      var startIndex = this.getStartIndex(scrollOffset);
      var endIndex = this.getStopIndex(startIndex, scrollOffset);
      this.filterList.forEach(function (v, index) {
        return v.index = index;
      });
      this.clist = this.filterList.slice(startIndex, endIndex + 1);
      this.viewH = this.filterList.length * this.itemH;
    },
    getStartIndex: function getStartIndex(offset) {
      var total = this.list.length;
      return Math.max(0, Math.min(total, Math.floor(offset / this.itemH)));
    },
    getStopIndex: function getStopIndex(startIndex, scrollOffset) {
      var total = this.list.length;
      var offset = startIndex * this.itemH;
      var size = this.boxHeigth;
      var numVisibleItems = Math.ceil((size + scrollOffset - offset) / this.itemH);
      return Math.max(0, Math.min(total - 1, startIndex + numVisibleItems));
    },
    inputFocus: function inputFocus() {
      this.visible = true;
    }
  }
};