export default {
  name: 'EditItem',
  props: {
    type: {
      type: String
    },
    row: {
      type: Object
    },
    column: {
      type: Object
    },
    clickStatus: {
      type: Boolean
    }
  },
  render: function render(h) {
    var _this = this;

    var type = this.type;
    var row = this.row;
    var column = this.column;

    if (type === 'date' || type === 'datetime') {
      if (this.clickStatus) {
        return h('el-date-picker', {
          props: {
            value: row[column.field],
            type: type,
            size: 'mini',
            format: type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm',
            valueFormat: type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm',
            clearable: false,
            pickerOptions: column.pickerOptions || {}
          },
          style: {
            width: '135px'
          },
          on: {
            input: function input(value) {
              console.log('data-value', value);

              _this.$set(row, column.field, value); // row[column.field] = value;

            },
            change: function change() {
              // row[column.field] = e;
              // console.log('date-e', e);
              column.change && column.change(row, column.field);
            }
          }
        });
      } else {
        return h('span', {}, row[column.field]);
      }
    } else if (type === 'input' || type === 'number' || type === 'textarea') {
      if (this.clickStatus) {
        return h('el-input', {
          props: {
            value: row[column.field],
            type: type,
            size: 'mini',
            oninput: column.oninput,
            maxlength: column.maxlength
          },
          directives: [{
            name: 'focus'
          }],
          style: {
            width: column.width ? column.width : '135px'
          },
          on: {
            input: function input(value) {
              _this.$set(row, column.field, value);

              column.input && column.input(row, column.field, row.index);
            },
            change: function change() {
              column.change && column.change(row, column.field);
            },
            blur: function blur(e) {
              _this.$emit('blur', e);
            }
          }
        });
      } else {
        return h('span', {}, column.formatter ? column.formatter(row[column.field], column.searchList, row) : row[column.field]);
      }
    } else if (type === 'checkbox') {
      return h('el-checkbox', {
        props: {
          value: row[column.field]
        },
        on: {
          input: function input(value) {
            row[column.field] = value;
          }
        }
      });
    } else if (type === 'radio') {
      return h('el-radio-group', {
        props: {
          value: row[column.field]
        },
        on: {
          input: function input(value) {
            row[column.field] = value;
          }
        }
      }, column.searchList.map(function (v, index) {
        return h('el-radio', {
          props: {
            key: index,
            label: v.field
          }
        }, v.title);
      }));
    } else if (type === 'text') {
      if (column.filter && row[column.field]) {
        return h('el-tag', {
          props: {
            type: column.filter(row[column.field], 'type')
          }
        }, column.formatter ? column.formatter(row[column.field], column.searchList, row) : row[column.field]);
      } else {
        if (column.formatter) {
          return h('div', {
            style: {
              color: row.total ? 'red' : ''
            }
          }, column.formatter ? column.formatter(row[column.field], column.searchList, row) : row[column.field]);
        } else if (column.catStr) {
          return h('el-tooltip', {
            props: {
              effect: 'dark',
              placement: 'top'
            },
            class: {
              item: true
            }
          }, [h('div', {
            slot: 'content',
            domProps: {
              innerHTML: row[column.field1]
            }
          }), h('div', {
            class: {
              oneLine: true
            }
          }, row[column.field])]);
        } else {
          return h('pre', {
            style: {
              color: row.total ? 'red' : ''
            }
          }, row[column.field]);
        }
      }
    }

    return null;
  }
};