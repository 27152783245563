import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.find-index";
import _toConsumableArray from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.regexp.replace";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
import SelectAddCar from '@/components/SelectAddCar';
import VxeSelect from '@/components/VxeComponent/select.vue';
import VxeSelectNull from '@/components/VxeComponent/selectNull.vue';
export default {
  name: 'HeadItem',
  components: {
    SelectAddCar: SelectAddCar,
    VxeSelect: VxeSelect,
    VxeSelectNull: VxeSelectNull
  },
  props: {
    type: {
      type: String
    },
    query: {
      type: Object
    },
    column: {
      type: Object
    },
    searchForm: {
      type: Boolean
    }
  },
  render: function render(h) {
    var _this = this;

    var type = this.type;
    var query = this.query;
    var column = this.column;

    if (type === 'datetime' || type === 'date' || type === 'month') {
      return h('el-date-picker', {
        props: _objectSpread(_objectSpread({
          clearable: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          placeholder: '',
          type: type
        }, column.props), {}, {
          value: query[column.field]
        }),
        style: {
          width: this.searchForm ? '80px' : '250px'
        },
        on: {
          blur: function blur(e) {
            _this.$emit('blur', e);
          },
          input: function input(value) {
            _this.$set(query, column.field, value);
          }
        }
      });
    } else if (type === 'autocomplete') {
      return h('el-autocomplete', {
        props: {
          value: query[column.field],
          fetchSuggestions: column.fetchSuggestions
        },
        style: {
          width: this.searchForm ? '90px' : '250px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          },
          select: function select(e) {
            column.autoSelect && column.autoSelect(e);
          }
        }
      });
    } else if (type === 'autocompleteStyle') {
      var temp = h('template', {
        scopedSlots: {
          default: function _default(item) {
            return h('span', {}, item);
          }
        }
      });
      var hs = h('el-autocomplete', {
        props: {
          value: query[column.field],
          fetchSuggestions: column.fetchSuggestions,
          triggerOnFocus: false,
          popperAppendToBody: false
        },
        style: {
          width: this.searchForm ? '90px' : '250px'
        },
        on: {
          input: function input(value) {
            value = value.replace('-(停用)', '');

            _this.$set(query, column.field, value);
          },
          select: function select(e) {
            column.autoSelect && column.autoSelect(e);
          }
        }
      }, temp);
      return hs;
    } else if (type === 'vxeSelect') {
      return h('VxeSelect', {
        props: {
          list: column.searchList,
          query: query,
          field: column.field,
          showNull: column.showNull,
          selectStyle: {
            width: this.searchForm ? '90px' : '250px'
          }
        },
        on: {
          vxeSelect: function vxeSelect(value) {// this.$set(query, column.field, value);
          }
        }
      });
    } else if (type === 'VxeSelectNull') {
      return h('VxeSelectNull', {
        props: {
          list: column.searchList,
          query: query,
          field: column.field,
          showNull: column.showNull,
          selectStyle: {
            width: this.searchForm ? '90px' : '250px'
          }
        },
        on: {
          VxeSelectNull: function VxeSelectNull(value) {// this.$set(query, column.field, value);
          }
        }
      });
    } else if (type === 'selectRange') {
      return h('div', {}, [h('el-select', {
        props: {
          filterable: true,
          clearable: true,
          value: query[column.field],
          placeholder: '',
          multiple: column.multiple || column.multiple1,
          remote: column.remote,
          collapseTags: column.multiple || column.multiple1,
          remoteMethod: column.remoteMethod,
          disabled: column.disabled && column.disabled(),
          valueKey: 'field'
        },
        style: {
          width: this.searchForm ? column.width ? column.width : '80px' : '120px',
          position: 'relative'
        },
        on: {
          change: function change(value) {
            _this.$set(query, column.field, value);
          }
        },
        nativeOn: {}
      }, _toConsumableArray(column.searchList.map(function (v) {
        return h('el-option', {
          props: {
            value: v.field,
            label: v.title // disabled: type === 'CheckSelect'

          },
          style: {// marginTop: (type === 'CheckSelect' && index === 0) ? '20px' : '0'
          }
        });
      }))), h('span', {}, '-'), h('el-select', {
        props: {
          filterable: true,
          clearable: true,
          value: query[column.field2],
          placeholder: '',
          multiple: column.multiple || column.multiple2,
          remote: column.remote,
          collapseTags: column.multiple || column.multiple2,
          remoteMethod: column.remoteMethod,
          disabled: column.disabled && column.disabled(),
          valueKey: 'field2'
        },
        style: {
          width: this.searchForm ? column.width ? column.width : '80px' : '120px',
          position: 'relative'
        },
        on: {
          change: function change(value) {
            _this.$set(query, column.field2, value);
          }
        },
        nativeOn: {}
      }, _toConsumableArray(column.searchList2.map(function (v) {
        return h('el-option', {
          props: {
            value: v.field,
            label: v.title // disabled: type === 'CheckSelect'

          },
          style: {// marginTop: (type === 'CheckSelect' && index === 0) ? '20px' : '0'
          }
        });
      })))]);
    } else if (type === 'event') {
      return h('div', {}, [h('el-input', {
        props: {
          value: query[column.field],
          placeholder: '',
          clearable: true
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          }
        },
        style: {
          width: this.searchForm ? '120px' : '190px'
        }
      }), !this.searchForm ? h('el-button', {
        props: {
          plain: true,
          type: 'primary'
        },
        // style: {
        //    width: '80px'
        // },
        on: {
          click: function click() {
            column.event(column, query);
          }
        }
      }, '选择') : null]);
    } else if (type === 'numRange') {
      return h('div', {}, [h('el-input', {
        props: {
          type: 'number',
          value: query[column.field],
          placeholder: '',
          clearable: true
        },
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          }
        }
      }), h('div', {
        style: {
          display: 'inline'
        }
      }, '-'), h('el-input', {
        props: {
          type: 'number',
          value: query[column.field1],
          clearable: true
        },
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field1, value);
          }
        }
      })]);
    } else if (column.searchList1 ? column.searchList1 : column.searchList) {
      return h('el-select', {
        props: {
          filterable: true,
          clearable: true,
          value: query[column.field],
          placeholder: '',
          multiple: column.multiple,
          remote: column.remote,
          collapseTags: column.multiple,
          remoteMethod: column.remoteMethod,
          disabled: column.disabled && column.disabled(),
          valueKey: type === 'CheckSelect' ? 'field' : null
        },
        style: {
          width: this.searchForm ? column.width ? column.width : '80px' : '250px',
          position: 'relative'
        },
        on: {
          change: function change(e) {
            if (column.multiple) {
              _this.$emit('selectChange', e);

              if (column.doneselect) {
                query[column.checkList] = [];
                query[column.notField] = column.searchList.filter(function (item) {
                  return e.findIndex(function (que) {
                    return item.field === que.field;
                  }) === -1;
                }).map(function (v) {
                  return v.field;
                });
              } else {
                if (column.notField) {
                  query[column.notField] = [];
                }

                if (column.checkList) {
                  query[column.checkList] = e.map(function (v) {
                    return v.field;
                  });
                }
              }
            }

            column['onChange'] && column['onChange'](e);

            if (column.hideHollSelect) {
              query[column.field] = e;
            }
          },
          input: function input(value) {
            _this.$set(query, column.field, value);
          },
          clear: function clear() {
            if (type === 'CheckSelect') {
              _this.$set(column, 'allSelect', false);

              _this.$set(column, 'doneselect', false);

              _this.$set(column, 'hollSelect', false);

              query[column.checkList] = [];
              query[column.notField] = [];
            }
          },
          'remove-tag': function removeTag(value) {
            console.log('value-tag', value);
          },
          'visible-change': function visibleChange(visibleFlag) {
            if (type === 'CheckSelect') {
              if (column.hideHollSelect) {
                return;
              }

              if (!visibleFlag) {
                if (column.doneselect) {
                  query[column.checkList] = [];
                  query[column.notField] = column.searchList.filter(function (item) {
                    return query[column.field].findIndex(function (que) {
                      return item.field === que.field;
                    }) === -1;
                  }).map(function (v) {
                    return v.field;
                  });
                } else {
                  query[column.notField] = [];
                  query[column.checkList] = query[column.field].map(function (v) {
                    return v.field;
                  });
                }
              }
            }
          }
        },
        nativeOn: {}
      }, [type === 'CheckSelect' ? h('div', {
        props: {},
        style: {
          height: 'auto',
          display: 'flex',
          position: 'absolute',
          backgroundColor: '#fff',
          width: column.hideHollSelect ? '100px' : '230px',
          zIndex: 200,
          top: 0,
          padding: '5px 0'
        }
      }, [h('span', {
        style: {
          flex: '1',
          marginLeft: column.hideHollSelect ? '20px' : '20px'
        }
      }, [h('el-checkbox', {
        props: {
          value: column.allSelect
        },
        on: {
          change: function change(e) {
            _this.$set(column, 'allSelect', e);

            column.doneselect = false;

            if (e && !column.hideHollSelect) {
              column.hollSelect = false;
              var nullStr = column.checkList.split('List');

              _this.$set(query, nullStr[0] + 'Null', false);
            }

            query[column.field] = e ? column.searchList : [];
          }
        }
      }, '全选')]), h('span', {
        style: {
          flex: '1',
          color: '#8492a6',
          fontSize: '13px',
          marginLeft: column.hideHollSelect ? '8px' : '0'
        }
      }, [h('el-checkbox', {
        props: {
          value: column.doneselect
        },
        on: {
          change: function change(e) {
            _this.$set(column, 'doneselect', e);

            query[column.field] = column.searchList.filter(function (item) {
              return query[column.field].findIndex(function (que) {
                return item.field === que.field;
              }) === -1;
            });
            column.allSelect = false;

            if (e && !column.hideHollSelect) {
              column.hollSelect = false;
              var nullStr = column.checkList.split('List');

              _this.$set(query, nullStr[0] + 'Null', false);
            }
          }
        }
      }, '反选')]), !column.hideHollSelect ? h('span', {
        style: {
          flex: '1'
        }
      }, [h('el-checkbox', {
        props: {
          value: column.hollSelect
        },
        on: {
          change: function change(e) {
            _this.$set(column, 'hollSelect', e);

            column.allSelect = false;
            column.doneselect = false;
            query[column.field] = [];
            query[column.notField] = [];
            query[column.checkList] = [];
            var nullStr = column.checkList.split('List');

            _this.$set(query, nullStr[0] + 'Null', e);
          }
        }
      }, '空')]) : null]) : null, h('div', {
        style: {
          marginTop: type === 'CheckSelect' ? '20px' : '0'
        }
      })].concat(_toConsumableArray((column.searchList1 ? column.searchList1 : column.searchList).map(function (v) {
        return h('el-option', {
          props: {
            value: type === 'CheckSelect' ? v : v.field,
            label: v.title // disabled: type === 'CheckSelect'

          },
          style: {// marginTop: (type === 'CheckSelect' && index === 0) ? '20px' : '0'
          }
        }, v.disabled || type === 'CheckSelect' ? [v.disabled ? h('span', {
          style: {
            float: 'left',
            color: v.disabled ? '#ff4949' : '#606266'
          }
        }, v.title) : h('span', {
          style: {
            float: 'left'
          }
        }, v.title), h('span', {
          style: {
            float: 'right',
            color: '#8492a6',
            fontSize: '13px'
          }
        }, type === 'CheckSelect' ? [h('span', {
          class: {
            check: true
          }
        })] : null)] : null);
      }))));
    } else if (type === 'dateMonthRange') {
      return h('div', {}, [h('el-date-picker', {
        props: _objectSpread({
          type: 'month',
          value: query[column.field],
          placeholder: '',
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          clearable: true,
          pickerOptions: column.pickerOptions
        }, column.props),
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          },
          blur: function blur(value) {
            _this.$emit('blur', value);
          }
        }
      }), h('div', {
        style: {
          display: 'inline'
        }
      }, '-'), h('el-date-picker', {
        props: _objectSpread({
          type: 'month',
          value: query[column.field1],
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          clearable: true
        }, column.props),
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field1, value);
          },
          blur: function blur(value) {
            _this.$emit('blur', value);
          }
        }
      })]);
    } else if (type === 'dateRange') {
      return h('div', {}, [h('el-date-picker', {
        props: _objectSpread({
          type: 'date',
          value: query[column.field],
          placeholder: '',
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          clearable: true,
          pickerOptions: column.pickerOptions
        }, column.props),
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          },
          blur: function blur(value) {
            _this.$emit('blur', value);
          }
        }
      }), h('div', {
        style: {
          display: 'inline'
        }
      }, '-'), h('el-date-picker', {
        props: _objectSpread({
          type: 'date',
          value: query[column.field1],
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          clearable: true
        }, column.props),
        style: {
          width: this.searchForm ? '90px' : '125px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field1, value);
          },
          blur: function blur(value) {
            _this.$emit('blur', value);
          }
        }
      })]);
    } else if (type === 'dateTimeRange') {
      return h('div', {}, [h('el-date-picker', {
        props: {
          type: 'datetime',
          value: query[column.field],
          placeholder: '',
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          clearable: true
        },
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          },
          blur: function blur(value) {
            _this.$emit('blur', value);
          }
        }
      }), h('div', {
        style: {
          display: 'inline'
        }
      }, '-'), h('el-date-picker', {
        props: {
          type: 'datetime',
          value: query[column.field1],
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          clearable: true
        },
        style: {
          width: this.searchForm ? '90px' : '120px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field1, value);
          },
          blur: function blur(value) {
            _this.$emit('blur', value);
          }
        }
      })]);
    } else if (type === 'cascader') {
      return h('el-cascader', {
        props: {
          value: query[column.field],
          collapseTags: true,
          options: column.options,
          placeholder: '',
          clearable: true,
          separator: column.separator,
          props: {
            multiple: true,
            expandTrigger: 'hover' // checkStrictly: true,

          }
        },
        style: {
          width: this.searchForm ? '90px' : '250px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          },
          change: function change(val) {
            column.change && column.change(val);
          },
          'expand-change': function expandChange(val) {
            column.expandChange && column.expandChange(val);
          }
        }
      });
    } else if (type === 'autoSelect') {
      return h('SelectAddCar', {
        props: {
          value: query[column.field2],
          avtiveValue: query[column.field],
          query: query,
          showNull: column.showNull
        },
        style: {
          width: this.searchForm ? '90px' : '250px'
        },
        ref: 'autoSelect',
        on: {
          selectChange: function selectChange(value) {
            _this.$set(query, column.field, value);
          }
        }
      });
    } else {
      return h('el-input', {
        props: {
          value: query[column.field],
          placeholder: '',
          clearable: true,
          disabled: column.disabled && column.disabled(query)
        },
        style: {
          width: this.searchForm && column.inputWidth ? column.inputWidth : this.searchForm ? '90px' : '250px'
        },
        on: {
          input: function input(value) {
            _this.$set(query, column.field, value);
          }
        }
      });
    }
  }
};