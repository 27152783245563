import request from '@/utils/request'; // 获取未读消息

export function getUnRead(data) {
  return request({
    url: '/manager/msg/getUnRead',
    method: 'get',
    params: data
  });
}
/**
 *
 * @params {string} data id拼接
 * */
// 设置已读

export function setRead(data) {
  return request({
    url: '/manager/msg/setRead',
    method: 'get',
    params: data
  });
} // 获取消息列表

export function msgGetList(data) {
  return request({
    url: '/manager/msg/getList',
    method: 'post',
    data: data
  });
}