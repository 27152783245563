import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { purchaseDetailPage, stockPage, pageForGroup } from '@/api/stock';
import EditTable from "../components/EditTable";
import KcTable from "./cp-table";
import { mapGetters } from 'vuex';
import { ctmzptDeptUpdate, ctmzptdUpdate } from '@/api/comsume';
import { getToken } from '@/utils/auth';
export default {
  name: 'HkForm',
  components: {
    EditTable: EditTable,
    KcTable: KcTable
  },
  props: {
    temp: {
      required: true,
      type: Object
    },
    customer: {
      required: true,
      type: Object
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    typeList: {
      type: Array
    },
    storageList: {
      type: Array
    },
    departmentList: {
      type: Array
    },
    msgList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    },
    zptIdList: {
      type: Array
    },
    hcList: {
      type: Array
    },
    setHcType: {},
    oldTemp: {}
  },
  data: function data() {
    return {
      list: [],
      dialogVisible2: false,
      columns: [],
      row: {},
      statList: [{
        title: '已出库',
        field: 1
      }, {
        title: '未出库',
        field: 0
      }],
      empoutColumns: [],
      showZptlist: false,
      listQuery: {},
      planTableIndex: '',
      empoutIndex: 0,
      tableIndex: '',
      selectData: [],
      clickRow: {},
      initNum: 0,
      initCtpYpinfo: null,
      changeFlag: false
    };
  },
  watch: {
    eptPid: function eptPid(id) {
      this.temp.eptId = '';
      this.caltypeList2 = [];
      this.getCaltypeList(id);
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['intensList', 'employees', 'empCode', 'userInfo'])), {}, {
    hkDisabled: function hkDisabled() {
      // let list = this.list.filter((v) => {
      //   return v.status === 1;
      // });
      var list2 = this.list.filter(function (v) {
        return v.status !== 1 && v.checked === 1;
      });
      return this.temp.ctpStep !== 'newly' || list2.length > 0;
    }
  }),
  created: function created() {
    console.log('this.customer', this.customer);
    console.log('hcList', this.hcList);
    this.initNum = this.temp.ctpUsenum;
    this.initCtpYpinfo = this.temp.ctpYpinfo;
    this.empoutColumns = [{
      title: '物料组名',
      field: 'ctpZptname',
      type: 'button',
      showStyle: true,
      width: '300px',
      icon: 'el-icon-more',
      canEdit: function canEdit() {
        return true;
      },
      event: this.selectZpt,
      focus: this.handleInput3,
      input: this.handleInput3,
      select: this.selectZptName,
      children2: [// { title: '物品编号', field: 'stoZptcode', searchable: true, width: '100px' },
      // { title: '物品名称', field: 'zptName', searchable: true, width: '150px' },
      {
        title: '物料组名',
        field: 'zptName',
        searchable: true,
        width: '100px'
      }, {
        title: '物料组号',
        field: 'stoZptcode',
        searchable: true,
        width: '150px'
      }, {
        title: '仓库名称',
        field: 'stoStockName',
        searchable: true,
        width: '150px'
      }, {
        title: '拼音码查询',
        field: 'zptOldid',
        searchable: true,
        width: '100px'
      }, {
        title: '规格',
        field: 'zptOrder',
        width: '80px'
      }, {
        title: '价格',
        field: 'zptPducomsAmt',
        width: '80px'
      }, {
        title: '可用库存',
        field: 'stoStnum',
        width: '100px'
      }, {
        title: '实际库存',
        field: 'stoNum',
        width: '100px'
      }, {
        title: '单位',
        field: 'zptUnitName',
        width: '80px'
      }]
    }, {
      title: '规格',
      field: 'zptOrder',
      width: '80px'
    }, {
      title: '领料数量',
      field: 'num',
      canEdit: function canEdit(row) {
        return !row.id || row.status === 0 || row.checked === 1 && row.status === 0 && row.rowCanEdit;
      },
      input: this.handleNumInput,
      required: true,
      type: 'input',
      width: '60px'
    }, {
      title: '标准用量',
      field: 'standerNum',
      width: '60px'
    }, {
      title: '可用数量',
      field: 'stoStnum',
      width: '60px'
    }, {
      title: '实际数量',
      field: 'stoNum',
      width: '60px'
    }, {
      title: '状态',
      field: 'status',
      width: '80px',
      searchList: this.statList,
      formatter: this.getColumnValueEditTable
    }];
    this.getList();
  },
  mounted: function mounted() {
    this.$set(this.temp, 'cpyStkcode', this.userInfo.strCode);
    console.log('temp', this.temp);

    if (this.setHcType) {
      window.addEventListener('beforeunload', this.unload);
      window.addEventListener('unload', this.updateHandler);
    }
  },
  methods: {
    numInput: function numInput(e) {
      var _this = this;

      console.log('this.temp.ctpSubnum', this.temp);

      if (e > this.temp.ctpOldnum) {
        this.$message({
          message: "\u4FEE\u6539\u7684\u6B21\u6570\u4E0D\u80FD\u5927\u4E8E\u5269\u4F59\u6B21\u6570\uFF01",
          type: 'warning',
          onClose: function onClose() {}
        });
      }

      console.log('this.list', this.list);
      this.list.forEach(function (v) {
        // console.log('v', v);
        if (v.status === 0) {
          v.rowCanEdit = _this.temp.ctpUsenum > 1;
          v.num = e * (v.productNum || 1);
        }

        if (v.stander === 1) {
          v.standerNum = v.productNum * _this.temp.ctpUsenum;
        }
      });
    },
    getList: function getList() {
      // this.$set(this, 'list', this.hcList);
      this.list = JSON.parse(JSON.stringify(this.hcList)); // this.list = this.hcList;

      console.log('this.list', this.list);
      this.row = this.hcList[0];
    },
    unload: function unload(e) {
      e = e || window.event;
      e.preventDefault(); // return false;

      if (e) {
        // 在这里阻止默认的刷新，弹出弹框
        e.returnValue = '关闭提示';
      }

      return '关闭';
    },
    updateHandler: function updateHandler() {
      if (this.setHcType === 'numchange') {
        this.oldTemp.qzxq1 = this.oldTemp.qzxq1.join();
        this.oldTemp.qzxq2 = this.oldTemp.qzxq2.join();
        fetch("".concat(process.env.VUE_APP_BASE_API, "/manager/ctmZpt/ctmzptdUpdate"), {
          method: 'POST',
          body: JSON.stringify(this.oldTemp),
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            token: getToken()
          },
          keepalive: true
        });
      } else {
        fetch("".concat(process.env.VUE_APP_BASE_API, "/manager/comsume/cancalComsume?ctmzptDIdList=").concat(this.temp.id), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: getToken()
          },
          keepalive: true
        });
      } // this.$emit('cancelHk');

    },
    selectItem: function selectItem(_ref) {
      var selection = _ref.selection,
          row = _ref.row;
      var flag = selection.findIndex(function (v) {
        return v.index === row.index;
      }); // if (flag !== -1) {
      //   row.checked = 1;
      // } else {
      //   row.checked = 0;
      // }

      console.log('flag', flag);
      this.mapListCheck(row, flag);
    },
    mapListCheck: function mapListCheck(row, flag) {
      var index = this.list.findIndex(function (v) {
        return v.index === row.index;
      });
      var item = this.list[index]; // console.log('row', row);

      this.list[index].change = true;

      if (flag !== -1) {
        if (item.stander === 1 && item.status === 0) {
          // this.list[index].checked = row.checked;
          this.list[index].rowCanEdit = this.temp.ctpUsenum > 1;
          this.list[index].num = this.temp.ctpUsenum * (item.productNum || 1);

          if (item.num > item.stoStnum) {
            this.list[index].num = this.list[index].productNum;
          }
        }
      } else {
        console.log('this.list[index]', this.list[index]); // this.list[index].checked = 0;

        if (item.stander === 1 && item.status === 0) {
          // v.rowCanEdit = this.temp.ctpUsenum > 1;
          this.list[index].num = this.list[index].productNum;
        }
      }
    },
    handleSelectionChange: function handleSelectionChange(data) {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.list.forEach(function (v) {
          v.change = true;

          if (data.findIndex(function (item) {
            return item.index === v.index;
          }) !== -1) {
            v.checked = 1;
          } else {
            v.checked = 0;
          }
        });
      }); // this.changeFlag = true;
    },
    camEditCheck: function camEditCheck() {
      return this.temp.cpyStkcode ? true : false;
    },
    clickBtn: function clickBtn(e) {
      console.log('e', e);

      if (this.clickRow.id !== e.id && e.id) {
        this.clickRow = JSON.parse(JSON.stringify(e));
      }

      this.$set(this.temp, 'cpyStkcode', this.userInfo.strCode);

      if (!this.temp.cpyStkcode) {
        this.$message({
          message: '请选择仓库',
          type: 'warning',
          onClose: function onClose() {// this.cancel()
          }
        });
      }

      this.row = e;
      this.empoutIndex = e.index;
    },
    selectInit: function selectInit(row) {
      return row.status === 0 || !row.id;
    },
    handleInput2: function handleInput2(row, val) {
      var _this3 = this;

      if (this.temp.cpyStkcode) {
        this.tableIndex = row.index;
        var params = {
          pageNum: 1,
          pageSize: 20,
          gtPchUsenum: 0.01,
          pchId: val || null,
          pchStkcode: this.temp.cpyStkcode,
          pchZptcode: row.stoZptcode || row.ctpZptcode,
          pchIfjz: 'Y',
          pchStatusList: ['IN', 'PDM'],
          earId: this.temp.earId
        };
        purchaseDetailPage(params).then(function (response) {
          if (response.data && response.data.list) {
            var list = response.data.list;

            _this3.$set(row, 'data', list);
          }
        });
      }
    },
    handleNumInput: function handleNumInput(row) {
      row.change = true;
      console.log('row.productNum', row.productNum);

      if (row.id && row.stander === 1) {
        if (row.num % row.productNum !== 0) {// row.num = row.numOld;
          // this.$set(row, 'num', row.numOld);
        }
      }

      if (row.num > row.stoStnum) {
        this.$message({
          type: 'warning',
          message: '数量不能大于剩余库存!'
        });
      }
    },
    handleInput3: function handleInput3(row, val, type) {
      var _this4 = this;

      this.tableIndex = row.index;
      var params = {
        gteStoNum: 0.01,
        pageNum: 1,
        pageSize: 50,
        zptName: val,
        stoZptcode: type ? row.stoZptcode : null,
        stoStock: this.temp.cpyStkcode,
        earId: this.temp.earId,
        zptJtcode: 'N'
      };
      pageForGroup(params).then(function (response) {
        if (response.data && response.data.list) {
          var list = response.data.list;

          _this4.$set(row, 'data', list);
        }
      });
    },
    selectOrder: function selectOrder(row, val) {
      val.pchDate = row.pchDate;
      this.$set(val, 'cpyPchserno', row.pchId);
      this.$set(val, 'oldPurchaseid', row.id);
      this.$set(val, 'pchDate', row.pchDate);
      this.$set(val, 'pchPageid', row.pchPageid);
      this.$set(val, 'stkcode', this.temp.cpyStkcode);
      this.$refs.table.handleBlur(val);
    },
    hashHcItem: function hashHcItem(item) {
      var flag = this.list.some(function (v) {
        return v.ctpZptcode === item.stoZptcode && v.status !== 1;
      });
      console.log('flag', flag);
      flag && this.$message({
        message: '该耗材已经在耗材列表中，请勿重复添加！',
        type: 'warning',
        onClose: function onClose() {// this.cancel()
        }
      });
      return flag;
    },
    selectZptName: function selectZptName(row, val) {
      if (this.hashHcItem(row)) return;
      this.$set(val, 'stoZptcode', row.stoZptcode);
      this.$set(val, 'ctpZptcode', row.stoZptcode);
      this.$set(val, 'ctpZptname', row.zptName);
      this.$set(val, 'cpyPchserno', null);
      this.$set(val, 'oldPurchaseid', null);
      this.$set(val, 'stoStnum', row.stoStnum);
      this.$set(val, 'stoNum', row.stoNum);
      this.$set(val, 'zptOrder', row.zptOrder);
      this.$set(val, 'change', true);
      this.$refs.table.handleBlur(val);
    },
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    },
    addEmpout: function addEmpout() {
      if (this.temp.ctpStep === 'over' || this.temp.ctpStatus !== 'USE') {
        this.$message({
          message: '无法增加耗材',
          type: 'warning',
          onClose: function onClose() {// this.cancel()
          }
        });
        return;
      }

      this.list.unshift({
        checked: 1,
        total: true,
        change: true
      });
    },
    delEmpout: function delEmpout() {
      if (this.temp.ctpStep === 'over' || this.temp.ctpStatus !== 'USE') {
        this.$message({
          message: '无法删除耗材',
          type: 'warning',
          onClose: function onClose() {// this.cancel()
          }
        });
        return;
      }

      if (!this.list[this.empoutIndex].stander && !this.list[this.empoutIndex].status) {
        this.list.splice(this.empoutIndex, 1);
      } else {
        if (this.list[this.empoutIndex].stander === 1) {
          this.$message({
            message: '该耗材为标准耗材，不能删除',
            type: 'warning',
            onClose: function onClose() {// this.cancel()
            }
          });
        }

        if (this.list[this.empoutIndex].status === 1) {
          this.$message({
            message: '该耗材已出库，不能删除',
            type: 'warning',
            onClose: function onClose() {// this.cancel()
            }
          });
        }
      }
    },
    selectZpt: function selectZpt(row) {
      this.empoutIndex = row.index;
      this.showZptlist = true;
    },
    selectZptSuccess: function selectZptSuccess(list) {
      if (this.hashHcItem(list)) return;
      this.list[this.empoutIndex].ctpZptcode = list.stoZptcode;
      this.list[this.empoutIndex].pickEmpCode = this.temp.cpyEmpcode3;
      this.list[this.empoutIndex].stoStnum = list.stoStnum;
      this.list[this.empoutIndex].status = 0; // this.list[this.empoutIndex].num = this.temp.ctpUsenum;

      this.$set(this.list[this.empoutIndex], 'num', this.temp.ctpUsenum);
      this.$set(this.list[this.empoutIndex], 'ctpZptname', list.zptName);
      this.$set(this.list[this.empoutIndex], 'ctpZptcode', list.stoZptcode);
      this.$set(this.list[this.empoutIndex], 'stoStnum', list.stoStnum);
      this.$set(this.list[this.empoutIndex], 'stoNum', list.stoNum);
      this.$set(this.list[this.empoutIndex], 'zptOrder', list.zptOrder);
      this.$set(this.list[this.empoutIndex], 'oldPurchaseid', null);
      this.$set(this.list[this.empoutIndex], 'oldPurchaseid', null);
      this.$set(this.list[this.empoutIndex], 'change', true);
      this.showZptlist = false;
    },
    submit: function submit() {
      var _this5 = this;

      var select = this.$refs.table.multipleSelection;

      if (select.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择耗材!'
        });
        return;
      }

      for (var i in this.list) {
        this.list[i].ctmzptdId = this.temp.id; // this.list[i].pickEmpCode = this.temp.cpyEmpcode3;
        // this.list[i].stkcode = this.temp.cpyStkcode;

        delete this.list[i].data;
        delete this.list[i].stoZptcode;
      }

      var list = this.list.filter(function (v) {
        return v.checked === 1 && (Number(v.num) || 0) > v.stoStnum;
      });

      if (list.length > 0) {
        this.$message({
          type: 'warning',
          message: "".concat(list[0].ctpZptname, "\u5E93\u5B58\u4E0D\u8DB3\uFF0C\u8BF7\u91CD\u65B0\u8BBE\u7F6E\uFF01")
        });
        return;
      }

      var changeList = this.list.filter(function (v) {
        return v.change;
      });
      var saveProjectChangeItem = this.initNum === this.temp.ctpUsenum && this.initCtpYpinfo === this.temp.ctpYpinfo;
      console.log('saveProjectChangeItem', saveProjectChangeItem);

      if (changeList.length > 0) {
        if (this.temp.ctpUsenum > this.temp.ctpOldnum) {
          this.$message({
            message: "\u4FEE\u6539\u7684\u6B21\u6570\u4E0D\u80FD\u5927\u4E8E\u5269\u4F59\u6B21\u6570\uFF01",
            type: 'warning',
            onClose: function onClose() {}
          });
          return;
        }

        ctmzptDeptUpdate({
          ctmzptDeptList: this.list
        }).then(function (res) {
          if (res.code === 200) {
            _this5.$message({
              type: 'success',
              message: '成功设置耗材!'
            });

            _this5.list = [];

            _this5.removeEnvListrner();

            _this5.$emit('closeVisible', _this5.temp, saveProjectChangeItem);
          }
        });
      } else {
        this.$emit('closeVisible', this.temp, saveProjectChangeItem);
      }
    },
    removeEnvListrner: function removeEnvListrner() {
      window.removeEventListener('beforeunload', this.unload);
      window.removeEventListener('unload', this.updateHandler);
    }
  }
};