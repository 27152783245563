import request from '@/utils/request';
import exportRequest from '@/utils/exportRequest'; // 员工列表

export function employeeList(data) {
  return request({
    url: '/manager/employee/page',
    method: 'post',
    data: data
  });
} // 简易员工列表

export function employeeSimpleList(data) {
  return request({
    url: '/manager/employee/pageSimple',
    method: 'post',
    data: data
  });
} // 员工资料详情

export function getdetialbycode(data) {
  return request({
    url: '/manager/employee/getdetialbycode',
    method: 'get',
    params: data
  });
} // 员工资料详情

export function employeeGetdetial(data) {
  return request({
    url: '/manager/employee/getdetial',
    method: 'get',
    params: data
  });
} // 员工新增或编辑

export function employeeAddOrUpdate(data) {
  return request({
    url: '/manager/employee/addOrUpdate',
    method: 'post',
    data: data
  });
} // 员工资料导出Excel

export function exportExcel(data) {
  return exportRequest({
    url: '/manager/employee/exportExcel',
    method: 'post',
    data: data
  });
} // 生成员工编号

export function createEmpCode(data) {
  return request({
    url: '/manager/employee/genCode',
    method: 'get',
    params: data
  });
} // 员工删除

export function employeeDel(data) {
  return request({
    url: '/manager/employee/delete',
    method: 'post',
    params: data
  });
} // 客户业绩归属明细

export function items(data) {
  return request({
    url: '/report/earning/items',
    method: 'post',
    data: data
  });
} // 护理师业绩统计

export function nurse(data) {
  return request({
    url: '/report/earning/nurse',
    method: 'post',
    data: data
  });
} // 咨询师业绩统计

export function consultant(data) {
  return request({
    url: '/report/earning/consultant',
    method: 'post',
    data: data
  });
} // 脱毛师业绩统计

export function depilation(data) {
  return request({
    url: '/report/earning/depilation',
    method: 'post',
    data: data
  });
} // 纹绣师业绩统计

export function tattoo(data) {
  return request({
    url: '/report/earning/tattoo',
    method: 'post',
    data: data
  });
} // 获取调岗信息

export function empTransferPosition(data) {
  return request({
    url: '/manager/employee/empTransferPosition',
    method: 'post',
    data: data
  });
} // 获取调薪信息

export function SalaryAdjustRecord(data) {
  return request({
    url: '/manager/employee/SalaryAdjustRecord',
    method: 'post',
    data: data
  });
} // 获取培训记录

export function empTrainRecord(data) {
  return request({
    url: '/manager/empTrainRecord/list',
    method: 'post',
    data: data
  });
} // 编辑培训记录

export function empTrainRecordAddOrUpdate(data) {
  return request({
    url: '/manager/empTrainRecord/addOrUpdate',
    method: 'post',
    data: data
  });
} // 员工培训记录删除

export function empTrainRecordDelete(data) {
  return request({
    url: "/manager/empTrainRecord/delete?ids=".concat(data.ids),
    method: 'post'
  });
} // 员工培训记录导出Excel

export function empTrainRecordExportExcel(data) {
  return exportRequest({
    url: '/manager/empTrainRecord/exportExcel',
    method: 'get',
    params: data
  });
} // 工资绩效

export function salaryQry(data) {
  return request({
    url: '/manager/salary/salaryQry',
    method: 'post',
    data: data
  });
} // 模板上传

export function salaryExe(data) {
  return request({
    url: '/manager/salary/salaryExe',
    method: 'post',
    data: data
  });
} // 保存调薪信息备注

export function updateSalaryRemark(data) {
  return request({
    url: '/manager/employee/updateSalaryRemark',
    method: 'post',
    data: data
  });
} // 保存调岗信息备注

export function updatePositionRemark(data) {
  return request({
    url: '/manager/employee/updatePositionRemark',
    method: 'post',
    data: data
  });
} // 打印调薪信息备注

export function exportExcelPositionRemark(data) {
  return request({
    url: '/manager/empTrainRecord/exportExcel',
    method: 'post',
    data: data
  });
} // 绩效保存

export function salaryUpdate(data) {
  return request({
    url: '/manager/salary/salaryUpdate',
    method: 'post',
    data: data
  });
} // 异动记录-新增

export function empChangeRecordAddOrUpdate(data) {
  return request({
    url: '/manager/empChangeRecord/addOrUpdate',
    method: 'post',
    data: data
  });
} // 异动记录-列表

export function empChangeRecord(data) {
  return request({
    url: '/manager/empChangeRecord/page',
    method: 'post',
    data: data
  });
} // 异动记录-员工异动记录审核

export function empChangeApproval(data) {
  return request({
    url: '/manager/empChangeRecord/check',
    method: 'post',
    data: data
  });
} // 异动记录-员工异动记录删除

export function empChangeDelete(data) {
  return request({
    url: "/manager/empChangeRecord/delete?ids=".concat(data.ids),
    method: 'post',
    data: data
  });
} // 员工异动记录导出Excel

export function empChangeRecordExport(data) {
  return exportRequest({
    url: '/manager/empChangeRecord/exportExcel',
    method: 'get',
    params: data
  });
} //  部门-列表

export function departmentList(data) {
  return request({
    url: '/manager/department/list',
    method: 'post',
    params: data
  });
} //  员工状态-列表

export function dictionaryGetMap() {
  return request({
    url: '/manager/dictionary/getMap',
    method: 'post'
  });
} //  提成-列表分页

export function percentage(data) {
  return request({
    url: '/manager/percentage/page',
    method: 'post',
    data: data
  });
} //  提成-删除

export function percentageDelete(data) {
  return request({
    url: '/manager/percentage/delete',
    method: 'post',
    params: data
  });
} //  提成-新增 编辑

export function percentageAddOrUpdate(data) {
  return request({
    url: '/manager/percentage/addOrUpdate',
    method: 'post',
    data: data
  });
} //  基准业绩设置

export function baseAddOrUpdate(data) {
  return request({
    url: '/manager/percentage/baseAddOrUpdate',
    method: 'post',
    data: data
  });
} //  基准手工设置

export function baseStandardAddOrUpdate(data) {
  return request({
    url: '/manager/percentage/baseStandardAddOrUpdate',
    method: 'post',
    data: data
  });
} //  价值标签业绩设置

export function pdulabelAddOrUpdate(data) {
  return request({
    url: '/manager/percentage/pdulabelAddOrUpdate',
    method: 'post',
    data: data
  });
} //  项目/产品业绩配置

export function productAddOrUpdate(data) {
  return request({
    url: '/manager/percentage/productAddOrUpdate',
    method: 'post',
    data: data
  });
} //  价值标签业绩批量按系数调整

export function pdulabelBatchUpdate(data) {
  return request({
    url: '/manager/percentage/pdulabelBatchUpdate',
    method: 'post',
    data: data
  });
} //  客服业绩设置

export function crmAddOrUpdate(data) {
  return request({
    url: 'manager/percentage/crmAddOrUpdate',
    method: 'post',
    data: data
  });
} //  客服业绩批量设置

export function crmBatchAdd(data) {
  return request({
    url: '/manager/percentage/crmBatchAdd',
    method: 'post',
    data: data
  });
} //  客服批量调整（仅调整提成标准）

export function crmBatchUpdate(data) {
  return request({
    url: '/manager/percentage/crmBatchUpdate',
    method: 'post',
    data: data
  });
} //  提成-列表

export function percentageList(data) {
  return request({
    url: '/manager/percentage/list',
    method: 'post',
    data: data
  });
} //  字典-列表

export function dictionaryItem(data) {
  return request({
    url: '/manager/dictionaryItem/getList',
    method: 'post',
    data: data
  });
} //  字典-列表

export function dictionary(data) {
  return request({
    url: '/manager/dictionary/page',
    method: 'post',
    data: data
  });
} //  字典-新增编辑

export function dictionaryAddOrUpdate(data) {
  return request({
    url: '/manager/dictionary/addOrUpdate',
    method: 'post',
    data: data
  });
} //  字典值-新增编辑

export function dictionaryItemAddOrUpdate(data) {
  return request({
    url: '/manager/dictionaryItem/addOrUpdate',
    method: 'post',
    data: data
  });
} //  字典值-删除

export function dictionaryItemDelete(data) {
  return request({
    url: '/manager/dictionaryItem/delete',
    method: 'post',
    params: data
  });
} //  员工字典值-删除

export function dictionaryDelete(data) {
  return request({
    url: '/manager/dictionary/delete',
    method: 'post',
    params: data
  });
} //  员工资料导入

export function employeeImport(data) {
  return request({
    url: '/manager/employee/upload',
    method: 'post',
    data: data
  });
} //  定时提醒

export function employeeRemind(data) {
  return request({
    url: '/manager/employee/remind',
    method: 'post',
    data: data
  });
} //  员工提成明细

export function employeeCommissionDetail(data) {
  return request({
    url: '/report/percentageInfo/page',
    method: 'post',
    data: data
  });
} //  业绩分配

export function achievementPage(data) {
  return request({
    url: '/employee/achievement/page',
    method: 'post',
    data: data
  });
} //  业绩分配-修改

export function achievementModify(data) {
  return request({
    url: '/employee/achievement',
    method: 'put',
    data: data
  });
} //  业绩分配-新增

export function achievementAdd(data) {
  return request({
    url: '/employee/achievement',
    method: 'post',
    data: data
  });
} //  业绩分配-detail

export function achievementAddById(data) {
  return request({
    url: '/employee/achievement',
    method: 'get',
    params: data
  });
} //  员工业绩手工汇总

export function percentagetTypePage(data) {
  return request({
    url: '/report/percentageInfo/typePage',
    method: 'post',
    data: data
  });
} //  员工按岗位查询

export function employeeGetPoList(data) {
  return request({
    url: '/manager/employee/getPoList',
    method: 'get',
    params: data
  });
} // 员工提成导出

export function percentageInfoPageExport(data) {
  return request({
    url: '/report/percentageInfo/pageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 员工业绩设置列表导出

export function percentageTypeExport(data) {
  return request({
    url: '/report/percentageTarget/percentageTypeExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //  各类员工业绩导出

export function percentageInfoTypePageExport(data) {
  return request({
    url: '/report/percentageInfo/typePageExport',
    method: 'post',
    data: data
  });
} //  员工业绩完成情况表

export function percentageTypeMonthPage(data) {
  return request({
    url: '/report/percentageTarget/percentageTypeMonthPage',
    method: 'post',
    data: data
  });
} //  员工业绩完成情况表 按天

export function percentageTypeDayPage(data) {
  return request({
    url: '/report/percentageTarget/percentageTypeDayPage',
    method: 'post',
    data: data
  });
} // 员工业绩设置列表 按天 导出

export function percentageTypeDayExport(data) {
  return request({
    url: '/report/percentageTarget/percentageTypeDayExport ',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //  员工业绩完成情况表-保存

export function insertOrUpdate(data) {
  return request({
    url: '/report/percentageTarget/insertOrUpdate',
    method: 'post',
    data: data
  });
} //  手工分值

export function getStandardReport(data) {
  return request({
    url: '/report/percentageTarget/getStandardReport',
    method: 'post',
    data: data
  });
} //  手工分值 导出

export function getStandardExport(data) {
  return request({
    url: '/report/percentageTarget/getStandardExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}