//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {};
  },
  props: {
    title: {
      type: String
    },
    visible: {
      type: Boolean
    },
    width: {
      type: String
    },
    footerBtn: {
      type: Boolean
    },
    top: {
      type: String,
      default: '80px'
    },
    submitDisabled: {
      type: Boolean
    }
  },
  created: function created() {},
  methods: {
    show: function show() {
      var _this = this;

      this.$nextTick(function () {
        _this.$emit('beforeClose');
      });
    },
    cancel: function cancel() {
      this.$emit('cancel');
    },
    submit: function submit() {
      this.$emit('submit');
    },
    closeDialog: function closeDialog(done) {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.$emit('beforeCloseDialog');
      });
      done();
    },
    close: function close() {
      this.$emit('close');
    }
  },
  computed: {
    openCardDialog: {
      get: function get() {
        return this.visible;
      },
      set: function set(val) {
        this.$emit('update:visible', val); // openCardDialog改变的时候通知父组件
      }
    }
  }
};