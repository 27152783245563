var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.prevent($event)
        },
      },
    },
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "el-drag-dialog", rawName: "v-el-drag-dialog" }],
          attrs: {
            title: "业绩分配",
            visible: _vm.dialogVisible,
            width: "50%",
            top: "50px",
            "before-close": _vm.handleClose,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-container",
            [
              _c(
                "el-header",
                { attrs: { height: "auto" } },
                [
                  _c("base-header", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName == "1",
                        expression: "activeName == '1'",
                      },
                    ],
                    attrs: {
                      actions: _vm.actions,
                      temp: _vm.customer,
                      "list-query": _vm.listQuery,
                      selectColumns: _vm.columns,
                      columns: _vm.columns,
                    },
                    on: { action: _vm.handleAction, search: _vm.handleSearch1 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-header",
                { attrs: { height: "auto" } },
                [
                  _c("base-header", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName == "2",
                        expression: "activeName == '2'",
                      },
                    ],
                    attrs: {
                      actions: _vm.actions,
                      temp: _vm.customer,
                      "list-query": _vm.listQuery2,
                      selectColumns: _vm.columns2,
                      columns: _vm.columns2,
                    },
                    on: { action: _vm.handleAction, search: _vm.handleSearch2 },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { "before-leave": _vm.getValue },
                          on: { "tab-click": _vm.handleTabClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: { label: "推荐顾问", name: "1" },
                          }),
                          _vm._v(" "),
                          _c("el-tab-pane", {
                            attrs: { label: "助推/二级咨询", name: "2" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("VxeTable", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "1",
                            expression: "activeName == '1'",
                          },
                        ],
                        ref: "table",
                        attrs: {
                          showIndex: false,
                          checkbox: true,
                          temp: _vm.customer,
                          list: _vm.list,
                          total: _vm.total,
                          height: _vm.innerHeight - 350,
                          "list-query": _vm.listQuery,
                          columns: _vm.columns,
                          checkMethod: _vm.checkMethod,
                          autoSelectList:
                            _vm.ProjectItem.advisers || _vm.advisersT,
                          autoResize: "",
                          autoClear: false,
                        },
                      }),
                      _vm._v(" "),
                      _c("VxeTable", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "2",
                            expression: "activeName == '2'",
                          },
                        ],
                        ref: "table2",
                        attrs: {
                          showIndex: false,
                          checkbox: true,
                          temp: _vm.customer,
                          list: _vm.list2,
                          total: _vm.total2,
                          height: _vm.innerHeight - 350,
                          "list-query": _vm.listQuery,
                          columns: _vm.columns2,
                          checkMethod: _vm.checkMethod,
                          autoSelectList:
                            _vm.ProjectItem.diagnosis || _vm.diagnosisT,
                          autoResize: "",
                          autoClear: false,
                          selectInit: _vm.selectInit,
                        },
                        on: {
                          selectItem: _vm.selectItem,
                          allSelect: _vm.allSelect,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "center", padding: "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.loadingSet },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }