import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';
import { setSettings, getSettings } from "@/utils/common";
var settings = getSettings() ? getSettings() : defaultSettings;
var tagsView = settings.tagsView,
    fixedHeader = settings.fixedHeader,
    sidebarLogo = settings.sidebarLogo,
    rightWidth = settings.rightWidth,
    lockScreen = settings.lockScreen;
var state = {
  theme: variables.theme,
  lockScreen: lockScreen ? lockScreen : false,
  showSettings: true,
  tagsView: tagsView != undefined ? tagsView : true,
  fixedHeader: fixedHeader != undefined ? fixedHeader : true,
  sidebarLogo: sidebarLogo != undefined ? sidebarLogo : true,
  rightWidth: rightWidth ? rightWidth : 35,
  openSearch: false,
  tableHeight: 20,
  searchStatus: {
    statusGether: false,
    statusRedius: false
  }
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  SET_SEARCHSTSTUS_GETHER: function SET_SEARCHSTSTUS_GETHER(state, gether) {
    state.searchStatus.statusGether = gether;
  },
  SET_SEARCHSTSTUS_REDIUS: function SET_SEARCHSTSTUS_REDIUS(state, redius) {
    state.searchStatus.statusRedius = redius;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
    setSettings(data);
  },
  toggleSearchGether: function toggleSearchGether(_ref3, gether) {
    var commit = _ref3.commit;
    commit('SET_SEARCHSTSTUS_GETHER', gether);
  },
  toggleSearchRedius: function toggleSearchRedius(_ref4, redius) {
    var commit = _ref4.commit;
    commit('SET_SEARCHSTSTUS_REDIUS', redius);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};