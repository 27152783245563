//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import EditTable from "../components/EditTable";
export default {
  name: 'HkForm',
  components: {
    EditTable: EditTable
  },
  props: {
    customer: {
      required: true,
      type: Object
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    }
  },
  data: function data() {
    return {
      temp: {},
      list: [],
      columns: [],
      listQuery: {}
    };
  },
  watch: {
    eptPid: function eptPid(id) {
      this.customer.eptId = '';
      this.caltypeList2 = [];
      this.getCaltypeList(id);
    }
  },
  created: function created() {
    this.columns = [{
      display_name: '客户姓名',
      key: 'rviDate',
      type: 'input',
      fixed: false,
      checked: true,
      searchEnable: true,
      width: '180px'
    }, {
      display_name: '手机号码',
      key: 'rviTel',
      type: 'input',
      fixed: false,
      checked: true,
      searchEnable: true,
      width: '140px'
    }, {
      display_name: '发送时间',
      key: 'rviNext',
      type: 'date',
      fixed: false,
      checked: true,
      searchEnable: true,
      width: '180px'
    }, {
      display_name: '短信内容',
      key: 'rvemp',
      type: 'input',
      fixed: false,
      checked: false,
      searchEnable: true,
      width: '140px',
      searchList: this.ctfZdDocList
    }, {
      display_name: '调查方式',
      key: 'rvType',
      type: 'select',
      fixed: false,
      checked: true,
      searchEnable: true,
      width: '140px',
      searchList: this.rvTypes
    }];
  },
  methods: {
    handleAdd: function handleAdd() {
      this.temp = {
        ctfCtmname: '',
        ctfJsCtmcode: '',
        ctfJsPeople: '',
        ctfOpter: '',
        ctfPtype: '',
        ctfRemark: '',
        ctfSjTime: '',
        ctfState: '',
        ctfStatus: '',
        ctfTel: '',
        ctfZdDoc: '',
        ctfZsEar: '',
        earId: '',
        id: 0,
        kfPeople: '',
        maybeEndTime: '',
        maybeStartTime: ''
      };
      this.list.push(this.temp);
    },
    handleDel: function handleDel() {
      this.list.splice(this.$refs.table.curCell.row, 1);
    },
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    }
  }
};