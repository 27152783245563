import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import YgPopover from "../components/ygPopover";
export default {
  name: 'HkForm',
  components: {
    YgPopover: YgPopover
  },
  props: {
    temp: {
      required: true,
      type: Object
    }
  },
  data: function data() {
    return {
      ptList: []
    };
  },
  computed: _objectSpread({}, mapGetters(['intensList', 'employees', 'empCode'])),
  created: function created() {
    var _this = this;

    var empList = ['cpyEmpcode1', 'cpyEmpcode2', 'cpyEmpcode5', 'cpyEmpcode6', 'cpyEmpcode7'];
    empList.forEach(function (v) {
      if (_this.temp[v]) {
        _this.contorlEmpOnlyFun(_this.temp[v], null, v);
      }
    });
    this.ptList = this.getEmployeeTypeList('pths').filter(function (v) {
      return v.empStatus === 'CFM';
    });
  },
  methods: {
    handleSelectYs: function handleSelectYs(customer) {
      if (customer.empCode === '') {
        this.$set(this.temp, 'cpyEmpcode1Name', null);
        this.$set(this.temp, 'cpyEmpcode1', null);
        this.contorlEmpOnlyFun(customer.empCode, null, 'cpyEmpcode1');
        return;
      }

      this.$set(this.temp, 'cpyEmpcode1Name', customer.empName);
      this.$set(this.temp, 'cpyEmpcode1', customer.empCode);
      this.contorlEmpOnlyFun(customer.empCode, null, 'cpyEmpcode1');
    },
    handleSelectHls: function handleSelectHls(customer) {
      if (customer.empCode === '') {
        this.$set(this.temp, 'cpyEmpcode2Name', null);
        this.$set(this.temp, 'cpyEmpcode2', null);
        this.contorlEmpOnlyFun(customer.empCode, null, 'cpyEmpcode2');
        return;
      }

      this.$set(this.temp, 'cpyEmpcode2Name', customer.empName);
      this.$set(this.temp, 'cpyEmpcode2', customer.empCode);
      this.$set(this.temp, 'cpyEmpcode6Name', null);
      this.$set(this.temp, 'cpyEmpcode6', null);
      this.$set(this.temp, 'cpyEmpcode7Name', null);
      this.$set(this.temp, 'cpyCtmcode7', null);
      this.contorlEmpOnlyFun(customer.empCode);
    },
    handleSelectMrs: function handleSelectMrs(customer) {
      if (customer.empCode === '') {
        this.$set(this.temp, 'cpyEmpcode6Name', null);
        this.$set(this.temp, 'cpyEmpcode6', null);
        this.contorlEmpOnlyFun(customer.empCode, null, 'cpyEmpcode6');
        return;
      }

      this.$set(this.temp, 'cpyEmpcode6Name', customer.empName);
      this.$set(this.temp, 'cpyEmpcode6', customer.empCode);
      this.$set(this.temp, 'cpyEmpcode2Name', null);
      this.$set(this.temp, 'cpyEmpcode2', null);
      this.$set(this.temp, 'cpyEmpcode7Name', null);
      this.$set(this.temp, 'cpyEmpcode7', null);
      this.contorlEmpOnlyFun(customer.empCode);
    },
    handleSelectWxs: function handleSelectWxs(customer) {
      if (customer.empCode === '') {
        this.$set(this.temp, 'cpyEmpcode7Name', null);
        this.$set(this.temp, 'cpyEmpcode7', null);
        this.contorlEmpOnlyFun(customer.empCode, null, 'cpyEmpcode7');
        return;
      }

      this.$set(this.temp, 'cpyEmpcode7Name', customer.empName);
      this.$set(this.temp, 'cpyEmpcode7', customer.empCode);
      this.$set(this.temp, 'cpyEmpcode6Name', null);
      this.$set(this.temp, 'cpyEmpcode6', null);
      this.$set(this.temp, 'cpyEmpcode2Name', null);
      this.$set(this.temp, 'cpyEmpcode2', null);
      this.contorlEmpOnlyFun(customer.empCode);
    },
    contorlEmpOnlyFun: function contorlEmpOnlyFun(code, select, target) {
      if (select) {
        if (this.temp.cpyEmpcode5.includes(code)) {
          return false;
        }
      } else {
        this.ptList.forEach(function (v) {
          if (code === v.field) {
            v.target = target;
            v.disabled = true;
          } else if (v.target === target) {
            v.disabled = false;
            v.target = null;
          }
        });
      }

      return true;
    }
  }
};