import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import BaseTable from "../components/BaseTable.vue";
import { billPayDetail } from '@/api/cash';
import { customerLevelList } from '@/api/manage';
import bus from "../components/bus";
export default {
  name: 'GpForm',
  components: {
    BaseTable: BaseTable
  },
  props: {},
  watch: {},
  data: function data() {
    return {
      isBillList: [{
        field: true,
        title: '已开票'
      }, {
        field: false,
        title: '未开票'
      }],
      statusList: [{
        field: false,
        title: '未开票'
      }, {
        field: true,
        title: '已开票'
      }],
      tranTypeList: [{
        field: '项目收款',
        title: '项目收款'
      }, {
        field: '产品销售',
        title: '产品销售'
      }, {
        field: '预收费',
        title: '预收费'
      }, {
        field: '项目消耗',
        title: '项目消耗'
      }],
      pageSizes: [15, 30, 50, 100, 500],
      levelAllList: [],
      customerIdList: [],
      xfList: [],
      list1Loading: false,
      customer2: {},
      list: [],
      total: 0,
      allPrice: 0,
      cashNum: 0,
      cardNum: 0,
      cpyStoamt: 0,
      cpyUseMonAmt: 0,
      reassignAll: false,
      customer: {},
      selectList: [],
      listQuery: {
        pageNum: 1,
        pageSize: 500,
        cpyCtmcode: '',
        cpyCtmname: '',
        ctmDangid: '',
        isBill: '',
        tranType: '',
        date: '',
        cpyEndDate: '',
        cpyPageid: '',
        earId: this.earId //ctmLevel:''

      },
      innerHeight: 840,
      rules: {
        newEmpName: [{
          required: true,
          trigger: 'blur'
        }],
        newEmpCode: [{
          required: true,
          trigger: 'blur'
        }]
      },
      filters: {
        statusFilter: function statusFilter(status, type) {
          var map = {
            false: {
              type: 'primary',
              title: '未开票'
            },
            true: {
              type: 'warning',
              title: '已开票'
            }
          };
          return map[status][type];
        },
        payFilter: function payFilter(status, type) {
          var map = {
            A: {
              type: 'info',
              title: '现金'
            },
            B: {
              type: 'warning',
              title: '卡'
            },
            E: {
              type: 'warning',
              title: '卡'
            }
          };
          return map[status][type];
        }
      },
      idList: [],
      columns: [{
        title: '销售单号',
        field: 'cpyPageid',
        width: '80px'
      }, {
        title: '营业日期',
        field: 'cpyDate',
        searchable: false,
        width: '95px'
      }, {
        title: '客户卡号',
        field: 'cpyCtmcode',
        width: '100px',
        searchable: true
      }, {
        title: '客户姓名',
        field: 'cpyCtmname',
        notColumn: true,
        width: '80px',
        searchable: true
      }, {
        title: '客户姓名',
        field: 'cpyCtmname',
        width: '80px',
        searchable: false
      }, {
        title: '项目/产品名称',
        field: 'cpyZptName',
        width: '120px'
      }, {
        title: '次数',
        field: 'cpyNum',
        width: '50px'
      }, {
        title: '消费类型',
        field: 'zptTypeName',
        width: '80px'
      }, // {title:'单价',field:'cpyUnitamt',width:'50px'},
      {
        title: '收款金额',
        field: 'cpyCardRmAmt',
        width: '70px'
      }, {
        title: '其中现金',
        field: 'cpyRmamt',
        width: '70px'
      }, {
        title: '其中刷卡',
        field: 'cpyCardamt',
        width: '70px'
      }, // {title:'券额支付',field:'cpyOthamt',searchable:true,width:'70px'},
      {
        title: '存款支付',
        field: 'cpyStoamt',
        searchable: true,
        width: '70px'
      }, {
        title: '消耗金额',
        field: 'cpyUseMonAmt',
        searchable: true,
        width: '70px'
      }, {
        title: '状态',
        field: 'isBill',
        width: '60px',
        filter: this.$options.filters.statusFilter
      }, {
        title: '开票人',
        field: 'cpyCtmcfm5',
        width: '70px'
      }]
    };
  },
  computed: _objectSpread({}, mapGetters(['earId', 'employees', 'rightWidth', 'eareList'])),
  created: function created() {
    //this.getCustomerLevelList();
    this.innerHeight = $(window).height();
    this.getlist1();
    this.listQuery.earId = this.earId;
  },
  methods: {
    handleSave: function handleSave() {},
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    },
    handleDone: function handleDone() {
      var _this = this;

      this.idList = [];
      this.selectList.forEach(function (item) {
        _this.idList.push(item.id);
      });
      this.$emit('done', this.idList, this.listQuery);
    },
    clear: function clear() {
      this.listQuery = {};
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = 15;
    },
    getListForReassign: function getListForReassign() {
      this.getlist1();
    },
    customerAllSelect: function customerAllSelect(index) {
      this.reassignAll = index.length > 0;
    },
    handleSelect: function handleSelect() {},
    getCustomerLevelList: function getCustomerLevelList() {
      var _this2 = this;

      customerLevelList().then(function (res) {
        res.data.forEach(function (item) {
          var lev = {};
          lev.title = item.name;
          lev.field = item.level;

          _this2.levelAllList.push(lev);
        });
      });
    },
    getlist1: function getlist1() {
      var _this3 = this;

      console.log('当前的查询参数', this.listQuery);
      this.list1Loading = true; // if (this.listQuery.cpyPtype2 === 'qt429')

      billPayDetail(this.listQuery).then(function (response) {
        console.log('顾客消费信息查询', response);

        if (response.data && response.data.list) {
          var list = response.data.list;

          for (var i = 0; i < list.length; i++) {
            list[i].cpyCardRmAmt = list[i].cpyRmamt + list[i].cpyCardamt;

            if (list[i].isBill == false) {
              list[i].isBill = '未开票';
            } else {
              list[i].isBill = '已开票';
            }

            if (_this3.earId !== '013' && _this3.earId !== '014' && _this3.earId !== '015' && _this3.earId !== '000') {
              list[i].cpyUseMonAmt = 0;
            }
          }

          _this3.list1Loading = false;
          _this3.list = list;
          _this3.total = response.data.total;
          if (_this3.list.length > 0) _this3.customer2 = JSON.parse(JSON.stringify(_this3.list[0]));
        }
      });
    },
    selectAll: function selectAll(e) {
      var _this4 = this;

      this.xfList = e;
      this.allPrice = 0;
      this.cashNum = 0;
      this.cardNum = 0;
      this.cpyStoamt = 0;
      this.cpyUseMonAmt = 0;
      this.xfList.forEach(function (item) {
        _this4.allPrice += item.cpyCardRmAmt;
        _this4.cashNum += item.cpyRmamt;
        _this4.cardNum += item.cpyCardamt;
        _this4.cpyStoamt += item.cpyStoamt;
        _this4.cpyUseMonAmt += item.cpyUseMonAmt || 0;
      });
      this.allPrice = this.allPrice.toFixed(4);
      this.cashNum = this.cashNum.toFixed(4);
      this.cardNum = this.cardNum.toFixed(4);
      this.cpyStoamt = this.cpyStoamt.toFixed(4);
      this.cpyUseMonAmt = this.cpyUseMonAmt.toFixed(4);
    },
    selectItem: function selectItem(e) {
      var _this5 = this;

      this.xfList = e.selection;
      this.allPrice = 0;
      this.cashNum = 0;
      this.cardNum = 0;
      this.cpyStoamt = 0;
      this.cpyUseMonAmt = 0;
      this.xfList.forEach(function (item) {
        _this5.allPrice += item.cpyCardRmAmt;
        _this5.cashNum += item.cpyRmamt;
        _this5.cardNum += item.cpyCardamt;
        _this5.cpyStoamt += item.cpyStoamt;
        _this5.cpyUseMonAmt += item.cpyUseMonAmt || 0;
      });
      this.allPrice = this.allPrice.toFixed(4);
      this.cashNum = this.cashNum.toFixed(4);
      this.cardNum = this.cardNum.toFixed(4);
      this.cpyStoamt = this.cpyStoamt.toFixed(4);
      this.cpyUseMonAmt = this.cpyUseMonAmt.toFixed(4);
    },
    sure: function sure() {
      if (this.xfList.length > 0) {
        console.log('this.xfList', this.xfList);
        bus.$emit('xfList', this.xfList);
      } else this.$message({
        message: '请勾选需要开单的信息',
        type: 'warning'
      });
    }
  }
};