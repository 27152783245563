// export default {
//   bind(el, binding, vnode) {
//     let flag = Number(el.style.width.split('%')[0])
//     let width =  flag ?
//     window.innerWidth * Number('0.' + flag) :
//     el.style.width.split('px')[0]
//     el.onmousemove = (e) => {
//       let minWidth = width - ( window.innerWidth - e.clientX + (flag ? 70 : 0))
//       if (minWidth < 20) {
//         el.style.cssText += ';cursor:w-resize;'
//       } else {
//         el.style.cssText += ';cursor:auto;'
//       }
//     }
//     el.onmousedown = (e) => {
//       let minWidth = width - ( window.innerWidth - e.clientX + (flag ? 70 : 0))
//       if (minWidth < 20) {
//           el.style.cssText += ';cursor:w-resize;'
//           let winWidth = window.innerWidth
//             document.onmousemove = (e) => {
//             // 鼠标按下，计算当前元素距离可视区的距离
//             // let asideElWidth = el.style.width
//             el.style.width = winWidth - e.clientX  + 'px'
//           }
//           document.onmouseup = (e) => {
//             document.onmousemove = null
//             document.onmouseup = null
//             el.style.cssText += ';cursor:auto;'
//           }
//       } else {
//         el.style.cssText += ';cursor:auto;'
//       }
//     }
//     el.onmouseup = (e) => {
//       document.onmousemove = null
//       document.onmouseup = null
//       el.style.cssText += ';cursor:auto;'
//     }
//   }
// }
export default {
  bind: function bind(el, binding, vnode) {
    var dialogHeaderEl = el.querySelector('.drag_box');
    dialogHeaderEl.style.cssText += ';cursor:w-resize;';

    dialogHeaderEl.onmousedown = function (e) {
      var winWidth = window.innerWidth;
      var nowValue = e.clientX;

      document.onmousemove = function (e) {
        // 鼠标按下，计算当前元素距离可视区的距离
        // let asideElWidth = el.style.width
        el.style.width = winWidth - e.clientX + 'px';
      };

      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }
};