var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "el-container",
        { staticStyle: { "flex-direction": "column" } },
        [
          _c("el-main", [
            _c(
              "div",
              {
                staticClass: "form-container",
                style: { height: (_vm.height - 150) / 2 + "px" },
              },
              [
                _c("el-button", { attrs: { type: "text" } }, [
                  _vm._v(
                    "客户现有项目列表(点住产品可以拖拉到下方列表进行消耗)"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  { staticStyle: { color: "red" }, attrs: { type: "text" } },
                  [
                    _vm._v(
                      "【" +
                        _vm._s(
                          _vm.customer.pidCtmname || _vm.customer.ctpCtmname
                        ) +
                        "-" +
                        _vm._s(
                          _vm.customer.pidCtmcode || _vm.customer.ctpCtmcode
                        ) +
                        "-" +
                        _vm._s(
                          _vm.customer.pidCtmTel ||
                            _vm.customer.mobile ||
                            _vm.customer.ctmMobile ||
                            _vm.customer.ctfTel
                        ) +
                        "】"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("base-table", {
                  ref: "table1",
                  attrs: {
                    "row-class-name": _vm.tableRowClassName,
                    temp: _vm.tempXmTable,
                    list: _vm.list1,
                    height: (_vm.height - 100) / 2 - 55,
                    "list-loading": _vm.listLoading,
                    "list-query": _vm.listQuery,
                    columns: _vm.columns1,
                    localStorageCurrentKey: "comsume_xm_local",
                  },
                  on: { click: _vm.handleClick2 },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-main", [
            _c(
              "div",
              {
                staticClass: "form-container",
                style: { height: (_vm.height + 10) / 2 + "px" },
              },
              [
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "10px" } },
                  [
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("当前消耗项目列表"),
                    ]),
                    _vm._v(" "),
                    !_vm.checkComsume &&
                    (_vm.userInfo.dptCode === "000D75" ||
                      _vm.userInfo.dptCode === "000D76" ||
                      _vm.userInfo.dptCode === "000D77" ||
                      _vm.userInfo.GLOBAL_RIGHT)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              size: "mini",
                              disabled:
                                _vm.customer.verifyState == "4" ||
                                !_vm.clickTemp.id ||
                                _vm.list2.length === 0,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleSubmit(2)
                              },
                            },
                          },
                          [_vm._v("设置耗材")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.checkComsume &&
                    (_vm.userInfo.dptCode === "000D75" ||
                      _vm.userInfo.dptCode === "000D81" ||
                      _vm.userInfo.empCode === "Y090131" ||
                      _vm.userInfo.GLOBAL_RIGHT)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "mini",
                              disabled:
                                _vm.customer.verifyState == "4" ||
                                (this.clickTemp.ctpStatus !== "USE" &&
                                  _vm.selection.length === 0),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.oprateBtnClick(1, null, {})
                              },
                            },
                          },
                          [_vm._v("治疗开始")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userInfo.dptCode === "000D75" ||
                    _vm.userInfo.GLOBAL_RIGHT
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-top": "10px",
                            },
                            attrs: {
                              type: "danger",
                              size: "mini",
                              disabled: this.operateDisabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.oprateBtnClick(3, null, {
                                  editFlag: true,
                                  temp: _vm.clickTemp,
                                })
                              },
                            },
                          },
                          [_vm._v("补划扣")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: { click: _vm.handlePrint },
                      },
                      [_vm._v("打印")]
                    ),
                    _vm._v(" "),
                    _vm.checkComsume
                      ? [
                          _c(
                            "el-form",
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 19 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "审核备注:" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                              placeholder: "",
                                            },
                                            model: {
                                              value: _vm.approvalInfo.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.approvalInfo,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression: "approvalInfo.remark",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-top": "60px",
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            disabled:
                                              _vm.customer.verifyState == "4",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("sureCheckXm")
                                            },
                                          },
                                        },
                                        [_vm._v("审核通过")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-top": "60px" },
                                          attrs: {
                                            size: "mini",
                                            type: "danger",
                                            disabled:
                                              _vm.customer.verifyState == "4",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$emit("cancelCheckXm")
                                            },
                                          },
                                        },
                                        [_vm._v("审核驳回")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("edit-table", {
                  ref: "table2",
                  staticClass: "aoto_header_heigth",
                  attrs: {
                    preColumnSlot: "",
                    "row-class-name": _vm.tableRowClassName,
                    checkbox: true,
                    temp: _vm.customer,
                    list: _vm.list2,
                    height: (_vm.height - 120) / 2,
                    "list-loading": _vm.listLoading,
                    "list-query": {},
                    columns: _vm.columns2,
                    selectInit: _vm.selectInit,
                  },
                  on: {
                    click: _vm.handleClick,
                    selectItem: _vm.selectItem,
                    allSelect: _vm.allSelect,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "preColumn",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("el-checkbox", {
                            attrs: { disabled: !row.id },
                            model: {
                              value: row.checked,
                              callback: function ($$v) {
                                _vm.$set(row, "checked", $$v)
                              },
                              expression: "row.checked",
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "ctpOverOptImg",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _vm.validURL(row.ctpOverOptImg)
                            ? _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    title: "",
                                    width: "200",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "150px",
                                      height: "150px",
                                    },
                                    attrs: { src: row.ctpOverOptImg },
                                  }),
                                  _vm._v(" "),
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                    },
                                    attrs: {
                                      slot: "reference",
                                      src: row.ctpOverOptImg,
                                    },
                                    slot: "reference",
                                  }),
                                ],
                                1
                              )
                            : [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.getColumnValueBaseTable(
                                        row.ctpOpter ||
                                          row.ctpOverOptCode ||
                                          row.ctpOverOptImg,
                                        null,
                                        _vm.employees
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ],
                        ]
                      },
                    },
                    {
                      key: "ctpDate",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top-start",
                                title: "",
                                width: "280",
                                trigger: "hover",
                              },
                            },
                            [
                              [
                                _c("div", [
                                  _vm._v(
                                    "创建时间:" + _vm._s(row.pidTdatetime)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "治疗开始时间:" +
                                      _vm._s(row.ctpReceptionTime)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "治疗结束时间:" + _vm._s(row.ctpOverTime)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "确认时间:" + _vm._s(row.ctpConfirmTime)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div"),
                              ],
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                  },
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.ctpDate) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(
                  "el-button",
                  { staticStyle: { color: "red" }, attrs: { type: "text" } },
                  [_vm._v("划扣(现金/卡/存款)总消耗:" + _vm._s(_vm.price))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  { staticStyle: { color: "red" }, attrs: { type: "text" } },
                  [_vm._v("划扣券总消耗:" + _vm._s(_vm.coup))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                center: "",
                title: "登记划扣内容",
                "append-to-body": "",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "35%",
                top: "100px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.kcDialogFunClose,
              },
            },
            [
              _c("hk-form", { ref: "HkForm", attrs: { temp: _vm.comsume } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "bottomBtn dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleDone },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            center: "",
            title: "消耗项目修改",
            "append-to-body": "",
            visible: _vm.mdyDialogVisible,
            "close-on-click-modal": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mdyDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-container" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "small dialog-form select-block date-block",
                  attrs: {
                    "show-message": false,
                    model: _vm.temp,
                    size: "mini",
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用次数", prop: "ctpUsenum" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: "",
                          disabled: _vm.temp.ctpStatus !== "USE",
                        },
                        model: {
                          value: _vm.temp.ctpUsenum,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "ctpUsenum", $$v)
                          },
                          expression: "temp.ctpUsenum",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "潜在需求1" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "",
                            clearable: "",
                            multiple: "",
                            "collapse-tags": "",
                          },
                          on: { change: _vm.getIntens },
                          model: {
                            value: _vm.temp.qzxq1,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "qzxq1", $$v)
                            },
                            expression: "temp.qzxq1",
                          },
                        },
                        _vm._l(_vm.intensList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.title, value: item.field },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "潜在需求2" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            placeholder: "",
                            multiple: "",
                            "collapse-tags": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.temp.qzxq2,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "qzxq2", $$v)
                            },
                            expression: "temp.qzxq2",
                          },
                        },
                        _vm._l(_vm.intensList1, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.intentionDes,
                              value: item.intentionDes,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", placeholder: "" },
                        model: {
                          value: _vm.temp.ctpRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "ctpRemark", $$v)
                          },
                          expression: "temp.ctpRemark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    loading: _vm.btnLoading,
                    type: "primary",
                  },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.hcVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                center: "",
                title: "设置项目/产品【" + _vm.showItem.ctpZptName + "】耗材",
                "append-to-body": "",
                visible: _vm.hcVisible,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "show-close": false,
                width: "42%",
                top: "10px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.hcVisible = $event
                },
                open: _vm.gethcList,
              },
            },
            [
              _c("HcTable", {
                ref: "hcTable",
                attrs: {
                  hcList: _vm.hcList,
                  customer: _vm.customer,
                  temp: _vm.showItem,
                  zptIdList: _vm.empoutVoList,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  departmentList: _vm.departmentList,
                  storageList: _vm.storageList,
                  typeList: _vm.rvTypes,
                  "msg-list": _vm.msgList,
                  height: _vm.height - 200,
                  setHcType: _vm.setHcType,
                  oldTemp: _vm.clickTemp2,
                },
                on: {
                  closeVisible: _vm.kcVisibleFun,
                  close: function ($event) {
                    return _vm.oprateBtnClick(3, "kc", $event)
                  },
                  cancelHk: function ($event) {
                    return _vm.handleCancelC(_vm.showItem.id)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        loading: _vm.btnLoading,
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleSubmit(3)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.cancelSetHc } },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Print", {
        ref: "cumsumeRef",
        attrs: { customer: _vm.customer, projectList: _vm.projectList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }