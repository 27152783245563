import request from '@/utils/request'; // 院内营收汇总

export function statistics(data) {
  return request({
    url: '/manager/revenue/statistics',
    method: 'post',
    data: data
  });
} // 院内营收明细

export function statisticsItem(data) {
  return request({
    url: '/manager/revenue/item/statistics',
    method: 'post',
    data: data
  });
} // 各类型收款情况

export function payType(data) {
  return request({
    url: '/manager/revenue/payType',
    method: 'post',
    data: data
  });
} // 收银员营收汇总

export function operation(data) {
  return request({
    url: '/manager/revenue/operation',
    method: 'post',
    data: data
  });
} // 日常收支明细

export function account(data) {
  return request({
    url: '/manager/revenue/itme/account',
    method: 'post',
    data: data
  });
} // 消费排行列表

export function rankList(data) {
  return request({
    url: '/manager/ctmPay/payRanking',
    method: 'post',
    data: data
  });
} // 已消费列表

export function consumedList(data) {
  return request({
    url: '/manager/ctmPay/pageConsumed',
    method: 'post',
    data: data
  });
} // 已订购列表

export function payList(data) {
  return request({
    url: '/manager/ctmPay/pageCustomerpay',
    method: 'post',
    data: data
  });
} // 顾客消耗明细

export function queryConsumeList(data) {
  return request({
    url: '/manager/ctmPay/queryConsumeList',
    method: 'post',
    data: data
  });
} // 顾客消费明细

export function queryConsumptionList(data) {
  return request({
    url: '/manager/ctmPay/queryConsumptionList',
    method: 'post',
    data: data
  });
} // 科室营收查询

export function revenueDept(data) {
  return request({
    url: '/manager/revenue/revenueDept',
    method: 'post',
    data: data
  });
} // 科室消费查询

export function queryPayDept(data) {
  return request({
    url: '/manager/ctmPay/queryPayDept',
    method: 'post',
    data: data
  });
} // 客户退款

export function refundGetList(data) {
  return request({
    url: '/manager/refund/getList',
    method: 'post',
    data: data
  });
} // 院内营收汇总表

export function excelStatistics(data) {
  return request({
    url: '/manager/revenue/excelStatistics',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 各类型收款情况导出Excel

export function payTypeExportExcel(data) {
  return request({
    url: '/manager/revenue/payType/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 收银员营收汇总导出Excel

export function operationExportExcel(data) {
  return request({
    url: '/manager/revenue/operation/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 院内营收汇总表

export function exportExcelItem(data) {
  return request({
    url: '/manager/revenue/item/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 平台列表

export function plantformGetList(data) {
  return request({
    url: '/manager/plantform/getList',
    method: 'get',
    params: data
  });
} // 平台列表-更新

export function plantformAddOrUpdate(data) {
  return request({
    url: '/manager/plantform/addOrUpdate',
    method: 'post',
    data: data
  });
} // 日常收支

export function dayaccxportExcel(data) {
  return request({
    url: '/manager/dayacc/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 退款调整收入列表

export function queryRefundAmountPage(data) {
  return request({
    url: '/manager/refundAmount/queryRefundAmountPage',
    method: 'post',
    data: data
  });
} // 退款调整收入列表-导出

export function queryRefundAmountExport(data) {
  return request({
    url: '/manager/refundAmount/queryRefundAmountExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 退款明细-导出

export function refundExportExcel(data) {
  return request({
    url: '/manager/refund/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 退款明细总计

export function refundGetSum(data) {
  return request({
    url: '/manager/refund/getSum',
    method: 'post',
    data: data
  });
} // 退款明细总计

export function getPlantform(data) {
  return request({
    url: '/manager/revenue/getPlantform',
    method: 'post',
    data: data
  });
} // 平台导出

export function getPlantformExport(data) {
  return request({
    url: '/manager/revenue/getPlantformExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 对接表1

export function getKingdeeAmountReport(data) {
  return request({
    url: '/manager/report/getKingdeeAmountReport',
    method: 'post',
    data: data
  });
} // 对接表2

export function getKingdeeRefundAmountReport(data) {
  return request({
    url: '/manager/report/getKingdeeRefundAmountReport',
    method: 'post',
    data: data
  });
} // 对接表3

export function queryTransactionGroupByZptCode(data) {
  return request({
    url: '/manager/report/queryTransactionGroupByZptCode',
    method: 'post',
    data: data
  });
}