import request from '@/utils/request'; // 现场咨询审核

export function verifyproduct(data) {
  return request({
    url: '/manager/verifyproduct/pageVo',
    method: 'post',
    data: data
  });
} // 现场咨询审核 - 修改

export function verifyproductModifyPrice(data) {
  return request({
    url: '/manager/verifyproduct/modifyPrice',
    method: 'get',
    params: data
  });
} // 现场咨询审核 - 审核通过

export function verifyproductVerify(data) {
  return request({
    url: '/manager/verifyproduct/batchVerify',
    method: 'get',
    params: data
  });
} // 退款审核

export function zsbAmountVerifyPageVo(data) {
  return request({
    url: '/manager/verifyproduct/zsbAmountVerify/pageVo',
    method: 'post',
    data: data
  });
} // 截止日期审核

export function zsbAmountFnsdateVerifyPageVo(data) {
  return request({
    url: '/manager/verifyfnsdate/zsbAmountVerify/pageVo',
    method: 'post',
    data: data
  });
} // // 退款审核 - 修改
// export function verifyproductModifyPrice(data) {
// return request({
//     url: '/manager/verifyproduct/modifyPrice',
//     method: 'get',
//     params: data
// })
// }
// 退款审核 - 审核通过

export function zsbAmountVerify(data) {
  return request({
    url: '/manager/verifyproduct/zsbAmountVerify',
    method: 'get',
    params: data
  });
} // 截止日期审核 - 审核通过

export function zsbAmountFnsVerify(data) {
  return request({
    url: '/manager/verifyfnsdate/zsbAmountVerify',
    method: 'get',
    params: data
  });
} // 处方药审核

export function zsbAmountFnsVerifyBatch(data) {
  return request({
    url: '/manager/verifyfnsdate/zsbAmountVerify/batch',
    method: 'get',
    params: data
  });
} // 现场咨询审核导出

export function verifyproductPageVoExport(data) {
  return request({
    url: '/manager/verifyproduct/pageVoExport ',
    method: 'post',
    data: data
  });
} //退款审核列表导出

export function zsbAmountVerifyExport(data) {
  return request({
    url: '/manager/verifyproduct/zsbAmountVerify/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //戒子日期审核列表导出

export function zsbAmountFnsdateVerifyExport(data) {
  return request({
    url: '/manager/verifyfnsdate/zsbAmountVerify/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 处方药审核列表

export function verifycfPageVo(data) {
  return request({
    url: '/manager/verifycf/pageVo',
    method: 'post',
    data: data
  });
} // 处方药审核

export function verifycfBatchVerify(data) {
  return request({
    url: '/manager/verifycf/batchVerify',
    method: 'post',
    params: data
  });
} // 处方药审核

export function verifycfDeptPageVo(data) {
  return request({
    url: '/manager/verifycf/deptPageVo',
    method: 'post',
    data: data
  });
} // 处方药审核

export function verifycfDeptBatchVerify(data) {
  return request({
    url: '/manager/verifycf/deptBatchVerify',
    method: 'post',
    params: data
  });
} // 审核 业绩分配

export function changeAdvisers(data) {
  return request({
    url: '/manager/ctmZpt/changeAdvisers',
    method: 'post',
    data: data
  });
} // 券 审核列表

export function verifycoup(data) {
  return request({
    url: '/manager/verifycoup/pageVo',
    method: 'post',
    data: data
  });
} // 券 审核

export function batchVerify(data) {
  return request({
    url: '/manager/verifycoup/batchVerify',
    method: 'post',
    params: data
  });
} // 平台 审核列表

export function verifyplantPageVo(data) {
  return request({
    url: '/manager/verifyplant/pageVo',
    method: 'post',
    data: data
  });
} // 平台 审核

export function verifyplantBatchVerify(data) {
  return request({
    url: '/manager/verifyplant/batchVerify',
    method: 'post',
    params: data
  });
} // 咨询情况 审核列表

export function verifyzx(data) {
  return request({
    url: '/manager/verifyzx/pageVo',
    method: 'post',
    data: data
  });
} // 咨询情况 审核

export function zxBatchVerify(data) {
  return request({
    url: '/manager/verifyzx/zxBatchVerify',
    method: 'post',
    params: data
  });
}