import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import backTable from "./back-table";
import { comsumeRtdDeductionVerify, comsumeGetRtdDeductionAmt } from '@/api/comsume';
import moment from 'moment';
export default {
  name: 'backHk',
  components: {
    backTable: backTable
  },
  props: {
    customer: {
      type: Object
    },
    height: {
      required: true
    },
    checkTemp: {
      type: Object
    },
    approvalInfo: {
      type: Object
    }
  },
  data: function data() {
    return {
      dialogVisible1: false,
      listQuery: {
        pageNum: 1,
        pageSize: 10
      },
      num: 0,
      disabledBtn: true,
      temp: {},
      rules: {
        giftPrice: [{
          required: true,
          trigger: 'change'
        }],
        payType: [{
          required: true,
          trigger: 'change'
        }],
        giftCtmCode: [{
          required: true,
          trigger: 'change'
        }],
        giftCtmName: [{
          required: true,
          trigger: 'change'
        }],
        cardType: [{
          required: true,
          trigger: 'blur'
        }]
      }
    };
  },
  computed: _objectSpread({}, mapGetters(['earId', 'eareList', 'employees', 'departmentList', 'empCode', 'userName', 'userInfo'])),
  created: function created() {
    if (this.checkTemp) {
      this.temp = JSON.parse(JSON.stringify(this.checkTemp)); // this.getNum()
    }

    this.$set(this.temp, 'cpyDate', moment().format('YYYY-MM-DD HH:mm:ss'));
  },
  watch: {
    'temp.rtdNum': {
      handler: function handler(val) {
        var _this = this;

        if (val) {
          console.log('val', val);

          if (val > this.temp.cpyHknum && this.checkTemp == null) {
            this.disabledBtn = true;
            this.$message({
              message: '退划扣次数不能大于总次数',
              type: 'warning'
            });
            return;
          }

          if (val <= 0) {
            this.disabledBtn = true;
            this.$message({
              message: '退划扣次数不能小于0',
              type: 'warning'
            });
            return;
          }

          this.disabledBtn = false;

          if (this.temp.ctmZptId && this.checkTemp == null) {
            comsumeGetRtdDeductionAmt({
              rtdNum: val,
              ctmZptId: this.temp.ctmZptId
            }).then(function (res) {
              console.log('res', res);

              _this.$set(_this.temp, 'rtdAmt', res.data); // this.temp.rtdAmt = res.data

            });
          }
        } else {
          this.$set(this.temp, 'rtdAmt', null);
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleDone: function handleDone() {
      var _this2 = this;

      this.$confirm("\u9000\u5212\u6263\u9700\u8981\u5BA1\u6838\uFF0C\u5982\u9700\u8C03\u6574\u9879\u76EE\u622A\u6B62\u65F6\u95F4\u8BF7\u624B\u52A8\u8C03\u6574\uFF01\u786E\u5B9A\u8981\u5BF9\u5BA2\u6237 ".concat(this.customer.pidCtmname, " \u9000\u5212\u6263? "), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.temp.earId = _this2.earId;
        _this2.temp.ctmCode = _this2.customer.pidCtmcode;
        _this2.temp.ctmName = _this2.customer.pidCtmname;
        _this2.temp.optCode = _this2.empCode;
        _this2.temp.optName = _this2.userName;
        comsumeRtdDeductionVerify(_this2.temp).then(function (res) {
          var msg = _this2.userInfo.GLOBAL_RIGHT ? '操作成功！' : '提交审核成功';

          if (res.code === 200) {
            _this2.$message({
              message: msg,
              type: 'success',
              onClose: function onClose() {
                _this2.$emit('done');
              }
            });
          }
        });
      }).catch(function () {});
    },
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    },
    handleCancel1: function handleCancel1() {
      this.dialogVisible1 = false;
    },
    // getNum() {
    //   queryConsumeList({pageNum:1, pageSize: 50,cpyCtmcode: this.temp.ctmCode, gtTrueHknum:0}).then(res => {
    //     console.log('ctmZptId', res.data.list);
    //     res.data.list.some(item => {
    //       if (item.id === this.temp.ctmZptId) {
    //         this.num = item.cpyHknum
    //       }
    //     })
    //   })
    // },
    handleSelect1: function handleSelect1(customer) {
      this.dialogVisible1 = false;
      this.temp.zptName = customer.cpyZptName;
      this.temp.ctmZptId = customer.id;
      this.temp.cpyHknum = customer.cpyHknum2;
    }
  }
};