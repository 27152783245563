//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Bar from "./bar";
export default {
  name: 'Scroll',
  components: {
    Bar: Bar
  },
  props: {
    native: {},
    wrapClass: {},
    tag: {
      type: String,
      default: 'div'
    },
    viewClass: {
      type: [String, Array],
      default: ''
    },
    viewStyle: {
      type: [String, Array],
      default: ''
    },
    viewH: {},
    itemH: {},
    listSize: {},
    boxHeigth: {},
    state: {
      type: Object
    }
  },
  data: function data() {
    return {
      moveX: 0,
      sizeWidth: 0,
      // sizeHeight: 0,
      style: {},
      offset: 0,
      moveY: 0
    };
  },
  created: function created() {},
  computed: {
    sizeHeight: function sizeHeight() {
      return this.boxHeigth * 100 / this.viewH;
    }
  },
  mounted: function mounted() {},
  watch: {
    data: {
      handler: function handler(value) {// console.log('value', value);
      }
    }
  },
  updated: function updated() {
    console.log('uptate', this.state.updateRequested);

    if (this.state.updateRequested) {
      this.$refs.wrap.scrollTop = this.state.scrollOffset;
    }
  },
  methods: {
    handleScroll: function handleScroll(e) {
      // console.log('e', e);
      this.moveY = Math.max(0, Math.min(e.currentTarget.scrollTop, this.viewH - this.boxHeigth)) / (this.viewH - this.boxHeigth) * Math.floor(this.boxHeigth - this.sizeHeight - 4); // this.state.scrollOffset = e.currentTarget.scrollTop;
    },
    handleWheel: function handleWheel(e) {
      var _this = this;

      e.preventDefault();
      var atStartEdge = this.state.scrollOffset <= 0;
      var atEndEdge = this.state.scrollOffset >= this.viewH;
      var offset = 0;

      var hasReachedEdge = function hasReachedEdge(offset) {
        var edgeReached = offset < 0 && atStartEdge || offset > 0 && atEndEdge;
        return edgeReached;
      };

      var newOffset = e.deltaY;
      if (hasReachedEdge(offset) && hasReachedEdge(offset + newOffset)) return;
      offset += newOffset;
      var times = setTimeout(function () {
        _this.$emit('wheelFun', Math.min(_this.state.scrollOffset + offset, _this.viewH - _this.boxHeigth));

        clearTimeout(times);
        offset = 0;
      }, 16);
    }
  }
};