import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000000 // request timeout

});
service.defaults.headers.post['Content-Type'] = 'application/json';
service.defaults.responseType = 'blob'; // request interceptor

service.interceptors.request.use(function (config) {
  for (var i in config.data) {
    if (!config.data[i] && config.data[i] != 0) {
      delete config.data[i];
    }
  } // do something before request is sent


  if (!config.noToken) {
    config.headers['token'] = getToken();
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
});
export default service;