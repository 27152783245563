var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "trackRef",
      class: ["scrollbar__bar", "is-" + _vm.bar.key],
      style: _vm.trackStyle,
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.handleMousedown($event)
        },
      },
    },
    [
      _c("div", {
        ref: "thumbRef",
        staticClass: "scrollbar__thumb",
        style: _vm.getStyle,
        on: { mousedown: _vm.handleMousedown },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }