import request from '@/utils/request'; // 接待分诊分页

export function receptionList(data, stillStates) {
  return request({
    url: '/manager/reception/callInfo/page',
    method: 'post',
    data: data,
    stillStates: stillStates
  });
} // 接待分诊详情

export function callInfo(data) {
  return request({
    url: '/manager/reception/callInfo',
    method: 'get',
    params: data
  });
} // 新增接待分诊

export function addCallInfo(data) {
  return request({
    url: '/manager/reception/callInfo/add',
    method: 'post',
    data: data
  });
} // 修改接待分诊(现场咨询的修改也是这个)

export function updateCallInfo(data) {
  return request({
    url: '/manager/reception/callInfo/update',
    method: 'post',
    data: data
  });
} // 现场设计列表

export function listCurentTabl(data) {
  return request({
    url: '/manager/curentMemo/listCurentTabl',
    method: 'post',
    params: data
  });
} // 电网保单记录列表

export function listCurentMemo(data) {
  return request({
    url: '/manager/curentMemo/listCurentMemo',
    method: 'post',
    params: data
  });
} // 过往咨询

export function listHistory(data) {
  return request({
    url: '/manager/reception/callInfo/listHistory',
    method: 'post',
    params: data
  });
} // 改派(新系统无助推医师)

export function changeEmp(data) {
  return request({
    url: '/manager/reception/callInfo/changeEmp',
    method: 'post',
    data: data
  });
} //

export function receptionCallInfo(data) {
  return request({
    url: '/manager/reception/callInfo/stat',
    method: 'post',
    data: data
  });
} // f分诊导出

export function receptionExport(data) {
  return request({
    url: '/manager/reception/callInfo/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}