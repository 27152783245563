/*
 * @Author: yangchao
 * @Date: 2020-08-11 09:19:11
 * @LastEditors: yangchao
 * @LastEditTime: 2021-07-24 15:16:22
 * @Description:
 */
import Cookies from 'js-cookie';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  globalLoading: false,
  empSelectSaveStatus: false,
  tableLoading: false,
  mainLoading: false
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;

    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    Cookies.set('size', size);
  },
  SET_GLOBALLOADING: function SET_GLOBALLOADING(state, loading) {
    if (loading !== undefined) {
      state.globalLoading = loading;
    }

    state.globalLoading = !state.globalLoading;
  },
  SET_EMP_SAVE_STATUS: function SET_EMP_SAVE_STATUS(state, status) {
    state.empSelectSaveStatus = status;
  },
  SET_TABLE_LOADING: function SET_TABLE_LOADING(state, loading) {
    state.tableLoading = loading;
  },
  TOGGLE_MAIN_LOADING: function TOGGLE_MAIN_LOADING(state, loading) {
    state.mainLoading = loading;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref5, size) {
    var commit = _ref5.commit;
    commit('SET_SIZE', size);
  },
  toggleLoading: function toggleLoading(_ref6, loading) {
    var commit = _ref6.commit;
    commit('SET_GLOBALLOADING', loading);
  },
  toggleEmpStauts: function toggleEmpStauts(_ref7, status) {
    var commit = _ref7.commit;
    commit('SET_EMP_SAVE_STATUS', status);
  },
  setTableLoading: function setTableLoading(_ref8, status) {
    var commit = _ref8.commit;
    commit('SET_TABLE_LOADING', status);
  },
  toggleMainLoading: function toggleMainLoading(_ref9, status) {
    var commit = _ref9.commit;
    commit('TOGGLE_MAIN_LOADING', status);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};