var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      style: { height: _vm.height + "px", overflow: "scroll" },
    },
    [
      _c(
        "el-container",
        { staticClass: "fixed_table" },
        [
          _c(
            "el-header",
            { attrs: { height: "auto" } },
            [
              _c("base-header", {
                attrs: {
                  actions: _vm.actions,
                  temp: _vm.customer,
                  selectColumns: _vm.columns2,
                  "list-query": _vm.listQuery,
                  columns: _vm.columns2,
                },
                on: { action: _vm.handleAction, search: _vm.handleRefresh },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "form-container",
              style: { height: _vm.height - 100 + "px" },
            },
            [
              !_vm.approvalInfo
                ? [
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("交易明细列表(项目+产品+预收)"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "red" },
                        attrs: { type: "text" },
                      },
                      [
                        _vm._v(
                          "【" +
                            _vm._s(
                              _vm.customer.pidCtmname ||
                                _vm.customer.ctfCtmname ||
                                _vm.customer.ctmName ||
                                _vm.customer.ctpCtmname
                            ) +
                            "-" +
                            _vm._s(
                              _vm.customer.pidCtmcode ||
                                _vm.customer.ctfCtmcode ||
                                _vm.customer.ctmCode ||
                                _vm.customer.ctpCtmcode
                            ) +
                            "-" +
                            _vm._s(
                              _vm.customer.pidCtmTel ||
                                _vm.customer.ctfTel ||
                                _vm.customer.mobile
                            ) +
                            "】"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "red" },
                        attrs: { type: "text" },
                      },
                      [_vm._v("应付金额:" + _vm._s(_vm.price))]
                    ),
                  ]
                : [
                    _c(
                      "el-form",
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "审核备注:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea", rows: 3 },
                                      model: {
                                        value: _vm.approvalInfo.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.approvalInfo,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression: "approvalInfo.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-top": "60px",
                                      "margin-left": "20px",
                                    },
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      disabled: _vm.customer.verifyState == "4",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("sureCheckXm")
                                      },
                                    },
                                  },
                                  [_vm._v("审核通过")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "60px" },
                                    attrs: {
                                      size: "mini",
                                      type: "danger",
                                      disabled: _vm.customer.verifyState == "4",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("cancelCheckXm")
                                      },
                                    },
                                  },
                                  [_vm._v("审核驳回")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
              _vm._v(" "),
              _c("VxeTable", {
                ref: "table",
                attrs: {
                  preColumnSlot: "",
                  checkbox: "",
                  showIndex: false,
                  topSelect: _vm.customer.autoSelect,
                  temp: _vm.customer,
                  list: _vm.list1,
                  height: _vm.height - 100 - 50,
                  "list-query": _vm.listQuery,
                  columns: _vm.columns1,
                  autoClear: false,
                  scrollTopLastLocation: false,
                  setCurrentRowFirst: false,
                  deepWatchCol: false,
                  selectInit: _vm.selectInit,
                  checkMethod: _vm.checkMethod,
                  spanMethod: _vm.spanMethod,
                  "footer-method": _vm.footerMethod,
                  "merge-footer-items": [
                    {
                      row: 0,
                      col: 1,
                      rowspan: 2,
                      colspan: 6,
                    },
                  ],
                },
                on: {
                  selectItem: _vm.selectItem,
                  allSelect: _vm.allSelect,
                  click: _vm.handleClick,
                },
                scopedSlots: _vm._u([
                  {
                    key: "preColumn",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-checkbox", {
                          attrs: { disabled: !row.id },
                          on: {
                            change: function (val) {
                              return _vm.printCheckChange(val, row)
                            },
                          },
                          model: {
                            value: row.checked,
                            callback: function ($$v) {
                              _vm.$set(row, "checked", $$v)
                            },
                            expression: "row.checked",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              [
                _c(
                  "el-button",
                  { staticStyle: { color: "red" }, attrs: { type: "text" } },
                  [
                    _vm._v(
                      "注： 第一列选中的是需要打印的项目， 第二列选中的需要结账的项目"
                    ),
                  ]
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                center: "",
                title: "选择项目列表",
                "append-to-body": "",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
                top: "80px",
                width: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("xm-table", {
                ref: "customer",
                attrs: {
                  pidCode: _vm.pidCode,
                  advisers: _vm.advisers,
                  customer: _vm.customer,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  height: _vm.height - 60,
                },
                on: {
                  cancel: _vm.handleCancel,
                  setVisible: _vm.setProjectItem,
                  done: _vm.handleProject,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                center: "",
                title: "当催划扣次数设定",
                "append-to-body": "",
                visible: _vm.dialogVisible2,
                "close-on-click-modal": false,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
              },
            },
            [
              _c("hk-form", {
                ref: "customer",
                attrs: {
                  customer: _vm.customer,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  height: _vm.height - 200,
                },
                on: { cancel: _vm.handleCancel },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible3
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                center: "",
                title: "需要调整的消费记录",
                "append-to-body": "",
                visible: _vm.dialogVisible3,
                "close-on-click-modal": false,
                top: "80px",
                width: "70%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible3 = $event
                },
              },
            },
            [
              _c("tz-table", {
                ref: "customer",
                attrs: {
                  customer: _vm.customer,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  height: _vm.height - 120,
                },
                on: { cancel: _vm.handleCancel3, done: _vm.handleDone3 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible4
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户转赠项目资料",
                visible: _vm.dialogVisible4,
                "close-on-click-modal": false,
                top: (_vm.innerHeight - 360) / 2 + "px",
                width: "550px",
                center: "",
                "destroy-on-close": "",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible4 = $event
                },
              },
            },
            [
              _c("zz-form", {
                ref: "dialog2",
                attrs: {
                  customer: _vm.temp,
                  "ctf-zd-doc-list": _vm.employees,
                  height: 320,
                },
                on: { cancel: _vm.handleCancel4, done: _vm.handleDone4 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible5
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "零购产品",
                visible: _vm.dialogVisible5,
                top: "60px",
                "append-to-body": "",
                "close-on-click-modal": false,
                center: "",
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible5 = $event
                },
              },
            },
            [
              _c("lg-table", {
                ref: "dialog1",
                attrs: { height: _vm.height - 20 },
                on: { done: _vm.handleDone5 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showJZ
        ? _c("JzDialog", {
            attrs: {
              selectProList: _vm.selectProList,
              top: "80px",
              width: "50%",
              pidIdserno: _vm.pidIdserno,
              pidCode: _vm.customer.pidCode,
              ids: _vm.ids,
              ctmCode: _vm.customer.pidCtmcode || _vm.customer.ctmCode,
            },
            on: {
              cancel: function ($event) {
                _vm.showJZ = false
              },
              success: _vm.success,
            },
            model: {
              value: _vm.showJZ,
              callback: function ($$v) {
                _vm.showJZ = $$v
              },
              expression: "showJZ",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }