var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { attrs: { height: "auto" } },
            [
              _c("base-header", {
                ref: "header",
                attrs: {
                  excel: true,
                  "task-btn": _vm.activeName === "3",
                  actions: _vm.actions,
                  temp: _vm.customer,
                  listQuery:
                    _vm.activeName === "3" ? _vm.listQuery3 : _vm.listQuery,
                  columns: _vm.columns,
                  exportMessage: _vm.exportMessage,
                  selectColumns: _vm.columns1,
                  revenueBtnShow: "",
                  timeControl: {
                    begin: "ctfFucdate",
                    end: "endCtfFucdate",
                    time: "day",
                  },
                },
                on: { action: _vm.handleAction, search: _vm.handleSearch },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            [
              _c(
                "search-form",
                {
                  attrs: {
                    columns: _vm.columns,
                    listQuery:
                      _vm.activeName === "3" ? _vm.listQuery3 : _vm.listQuery2,
                  },
                  on: {
                    search: function ($event) {
                      return _vm.handleSearch(null, 1)
                    },
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName === "3",
                          expression: "activeName === '3'",
                        },
                      ],
                      staticStyle: { "margin-left": "20px" },
                      on: { change: _vm.handleSearch },
                      model: {
                        value: _vm.searchStatus,
                        callback: function ($$v) {
                          _vm.searchStatus = $$v
                        },
                        expression: "searchStatus",
                      },
                    },
                    [_vm._v("查询总合计")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml20" },
                _vm._l(_vm.ctmstat, function (item, index) {
                  return _c(
                    "el-tag",
                    {
                      key: item.ctmLevel,
                      staticClass: "mr10",
                      attrs: { type: _vm.getTagType(index) },
                    },
                    [
                      _vm._v(
                        _vm._s(item.ctmLevelName || "暂无等级") +
                          ": " +
                          _vm._s(item.num)
                      ),
                    ]
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-header",
                { staticClass: "tabs-header" },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleTabClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "员工预约", name: "2" },
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "预约", name: "1" },
                      }),
                      _vm._v(" "),
                      _c("el-tab-pane", {
                        attrs: { label: "预约统计", name: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                [
                  _vm.activeName === "1"
                    ? [
                        _c("edit-table", {
                          ref: "table1",
                          attrs: {
                            temp: _vm.temp,
                            list: _vm.list1,
                            height: _vm.innerHeight - 320,
                            listQuery:
                              _vm.selectIndex == 1
                                ? _vm.listQuery2
                                : _vm.listQuery,
                            columns: _vm.columns1,
                            pagination: "",
                            total: _vm.total1,
                            localStorageCurrentKey: "fuc_employee_local",
                          },
                          on: {
                            click: _vm.handleClick,
                            pagination: _vm.getlist1,
                          },
                        }),
                      ]
                    : _vm.activeName === "2"
                    ? [
                        _c("base-table", {
                          ref: "table3",
                          attrs: {
                            "not-high-light": "",
                            tableClass: "auto_header_heigth",
                            temp: _vm.customer,
                            list: _vm.list2,
                            height: _vm.innerHeight - 320,
                            listQuery:
                              _vm.selectIndex == 1
                                ? _vm.listQuery2
                                : _vm.listQuery,
                            columns: _vm.columns3,
                            localStorageCurrentKey: "fuc_local",
                          },
                          on: { "cell-dblclick": _vm.handleDblClick1 },
                          scopedSlots: _vm._u(
                            [
                              _vm._l(15, function (key) {
                                return {
                                  key: "field_" + (key + 7),
                                  fn: function (scope) {
                                    return [
                                      _vm.getMin(
                                        scope.row.ctfSftime,
                                        key + 7
                                      ) ||
                                      _vm.getMax(scope.row.ctfSttime, key + 7)
                                        ? [
                                            _c(
                                              "el-tag",
                                              {
                                                key: key,
                                                attrs: { type: "info" },
                                              },
                                              [_vm._v("休息")]
                                            ),
                                          ]
                                        : [
                                            scope.row.fucMap &&
                                            scope.row.fucMap[key + 7] &&
                                            scope.row.fucMap[key + 7].length > 0
                                              ? _c(
                                                  "div",
                                                  { key: key },
                                                  _vm._l(
                                                    scope.row.fucMap[key + 7],
                                                    function (item, index) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.ctfCode,
                                                          staticStyle: {
                                                            height: "32px",
                                                            width: "170px",
                                                            "margin-left":
                                                              "-10px",
                                                            padding: "0 5px",
                                                            "text-align":
                                                              "left",
                                                          },
                                                          style: {
                                                            "border-bottom":
                                                              scope.row.fucMap[
                                                                key + 7
                                                              ].length ===
                                                              index + 1
                                                                ? "none"
                                                                : "1px solid #ddd",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-popover",
                                                            {
                                                              attrs: {
                                                                placement:
                                                                  "top",
                                                                width: "240",
                                                                trigger:
                                                                  "hover",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c("div", {
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          _vm.formatter2(
                                                                            item
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  item.ctfStatus ===
                                                                  "USE"
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          staticClass:
                                                                            "mt10",
                                                                          attrs:
                                                                            {
                                                                              size: "mini",
                                                                              disabled:
                                                                                _vm.getCancelEnable(
                                                                                  item.ctfOpter
                                                                                ),
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleCancelFuc(
                                                                                  item.id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "取消预约"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  item.ctfStatus ===
                                                                  "USE"
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          staticClass:
                                                                            "mt10",
                                                                          attrs:
                                                                            {
                                                                              type: "primary",
                                                                              size: "mini",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.modifyFun(
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "修改预约"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "el-tag",
                                                                {
                                                                  attrs: {
                                                                    slot: "reference",
                                                                    type:
                                                                      item.ctfStatus !==
                                                                      "USE"
                                                                        ? "success"
                                                                        : "danger",
                                                                  },
                                                                  slot: "reference",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.ctfName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ],
                                    ]
                                  },
                                }
                              }),
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    : [
                        _c("VxeTable", {
                          attrs: {
                            temp: _vm.customer,
                            list: _vm.list2,
                            height: _vm.innerHeight - 320,
                            listQuery: _vm.listQuery3,
                            columns: _vm.columns5,
                            total: _vm.total1,
                            pagination: "",
                          },
                          on: { pagination: _vm.getlist3 },
                        }),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户列表",
                visible: _vm.dialogVisible,
                top: "80px",
                "append-to-body": "",
                "close-on-click-modal": false,
                center: "",
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("kh-table", {
                ref: "dialog",
                attrs: {
                  "ear-id": _vm.earId,
                  "ctf-zd-doc-list": _vm.employees,
                  height: _vm.innerHeight - 170,
                },
                on: { select: _vm.handleSelect },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.addFuc.visible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: _vm.addFuc.title,
                visible: _vm.addFuc.visible,
                top: "80px",
                "append-to-body": "",
                "close-on-click-modal": false,
                center: "",
                width: "360px",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(_vm.addFuc, "visible", $event)
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "fucForm",
                  staticClass: "mini",
                  attrs: {
                    "show-message": false,
                    model: _vm.temp,
                    rules: _vm.addFuc.rules,
                    size: "mini",
                    "label-position": "right",
                    "label-width": "100px",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.fnFucSubmit("fucForm")
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户手机号:", prop: "ctfMsgmobil" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: !!_vm.temp.id, placeholder: "" },
                        on: { blur: _vm.fnCtfMsgmobileBulr },
                        model: {
                          value: _vm.temp.ctfMsgmobil,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "ctfMsgmobil", $$v)
                          },
                          expression: "temp.ctfMsgmobil",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户姓名:", prop: "ctfName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          placeholder: "",
                          disabled: !!_vm.temp.ctfMsgmobil,
                        },
                        model: {
                          value: _vm.temp.ctfName,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "ctfName", $$v)
                          },
                          expression: "temp.ctfName",
                        },
                      }),
                      _vm._v(" "),
                      _vm.addFuc.title !== "修改预约"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible2 = true
                                },
                              },
                            },
                            [_vm._v("查询")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预约时间：", prop: "ctfFuctime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          type: "datetime",
                          clearable: false,
                          placeholder: "",
                          format: "yyyy-MM-dd HH:mm",
                          "value-format": "yyyy-MM-dd HH:mm",
                          "picker-options": {
                            selectableRange: _vm.temp.rankTime,
                          },
                        },
                        model: {
                          value: _vm.temp.ctfFuctime,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "ctfFuctime", $$v)
                          },
                          expression: "temp.ctfFuctime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预约类型：", prop: "ctfType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { filterable: "", placeholder: "" },
                          model: {
                            value: _vm.temp.ctfType,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "ctfType", $$v)
                            },
                            expression: "temp.ctfType",
                          },
                        },
                        _vm._l(
                          _vm.fucTypeList.filter(function (v) {
                            return v.status === "USE"
                          }),
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.title, value: item.field },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "邀约人：", prop: "ctfInviting" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { filterable: "", placeholder: "" },
                          model: {
                            value: _vm.temp.ctfInviting,
                            callback: function ($$v) {
                              _vm.$set(_vm.temp, "ctfInviting", $$v)
                            },
                            expression: "temp.ctfInviting",
                          },
                        },
                        _vm._l(
                          _vm.getEmployeeTypeList(),
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.title, value: item.field },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预约说明：", prop: "ctfRemark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "", type: "textarea", rows: "2" },
                        model: {
                          value: _vm.temp.ctfRemark,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "ctfRemark", $$v)
                          },
                          expression: "temp.ctfRemark",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.addFuc.visible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.fnFucSubmit("fucForm")
                            },
                          },
                        },
                        [_vm._v("提交")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb40" }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户列表",
                visible: _vm.dialogVisible2,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "100px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
              },
            },
            [
              _c("all-kh-table", {
                ref: "customer",
                attrs: {
                  height: _vm.innerHeight - 250,
                  ctmName: _vm.temp.ctfName,
                },
                on: { select: _vm.handleSelect2 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeName == 2
        ? _c(
            "div",
            { staticStyle: { "margin-left": "20px" } },
            [
              _c("el-tag", [
                _vm._v("治疗:" + _vm._s(_vm.list2Sum.zl) + "    "),
              ]),
              _vm._v(" "),
              _c("el-tag", { attrs: { type: "success" } }, [
                _vm._v("护理:" + _vm._s(_vm.list2Sum.hl) + "   "),
              ]),
              _vm._v(" "),
              _c("el-tag", { attrs: { type: "info" } }, [
                _vm._v("纹绣:" + _vm._s(_vm.list2Sum.wx) + "   "),
              ]),
              _vm._v(" "),
              _c("el-tag", { attrs: { type: "warning" } }, [
                _vm._v("其他:" + _vm._s(_vm.list2Sum.qt) + "     "),
              ]),
              _vm._v(" "),
              _c("el-tag", { attrs: { type: "success" } }, [
                _vm._v(" 预约人数:" + _vm._s(_vm.list2Sum.fucNum) + "  "),
              ]),
              _vm._v(" "),
              _c("el-tag", [
                _vm._v("  已上门人数:" + _vm._s(_vm.list2Sum.finishNum)),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }