import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { employeeSimpleList } from '@/api/employee';
import { AppMain, Navbar, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { customerLevelList } from '@/api/manage';
import { mapState, mapGetters } from 'vuex';
import { getToken } from '@/utils/auth';
import { setEmployees } from '@/utils/common'; // import store from '@/store';

import { formatTime } from '@/utils/moment';
import JhTable from "./components/jh_table";
import fuc from '@/views/callFuc/fuc.vue';
import Check from '@/views/check/index.vue';
export default {
  name: 'Layout',
  components: {
    AppMain: AppMain,
    Navbar: Navbar,
    Sidebar: Sidebar,
    TagsView: TagsView,
    Check: Check
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {
      show: false,
      dialogVisible: false,
      setinterval: null,
      levelList: [],
      checkDialog: false,
      times: null,
      outDept: {
        showTip: false,
        tip: '您收到新的出库信息，请及时出库！'
      },
      outList: []
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(['earId', 'empCode', 'intensList', 'fucTypeList', 'employees', 'rightWidth', 'eareList', 'services', 'permission_AddRoutes'])), mapState({
    earId: function earId(state) {
      return state.user.earId;
    },
    employees: function employees(state) {
      return state.common.employees;
    },
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    globalLoading: function globalLoading() {
      return this.$store.state.app.mainLoading;
    }
  }),
  activated: function activated() {},
  created: function created() {
    this.initWebSocket(); // this.$store.dispatch('common/getCommon')

    var sizeStore = 0;

    if (window.localStorage) {
      // 遍历所有存储
      for (var item in window.localStorage) {
        if (window.localStorage.hasOwnProperty(item)) {
          sizeStore += window.localStorage.getItem(item).length;
        }
      }
    }

    console.log((sizeStore / 1024 / 1024).toFixed(2) + 'M');
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      // 当关闭的时候关闭这个弹窗
      if (!val) {
        this.setinterval && clearTimeout(this.setinterval);
      }
    },
    checkDialog: function checkDialog(val) {
      // 当关闭的时候关闭这个弹窗
      if (!val) {
        clearTimeout(this.times);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearTimeout(this.times);
  },
  methods: {
    setTimeLinkWebSocket: function setTimeLinkWebSocket() {
      var _this = this;

      console.log('this.times', this.times);
      clearTimeout(this.times);
      this.times = setTimeout(function () {
        var data = {
          code: 0,
          msg: '这是client：心跳检测'
        };

        if (_this.websock.readyState == 1) {
          //如果连接正常
          _this.websocketsend(JSON.stringify(data));
        } else {
          //否则重连
          _this.initWebSocket();
        }

        _this.setTimeLinkWebSocket();
      }, 9 * 60 * 1000);
    },
    getColumnValue: function getColumnValue(value, row, list) {
      var doc;

      if (list) {
        list.map(function (item) {
          if (item.field == value) {
            doc = item;
          }
        });
      }

      return doc ? doc.title : value;
    },
    getColumnValue2: function getColumnValue2(value) {
      return value != null ? value.join(',') : '';
    },
    getCustomerLevelList: function getCustomerLevelList() {
      var _this2 = this;

      customerLevelList().then(function (res) {
        res.data.forEach(function (item) {
          var lev = {};
          lev.title = item.name;
          lev.field = item.level;

          _this2.levelList.push(lev);
        });
      });
    },
    handleDialog: function handleDialog(data) {
      var _this3 = this;

      // expireTask("expireTask","过期提醒"),
      // ctmfucTask("ctmfucTask","预约提醒"),
      // rvinfoTask("rvinfoTask","回访信息"),
      // customBirthdayTask("customBirthdayTask","客户生日"),
      // CallInfoTask("callInfoTask","分诊接待")
      if (data.code === 200) {
        if (data.wsType === 'callInfoTask') {// 分诊接待
        } else if (data.wsType === 'ctmfucTask') {
          return; // 预约提醒

          var columns = fuc.methods.getWebsockColumns(); // 取页面展示字段

          var dColumns = []; // 对columns数据进行转换

          columns.forEach(function (v) {
            var obj = {};
            obj.visible = true;
            obj.title = v.title;
            obj.field = v.field;
            obj.type = v.type;
            obj.width = '140px';

            if (v.field === 'ctmLevel') {
              obj.searchList = _this3.levelList;
              obj.formatter = _this3.getColumnValue;
            } else if (v.field === 'earId') {
              obj.searchList = _this3.eareList;
              obj.formatter = _this3.getColumnValue;
            } else if (v.field === 'ctfSmYx') {
              obj.searchList = _this3.intensList;
              obj.formatter = _this3.getColumnValue2;
            } else if (v.field === 'ctfType') {
              obj.searchList = _this3.fucTypeList;
              obj.formatter = _this3.getColumnValue;
            } else if (v.field === 'ctfInviting' || v.field === 'ctfEmpCode' || v.field === 'ctfNurse' || v.field === 'ctfBeautician' || v.field === 'kfPeople' || v.field === 'ctfOpter') {
              obj.searchList = _this3.getEmployeeTypeList();
              obj.formatter = _this3.getColumnValue;
            } else if (v.field === 'ctfStatus') {
              obj.searchList = [{
                title: '预约',
                field: 'USE'
              }, {
                title: '取消',
                field: 'CAN'
              }, {
                title: '已完成',
                field: 'FNS'
              }, {
                title: '已上门',
                field: 'YES'
              }];
              obj.formatter = _this3.getColumnValue;
            }

            dColumns.push(obj);
          }); // 对日期格式进行转化，websock返回的时间格式都是时间戳

          data.data.forEach(function (v) {
            v.ctfFuctime = v.ctfFuctime ? formatTime(v.ctfFuctime) : '';
            v.firstDate = v.firstDate ? formatTime(v.firstDate) : '';
            v.ctfInTime = v.ctfInTime ? formatTime(v.ctfInTime) : '';
            v.ctfdate = v.ctfdate ? formatTime(v.ctfdate) : '';
          });
          this.fnOpenDialog('预约提醒', data.data, dColumns, {
            path: '/callFuc/fuc'
          });
        } else if (data.wsType === 'expireTask') {// 过期提醒
        } else if (data.wsType === 'rvinfoTask') {// 回访信息
        } else if (data.wsType === 'customBirthdayTask') {// 客户生日
        }
      }
    },
    fnOpenDialog: function fnOpenDialog(title, data, columns, routeParams) {
      var _this4 = this;

      this.dialogVisible = true; // 两分钟倒计时

      this.setinterval = setTimeout(function () {
        _this4.dialogVisible = false;
      }, 1000 * 60 * 2);
      this.$msgbox({
        title: title,
        customClass: 'custom-dialog',
        message: this.$createElement(JhTable, {
          ref: 'JhTable',
          props: {
            list: data,
            height: 400,
            columns: columns
          }
        }),
        showCancelButton: true,
        confirmButtonText: '查看',
        cancelButtonText: '我知道了'
      }).then(function (action) {
        if (action == 'confirm') {
          _this4.$router.push(routeParams);
        }

        _this4.dialogVisible = false;
      }).catch(function () {
        _this4.dialogVisible = false;
      });
    },
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    initWebSocket: function initWebSocket() {
      // 初始化weosocket
      if (process.env.VUE_APP_WEBSOCKET_URL) {
        var ip = process.env.VUE_APP_WEBSOCKET_URL.split('://')[1];
        this.websock = new WebSocket("wss://".concat(ip, "/managerws?token=").concat(getToken())); // this.websock = new WebSocket(`ws://119.29.221.111:9015/managerws?token=${getToken()}`)

        this.websock.onmessage = this.websocketonmessage;
        this.websock.onerror = this.websocketonerror;
        this.websock.onopen = this.websocketonopen;
        this.websock.onclose = this.websocketclose;
      }
    },
    websocketonopen: function websocketonopen() {
      // 连接建立之后执行send方法发送数据
      console.log('连接成功');
      var data = {
        code: 0,
        msg: '这是client：初次连接'
      };
      this.setTimeLinkWebSocket();
      this.websocketsend(JSON.stringify(data));
    },
    // 62161718
    websocketonerror: function websocketonerror() {
      console.log('WebSocket连接失败'); // this.initWebSocket();
    },
    websocketonmessage: function websocketonmessage(e) {
      var _this5 = this;

      console.log('WebSocket收到消息', e);
      var data = JSON.parse(e.data);
      console.log('WebSocket收到消息=》data', data); // 当dialogVisible = false，且数据条数大于0，才弹窗
      // if (!this.dialogVisible && data.data && data.data.length > 0) {
      // this.handleDialog(data)
      // }

      if (data.wsType == 'empUpdate') {
        console.log('更新员工信息'); // employeeSimpleList({ isAllStatus: 'Y' }).then((response) => {
        //   console.log('当前员工列表', response);
        //   let { data } = response;
        //   let list = data.list;
        //   let employees = [];
        //   for (let i in list) {
        //     employees.push({
        //       title: list[i].empName + '--' + list[i].empCode + '--' + list[i].earName,
        //       field: list[i].empCode,
        //       postTypes: list[i].postTypes,
        //       empStatus: list[i].empStatus,
        //       earName: list[i].earName,
        //     });
        //   }
        //   setEmployees(employees);
        // });

        this.$emit('done');
        this.$store.dispatch('common/employeeList');
      } else if (data.wsType == 'expUpdate') {
        this.$store.dispatch('common/simpleCalTypeList');
      } else if (data.wsType == 'intenUpdate') {
        this.$store.dispatch('common/symptomList');
        this.$store.dispatch('common/intensList');
      } else if (data.wsType == 'cfVerify') {
        this.$message({
          type: 'success',
          message: '您收到处方药审核信息!',
          duration: 5000
        });
        this.checkDialog = true;
      } else if (data.wsType == 'ctmzptDeptOutSchedule' || data.wsType === 'ctmzptDeptReturnSchedule' || data.wsType === 'ctmzptDeptOverSchedule' || data.wsType === 'rfmTaskFinish') {
        if (data.wsType === 'rfmTaskFinish') {
          this.outDept.tip = data.data;
          this.outDept.showTip = true;
          return;
        }

        var index = this.permission_AddRoutes.findIndex(function (v) {
          return v.name === 'Stock';
        });

        if (index !== -1) {
          var index2 = this.permission_AddRoutes[index].children.findIndex(function (o) {
            return o.name === 'Out';
          });

          if (index2 !== -1) {
            if (data.wsType == 'ctmzptDeptOutSchedule') {
              data.data.forEach(function (v) {
                if (_this5.outList.length === 0 && v.checked === 1) {
                  _this5.outList.push(_objectSpread(_objectSpread({}, v), {}, {
                    outStatus: '未出库'
                  }));
                } else if (_this5.outList.length > 0) {
                  var _index = _this5.outList.findIndex(function (o) {
                    return o.id === v.id;
                  });

                  if (_index === -1 && v.checked === 1) {
                    _this5.outList.push(_objectSpread(_objectSpread({}, v), {}, {
                      outStatus: '未出库'
                    }));
                  }
                }
              });
              this.outDept.tip = '您收到新的出库信息，请及时出库！';
            } else if (data.wsType === 'ctmzptDeptReturnSchedule') {
              this.outDept.tip = "\u5BA2\u6237\u3010".concat(data.data[0].ctpCtmname, "\u3011\u53D6\u6D88\u6CBB\u7597,");
              this.outDept.tip += data.data.map(function (v) {
                return v.ctpZptname + ':' + formatTime(v.cypDate);
              }).join();
              this.outDept.tip += '已退库';
              this.outList.forEach(function (v) {
                data.data.findIndex(function (o) {
                  return o.id === v.id;
                }) !== -1 ? v.outStatus = '已退库' : null;
              });
            } else if (data.wsType === 'ctmzptDeptOverSchedule') {
              this.outList.forEach(function (v) {
                if (v.outStatus === '未出库') {
                  v.outStatus = data.data.findIndex(function (o) {
                    return o.id === v.id;
                  }) !== -1 ? '已出库' : '未出库';
                }
              });
              var list = this.outList.filter(function (v) {
                return v.outStatus === '未出库';
              });

              if (list.length === 0) {
                this.outDept.showTip = false;
              }

              return;
            }

            this.outDept.showTip = true;
            var setTime = setInterval(function () {
              _this5.outDept.showTip = false;
              clearInterval(setTime);
            }, 1500);
          }
        }
      } else if (data.wsType === 'coupVerifySuc') {
        // "购券审核提醒"
        var _data$data = data.data,
            ctmName = _data$data.ctmName,
            ctmCode = _data$data.ctmCode;
        this.outDept.tip = '客户：' + ctmName + '-' + ctmCode + ',';
        this.outDept.tip += '购券审核成功！';
        this.outDept.showTip = true;
      } else if (data.wsType === 'coupVerifyFail') {
        // "购券审核提醒"
        var _data$data2 = data.data,
            _ctmName = _data$data2.ctmName,
            _ctmCode = _data$data2.ctmCode;
        this.outDept.tip = '客户：' + _ctmName + '-' + _ctmCode + ',';
        this.outDept.tip += '购券审核失败！';
        this.outDept.showTip = true;
      }
    },
    websocketsend: function websocketsend(Data) {
      // clearTimeout(this.times);
      // 数据发送
      this.websock.send(Data);
    },
    websocketclose: function websocketclose(e) {
      // 关闭
      this.websock.close();
      console.log('已关闭连接', e);
    }
  }
};