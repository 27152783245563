import request from '@/utils/request'; //获取当前系统所有的菜单

export function getMenuAll(data) {
  return request({
    url: '/manager/user/menuAll',
    method: 'get',
    params: data
  });
} //获取当前系统配置

export function getAbsKeyByKeyCode(data) {
  return request({
    url: '/manager/apskey/getAbsKeyByKeyCode',
    method: 'get',
    params: data
  });
}
export function updateAbsKeyByKeyCode(data) {
  return request({
    url: '/manager/apskey/updateAbsKeyByKeyCode',
    method: 'post',
    data: data
  });
} //获取部门岗位的所有的菜单栏

export function menuPosList(data) {
  return request({
    url: '/manager/user/menuPosIdList',
    method: 'get',
    params: data
  });
} //为部门岗位添加菜单权限

export function roleAddMenu(data) {
  return request({
    url: '/manager/user/roleAddMenu',
    method: 'get',
    params: data
  });
} //获取用户的左侧菜单栏

export function userMenuList(data) {
  return request({
    url: '/manager/user/menuList',
    method: 'get',
    params: data
  });
} //新增员工权限

export function roleAddEmpMenu(data) {
  return request({
    url: '/manager/user/roleAddEmpMenu',
    method: 'get',
    params: data
  });
} //员工权限

export function menuIdListByEmpCode(data) {
  return request({
    url: '/manager/user/menuIdListByEmpCode',
    method: 'get',
    params: data
  });
}