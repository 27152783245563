var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.updateTable
        ? _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: {
                    loadData: _vm.loadData,
                    tableEl: ".vxe-table--body-wrapper",
                    autoClear: _vm.autoClear,
                  },
                  expression:
                    "{ loadData, tableEl: '.vxe-table--body-wrapper', autoClear }",
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading ? _vm.listLoading : _vm.tableLoading,
                  expression: "listLoading ? listLoading : tableLoading",
                },
              ],
              ref: "table",
              attrs: {
                border: "",
                resizable: "",
                "show-overflow": "",
                "show-header-overflow": "",
                "auto-resize": "",
                "highlight-current-row": "",
                "highlight-hover-row": "",
                "header-align": "center",
                align: "center",
                data: _vm.list,
                "scroll-x": { gt: _vm.scroll.x },
                "scroll-y": { gt: _vm.scroll.y, mode: "wheel" },
                "row-config": { height: 28 },
                height: _vm.height - 40,
                "edit-config": {
                  trigger: "click",
                  mode: "cell",
                  autoClear: _vm.autoClear,
                  showIcon: false,
                },
                "keyboard-config": { isArrow: true },
                "row-class-name": _vm.rowClassName || _vm.rowClassNameold,
                "checkbox-config": Object.assign(
                  {},
                  { labelField: "name", checkMethod: _vm.selectInit },
                  _vm.checkboxConfig
                ),
                "tooltip-config": { theme: "light", enterable: true },
                "row-style": _vm.rowStyle,
                "header-row-class-name": _vm.cellClass,
                "span-method": _vm.spanMethod,
                "footer-method": _vm.footerMethod,
                "merge-footer-items": _vm.mergeFooterItems,
                "show-footer": !!_vm.footerMethod,
              },
              on: {
                "cell-click": _vm.handleClick,
                "cell-dblclick": _vm.handleCellDblClick,
                "checkbox-change": _vm.select,
                "checkbox-all": _vm.selectAll,
              },
            },
            [
              _vm.preColumnSlot
                ? _c("vxe-column", {
                    attrs: { fixed: "left", title: "打印" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [_vm._t("preColumn", null, { row: row })]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkbox && !_vm.showIndex
                ? _c("vxe-column", {
                    attrs: {
                      width: "60",
                      type: "checkbox",
                      fixed: "left",
                      field: "checkbox",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showIndex
                ? _c("vxe-column", {
                    attrs: {
                      type: "seq",
                      title: "序号",
                      width: "40",
                      fixed: "left",
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.slotHeader
                          ? {
                              key: "header",
                              fn: function () {
                                return [_vm._t("indexHeader")]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.activeColumns, function (column, index) {
                return [
                  column.children
                    ? _c(
                        "vxe-colgroup",
                        { key: index, attrs: { title: column.title } },
                        [
                          _vm._l(column.children, function (col, index2) {
                            return [
                              col.children
                                ? _c(
                                    "vxe-colgroup",
                                    {
                                      key: index2,
                                      attrs: { title: col.title },
                                    },
                                    [
                                      _vm._l(
                                        col.children,
                                        function (co, index3) {
                                          return [
                                            _c("vxe-column", {
                                              key: index3,
                                              attrs: {
                                                field: co.field,
                                                title: co.title,
                                                width: co.width,
                                                fixed: co.fixed,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (ref) {
                                                      var row = ref.row
                                                      return !co.type
                                                        ? [
                                                            _c("VxeItemText", {
                                                              attrs: {
                                                                column: co,
                                                                row: row,
                                                              },
                                                            }),
                                                          ]
                                                        : undefined
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _c("vxe-column", {
                                    key: index2,
                                    attrs: {
                                      field: col.field,
                                      title: col.title,
                                      width: col.width,
                                      fixed: col.fixed,
                                      "edit-render": {
                                        enabled:
                                          col.type && col.type !== "text",
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "edit",
                                          fn: function (scope) {
                                            return [
                                              (col.type === "date" ||
                                                col.type === "datetime" ||
                                                col.type === "input" ||
                                                col.type === "number" ||
                                                col.type === "textarea" ||
                                                col.type === "checkbox" ||
                                                col.type === "radio") &&
                                              _vm.getCanEdit(col, scope.row)
                                                ? _c("EditItem", {
                                                    attrs: {
                                                      row: scope.row,
                                                      column: col,
                                                      type: col.type,
                                                      clickStatus: true,
                                                    },
                                                  })
                                                : _c("VxeItemText", {
                                                    attrs: {
                                                      column: col,
                                                      row: scope.row,
                                                    },
                                                  }),
                                            ]
                                          },
                                        },
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c("VxeItemText", {
                                                attrs: {
                                                  column: col,
                                                  row: row,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                            ]
                          }),
                        ],
                        2
                      )
                    : column.type &&
                      column.type !== "text" &&
                      column.type !== "slot" &&
                      column.type !== "changeText"
                    ? _c("vxe-column", {
                        key: index,
                        attrs: {
                          sortable: column.sort,
                          field: column.field,
                          title: column.title,
                          width: _vm.autoResize ? null : column.width || "80px",
                          fixed: column.fixed,
                          "edit-render": {
                            enabled: column.type && column.type !== "text",
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            column.type && column.type !== "text"
                              ? {
                                  key: "edit",
                                  fn: function (scope) {
                                    return [
                                      _vm.getCanEdit(column, scope.row)
                                        ? [
                                            column.type === "date" ||
                                            column.type === "datetime" ||
                                            column.type === "input" ||
                                            column.type === "number" ||
                                            column.type === "textarea" ||
                                            column.type === "checkbox" ||
                                            column.type === "radio"
                                              ? _c("EditItem", {
                                                  attrs: {
                                                    row: scope.row,
                                                    column: column,
                                                    type: column.type,
                                                    clickStatus: true,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      column.blur &&
                                                        column.blur()
                                                    },
                                                  },
                                                })
                                              : column.type == "button"
                                              ? [
                                                  column.children2
                                                    ? _c(
                                                        "el-popover",
                                                        {
                                                          ref:
                                                            "tablePopover" +
                                                            scope.row.index,
                                                          refInFor: true,
                                                          attrs: {
                                                            placement: "bottom",
                                                            width: "600",
                                                            trigger:
                                                              column.trigger ||
                                                              "focus",
                                                            "popper-options": {
                                                              boundariesElement:
                                                                "viewport",
                                                              removeOnDestroy: true,
                                                            },
                                                          },
                                                          on: {
                                                            show: function (
                                                              $event
                                                            ) {
                                                              column.setPalteItem &&
                                                                column.setPalteItem()
                                                            },
                                                            hide: function (
                                                              $event
                                                            ) {
                                                              column.setPlate &&
                                                                column.setPlate()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.showPop,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "showPop",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.showPop",
                                                          },
                                                        },
                                                        [
                                                          _c("VxeAutoTable", {
                                                            ref: "childTable",
                                                            refInFor: true,
                                                            attrs: {
                                                              data: column.inputTable
                                                                ? scope.row
                                                                    .ctmPlantformList
                                                                : scope.row
                                                                    .data,
                                                              height: 200,
                                                              columns:
                                                                column.children2,
                                                              checkbox:
                                                                _vm.childrenCheckbox,
                                                              total: scope.row
                                                                .total
                                                                ? scope.row
                                                                    .total
                                                                : 0,
                                                              scrollTopLastLocation: false,
                                                              autoResize:
                                                                column.inputTable,
                                                              pagination:
                                                                column.pagination,
                                                            },
                                                            on: {
                                                              pagination:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  column.input &&
                                                                    column.input(
                                                                      scope.row,
                                                                      scope.row[
                                                                        column
                                                                          .field
                                                                      ],
                                                                      true
                                                                    )
                                                                },
                                                              click: function (
                                                                $event
                                                              ) {
                                                                column.inputTable
                                                                  ? (scope.row.index2 =
                                                                      $event.index)
                                                                  : _vm.updateSelect(
                                                                      scope,
                                                                      column,
                                                                      $event,
                                                                      "chiledTableSelect"
                                                                    )
                                                              },
                                                              multipleSelection:
                                                                function (e) {
                                                                  return column.select(
                                                                    e,
                                                                    scope.row
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          column.inputTable
                                                            ? [
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      size: "mini",
                                                                      type: "success",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return column.click(
                                                                            val,
                                                                            scope.row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                    增加\n                  "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      size: "mini",
                                                                      type: "warning",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return column.delete(
                                                                            scope
                                                                              .row
                                                                              .index2
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                    删除\n                  "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("el-input", {
                                                            style: column.icon
                                                              ? "width: calc(100% - 35px); float: left;"
                                                              : "",
                                                            attrs: {
                                                              slot: "reference",
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                column.input &&
                                                                  column.input(
                                                                    scope.row,
                                                                    scope.row[
                                                                      column
                                                                        .field
                                                                    ]
                                                                  )
                                                              },
                                                              focus: function (
                                                                $event
                                                              ) {
                                                                column.focus &&
                                                                  column.focus(
                                                                    scope.row,
                                                                    scope.row[
                                                                      column
                                                                        .field
                                                                    ]
                                                                  )
                                                              },
                                                            },
                                                            slot: "reference",
                                                            model: {
                                                              value:
                                                                scope.row[
                                                                  column.field
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    scope.row,
                                                                    column.field,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "scope.row[column.field]",
                                                            },
                                                          }),
                                                        ],
                                                        2
                                                      )
                                                    : column.showBtn
                                                    ? [
                                                        column.showBtn(
                                                          scope.row
                                                        )
                                                          ? _vm._l(
                                                              column.btnList,
                                                              function (
                                                                btn,
                                                                btnIndex
                                                              ) {
                                                                return _c(
                                                                  "el-button",
                                                                  {
                                                                    key: btnIndex,
                                                                    attrs: {
                                                                      disabled:
                                                                        btn.disabled &&
                                                                        btn.disabled(
                                                                          scope.row
                                                                        ),
                                                                      size: "mini",
                                                                      type: btn.type(
                                                                        scope.row
                                                                      ),
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return btn.click(
                                                                            scope.row
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        btn.btnText
                                                                          ? btn.btnText
                                                                          : column.formatter
                                                                          ? column.formatter(
                                                                              scope
                                                                                .row[
                                                                                column
                                                                                  .field
                                                                              ],
                                                                              column.searchList,
                                                                              {
                                                                                key:
                                                                                  column.valueKey ||
                                                                                  "field",
                                                                                label:
                                                                                  column.label ||
                                                                                  "title",
                                                                                row: scope.row,
                                                                              }
                                                                            )
                                                                          : ""
                                                                      )
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    : _c("el-input", {
                                                        style: column.icon
                                                          ? "width: calc(100% - 35px); float: left;"
                                                          : "",
                                                        attrs: { size: "mini" },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            column.input &&
                                                              column.input(
                                                                scope.row,
                                                                scope.row[
                                                                  column.field
                                                                ]
                                                              )
                                                          },
                                                          focus: function (
                                                            $event
                                                          ) {
                                                            column.focus &&
                                                              column.focus(
                                                                scope.row,
                                                                scope.row[
                                                                  column.field
                                                                ]
                                                              )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row[
                                                              column.field
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              column.field,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[column.field]",
                                                        },
                                                      }),
                                                  _vm._v(" "),
                                                  _vm.getCanEdit(
                                                    column,
                                                    scope.row
                                                  ) && column.icon
                                                    ? _c("el-tag", {
                                                        class: column.icon,
                                                        staticStyle: {
                                                          position: "absolute",
                                                          "border-radius": "0",
                                                          height: "100%",
                                                          "line-height": "40px",
                                                          right: "0",
                                                          top: "0",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return column.event(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              : column.type == "btnTextarea"
                                              ? [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      ref:
                                                        "popover-" +
                                                        scope.row.index,
                                                      refInFor: true,
                                                      attrs: {
                                                        placement: "top",
                                                        width: "600",
                                                        trigger:
                                                          column.trigger ||
                                                          "click",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                          },
                                                        ],
                                                        attrs: {
                                                          type: "textarea",
                                                          rows: 7,
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            column.input &&
                                                              column.input(
                                                                scope.row,
                                                                column.field,
                                                                scope.row.index
                                                              )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            column.change &&
                                                              column.change(
                                                                scope.row,
                                                                column.field
                                                              )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            scope.row[
                                                              column.field
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              column.field,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[column.field]",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      !column.hideBtn
                                                        ? [
                                                            column.btnVisible
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    ref:
                                                                      "closepopover-" +
                                                                      (scope.row
                                                                        .index +
                                                                        1),
                                                                    refInFor: true,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "mb-popover",
                                                                      {
                                                                        attrs: {
                                                                          type: 5,
                                                                          placement:
                                                                            "top",
                                                                          trigger:
                                                                            "hover",
                                                                          title:
                                                                            "客户消耗模板",
                                                                        },
                                                                        on: {
                                                                          select:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return column.select(
                                                                                scope.row,
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "8px",
                                                                          },
                                                                        attrs: {
                                                                          size: "mini",
                                                                          type: "success",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return column.event(
                                                                                scope.row
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                      保存\n                    "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                          ]
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c("el-input", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                          },
                                                        ],
                                                        attrs: {
                                                          slot: "reference",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            column.input &&
                                                              column.input(
                                                                scope.row,
                                                                column.field,
                                                                scope.row.index
                                                              )
                                                          },
                                                          change: function (
                                                            $event
                                                          ) {
                                                            column.change &&
                                                              column.change(
                                                                scope.row,
                                                                column.field,
                                                                scope.row.index
                                                              )
                                                          },
                                                        },
                                                        slot: "reference",
                                                        model: {
                                                          value:
                                                            scope.row[
                                                              column.field
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              column.field,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row[column.field]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              : column.type == "vxeSelect"
                                              ? [
                                                  _c("VxeSelect", {
                                                    attrs: {
                                                      list:
                                                        column.searchList1 ||
                                                        column.searchList,
                                                      query: _vm.row,
                                                      field: column.field,
                                                    },
                                                  }),
                                                ]
                                              : column.type === "select"
                                              ? _c(
                                                  "el-select",
                                                  {
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "",
                                                      clearable: "",
                                                      size: "mini",
                                                      multiple: column.multiple,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        column.inputTable
                                                          ? null
                                                          : _vm.updateSelect(
                                                              scope,
                                                              column
                                                            )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row[column.field],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          column.field,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row[column.field]",
                                                    },
                                                  },
                                                  [
                                                    !column.desc
                                                      ? _vm._l(
                                                          column.useRowList ||
                                                            column.searchList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    column.label
                                                                      ? item[
                                                                          column
                                                                            .label
                                                                        ]
                                                                      : item.title,
                                                                  value:
                                                                    column.valueKey
                                                                      ? item[
                                                                          column
                                                                            .valueKey
                                                                        ]
                                                                      : item.field,
                                                                  disabled:
                                                                    item.disabled,
                                                                },
                                                              },
                                                              [
                                                                item.empStatus
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        style: {
                                                                          color:
                                                                            item.empStatus !==
                                                                            "CFM"
                                                                              ? "#ff4949"
                                                                              : "#606266",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            column.label
                                                                              ? item[
                                                                                  column
                                                                                    .label
                                                                                ]
                                                                              : item.title
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      : _vm._l(
                                                          column.searchList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-tooltip",
                                                              {
                                                                key: index,
                                                                staticClass:
                                                                  "item",
                                                                attrs: {
                                                                  effect:
                                                                    "light",
                                                                  placement:
                                                                    "right",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "200px",
                                                                      },
                                                                    attrs: {
                                                                      slot: "content",
                                                                    },
                                                                    slot: "content",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.desc
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        item.title,
                                                                      value:
                                                                        item.field,
                                                                      disabled:
                                                                        item.disabled,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                  ],
                                                  2
                                                )
                                              : column.type == "remote"
                                              ? [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder: "",
                                                        size: "mini",
                                                        "allow-create": "",
                                                        remote: "",
                                                        "remote-method":
                                                          column.remoteMethod(
                                                            scope.row,
                                                            column
                                                          ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          event
                                                        ) {
                                                          _vm.handleBlur(
                                                            event,
                                                            scope.row,
                                                            column
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          scope.row[
                                                            column.field
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            column.field,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row[column.field]",
                                                      },
                                                    },
                                                    _vm._l(
                                                      scope.row.searchList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.field,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ]
                                              : column.type == "bottom" &&
                                                column.showBtn(scope.row)
                                              ? _vm._l(
                                                  column.btnList,
                                                  function (btn, btnIndex) {
                                                    return _c(
                                                      "el-button",
                                                      {
                                                        key: btnIndex,
                                                        attrs: {
                                                          disabled:
                                                            btn.disabled &&
                                                            btn.disabled(
                                                              scope.row
                                                            ),
                                                          size: "mini",
                                                          type: btn.type(
                                                            scope.row
                                                          ),
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            btn.click &&
                                                              btn.click(
                                                                scope.row
                                                              )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            btn.btnText
                                                              ? btn.btnText
                                                              : column.formatter
                                                              ? column.formatter(
                                                                  scope.row[
                                                                    column.field
                                                                  ],
                                                                  scope.row,
                                                                  column.searchList
                                                                )
                                                              : ""
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                )
                                              : _vm._e(),
                                          ]
                                        : _c("VxeItemText", {
                                            attrs: {
                                              column: column,
                                              row: scope.row,
                                            },
                                          }),
                                    ]
                                  },
                                }
                              : null,
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  _c("VxeItemText", {
                                    attrs: { column: column, row: row },
                                  }),
                                  _vm._v(" "),
                                  _vm.getCanEdit(column, row) && column.icon
                                    ? _c("el-tag", {
                                        class: column.icon,
                                        staticStyle: {
                                          position: "absolute",
                                          "border-radius": "0",
                                          height: "100%",
                                          "line-height": "40px",
                                          right: "0",
                                          top: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return column.event(row)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _c("vxe-column", {
                        key: column.field + index,
                        attrs: {
                          field: column.field,
                          title: column.title,
                          width: _vm.autoResize ? null : column.width || "80px",
                          fixed: column.fixed,
                          "show-overflow": column.noTooltip ? false : "tooltip",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                return [
                                  column.type === "slot"
                                    ? [
                                        _vm.tableSlot
                                          ? _vm._t("tableSlot", null, {
                                              row: row,
                                              column: column,
                                            })
                                          : _vm._t(column.field, null, {
                                              row: row,
                                              column: column,
                                            }),
                                      ]
                                    : column.type == "changeText"
                                    ? [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(row[column.field]) +
                                            "\n          "
                                        ),
                                      ]
                                    : column.inputTable &&
                                      column.inputTable(row)
                                    ? [
                                        column.children2
                                          ? _c(
                                              "el-popover",
                                              {
                                                ref: "dcPopover",
                                                refInFor: true,
                                                attrs: {
                                                  placement: "bottom",
                                                  width: "600",
                                                  trigger:
                                                    column.trigger || "focus",
                                                  "popper-options": {
                                                    boundariesElement:
                                                      "viewport",
                                                    removeOnDestroy: true,
                                                  },
                                                },
                                                on: {
                                                  show: function ($event) {
                                                    column.setPalteItem &&
                                                      column.setPalteItem(row)
                                                  },
                                                  hide: function ($event) {
                                                    column.setPlate &&
                                                      column.setPlate()
                                                  },
                                                },
                                              },
                                              [
                                                _c("VxeAutoTable", {
                                                  ref: "childTable",
                                                  refInFor: true,
                                                  attrs: {
                                                    data: row.ctmPlantformList,
                                                    height: 200,
                                                    columns: column.children2,
                                                    checkbox:
                                                      _vm.childrenCheckbox,
                                                    total: row.total
                                                      ? row.total
                                                      : 0,
                                                    scrollTopLastLocation: false,
                                                    autoResize:
                                                      column.inputTable &&
                                                      column.inputTable(row),
                                                    pagination:
                                                      column.pagination,
                                                  },
                                                  on: {
                                                    pagination: function (
                                                      $event
                                                    ) {
                                                      column.input &&
                                                        column.input(
                                                          row,
                                                          row[column.field],
                                                          true
                                                        )
                                                    },
                                                    click: function ($event) {
                                                      row.index2 = $event.index
                                                    },
                                                    multipleSelection:
                                                      function (e) {
                                                        return column.select(
                                                          e,
                                                          row
                                                        )
                                                      },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                column.inputTable &&
                                                column.inputTable(row) &&
                                                !column.dateForm
                                                  ? [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            type: "success",
                                                          },
                                                          on: {
                                                            click: function (
                                                              val
                                                            ) {
                                                              return column.click(
                                                                val,
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  增加\n                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            type: "warning",
                                                          },
                                                          on: {
                                                            click: function (
                                                              val
                                                            ) {
                                                              return column.delete(
                                                                row.index2
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                  删除\n                "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                column.inputTable &&
                                                column.inputTable(row)
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        attrs: {
                                                          slot: "reference",
                                                          icon: "el-icon-caret-bottom",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleClick(
                                                              { row: row }
                                                            )
                                                          },
                                                        },
                                                        slot: "reference",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                " +
                                                            _vm._s(
                                                              row[column.field]
                                                            ) +
                                                            "\n              "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    : _c("VxeItemText", {
                                        attrs: {
                                          column: column,
                                          row: row,
                                          summaryMethod: _vm.summaryMethod,
                                        },
                                      }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _vm.pagination
                ? _c("pagination", {
                    attrs: {
                      pageSizes: _vm.pageSizes,
                      total: _vm.total ? _vm.total : 0,
                      page: _vm.listQuery.pageNum,
                      limit: _vm.listQuery.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.listQuery, "pageSize", $event)
                      },
                      pagination: _vm.getList,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 2 } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }