import _defineProperty from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";

var _methods;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { changeEmp } from '@/api/reception';
import { mapGetters } from 'vuex';
import { Message } from 'element-ui';
import BaseTable from "../components/BaseTable.vue";
import EditTable from "../components/EditTable";
import { employeeList } from '@/api/employee';
import XfTable from "./xf-table";
import moment from 'moment';
import { reassign, listForReassign } from '@/api/manager';
import { billOpen } from '@/api/cash';
import bus from "../components/bus";
export default {
  name: 'dj-table',
  components: {
    BaseTable: BaseTable,
    XfTable: XfTable,
    EditTable: EditTable
  },
  props: {},
  watch: {
    'customer.oldEmpCode': {
      handler: function handler(val) {
        if (val) {// this.getListForReassign();
        }
      }
    }
  },
  data: function data() {
    return {
      ctbMenstateList: [{
        title: '门诊',
        field: 'MEN'
      } // { title: "住院", field: "STA" }
      ],
      statusList: [{
        field: false,
        title: '未开票'
      }, {
        field: true,
        title: '已开票'
      }],
      customerIdList: [],
      selectList: [],
      catList: [{
        title: '所属顾问',
        field: 'gw'
      }, {
        title: '所属护理师',
        field: 'hls'
      }, {
        title: '属美容师',
        field: 'mrs'
      }, {
        title: '开发人员',
        field: 'kf'
      }],
      catList2: [{
        title: '所属顾问',
        field: 'gw'
      }, {
        title: '所属护理师',
        field: 'hls'
      }, {
        title: '属美容师',
        field: 'mrs'
      }, {
        title: '开发人员',
        field: 'kf'
      }],
      setEmployeeList: [],
      setEmployeeList2: [],
      setEmployeeList3: [],
      setEmployeeList4: [],
      flag: true,
      customer2: {},
      xmlabel: '',
      bhlabel: '',
      nxmlabel: '',
      nbhlabel: '',
      list: [],
      total: 0,
      reassignAll: false,
      customer: {},
      listLoading: false,
      EmpList: {
        excludeCustomerIdList: []
      },
      listQuery: {
        pageNum: 1,
        pageSize: 15,
        ctbCode: '',
        ctbInfo: '',
        ctbCtmcode: '',
        ctbCtmname: '',
        ctbMenstate: '',
        ctmCrndate: '',
        //ctbStaday:'',
        ctbTaxcode: '',
        ctbTaxid: '',
        ctmDate: '',
        //ctbSf:'',
        ctbRemark: ''
      },
      listQuery2: {
        pageNum: 1,
        pageSize: 15
      },
      dialogVisible: false,
      dialogVisible3: false,
      checked: false,
      innerHeight: 840,
      onSelectList: [],
      onSelectId: '',
      rules: {
        newEmpName: [{
          required: true,
          trigger: 'blur'
        }],
        newEmpCode: [{
          required: true,
          trigger: 'blur'
        }]
      },
      idList: [],
      columns: [],
      changeList: []
    };
  },
  computed: _objectSpread({}, mapGetters(['earId', 'employees', 'rightWidth'])),
  created: function created() {
    this.columns = [{
      title: '销售单号',
      field: 'cpyPageid',
      type: 'input',
      width: '120px'
    }, {
      title: '营业日期',
      field: 'cpyDate',
      type: 'input',
      searchable: false,
      width: '95px'
    }, {
      title: '客户卡号',
      field: 'cpyCtmcode',
      type: 'input',
      width: '100px',
      searchable: true
    }, {
      title: '客户姓名',
      field: 'cpyCtmname',
      type: 'input',
      notColumn: true,
      width: '80px',
      searchable: true
    }, {
      title: '客户姓名',
      field: 'cpyCtmname',
      type: 'input',
      width: '80px',
      searchable: false
    }, {
      title: '购买项目/产品',
      field: 'cpyZptName',
      type: 'input',
      width: '120px'
    }, {
      title: '次数',
      field: 'cpyNum',
      type: 'input',
      width: '50px'
    }, {
      title: '单价',
      field: 'cpyUnitamt',
      type: 'input',
      width: '50px'
    }, {
      title: '收款金额',
      field: 'cpyPayamt',
      type: 'textarea',
      required: false,
      width: '70px'
    }, {
      title: '其中现金',
      field: 'cpyRmamt',
      type: 'input',
      width: '70px',
      input: this.rvChange,
      canEdit: this.handCanEdit
    }, {
      title: '其中刷卡',
      field: 'cpyCardamt',
      type: 'input',
      width: '70px',
      input: this.rvChange,
      canEdit: this.handCanEdit
    }, {
      title: '存款支付',
      field: 'cpyStoamt',
      type: 'input',
      searchable: true,
      width: '70px',
      input: this.rvChange,
      canEdit: this.handCanEdit
    }, {
      title: '消耗金额',
      field: 'cpyUseMonAmt',
      type: 'input',
      input: this.rvChange,
      canEdit: this.handCanEdit,
      searchable: true,
      width: '70px'
    }, // {title:'券额支付',field:'cpyOthamt',type: "input",searchable:true,width:'70px',},
    {
      title: '开票金额',
      field: 'cpyTaxamt',
      type: 'text',
      searchable: true,
      width: '70px',
      input: this.rvChange,
      canEdit: this.handCanEdit
    }, {
      title: '状态',
      field: 'isBill',
      type: 'input',
      width: '60px',
      filter: this.$options.filters.statusFilter
    }];
    var date = new Date();
    var time = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    this.listQuery.ctbMenstate = this.ctbMenstateList[0].field;
    this.listQuery.ctmCrndate = time;
    this.listQuery.ctmDate = moment().format('YYYY-MM-DD');
    this.innerHeight = $(window).height();
  },
  mounted: function mounted() {
    var that = this;
    bus.$on('xfList', function (msg) {
      //console.log('接收消息',msg)
      that.listQuery.ctbCtmcode = msg[0].cpyCtmcode;
      that.listQuery.ctbCtmname = msg[0].cpyCtmname;

      for (var i = 0; i < msg.length; i++) {
        console.log('earId', that.earId);

        if ((that.earId === '013' || this.earId === '014' || this.earId === '015' || that.earId === '000') && (msg[i].zptTypeName === '产品销售' || msg[i].zptTypeName === '项目消耗')) {
          this.$set(msg[i], 'cpyTaxamt', msg[i].cpyUseMonAmt || 0); // msg[i].cpyTaxamt = msg[i].cpyUseMonAmt || 0;

          msg[i].cpyCardRmAmt = 0;
          msg[i].cpyRmamt = 0;
          msg[i].cpyCardamt = 0;
          msg[i].cpyStoamt = 0;
        } else {
          this.$set(msg[i], 'cpyTaxamt', msg[i].cpyRmamt + msg[i].cpyCardamt + msg[i].cpyStoamt + (msg[i].cpyUseMonAmt || 0));
        }

        msg[i].cpyPayamt = msg[i].cpyRmamt + msg[i].cpyCardamt;
      }

      that.list = msg;

      if (that.list.length) {
        that.upDateListCount();
      }

      that.dialogVisible = false;
    });
  },
  methods: (_methods = {
    handleSave: function handleSave() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          reassign(_this.customer).then(function (response) {
            if (response && response.code == 200) {
              Message({
                message: '保存成功',
                type: 'success',
                onClose: function onClose() {
                  _this.$refs.form.resetFields();

                  _this.$emit('cancel');
                }
              });
            }
          });
        }
      });
    },
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    },
    handleDone: function handleDone() {
      var _this2 = this;

      this.idList = [];
      this.selectList.forEach(function (item) {
        _this2.idList.push(item.id);
      });
      this.$emit('done', this.idList, this.listQuery);
    },
    getListForReassign: function getListForReassign() {
      var _this3 = this;

      this.listLoading = true;
      var param = [];

      if (this.list.length > 0) {
        for (var i = 0; i < this.list.length; i++) {
          if (!this.list[i].statistics) {
            //除去统计数的一列
            this.onSelectList.push(this.list[i]);

            var data = _objectSpread(_objectSpread({}, {}), this.list[i]);

            data['ctmPayId'] = this.list[i].id;
            delete data['id'];
            param.push(data);
          }
        }
        /* this.listQuery.ctmPayIds = this.onSelectList;*/


        this.listQuery.billVos = param;
        this.listQuery.ctbTime = this.listQuery.ctmDate + ' ' + this.listQuery.ctmCrndate;
        billOpen(this.listQuery).then(function (response) {
          _this3.listLoading = false;

          if (response.code == 200) {
            _this3.$message({
              type: 'success',
              message: '开票成功!'
            });

            bus.$emit('tips', false);
          }
        });
      } else {
        this.listLoading = false;
        this.listQuery.ctbTime = this.listQuery.ctmDate + ' ' + this.listQuery.ctmCrndate;
        console.log(this.listQuery);
        this.$message({
          message: '请选择开票信息',
          type: 'warning'
        });
      }
    },
    clear: function clear() {
      this.listQuery = {};
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = 15;
    },
    selectItem: function selectItem(_ref) {
      var selection = _ref.selection,
          row = _ref.row;
      var obj = selection.filter(function (item) {
        return item.id === row.id;
      });
      var obj2 = this.selectList.filter(function (item) {
        return item.id === row.id;
      });

      if (obj.length > 0 && obj2.length === 0) {
        this.selectList.push(row);
      } else if (obj.length === 0) {
        this.selectList.splice(this.selectList.indexOf(function (item) {
          return item.id === row.id;
        }), 1);
      }
    },
    getKfCode: function getKfCode(code) {
      console.log('code', code);
    },
    getEmployeeList: function getEmployeeList() {
      this.setEmployeeList = this.getEmployeeTypeList('ssgw');
      this.setEmployeeList2 = this.getEmployeeTypeList('sshls');
      this.setEmployeeList3 = this.getEmployeeTypeList('ssmrs'); // this.setEmployeeList4 = this.getEmployeeTypeList()
    },
    customerAllSelect: function customerAllSelect(index) {
      this.reassignAll = index.length > 0;
    },
    handleSelect: function handleSelect() {},
    done: function done(list) {// console.log(list);
      // console.log(this.list);
    },
    handleClick: function handleClick(row) {
      console.log('当前选中行id', row);
      this.onSelectId = row.id;
    },
    del: function del() {
      if (this.onSelectId) {
        for (var i = 0; i < this.list.length; i++) {
          if (this.onSelectId == this.list[i].id) {
            this.list.splice(i, 1);
          }
        }

        this.list = this.list; //操作数组更新统计列

        this.upDateListCount();
        console.log('当前剩余数组', this.list);
      } else {
        this.$message({
          message: '请x选中需要删除的信息',
          type: 'warning'
        });
      }
    },
    cancel: function cancel() {
      this.dialogVisible3 = false;
    }
  }, _defineProperty(_methods, "handleSelect", function handleSelect(data) {
    this.$set(this.customer, 'newEmpName', data.empName);
    this.$set(this.customer, 'newEmpCode', data.empCode);
    this.$refs.closepopover.click();
  }), _defineProperty(_methods, "handleSelect2", function handleSelect2(data) {
    this.$set(this.customer, 'oldEmpName', data.empName);
    this.$set(this.customer, 'oldEmpCode', data.empCode);
    this.$refs.closepopover2.click();
  }), _defineProperty(_methods, "rvChange", function rvChange(row, field, idx) {
    //idx 索引
    this.countColumnNum(idx, field, row);

    if (row.id) {
      this.addList(row, field);
    }
  }), _defineProperty(_methods, "handCanEdit", function handCanEdit(column, row) {
    /*this.upDateListCount()*/
    console.log('column', column);
    console.log('row', row);

    if (column.zptTypeName !== '项目消耗' || this.earId === '013' || this.earId === '014' || this.earId === '015' || this.earId === '000') {
      return true;
    } else {
      return false;
    }
  }), _defineProperty(_methods, "countColumnNum", function countColumnNum(idx, field, row) {
    //1.收款金额 = 其中现金 + 其中刷卡
    console.log('field', field);
    console.log('earId', this.earId);
    console.log('this.list', this.list[idx].zptTypeName);

    if (field == 'cpyRmamt' || field == 'cpyCardamt' || field == 'cpyStoamt' || field == 'cpyUseMonAmt') {
      this.list[idx]['cpyPayamt'] = Number(this.list[idx]['cpyRmamt']) + Number(this.list[idx]['cpyCardamt']);

      if ((this.earId == '013' || this.earId == '014' || this.earId == '015' || this.earId == '000') && (this.list[idx].zptTypeName === '产品销售' || this.list[idx].zptTypeName === '项目消耗')) {
        console.log(Number(this.list[idx]['cpyUseMonAmt']));
        this.$set(this.list[idx], 'cpyTaxamt', Number(this.list[idx]['cpyUseMonAmt']));
        console.log('this.list', this.list); // this.list[idx]["cpyTaxamt"] = Number(this.list[idx]["cpyUseMonAmt"]);
      } else {
        this.$set(this.list[idx], 'cpyTaxamt', Number(this.list[idx]['cpyPayamt']) + Number(this.list[idx]['cpyStoamt']) + Number(this.list[idx]['cpyUseMonAmt']));
        console.log(1111); // this.list[idx]["cpyTaxamt"] =
        //   Number(this.list[idx]["cpyPayamt"]) +
        //   Number(this.list[idx]["cpyStoamt"]) +
        //   Number(this.list[idx]["cpyUseMonAmt"]);
      }
    }

    this.upDateListCount();
  }), _defineProperty(_methods, "countDiffer", function countDiffer() {}), _defineProperty(_methods, "upDateListCount", function upDateListCount() {
    if (!this.list.length) {
      return;
    }

    var skje = 0,
        //收款金额
    qzxj = 0,
        //其中现金
    qksk = 0,
        //其中刷卡
    ckzf = 0,
        //存款支付
    zqzf = 0,
        //开票金额
    xh = 0,
        //消耗金额
    idx = -1; //统计列在数组中的索引

    for (var i = 0; i < this.list.length; i++) {
      if (this.list[i].statistics) {
        idx = i;
        continue;
      }

      skje += this.list[i].cpyPayamt ? Number(this.list[i].cpyPayamt) : 0;
      qzxj += this.list[i].cpyRmamt ? Number(this.list[i].cpyRmamt) : 0;
      qksk += this.list[i].cpyCardamt ? Number(this.list[i].cpyCardamt) : 0;
      ckzf += this.list[i].cpyStoamt ? Number(this.list[i].cpyStoamt) : 0;
      zqzf += this.list[i].cpyTaxamt ? Number(this.list[i].cpyTaxamt) : 0;
      xh += this.list[i].cpyUseMonAmt ? Number(this.list[i].cpyUseMonAmt) : 0;
    }

    var param = {
      statistics: true,
      //是否存在统计数这一条
      cpyPayamt: skje.toFixed(2),
      cpyRmamt: qzxj.toFixed(2),
      cpyCardamt: qksk.toFixed(2),
      cpyStoamt: ckzf.toFixed(2),
      cpyTaxamt: zqzf.toFixed(2),
      cpyUseMonAmt: xh.toFixed(2)
    };

    if (idx != -1) {
      this.list[idx] = param;
    } else {
      this.list.push(param);
    }
  }), _defineProperty(_methods, "addList", function addList(row, field) {
    var obj = this.changeList.filter(function (item) {
      return row.id == item.id;
    });

    if (obj.length == 0) {
      this.changeList.push(row);
      console.log('rowrv', this.changeList);
    } else {
      this.changeList.some(function (item) {
        if (item.id == obj[0].id) {
          item[field] = obj[0][field];
          console.log('item', item);
        }
      });
    }
  }), _defineProperty(_methods, "clean", function clean() {
    bus.$emit('tips', false);
  }), _methods)
};