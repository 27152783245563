import request from '@/utils/request';
import exportRequest from '@/utils/exportRequest'; // 工作种类详情

export function worktypeDetail(data) {
  return request({
    url: '/manager/worktype/detail',
    method: 'post',
    data: data
  });
} // 工作种类列表

export function worktypePage(data) {
  return request({
    url: '/manager/worktype/page',
    method: 'post',
    data: data
  });
} // 新增工作种类

export function addWorkType(data) {
  return request({
    url: '/manager/worktype/addOrupdate',
    method: 'post',
    data: data
  });
} // 删除工作种类

export function delWorkType(data) {
  return request({
    url: '/manager/worktype/delete',
    method: 'post',
    params: data
  });
} // 科室列表

export function departmentPage(data) {
  return request({
    url: '/manager/department/page',
    method: 'post',
    data: data
  });
} //新增编辑科室

export function addDepartment(data) {
  return request({
    url: '/manager/department/addOrUpdate',
    method: 'post',
    data: data
  });
} //删除科室

export function delDepartment(data) {
  return request({
    url: '/manager/department/delete',
    method: 'post',
    params: data
  });
} // 医院列表

export function earePage(data) {
  return request({
    url: '/manager/eare/page',
    method: 'post',
    data: data
  });
} // 新增医院

export function addEare(data) {
  return request({
    url: '/manager/eare/add',
    method: 'post',
    data: data
  });
} // 删除医院

export function delEare(data) {
  return request({
    url: '/manager/eare/delete',
    method: 'post',
    params: data
  });
} // 仓库列表

export function storagePage(data) {
  return request({
    url: '/manager/storage/page',
    method: 'post',
    data: data
  });
} // 新增仓库

export function addStorage(data) {
  return request({
    url: '/manager/storage/add',
    method: 'post',
    data: data
  });
} // 编辑仓库

export function updateStorage(data) {
  return request({
    url: '/manager/storage/update',
    method: 'post',
    data: data
  });
} // 删除仓库

export function delStorage(data) {
  return request({
    url: '/manager/storage/delete',
    method: 'post',
    params: data
  });
} // 仓库列表

export function outStorageList(data) {
  return request({
    url: '/manager/storage/getMoveOutList',
    method: 'post',
    data: data
  });
} // 仓库列表

export function inStorageList(data) {
  return request({
    url: '/manager/storage/getMoveInList',
    method: 'post',
    data: data
  });
} // 收支项目列表

export function accounttypePage(data) {
  return request({
    url: '/manager/accounttype/page',
    method: 'post',
    data: data
  });
} // 新增收支项目

export function addAccount(data) {
  return request({
    url: '/manager/accounttype/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除收支项目

export function delAccount(data) {
  return request({
    url: '/manager/accounttype/delete',
    method: 'post',
    params: data
  });
} // 项目列表分页

export function projectPage(data) {
  return request({
    url: '/manager/project/page',
    method: 'post',
    data: data
  });
} // 物品列表

export function getMaterialList(data) {
  return request({
    url: '/manager/product/getMaterialList',
    method: 'post',
    data: data
  });
} // 获取信息来源

export function getcaltype(data) {
  return request({
    url: '/manager/call/getcaltype',
    method: 'get',
    params: data
  });
} // 会员卡类型列表

export function membertypePage(data) {
  return request({
    url: '/manager/membertype/page',
    method: 'post',
    data: data
  });
} // 新增会员类型

export function addMembertype(data) {
  return request({
    url: '/manager/membertype/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除会员类型

export function delMembertype(data) {
  return request({
    url: '/manager/membertype/delete',
    method: 'post',
    params: data
  });
}
export function reportAuthList(data) {
  return request({
    url: '/reportAuth/detail/detailList',
    method: 'post',
    data: data
  });
}
export function reportAuthAddOrUpdate(data) {
  return request({
    url: '/reportAuth/detail/addOrUpdate',
    method: 'post',
    data: data
  });
} // 职务列表

export function positionPage(data) {
  return request({
    url: '/manager/position/page',
    method: 'post',
    data: data
  });
} // 新增职务

export function addPosition(data) {
  return request({
    url: '/manager/position/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除职务

export function delPosition(data) {
  return request({
    url: '/manager/position/delete',
    method: 'post',
    params: data
  });
} // 产品促销列表

export function timeSalePage(data) {
  return request({
    url: '/manager/timeSale/page',
    method: 'post',
    data: data
  });
} // 新增促销

export function addSale(data) {
  return request({
    url: '/manager/timeSale/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除促销

export function delSale(data) {
  return request({
    url: '/manager/timeSale/delete',
    method: 'post',
    params: data
  });
} // 项目流程列表

export function productFlowList(data) {
  return request({
    url: '/manager/productflow/list',
    method: 'post',
    data: data
  });
} // 新增项目流程

export function addProductflow(data) {
  return request({
    url: '/manager/productflow/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除项目流程

export function delProductflow(data) {
  return request({
    url: '/manager/productflow/delete',
    method: 'post',
    params: data
  });
} // 信息来源列表

export function exprtypeList(data) {
  return request({
    url: '/manager/exprtype/getList',
    method: 'post',
    params: data
  });
} // 新增信息来源

export function addExprtype(data) {
  return request({
    url: '/manager/exprtype/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除信息来源

export function delExprtype(data) {
  return request({
    url: '/manager/exprtype/delete',
    method: 'post',
    params: data
  });
} // 积分参数详情

export function apskeyDetail(data) {
  return request({
    url: '/manager/apskey/getIntegralParam',
    method: 'post',
    data: data
  });
} // 积分参数设置

export function apskeyUpdate(data) {
  return request({
    url: '/manager/apskey/updateIntegralParam',
    method: 'post',
    data: data
  });
} // 所有物品分类

export function allTypePage(data) {
  return request({
    url: '/manager/pdutypeone/pageAll',
    method: 'post',
    data: data
  });
} // 删除分类1

export function delType1(data) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'delete';
  return request({
    url: "/manager/pdutypeone/".concat(type),
    method: 'post',
    params: data
  });
} // 删除分类2

export function delType2(data) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'delete';
  return request({
    url: "/manager/pdutypetwo/".concat(type),
    method: 'post',
    params: data
  });
} // 删除分类3

export function delType3(data) {
  return request({
    url: '/manager/pdutypethree/delete',
    method: 'post',
    params: data
  });
} // 新增分类1

export function addType1(data) {
  return request({
    url: '/manager/pdutypeone/addOrUpdate',
    method: 'post',
    data: data
  });
} // 新增分类2

export function addType2(data) {
  return request({
    url: '/manager/pdutypetwo/addOrUpdate',
    method: 'post',
    data: data
  });
} // 新增分类3

export function addType3(data) {
  return request({
    url: '/manager/pdutypethree/addOrUpdate',
    method: 'post',
    data: data
  });
} // 回访类型列表

export function rvTypeList(data) {
  return request({
    url: '/manager/rvftype/page',
    method: 'post',
    data: data
  });
} // 增加回访类型

export function addRvType(data) {
  return request({
    url: '/manager/rvftype/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除回访类型

export function delRvType(data) {
  return request({
    url: '/manager/rvftype/delete',
    method: 'post',
    params: data
  });
} // 预约类型列表

export function fucTypeList(data) {
  return request({
    url: '/manager/fuctype/page',
    method: 'post',
    data: data
  });
} // 预约类型列表

export function addFucType(data) {
  return request({
    url: '/manager/fuctype/addOrUpdate',
    method: 'post',
    data: data
  });
} // 删除预约类型

export function delFucType(data) {
  return request({
    url: '/manager/fuctype/delete',
    method: 'post',
    params: data
  });
} //短信模板列表

export function getSmsList(data) {
  return request({
    url: '/manager/sms/getList',
    method: 'post',
    data: data
  });
} // 修改 短信模板列表

export function SmsAddOrUpdate(data) {
  return request({
    url: '/manager/sms/addOrUpdate',
    method: 'post',
    data: data
  });
} //标签值列表

export function tagVlauePage(data) {
  return request({
    url: '/manager/ctmlab/page',
    method: 'post',
    data: data
  });
} //新增标签值

export function addTagValue(data) {
  return request({
    url: '/manager/ctmlab/addOrUpdate',
    method: 'post',
    data: data
  });
} //删除标签值

export function delTagValue(data) {
  return request({
    url: '/manager/ctmlab/delete',
    method: 'post',
    params: data
  });
} //标签名列表

export function tagNameList(data) {
  return request({
    url: '/manager/ctmlab/getLabList',
    method: 'post',
    data: data
  });
} //咨询项目列表

export function calpduTypeList(data) {
  return request({
    url: 'manager/calpdutype/page',
    method: 'post',
    data: data
  });
} //新增咨询项目

export function addCalpduType(data) {
  return request({
    url: 'manager/calpdutype/addOrUpdate',
    method: 'post',
    data: data
  });
} //删除咨询项目

export function delCalpduType(data) {
  return request({
    url: 'manager/calpdutype/delete',
    method: 'post',
    params: data
  });
} //模板列表

export function tempList(data) {
  return request({
    url: '/manager/configTemplate/page',
    method: 'post',
    data: data
  });
} //新增模板

export function addTemp(data) {
  return request({
    url: '/manager/configTemplate/addOrUpdate',
    method: 'post',
    data: data
  });
} //删除模板

export function delTemp(data) {
  return request({
    url: '/manager/configTemplate/delete',
    method: 'post',
    params: data
  });
} //上门意向列表

export function callList(data) {
  return request({
    url: '/manager/call/getIntensList',
    method: 'post',
    data: data
  });
} //新增上门意向

export function addCall(data) {
  return request({
    url: '/manager/call/addOrUpdateIntens',
    method: 'post',
    data: data
  });
} //删除上门意向

export function delCall(data) {
  return request({
    url: '/manager/call/delIntens',
    method: 'get',
    params: data
  });
} // 项目价值

export function queryProjectjzList(data) {
  return request({
    url: '/manager/report/zxest/projectjz',
    method: 'post',
    data: data
  });
} // 重点项目

export function getProjectImport(data) {
  return request({
    url: '/manager/report/zxest/getProjectImport',
    method: 'post',
    data: data
  });
} // 人数报表

export function getZxPeople(data) {
  return request({
    url: '/manager/report/zxest/zxPeople',
    method: 'post',
    data: data
  });
} // 客户交易明细表

export function queryTransactionList(data) {
  return request({
    url: '/manager/ctmPay/queryTransactionList',
    method: 'post',
    data: data
  });
} // 套餐明细表

export function getPtReport(data) {
  return request({
    url: '/manager/ctmPayExport/getPtReport',
    method: 'post',
    data: data
  });
} // 套餐统计表

export function getPtSaleReport(data) {
  return request({
    url: '/manager/ctmPayExport/getPtSaleReport',
    method: 'post',
    data: data
  });
} // 套餐明细表 导出

export function getPtReportExport(data) {
  return request({
    url: '/manager/ctmPay/getPtReportExport',
    method: 'post',
    data: data
  });
} // 套餐统计表 导出

export function getPtSaleReportExport(data) {
  return request({
    url: '/manager/ctmPay/getPtSaleReportExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 新客转化报表

export function getZhCovertReport(data) {
  return request({
    url: '/manager/ctmPayExport/getZhCovertReport',
    method: 'post',
    data: data
  });
} // 新客转化报表 导出

export function getZhCovertReportExport(data) {
  return request({
    url: '/manager/ctmPay/getZhCovertReportExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 获取操作用户列表

export function getUserList(data) {
  return request({
    url: '/manager/user/getListPage',
    method: 'post',
    data: data
  });
} // 删除操作用户

export function delUser(data) {
  return request({
    url: '/manager/user/delUser',
    method: 'get',
    params: data
  });
} // 更新操作用户信息

export function updateUser(data) {
  return request({
    url: '/manager/user/updateUser',
    method: 'post',
    data: data
  });
} // 客户类别-列表

export function customerLevelList(data) {
  return request({
    url: '/manager/customerLevel/listAll',
    method: 'GET',
    data: data
  });
} // 可手动标记的顾客等级列表

export function customerLevelListManual(data) {
  return request({
    url: '/manager/customerLevel/listManual',
    method: 'GET',
    data: data
  });
} // 客户类别-删除

export function customerLevelDelete(data) {
  return request({
    url: "/manager/customerLevel/delete/".concat(data.id),
    method: 'get',
    params: data
  });
} // 客户类别-新增

export function customerLevelAddOrUpdate(data) {
  return request({
    url: '/manager/customerLevel/addOrUpdate',
    method: 'POST',
    data: data
  });
} //项目适应症一级列表

export function symptoms(data) {
  return request({
    url: '/manager/symptom/page',
    method: 'post',
    data: data
  });
} //项目适应症一级列表-删除

export function symptomsDelete(data) {
  return request({
    url: '/manager/symptom/delete',
    method: 'post',
    params: data
  });
} //项目适应症一级列表-新增编辑

export function symptomsAddOrUpdate(data) {
  return request({
    url: '/manager/symptom/addOrUpdate',
    method: 'post',
    data: data
  });
} //指定服务 列表

export function servicesList(data) {
  return request({
    url: '/manager/employee/getdoch',
    method: 'get',
    params: data
  });
} //RFM 列表

export function customerImportantLevel(data) {
  return request({
    url: '/manager/customerImportantLevel/page',
    method: 'post',
    data: data
  });
} //RFM 编辑

export function customerImportantLevelUpdateName(data) {
  return request({
    url: '/manager/customerImportantLevel/updateName',
    method: 'post',
    params: data
  });
} //面诊方案 列表

export function preProject(data) {
  return request({
    url: '/manager/preProject/page',
    method: 'post',
    data: data
  });
} //面诊方案 删除

export function preProjectDelete(data) {
  return request({
    url: '/manager/preProject/delete',
    method: 'post',
    params: data
  });
} //面诊方案 新增/编辑

export function preProjectAddOrUpdate(data) {
  return request({
    url: '/manager/preProject/addOrUpdate',
    method: 'post',
    data: data
  });
} //手工种类  列表

export function manualTypePage(data) {
  return request({
    url: '/manager/manualType/page',
    method: 'post',
    data: data
  });
} //手工种类 增加编辑

export function manualTypePageAddOrUpdate(data) {
  return request({
    url: '/manager/manualType/addOrUpdate',
    method: 'post',
    data: data
  });
} //手工种类 删除

export function manualTypePageDelete(data) {
  return request({
    url: '/manager/manualType/delete',
    method: 'post',
    params: data
  });
} //手工种类 级别 列表

export function manualLevelPage(data) {
  return request({
    url: '/manager/manualLevel/page',
    method: 'post',
    data: data
  });
} //手工 级别 增加编辑

export function manualLevelPageAddOrUpdate(data) {
  return request({
    url: '/manager/manualLevel/addOrUpdate',
    method: 'post',
    data: data
  });
} //手工 级别 删除

export function manualLevelPageDelete(data) {
  return request({
    url: '/manager/manualLevel/delete',
    method: 'post',
    params: data
  });
} // 标签

export function labelPage(data) {
  return request({
    url: '/manager/label/page',
    method: 'post',
    data: data
  });
} // 标签 -保存

export function labelAddOrUpdate(data) {
  return request({
    url: '/manager/label/addOrUpdate',
    method: 'post',
    data: data
  });
} // 标签 -删除

export function labelDelete(data) {
  return request({
    url: '/manager/label/delete',
    method: 'post',
    params: data
  });
} //获取返券比例列表

export function getHhConfig() {
  return request({
    url: '/manager/apskey/getHhConfig',
    method: 'get'
  });
} //指定服务 列表

export function setHhConfig(data) {
  return request({
    url: '/manager/apskey/setHhConfig',
    method: 'get',
    params: data
  });
} //回访 列表

export function rvftype(data) {
  return request({
    url: '/manager/rv/ctmrvname',
    method: 'get',
    params: data
  });
} //回访 列表

export function addOrUpdateCtmrvname(data) {
  return request({
    url: '/manager/rv/addOrUpdateCtmrvname',
    method: 'post',
    data: data
  });
} //回访 删除

export function delCtmrvname(data) {
  return request({
    url: '/manager/rv/delCtmrvname',
    method: 'get',
    params: data
  });
} //回访日期删除 列表

export function delCtmrvdetail(data) {
  return request({
    url: '/manager/rv/delCtmrvdetail',
    method: 'get',
    params: data
  });
} //回访 列表

export function rvftypePage(data) {
  return request({
    url: '/manager/rv/ctmrvdetail',
    method: 'get',
    params: data
  });
} //回访 列表

export function addOrUpdateCtmrvdetail(data) {
  return request({
    url: '/manager/rv/addOrUpdateCtmrvdetail',
    method: 'post',
    data: data
  });
} // 自动 回访 列表

export function autoRvList(data) {
  return request({
    url: '/manager/rv/auto/getList',
    method: 'post',
    data: data
  });
} // 编辑 自动 回访 列表

export function autoAddOrUpdateRv(data) {
  return request({
    url: '/manager/rv/auto/addOrUpdate',
    method: 'post',
    data: data,
    isFilterWhite: true
  });
} // 删除 自动 回访 列表

export function autoDeleteRv(data) {
  return request({
    url: '/manager/rv/auto/delete',
    method: 'post',
    params: data
  });
} //回访 列表

export function queryAllConsumptionListExport(data) {
  return request({
    url: '/manager/ctmPayExport/queryAllConsumptionListExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //导出下载

export function taskDownload(data) {
  return exportRequest({
    url: '/manager/task/report/download',
    method: 'get',
    params: data
  });
} //导出任务列表

export function taskList(data) {
  return request({
    url: '/manager/task/list',
    method: 'post',
    data: data
  });
} //任务停止

export function taskStop(data) {
  return request({
    url: '/manager/task/stop',
    method: 'get',
    params: data
  });
} //导出退划扣

export function queryRtdDeductionListExport(data) {
  return request({
    url: '/manager/ctmPayExport/queryRtdDeductionListExport',
    method: 'post',
    data: data
  });
} //报单列表导出

export function callListExport(data) {
  return request({
    url: '/manager/call/listExport',
    method: 'post',
    data: data
  });
} //页面权限的列表

export function pageAuthGetConfig() {
  return request({
    url: '/manager/pageAuth/getConfig',
    method: 'get'
  });
} //页面权限配置

export function pageAuthSaveOrUpdateAuth(data) {
  return request({
    url: '/manager/pageAuth/saveOrUpdateAuth',
    method: 'get',
    params: data
  });
} //获取当前页面设置了的权限

export function pageAuthGetList(data) {
  return request({
    url: '/manager/pageAuth/getList',
    method: 'get',
    params: data
  });
} //获取激活列表

export function activConfigList(data) {
  return request({
    url: '/manager/activConfig/getList',
    method: 'get',
    params: data
  });
} //激活列表-编辑

export function activConfigSaveOrUpdate(data) {
  return request({
    url: '/manager/activConfig/saveOrUpdate',
    method: 'post',
    data: data
  });
} //消耗列表导出任务

export function queryConsumeListExport(data) {
  return request({
    url: 'manager/ctmPayExport/queryConsumeListExport',
    method: 'post',
    data: data
  });
} //短信任务

export function getSmsTask(data) {
  return request({
    url: '/manager/sms/getTask',
    method: 'post',
    data: data
  });
}