import request from '@/utils/request';
import exportRequest from '@/utils/exportRequest'; // 页面列表分页

export function managerBmmsPage(data) {
  return request({
    url: '/manager/b2c/bmms/page',
    method: 'post',
    data: data
  });
} // 页面播图列表分页

export function managerBmmsaAdPage(data) {
  return request({
    url: '/manager/b2c/bmms/adPage',
    method: 'post',
    data: data
  });
} //  小程序页面管理-播图配置

export function managerBmmsAdAddOrUpdate(data) {
  return request({
    url: '/manager/b2c/bmms/adAddOrUpdate',
    method: 'post',
    data: data
  });
} // 小程序页面管理-播图删除

export function managerBmmsAdDelete(data) {
  return request({
    url: '/manager/b2c/bmms/adDelete',
    method: 'post',
    data: data
  });
} //  积分兑换商品列表分页

export function managerProduct(data) {
  return request({
    url: '/manager/b2c/product/page',
    method: 'post',
    data: data
  });
} //  积分兑换商品列表分页-删除

export function managerProductDelete(data) {
  return request({
    url: '/manager/b2c/product/delete',
    method: 'post',
    data: data
  });
} //  积分兑换商品配置

export function managerProductAddOrUpdate(data) {
  return request({
    url: '/manager/b2c/product/pageAddOrUpdate',
    method: 'post',
    data: data
  });
} //   福利列表分页

export function managerProductGivePage(data) {
  return request({
    url: '/manager/b2c/product/givePage',
    method: 'post',
    data: data
  });
} //    福利配置

export function managerProductGiveAddOrUpdate(data) {
  return request({
    url: '/manager/b2c/product/giveAddOrUpdate',
    method: 'post',
    data: data
  });
} //    商品福利删除

export function managerProductGiveDelete(data) {
  return request({
    url: '/manager/b2c/product/delete',
    method: 'post',
    data: data
  });
} //   核销列表

export function managerSignPage(data) {
  return request({
    url: '/manager/b2c/sign/page',
    method: 'post',
    data: data
  });
} //    福利配置

export function managerConfirm(data) {
  return request({
    url: '/manager/b2c/sign/confirm',
    method: 'post',
    params: data
  });
} //    商品福利删除

export function managerSignCancel(data) {
  return request({
    url: '/manager/b2c/sign/cancel',
    method: 'post',
    params: data
  });
} //    积分兑换查询

export function exchangePage(data) {
  return request({
    url: '/manager/b2c/exchange/page',
    method: 'post',
    data: data
  });
} //    福利配置-查询

export function exchangeGiftPage(data) {
  return request({
    url: '/manager/b2c/exchange/giftPage',
    method: 'post',
    data: data
  });
} //    顾客审核

export function customerCheck(data) {
  return request({
    url: '/manager/customer/check',
    method: 'post',
    data: data
  });
} //    小程序注册

export function register(data) {
  return request({
    url: '/manager/cus/reception/register',
    method: 'post',
    data: data
  });
} //    新增or修改问题

export function addOrUdateQues(data) {
  return request({
    url: '/manager/wj/addOrUdateQues',
    method: 'post',
    data: data
  });
} //    获取问题列表

export function getQuesList(data) {
  return request({
    url: '/manager/wj/getQuesList',
    method: 'post',
    data: data
  });
} //    新增or修改问题

export function addOrUpdateAns(data) {
  return request({
    url: '/manager/wj/addOrUpdateAns',
    method: 'post',
    data: data
  });
} //    新增or修改问题选项

export function addQuesAnsRel(data) {
  return request({
    url: '/manager/wj/addQuesAnsRel',
    method: 'post',
    data: data
  });
} //    选项类型

export function getAnsType(data) {
  return request({
    url: '/manager/wj/getAnsType',
    method: 'post',
    data: data
  });
} //    获取问题的选项列表

export function getAnsList(data) {
  return request({
    url: '/manager/wj/getAnsList',
    method: 'post',
    data: data
  });
} //    统计 问卷

export function getAnsStat(data) {
  return request({
    url: '/manager/wj/getAnsStat',
    method: 'post',
    data: data
  });
}