/** @deprecated use `element.addEventListener` instead */
export var on = function on(element, event, handler) {
  var useCapture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (element && event && handler) {
    console.log('eeeee');
    element.addEventListener(event, handler, useCapture);
  }
};
/** @deprecated use `element.addEventListener` instead */

export var off = function off(element, event, handler) {
  var useCapture = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

  if (element && event && handler) {
    console.log('ddddd');
    element.removeEventListener(event, handler, useCapture);
  }
};