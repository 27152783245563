var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      style: { height: _vm.height + "px", overflow: "scroll" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "mini",
          attrs: {
            "show-message": false,
            inline: true,
            model: _vm.temp,
            rules: _vm.rules,
            size: "mini",
            "label-width": "90px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户卡号:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.pidCtmcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "pidCtmcode", $$v)
                  },
                  expression: "customer.pidCtmcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.pidCtmname,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "pidCtmname", $$v)
                  },
                  expression: "customer.pidCtmname",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退划扣日期:" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "165px" },
                attrs: {
                  type: "datetime",
                  disabled: "",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  clearable: false,
                },
                model: {
                  value: _vm.temp.cpyDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "cpyDate", $$v)
                  },
                  expression: "temp.cpyDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退划扣项目:", prop: "zptName" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.temp.zptName,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "zptName", $$v)
                  },
                  expression: "temp.zptName",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.checkTemp != null },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible1 = true
                    },
                  },
                },
                [_vm._v("选择")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "消耗次数:", prop: "num" } },
            [
              _c("el-input", {
                ref: "num",
                staticStyle: { width: "110px" },
                attrs: { disabled: "", type: "number" },
                model: {
                  value: _vm.temp.cpyHknum,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "cpyHknum", $$v)
                  },
                  expression: "temp.cpyHknum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退划扣次数:", prop: "rtdNum" } },
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                attrs: { type: "number", disabled: _vm.checkTemp != null },
                model: {
                  value: _vm.temp.rtdNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "rtdNum", $$v)
                  },
                  expression: "temp.rtdNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "退划扣金额:", prop: "rtdAmt" } },
            [
              _c("el-input", {
                ref: "rtdAmt",
                staticStyle: { width: "110px" },
                attrs: { disabled: "", type: "number" },
                model: {
                  value: _vm.temp.rtdAmt,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "rtdAmt", $$v)
                  },
                  expression: "temp.rtdAmt",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "说明: (Ctrl+Enter拆行)",
                "label-width": "185px",
              },
            },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "160%" },
                attrs: {
                  rows: 2,
                  type: "textarea",
                  disabled: _vm.checkTemp != null,
                },
                model: {
                  value: _vm.temp.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "remark", $$v)
                  },
                  expression: "temp.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.checkTemp != null
            ? _c(
                "el-form-item",
                { attrs: { label: "审核备注:" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 3, placeholder: "" },
                    model: {
                      value: _vm.approvalInfo.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.approvalInfo, "remark", $$v)
                      },
                      expression: "approvalInfo.remark",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.checkTemp == null
        ? _c(
            "div",
            { staticClass: "bottomBtn" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: _vm.disabledBtn,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("sureOut", { id: _vm.temp.ctmZptId })
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "bottomBtn" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "60px", "margin-left": "20px" },
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled: _vm.checkTemp.verifyState == "4",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("sureCheckXm")
                    },
                  },
                },
                [_vm._v("审核通过")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "60px" },
                  attrs: {
                    size: "mini",
                    type: "danger",
                    disabled: _vm.checkTemp.verifyState == "4",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancelCheckXm")
                    },
                  },
                },
                [_vm._v("审核驳回")]
              ),
            ],
            1
          ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "产品项目列表",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "100px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("back-table", {
                ref: "customer",
                attrs: {
                  "ctm-code": _vm.customer.pidCtmcode,
                  "page-id": _vm.customer.saleNo,
                  height: _vm.innerHeight - 200,
                  departmentList: _vm.departmentList,
                },
                on: { select: _vm.handleSelect1, cancel: _vm.handleCancel1 },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }