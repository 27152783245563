/*
 * @Author: yangchao
 * @Date: 2021-08-16 09:16:47
 * @LastEditors: yangchao
 * @LastEditTime: 2021-12-02 12:22:00
 * @Description:
 */
export default {
  bind: function bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    var SELECTWRAP_DOM = el.querySelector(binding.value.tableEl);
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      /*
       * scrollHeight 获取元素内容高度(只读)
       * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
       * clientHeight 读取元素的可见高度(只读)
       * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
       * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
       */
      var sign = 1; // 定义默认的向上滚于乡下滚的边界

      var CONDITION = this.scrollHeight - this.scrollTop - this.clientHeight; // 注意: && this.scrollTop

      if (this.scrollTop > sign) {
        sign = this.scrollTop;
      }

      if (this.scrollTop < sign) {
        sign = this.scrollTop;
      } // console.log('CONDITION', CONDITION);


      if (CONDITION < 1) {
        binding.value.loadData();
      }

      if (!binding.value.autoClear) {
        binding.value.loadData('clear');
      }
    });
  }
};