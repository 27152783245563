//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import EditTable from "../components/EditTable.vue";
import { findZptDList, adjustPrice } from '@/api/cash';
import { findByPy } from '@/api/consult';
import moment from 'moment';
export default {
  name: 'GwTable',
  components: {
    EditTable: EditTable
  },
  props: {
    customer: {
      required: true,
      type: Object
    },
    ctfZdDocList: {
      required: true,
      type: Array
    },
    height: {
      required: true
    }
  },
  filters: {
    statusFilter: function statusFilter(status, type) {
      var map = {
        USE: {
          type: 'success',
          title: '正常'
        },
        STO: {
          type: 'primary',
          title: '删除'
        }
      };
      return map[status] ? map[status][type] : status;
    }
  },
  data: function data() {
    return {
      curCell: {},
      list: [],
      dialogVisible: false,
      total: 0,
      temp: {},
      rvTypes: [],
      columns: [],
      statusList: [{
        title: '已完成',
        field: 'FSH'
      }, {
        title: '预约',
        field: 'FUC'
      }, {
        title: '取消',
        field: 'CAN'
      }, {
        title: '待划扣',
        field: 'USE'
      }],
      listQuery: {
        pageNum: 1,
        pageSize: 1000
      }
    };
  },
  created: function created() {
    this.columns = [{
      title: '选购项目/产品',
      field: 'ctpZptName',
      width: '150px',
      type: 'button',
      required: true,
      canEdit: function canEdit() {
        return true;
      },
      event: this.handleInput,
      input: this.handleInput,
      select: this.handleSelect,
      children2: [{
        title: '产品编号',
        field: 'zptCode',
        searchable: true,
        width: '100px'
      }, {
        title: '产品名称',
        field: 'zptName',
        searchable: true,
        width: '150px'
      }, {
        title: '拼音码查询',
        field: 'zptOldid',
        searchable: true,
        width: '100px'
      }, {
        title: '规格',
        field: 'zptOrder',
        width: '80px'
      }, {
        title: '价格',
        field: 'zptPrice',
        width: '80px'
      }, {
        title: '可用库存',
        field: 'sumUseNum',
        width: '100px'
      }, {
        title: '实际库存',
        field: 'sumNum',
        width: '100px'
      }, {
        title: '单位',
        field: 'unitName',
        width: '80px'
      }]
    }, {
      title: '类型',
      field: 'ctpPtype1Name',
      type: 'text',
      width: '100px'
    }, {
      title: '次数',
      field: 'ctpZptNum',
      type: 'input',
      canEdit: function canEdit() {
        return true;
      },
      width: '120px'
    }, {
      title: '单位',
      field: 'ctpUnit',
      type: 'text',
      width: '80px'
    }, {
      title: '单价',
      field: 'cpyUnitamt',
      type: 'input',
      width: '120px'
    }, {
      title: '折扣',
      field: 'cpyDisaccount',
      type: 'input',
      width: '80px',
      canEdit: function canEdit() {
        return true;
      }
    }, {
      title: '应付金额',
      field: 'cpyAccount',
      type: 'input',
      width: '80px',
      canEdit: function canEdit() {
        return true;
      },
      formatter: function formatter(value, list, row) {
        return row.ctpZptNum && row.cpyUnitamt ? row.ctpZptNum * row.cpyUnitamt * row.cpyDisaccount : 0;
      }
    }, {
      title: '已付金额',
      field: 'cpyPay',
      type: 'text',
      width: '80px'
    }, {
      title: '收款金额',
      field: 'ctpPayamt',
      type: 'input',
      width: '80px',
      canEdit: function canEdit() {
        return true;
      }
    }, {
      title: '状态',
      field: 'ctpStatus',
      type: 'select',
      width: '80px',
      searchList: this.statusList,
      formatter: this.getColumnValue
    }, {
      title: '开始时间',
      field: 'pidDate',
      width: '150px'
    }, {
      title: '顾问',
      field: 'cpyEmpname3',
      width: '80px'
    }, {
      title: '医生',
      field: 'cpyEmpname1',
      width: '80px'
    }, {
      title: '麻醉医生',
      field: 'ctfOpter',
      width: '80px'
    }, {
      title: '美疗师/护士',
      field: 'cpyEmpname4',
      width: '120px'
    }, {
      title: '护士',
      field: 'cpyEmpname5',
      width: '80px'
    }, {
      title: '备注',
      field: 'ctpRemark',
      width: '80px'
    }];
    this.getList();
  },
  methods: {
    getColumnValue: function getColumnValue(value, list) {
      var doc = {
        title: ''
      };

      if (list) {
        list.map(function (item) {
          if (item.field == value) {
            doc = item;
          }
        });
      }

      return doc.title;
    },
    handleInput: function handleInput(row, val) {
      var _this = this;

      this.curIndex = row.index;
      this.listQuery.py = val;
      findByPy(this.listQuery).then(function (response) {
        if (response && response.data) {
          //row.data = response.data
          _this.$set(row, 'data', response.data);
        }
      });
    },
    handleSelect: function handleSelect(row, val) {
      val.ctpZptName = row.zptName; //项目名称

      val.ctpPtype1Name = row.pdtName1; //类型

      val.ctpUnit = row.unitName; //单位

      val.ctpZptcode = row.zptCode; //项目编号

      val.ctpNum = row.zptNum; // 次数

      val.ctpAddunit = row.zptAddunit; //面积

      val.ctpPrice = row.zptPrice; //产品价格

      val.perPrice = row.zptPrice / row.zptNum;
      val.ctpDisaccount = row.zptDisaccount; //折扣

      val.ctpAccount = row.zptPrice; // 成交金额

      val.ctpPay = 0; //已付金额

      val.ctpMazpdu = row.zptOrder; //规格

      val.ctfOpter = row.zptDept; //使用定金类型

      val.ctfPtype = row.zptPtype;
      val.ctpZpttype = row.zptTypes;
      val.ctpPtype1 = row.zptPtype1;
      val.ctpPtype2 = row.zptPtype2; //项目组别

      val.ctpPtype3 = row.zptPtype3;
      val.ctpPtype4 = row.zptPtype4;
      val.ctpFnsdate = moment().add(1, 'year').format('YYYY-MM-DD');
      this.list.splice(this.curIndex, 1, JSON.parse(JSON.stringify(val)));
      this.$refs.table.handleBlur();
    },
    getList: function getList() {
      var _this2 = this;

      this.$setTableLoading(true);
      findZptDList({
        pidCode: this.customer.pidCode
      }).then(function (response) {
        if (response && response.data) {
          _this2.list = response.data;

          for (var i in _this2.list) {
            _this2.$set(_this2.list[i], 'ctpZptNum', _this2.list[i].ctpOldnum); //this.list[i].ctpZptNum = this.list[i].ctpOldnum

          }

          _this2.total = _this2.list.length;

          _this2.$setTableLoading(false);
        }
      });
    },
    handleClick: function handleClick(row) {
      this.temp = row;
    },
    handleClick2: function handleClick2() {},
    handleCancel: function handleCancel() {
      this.$emit('cancel');
    },
    handleDone: function handleDone() {
      var _this3 = this;

      if (!this.$refs.table.checkColumns()) {
        return;
      }

      var list = [];

      for (var i in this.list) {
        var item = {};
        item.id = this.list[i].id;
        item.ctmCode = this.list[i].ctpCtmcode; // 顾客

        item.ctpAccount = this.list[i].cpyAccount; // 应付金额

        item.ctpZptPrice = this.list[i].cpyUnitamt; // 价格

        item.cpyDisaccount = this.list[i].ctpZptDisaccount; // 折扣

        item.ctpEmpcode1 = this.list[i].cpyEmpcode3; // 顾问编号
        // ？？ item.ctpEmpcode2 = this.list[i].cpyUnitamt // 原述者

        item.ctpEmpcode3 = this.list[i].cpyEmpcode1; // 医生

        item.ctpOldamt = this.list[i].cpyAccount; // 项目金额
        // ？？ item.ctpPtcode = this.list[i].cpyUnitamt // 套餐编码
        // ？？ item.ctpSpare = this.list[i].cpyUnitamt // 面积

        item.ctpZptNum = this.list[i].ctpOldnum; // 订购次数

        item.ctpZptcode = this.list[i].ctpZptcode; // 项目编码

        item.dept = this.list[i].ctpDept; // 科室

        item.remark = this.list[i].ctpRemark; // 备注

        list.push(item);
      }

      adjustPrice(list).then(function (response) {
        if (response && response.data) {
          var that = _this3;
          that.$message({
            message: '修改成功',
            type: 'success',
            onClose: function onClose() {
              that.$emit('done');
            }
          });
        }
      });
    },
    tableCellClassName: function tableCellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex - 1;
    }
  }
};