import Cookies from 'js-cookie';
export function setEmployees(employees) {
  localStorage.setItem('employees', JSON.stringify(employees));
}
export function getEmployees() {
  return JSON.parse(localStorage.getItem("employees"));
}
export function setEareList(eareList) {
  return localStorage.setItem('eareList', JSON.stringify(eareList));
}
export function getEareList() {
  return JSON.parse(localStorage.getItem("eareList"));
}
export function setSymptomList(symptomList) {
  return localStorage.setItem('symptomList', JSON.stringify(symptomList));
}
export function getSymptomList() {
  return JSON.parse(localStorage.getItem("symptomList"));
}
export function setDepartmentList(departmentList) {
  return localStorage.setItem('departmentList', JSON.stringify(departmentList));
}
export function setCustomerLevel(customerLevel) {
  return localStorage.setItem('customerLevel', JSON.stringify(customerLevel));
}
export function getCustomerLevel() {
  return JSON.parse(localStorage.getItem("customerLevel"));
}
export function setCustomerLevelManual(customerLevelManual) {
  return localStorage.setItem('customerLevelManual', JSON.stringify(customerLevelManual));
}
export function getCustomerLevelManual() {
  return JSON.parse(localStorage.getItem("customerLevelManual"));
}
export function setCustomerImportant(customerImportant) {
  return localStorage.setItem('customerImportant', JSON.stringify(customerImportant));
}
export function getCustomerImportant() {
  return JSON.parse(localStorage.getItem("customerImportant"));
}
export function getDepartmentList() {
  return JSON.parse(localStorage.getItem("departmentList"));
}
export function setDepartmentListAll(departmentListAll) {
  return localStorage.setItem('departmentListAll', JSON.stringify(departmentListAll));
}
export function getDepartmentListAll() {
  return JSON.parse(localStorage.getItem("departmentListAll"));
}
export function setDepartmentEmployList(departmentEmployList) {
  return localStorage.setItem('departmentEmployList', JSON.stringify(departmentEmployList));
}
export function getDepartmentEmployList() {
  return JSON.parse(localStorage.getItem("departmentEmployList"));
}
export function setPaymentList(paymentList) {
  return localStorage.setItem('paymentList', JSON.stringify(paymentList));
}
export function getPaymentList() {
  return JSON.parse(localStorage.getItem("paymentList"));
}
export function setItensList(intensList) {
  return localStorage.setItem("intensList", JSON.stringify(intensList));
}
export function getItensList() {
  return JSON.parse(localStorage.getItem("intensList"));
}
export function setRvtypeList(rvtypeList) {
  return localStorage.setItem("rvtypeList", JSON.stringify(rvtypeList));
}
export function getRvtypeList() {
  return JSON.parse(localStorage.getItem("rvtypeList"));
}
export function setVendorList(vendorList) {
  return localStorage.setItem("vendorList", JSON.stringify(vendorList));
}
export function getVendorList() {
  return JSON.parse(localStorage.getItem("vendorList"));
}
export function setStorageList(storageList) {
  return localStorage.setItem("storageList", JSON.stringify(storageList));
}
export function getStorageList() {
  return JSON.parse(localStorage.getItem("storageList"));
}
export function setWaiterList(waiterList) {
  return localStorage.setItem("waiterList", JSON.stringify(waiterList));
}
export function getWaiterList() {
  return JSON.parse(localStorage.getItem("waiterList"));
}
export function setPostionList(positionList) {
  return localStorage.setItem("positionList", JSON.stringify(positionList));
}
export function getPostionList() {
  return JSON.parse(localStorage.getItem("positionList"));
}
export function setFucTypeList(fuctypeList) {
  return localStorage.setItem("fuctypeList", JSON.stringify(fuctypeList));
}
export function getCaltypeList1() {
  return JSON.parse(localStorage.getItem("caltypeList"));
}
export function setCaltypeList1(caltypeList) {
  return localStorage.setItem("caltypeList", JSON.stringify(caltypeList));
}
export function getSimpleCalType() {
  return JSON.parse(localStorage.getItem("simpleCalType"));
}
export function setSimpleCalType(simpleCalType) {
  return localStorage.setItem("simpleCalType", JSON.stringify(simpleCalType));
}
export function getFucTypeList() {
  return JSON.parse(localStorage.getItem("fuctypeList"));
}
export function setSettings(data) {
  var key = data.key,
      value = data.value;
  var settings = JSON.parse(localStorage.getItem("settings"));

  if (!settings) {
    settings = {};
  }

  settings[key] = value;
  return localStorage.setItem("settings", JSON.stringify(settings));
}
export function getSettings() {
  return JSON.parse(localStorage.getItem("settings"));
}
export function setServices(data) {
  return localStorage.setItem("services", JSON.stringify(data));
}
export function getServices() {
  var services = localStorage.getItem("services") || "[]";
  return JSON.parse(services);
}
export function getZptPdulabel() {
  var data = localStorage.getItem('zptPdulabel') || '[]';
  return JSON.parse(data);
}
export function setZptPdulabel(data) {
  return localStorage.setItem('zptPdulabel', JSON.stringify(data));
}
export function getZptPdulabel2() {
  var data = localStorage.getItem('zptPdulabel2') || '[]';
  return JSON.parse(data);
}
export function setZptPdulabel2(data) {
  return localStorage.setItem('zptPdulabel2', JSON.stringify(data));
}
export function getPreProjectList() {
  return JSON.parse(localStorage.getItem("preProjectList"));
}
export function setPreProjectList(preProjectList) {
  return localStorage.setItem('preProjectList', JSON.stringify(preProjectList));
}