//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { purchaseDetailPage } from '@/api/stock';
import BaseTable from '@/views/components/BaseTable.vue';
import EditTable from '@/views/components/EditTable';
import { mapGetters } from "vuex";
export default {
  name: 'JhTable',
  components: {
    BaseTable: BaseTable,
    EditTable: EditTable
  },
  props: {
    list: {
      required: true,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    height: {
      required: true
    },
    columns: {
      required: true,
      type: Array
    }
  },
  data: function data() {
    return {
      total: 0,
      temp: {},
      listQuery: {
        pageNum: 1,
        pageSize: 1000
      }
    };
  },
  created: function created() {},
  methods: {}
};