/*
 * @Author: yangchao
 * @Date: 2021-07-24 10:20:43
 * @LastEditors: yangchao
 * @LastEditTime: 2021-07-24 13:55:06
 * @Description:
 */
// 导入momentjs
import moment from 'moment';
export var formatTime = function formatTime(type, str) {
  return moment(str).format(type || 'YYYY-MM-DD HH:mm:ss');
};
export var formatDate = function formatDate(str) {
  return moment(str).format('YYYY-MM-DD');
};
export var formatRankTime = function formatRankTime(type, dateStatus, str) {
  if (type === 'start') {
    return moment(str).startOf(dateStatus).format('YYYY-MM-DD HH:mm:ss');
  } else if (type === 'end') {
    return moment(str).endOf(dateStatus).format('YYYY-MM-DD 23:59:59');
  }
};