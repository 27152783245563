import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { verifyproductModifyPrice } from '@/api/check';
import { cloneDeep } from 'lodash';
export default {
  name: 'ExchangeAddOrEdit',
  props: {
    customer: {
      type: Object
    },
    operate: {
      required: true,
      type: String
    }
  },
  data: function data() {
    return {
      departmentList: [],
      dialogVisible: true,
      loading: false,
      cashDisabled: false,
      numDisabled: false,
      price: '',
      num: '',
      levelOptions: [],
      callPdus: [],
      project2: {}
    };
  },
  watch: {
    project2: {
      handler: function handler(val) {
        if (val.ctpAccount == val.oldAccount) {
          this.cashDisabled = true;
        }

        if (val.ctpZptNum) {
          this.numDisabled = false;
        } else {
          this.num = val.oldNum;
          this.numDisabled = true;
        }
      },
      immediate: true,
      deep: true
    },
    num: {
      handler: function handler(val) {
        if (val) {
          var acc = this.project2.ctpDisaccount != null && this.project2.ctpDisaccount != undefined ? Number(this.project2.ctpDisaccount) : 1;
          this.price = this.project2.ctpZptNum ? ("".concat(val) / "".concat(this.project2.ctpZptNum) * "".concat(this.project2.ctpAccount) * acc).toFixed(2) : '';
        } else {
          this.price = 0;
        }
      }
    }
  },
  created: function created() {
    console.log('this.customer', this.customer);
    var row = cloneDeep(this.customer);
    this.project2 = row;
    this.price = this.project2.ctpAccount;
    this.num = this.project2.ctpZptNum;
  },
  methods: {
    handleClose: function handleClose(done) {
      done();
      this.$emit('close');
    },
    setEmpChangeRecord: function setEmpChangeRecord() {
      var _this = this;

      var id = this.project2.ctmZptId ? this.project2.ctmZptId : this.project2.id;

      if (this.price !== this.project2.ctpAccount || this.num != this.project2.ctpNum) {
        verifyproductModifyPrice({
          num: this.num,
          price: this.price,
          id: id
        }).then(function (response) {
          if (response && response.data) {
            var that = _this;
            that.$message({
              message: '修改成功',
              type: 'success',
              onClose: function onClose() {
                that.$emit('done');
              }
            });
          }
        });
      }
    }
  }
};