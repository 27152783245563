import scroll from "./scroll";

var install = function install(Vue) {
  Vue.directive('scroll', scroll);
};

if (window.Vue) {
  window['scroll'] = scroll;
  Vue.use(install); // eslint-disable-line
}

scroll.install = install;
export default scroll;