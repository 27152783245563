import request from '@/utils/request'; // 物品列表

export function getMaterialList(data) {
  return request({
    url: '/manager/product/getMaterialList',
    method: 'post',
    data: data
  });
} // 供应商列表

export function vendorPage(data) {
  return request({
    url: '/manager/vendor/page',
    method: 'post',
    data: data
  });
} // 生产厂商列表

export function productcmPage(data) {
  return request({
    url: '/manager/productcm/page',
    method: 'post',
    data: data
  });
} // 生产厂商列表

export function purchasePage(data) {
  return request({
    url: '/manager/purchase/page',
    method: 'post',
    data: data
  });
} // 进入库明细分页

export function purchaseDetailPage(data) {
  return request({
    url: '/manager/purchase/detail/page',
    method: 'post',
    data: data
  });
} //  金蝶异常库存表

export function stockKingdeeErrPage(data) {
  return request({
    url: '/manager/stock/stockKingdeeErrPage',
    method: 'post',
    data: data
  });
} // 批号查询

export function getListForPageidUseNum(data) {
  return request({
    url: '/manager/purchase/getListForPageidUseNum',
    method: 'post',
    params: data
  });
} // 库房出料管理

export function stPage(data) {
  return request({
    url: '/manager/empout/stPage',
    method: 'post',
    data: data
  });
} // 科室领料

export function querySuportPage(data) {
  return request({
    url: '/manager/empout/querySuportPage',
    method: 'post',
    data: data
  });
} // 科室领料 - new

export function depPage(data) {
  return request({
    url: '/manager/empout/depPage',
    method: 'post',
    data: data
  });
} // 产品库存查询

export function stockPage(data) {
  return request({
    url: '/manager/stock/page',
    method: 'post',
    data: data
  });
} // 产品库存历史记录查询

export function stockDtPage(data) {
  return request({
    url: 'manager/stock/dt/page',
    method: 'post',
    data: data
  });
} // 获取单号

export function getPchId(data) {
  return request({
    url: '/manager/purchase/getPriKey',
    method: 'post',
    params: data
  });
} // 仓库列表

export function storagePage(data) {
  return request({
    url: '/manager/storage/page',
    method: 'post',
    data: data
  });
} // 进货入库

export function inPurchaseH(data) {
  return request({
    url: '/manager/purchase/inPurchaseH',
    method: 'post',
    data: data
  });
} // 编辑进货入库

export function updateInPurchaseH(data) {
  return request({
    url: '/manager/purchase/updateInPurchaseH',
    method: 'post',
    data: data
  });
} // 其他入库

export function pdmPurchaseH(data) {
  return request({
    url: '/manager/purchase/pdmPurchaseH',
    method: 'post',
    data: data
  });
} // 接收调拨

export function receivePdumove(data) {
  return request({
    url: '/manager/pdumove/receivePdumove',
    method: 'post',
    params: data
  });
} // 冲减库存

export function outPurchaseH(data) {
  return request({
    url: '/manager/purchase/outPurchaseH',
    method: 'post',
    data: data
  });
} // 插入库房出料

export function stEmpout(data) {
  return request({
    url: '/manager/empout/stEmpout',
    method: 'post',
    data: data
  });
} // 插入科室领料

export function depEmpout(data) {
  return request({
    url: '/manager/empout/depEmpout',
    method: 'post',
    data: data
  });
} // 调拨分页

export function pdumovePage(data) {
  return request({
    url: '/manager/pdumove/page',
    method: 'post',
    data: data
  });
} // 调拨明细分页

export function pdumoveDetailPage(data) {
  return request({
    url: '/manager/pdumove/detail/page',
    method: 'post',
    data: data
  });
} // 删除调拨单

export function deletePdumove(data) {
  return request({
    url: '/manager/pdumove/deletePdumove',
    method: 'post',
    params: data
  });
} // 盘点分页

export function stcheckPage(data) {
  return request({
    url: '/manager/stcheck/page',
    method: 'post',
    data: data
  });
} // 盘点明细分页

export function stcheckDetailPage(data) {
  return request({
    url: '/manager/stcheck/detail/page',
    method: 'post',
    data: data
  });
} // 盘点新增

export function doStcheckDet(data) {
  return request({
    url: '/manager/stcheck/doStcheckDet',
    method: 'post',
    data: data
  });
} // 盘点编辑

export function updateStcheckDet(data) {
  return request({
    url: '/manager/stcheck/updateStcheckDet',
    method: 'post',
    data: data
  });
} // 退货

export function tgstockPage(data) {
  return request({
    url: '/manager/tgstock/page',
    method: 'post',
    data: data
  });
} // 退货明细

export function tgstockDetail(data) {
  return request({
    url: '/manager/tgstock/detail/page',
    method: 'post',
    data: data
  });
} // 退货新增

export function doTgstock(data) {
  return request({
    url: '/manager/tgstock/doTgstock',
    method: 'post',
    data: data
  });
} // 退货新增

export function updateTgstock(data) {
  return request({
    url: '/manager/tgstock/updateTgstock',
    method: 'post',
    data: data
  });
} // 进行调拨

export function doPdumove(data) {
  return request({
    url: '/manager/pdumove/doPdumove',
    method: 'post',
    data: data
  });
} // 调拨编辑

export function editPdumove(data) {
  return request({
    url: '/manager/pdumove/editPdumove',
    method: 'post',
    data: data
  });
} // 应收财务登记-列表

export function queryDisaccList(data) {
  return request({
    url: '/manager/cashmanage/queryDisaccList',
    method: 'post',
    data: data
  });
}
export function queryDisaccListExport(data) {
  return request({
    url: '/manager/cashmanage/queryDisaccListExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 应收财务登记-删除

export function delDisacc(data) {
  return request({
    url: '/manager/cashmanage/deleteZsbDayacc',
    method: 'get',
    params: data
  });
} //发票作废

export function billDelete(data) {
  return request({
    url: '/bill/delete',
    method: 'post',
    params: data
  });
} // 产品变动明细表 key=产品名称 value=数据

export function queryChangeDetail(data) {
  return request({
    url: '/manager/stock/queryChangeDetail',
    method: 'post',
    data: data
  });
} // 产品变动汇总表 key=类别 value=数据

export function queryChangeReport(data) {
  return request({
    url: '/manager/stock/queryChangeReport',
    method: 'post',
    data: data
  });
} // 变动按类型汇总表 key=类型 value=数据

export function queryTypeChangeReport(data) {
  return request({
    url: '/manager/stock/queryTypeChangeReport',
    method: 'post',
    data: data
  });
} // 产品结余表 key=类别 value=数据

export function querySettleReport(data) {
  return request({
    url: '/manager/stock/querySettleReport',
    method: 'post',
    data: data
  });
} // 物料出库汇总表

export function sumGroupZptcodeList(data) {
  return request({
    url: '/manager/stock/querySumGroupZptcodePage',
    method: 'post',
    data: data
  });
} // 物品顶级分类

export function pdutypetopList(data) {
  return request({
    url: '/manager/pdutypetop/page',
    method: 'post',
    data: data
  });
} // 物品顶级分类 编辑

export function pdutypetopModify(data) {
  return request({
    url: '/manager/pdutypetop/addOrUpdate',
    method: 'post',
    data: data
  });
} // 物品顶级分类 删除

export function pdutypetopDelete(params) {
  return request({
    url: '/manager/pdutypetop/delete',
    method: 'post',
    params: params
  });
} // 物品一级分类

export function getPduTypeOne(data) {
  return request({
    url: '/manager/pdutypeone/page',
    method: 'post',
    data: data
  });
} // 物品二级分类

export function getPduTypeTwo(data) {
  return request({
    url: '/manager/pdutypetwo/page',
    method: 'post',
    data: data
  });
} // 物品三级分类

export function getPduTypeThr(data) {
  return request({
    url: '/manager/pdutypethree/page',
    method: 'post',
    data: data
  });
} // 删除物品

export function delProduct(data) {
  return request({
    url: '/manager/product/delete',
    method: 'post',
    params: data
  });
} // 生成物品编码

export function createProductCode(data) {
  return request({
    url: '/manager/product/genCode',
    method: 'get',
    params: data
  });
} // 领料单删除

export function deleteEmpout(data) {
  return request({
    url: '/manager/empout/deleteEmpout',
    method: 'post',
    params: data
  });
} // 单位列表

export function unitList(data) {
  return request({
    url: '/manager/units/page',
    method: 'post',
    data: data
  });
} // 新增物品

export function addProduct(data) {
  return request({
    url: '/manager/product/addOrUpdate',
    method: 'post',
    data: data
  });
} // 新增供应商

export function addVendor(data) {
  return request({
    url: '/manager/vendor/addOrUpdate',
    method: 'post',
    data: data
  });
} // 生成供应商编码

export function createVendorCode(data) {
  return request({
    url: '/manager/vendor/genCode',
    method: 'get',
    params: data
  });
} // 删除供应商

export function delVendor(data) {
  return request({
    url: '/manager/vendor/delete',
    method: 'post',
    params: data
  });
} // 新增厂家

export function addCj(data) {
  return request({
    url: '/manager/productcm/addOrUpdate',
    method: 'post',
    data: data
  });
} // 生成厂家编码

export function createCmCode(data) {
  return request({
    url: '/manager/productcm/genCode',
    method: 'get',
    params: data
  });
} // 删除厂家

export function delCj(data) {
  return request({
    url: '/manager/productcm/delete',
    method: 'post',
    params: data
  });
} // 进入库明细分页

export function purchaseDetail(data) {
  return request({
    url: '/manager/purchase/detail/page',
    method: 'post',
    data: data
  });
} // 获取过期时间

export function getExpireDate(data) {
  return request({
    url: '/manager/purchase/getExpireDate',
    method: 'post',
    data: data
  });
} // 设置过期时间

export function setExpireDate(data) {
  return request({
    url: '/manager/purchase/setExpireDate',
    method: 'post',
    params: data
  });
} // 设置过期时间

export function auditPurchaseH(data) {
  return request({
    url: '/manager/purchase/auditPurchaseH',
    method: 'post',
    params: data
  });
} // 设置过期时间

export function getPage(data) {
  return request({
    url: '/manager/ctmPay/getPage',
    method: 'post',
    data: data
  });
} // 设置过期时间

export function getByPduCodes(data) {
  return request({
    url: '/manager/product/getByPduCodes',
    method: 'post',
    params: data
  });
} // 客户交易明细表总计

export function queryTransactionSum(data) {
  return request({
    url: '/manager/ctmPay/queryTransactionSum',
    method: 'post',
    data: data
  });
} // 客户交易排行表总计

export function payRankingSum(data) {
  return request({
    url: '/manager/ctmPay/payRankingSum',
    method: 'post',
    data: data
  });
} // 客户已消耗列表人数

export function queryConsumeSum(data) {
  return request({
    url: '/manager/ctmPay/queryConsumeSum',
    method: 'post',
    data: data
  });
} // 上传入库

export function deleteInPurchaseH(data) {
  return request({
    url: '/manager/purchase/deleteInPurchaseH',
    method: 'post',
    params: data
  });
} // 项目价值

export function queryProjectjzExport(data) {
  return request({
    url: '/manager/report/zxest/projectjz/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 重点项目

export function getProjectImportExport(data) {
  return request({
    url: '/manager/report/zxest/getProjectImport/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 人数报表

export function getZxPeopleExport(data) {
  return request({
    url: '/manager/report/zxest/zxPeople/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 客户交易明细表

export function queryTransactionExport(data) {
  return request({
    url: '/manager/ctmPay/queryTransactionExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 客户交易汇总表

export function payCtmCollectExport(data) {
  return request({
    url: '/manager/ctmPay/payCtmCollectExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 客户交易排行表

export function payRankingExport(data) {
  return request({
    url: '/manager/ctmPay/payRankingExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 产品变动统计表

export function querySumGroupZptcodeExport(data) {
  return request({
    url: '/manager/stock/querySumGroupZptcodeExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 库存查询导出

export function pageExport(data) {
  return request({
    url: '/manager/stock/pageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 变动表导出

export function expireExport(data) {
  return request({
    url: '/manager/purchase/expireExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 汇总

export function queryOutSumExport(data) {
  return request({
    url: '/manager/stock/queryOutSumExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //  库房出料统计

export function empoutStSum(data) {
  return request({
    url: '/manager/empout/stSum',
    method: 'post',
    data: data
  });
} // 科室领料统计

export function empoutDepSum(data) {
  return request({
    url: '/manager/empout/depSum',
    method: 'post',
    data: data
  });
} //  物料登记管理导出

export function productExportExcel(data) {
  return request({
    url: '/manager/product/exportExcel',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 删除冲减单

export function deleteOutPurchaseH(data) {
  return request({
    url: '/manager/purchase/deleteOutPurchaseH',
    method: 'post',
    params: data
  });
} // 外帐入库-分页

export function outPurchasePage(data) {
  return request({
    url: '/manager/out/purchase/page',
    method: 'post',
    data: data
  });
} // 外帐入库

export function outInPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/inPurchaseH',
    method: 'post',
    data: data
  });
} // 外帐其他入库

export function outPdmPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/pdmPurchaseH',
    method: 'post',
    data: data
  });
} // 外帐入库明细

export function outDetailPage(data) {
  return request({
    url: '/manager/out/purchase/detail/page',
    method: 'post',
    data: data
  });
} // 外帐入库-删除

export function outDeleteInPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/deleteInPurchaseH',
    method: 'post',
    params: data
  });
} // 外帐入库-编辑

export function outUpdateInPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/updateInPurchaseH',
    method: 'post',
    data: data
  });
} // 外帐出库冲减

export function outOutPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/outPurchaseH',
    method: 'post',
    data: data
  });
} // 外帐出库-删除

export function outDeleteOutPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/deleteOutPurchaseH',
    method: 'get',
    params: data
  });
} // 外帐出库-编辑

export function outUpdateOutPurchaseH(data) {
  return request({
    url: '/manager/out/purchase/updateOutPurchaseH',
    method: 'post',
    data: data
  });
} // 外帐-产品库存历史记录查询

export function outStockDtPage(data) {
  return request({
    url: '/manager/out/stock/dt/page',
    method: 'post',
    data: data
  });
} // 外帐-产品库存查询

export function outStockPage(data) {
  return request({
    url: '/manager/out/stock/page',
    method: 'post',
    data: data
  });
} // 外帐-产品库存导出

export function outStockPageExport(data) {
  return request({
    url: '/manager/out/stock/pageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 外帐-科室领料

export function outEmpoutDepPage(data) {
  return request({
    url: '/manager/out/empout/depPage',
    method: 'post',
    data: data
  });
} // 外帐-科室领料汇总

export function outEmpoutDepSum(data) {
  return request({
    url: '/manager/out/empout/depSum',
    method: 'post',
    data: data
  });
} // 外帐-插入科室领料

export function outEmpoutDepEmpout(data) {
  return request({
    url: '/manager/out/empout/depEmpout',
    method: 'post',
    data: data
  });
} // 外帐-退库

export function outEmpoutDeleteEmpout(data) {
  return request({
    url: '/manager/out/empout/deleteEmpout',
    method: 'post',
    params: data
  });
} // 外帐-变动表-导出

export function outQuerySumGroupZptcodeExport(data) {
  return request({
    url: '/manager/out/stock/querySumGroupZptcodeExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 外帐-汇总表-导出

export function outQueryOutSumExport(data) {
  return request({
    url: '/manager/out/stock/queryOutSumExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 外帐-汇总表-变动表

export function outQuerySumGroupZptcodePage(data) {
  return request({
    url: '/manager/out/stock/querySumGroupZptcodePage',
    method: 'post',
    data: data
  });
} // 获取单号-外账

export function outGetPriKey(data) {
  return request({
    url: '/manager/out/purchase/getPriKey',
    method: 'post',
    params: data
  });
} // 变动表汇总

export function querySumGroupZptcodeSum(data) {
  return request({
    url: '/manager/stock/querySumGroupZptcodeSum',
    method: 'post',
    data: data
  });
} // 产品进货明细表-列表

export function purchaseQueryDetailPage(data) {
  return request({
    url: '/manager/purchase/queryDetailPage',
    method: 'post',
    data: data
  });
} // 产品进货明细表-汇总

export function purchaseQueryDetailSum(data) {
  return request({
    url: '/manager/purchase/queryDetailSum',
    method: 'post',
    data: data
  });
} // 产品进货明细表-汇总

export function outQuerySumGroupZptcodeSum(data) {
  return request({
    url: '/manager/out/stock/querySumGroupZptcodeSum',
    method: 'post',
    data: data
  });
} // 冲减单下拉框

export function purchaseSimplePage(data) {
  return request({
    url: '/manager/purchase/simple/page',
    method: 'post',
    data: data
  });
} //  领料出库导出

export function empoutStPageExport(data) {
  return request({
    url: '/manager/empout/stPageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //  科室领料导出

export function empoutStdepPageExport(data) {
  return request({
    url: '/manager/empout/depPageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} //   进货明细导出

export function queryDetailPageExport(data) {
  return request({
    url: '/manager/purchase/queryDetailPageExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 科室出库-new

export function ctmzptDPage(data) {
  return request({
    url: '/manager/ctmZpt/ctmzptDPage',
    method: 'post',
    data: data
  });
} // 科室出库-单号-new

export function getCtmzptDeptList(data) {
  return request({
    url: '/manager/ctmZpt/getCtmzptDeptList',
    method: 'post',
    data: data
  });
} // 科室出库-保存

export function consumeStockOut(data) {
  return request({
    url: '/manager/comsume/consumeStockOut',
    method: 'post',
    data: data
  });
}
/**
 * // 科室出库-项目主材
 * @param {Object} data
 * @param {String} type [queryPduPage: 列表 depSum：汇总]
 */

export function queryPduPage(data) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'queryPduPage';
  return request({
    url: "/manager/empout/".concat(type),
    method: 'post',
    data: data
  });
} //   辅材导出

export function suportPageExport(data) {
  return request({
    url: '/manager/empout/querySuportPageExport',
    method: 'post',
    data: data
  });
} //   主材导出

export function queryPduPageExport(data) {
  return request({
    url: '/manager/empout/queryPduPageExport',
    method: 'post',
    data: data
  });
} // 赠送领料

export function queryGivsPaget(data) {
  return request({
    url: '/manager/empout/queryGivsPage',
    method: 'post',
    data: data
  });
} // 赠送领料 导出

export function queryGivsPageExport(data) {
  return request({
    url: '/manager/empout/queryGivsPageExport',
    method: 'post',
    data: data
  });
} // 产品库存查询 金蝶

export function pageForGroup(data) {
  return request({
    url: '/manager/stock/pageForGroup',
    method: 'post',
    data: data
  });
}