var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      style: {
        height: _vm.height + "px",
        margin: "10px 0 40px",
        padding: "0 20px 50px",
        overflow: "auto auto",
      },
    },
    [
      _c(
        "el-form",
        {
          staticClass: "mini",
          attrs: { inline: true, model: _vm.temp, size: "mini" },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addEmpout } },
                [_vm._v("新增耗材")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.delEmpout } }, [
                _vm._v("删除耗材"),
              ]),
              _vm._v("\n      使用次数："),
              _c("el-input", {
                staticStyle: { width: "60px" },
                attrs: { disabled: _vm.temp.hasManySg },
                on: { input: _vm.numInput },
                model: {
                  value: _vm.temp.ctpUsenum,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "ctpUsenum", $$v)
                  },
                  expression: "temp.ctpUsenum",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { float: "right" } },
                [
                  _vm.userInfo.dptCode === "000D75" || _vm.userInfo.GLOBAL_RIGHT
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", disabled: _vm.hkDisabled },
                          on: {
                            click: function ($event) {
                              _vm.$emit("close", {
                                editFlag: _vm.initNum === _vm.temp.ctpUsenum,
                                temp: _vm.temp,
                              }),
                                _vm.removeEnvListrner()
                            },
                          },
                        },
                        [_vm._v("补划扣")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: { "label-width": "0" },
            },
            [
              _c("EditTable", {
                ref: "table",
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  columns: _vm.empoutColumns,
                  temp: _vm.row,
                  listQuery: {},
                  height: 500,
                  list: _vm.list,
                  checkbox: "",
                  index: "",
                  autoSelect: "checked",
                  selectInit: _vm.selectInit,
                },
                on: {
                  click: function ($event) {
                    return _vm.clickBtn($event)
                  },
                  multipleSelection: _vm.handleSelectionChange,
                  selectItem: _vm.selectItem,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "line-height": "50px" },
                },
                [
                  _vm._v("\n        说明："),
                  _c("el-input", {
                    staticStyle: { width: "95%" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.temp.ctpYpinfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "ctpYpinfo", $$v)
                      },
                      expression: "temp.ctpYpinfo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showZptlist
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "产品库存列表",
                visible: _vm.showZptlist,
                top: "20px",
                "append-to-body": "",
                "close-on-click-modal": false,
                center: "",
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showZptlist = $event
                },
              },
            },
            [
              _c("kc-table", {
                ref: "dialog2",
                attrs: {
                  justShow: "",
                  earId: _vm.temp.earId,
                  storageId: _vm.temp.cpyStkcode,
                  "sto-stock": _vm.temp.pchStkcode,
                  height: _vm.innerHeight - 250,
                  zptJtcode: "",
                  comsumeHc: "",
                },
                on: { done: _vm.selectZptSuccess },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }