import drag from "./el-drag-dialog/drag";

var install = function install(Vue, option) {
  // v-dialogDrag: 弹窗拖拽属性
  Vue.directive('dialogDrag', drag); // dialog高度设置

  Vue.directive('dialogHeight', {
    inserted: function inserted(el, binding) {
      var heightValue = binding.value && binding.value.height;

      if (heightValue) {
        el.style.cssText += "max-height: ".concat(heightValue, "; overflow-y: auto; padding-right: 20px;");
        return;
      }

      var clientHeight = document.body.clientHeight;
      var height = !(el.parentElement && el.parentElement.nextElementSibling) ? clientHeight - 165 : clientHeight - 220;
      el.style.cssText += "max-height: ".concat(height, "px; overflow-y: auto; padding-right: 20px;");
    }
  });
};

export default install;