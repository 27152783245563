import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.array.find-index";
import _slicedToArray from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries";
import "core-js/modules/web.dom.iterable";

/*
 * @Author: yangchao
 * @Date: 2021-07-08 14:58:32
 * @LastEditors: yangchao
 * @LastEditTime: 2021-08-21 10:28:42
 * @Description:
 */
import { activityTypeList } from '@/views/manage/discount/contant';
export var labelOptions = [{
  field: '新客体验',
  title: '新客体验'
}, {
  field: '项目首次体验',
  title: '项目首次体验'
}, {
  field: '复购续单',
  title: '复购续单'
}];
export var label2Options = [{
  field: '适应症一级1',
  title: '适应症一级1'
}, {
  field: '适应症一级2',
  title: '适应症一级2'
}, {
  field: '适应症一级3',
  title: '适应症一级3'
}];
export var label3Options = [{
  field: '新客体验',
  title: '新客体验'
}, {
  field: '项目首次体验',
  title: '项目首次体验'
}, {
  field: '其他促销',
  title: '其他促销'
}];
export var zptXmOptions = [{
  field: 'A+',
  title: 'A+'
}, {
  field: 'A',
  title: 'A'
}, {
  field: 'B',
  title: 'B'
}, {
  field: 'C',
  title: 'C'
}, {
  field: 'D',
  title: 'D'
}];
export var zptSpercAmtOptions = [{
  field: 'YEA',
  title: '年'
}, {
  field: 'Mon',
  title: '月'
}, {
  field: 'DAY',
  title: '日'
}];
export var standardOptions = [// {field: '手工种类1', title: '手工种类1'},
// {field: '手工种类2', title: '手工种类2'},
// {field: '手工种类3', title: '手工种类3'}
{
  field: 'I',
  title: 'I'
}, {
  field: 'II',
  title: 'II'
}];
export var standardLevelOptions = [{
  field: '手工级别1',
  title: '手工级别1'
}, {
  field: '手工级别2',
  title: '手工级别2'
}, {
  field: '手工级别3',
  title: '手工级别3'
}];
export var pdulabelList = [{
  pdulabel: 'A+',
  id: 'A+'
}, {
  pdulabel: 'A',
  id: 'A'
}, {
  pdulabel: 'B',
  id: 'B'
}, {
  pdulabel: 'C',
  id: 'C'
}, {
  pdulabel: 'D',
  id: 'D'
}];
export var yesOrNo = [{
  title: '否',
  field: 'N'
}, {
  title: '是',
  field: 'Y'
}];
export var pthCtypeDic = [{
  title: '可选项目',
  field: 'SEL'
}, {
  title: '固定项目',
  field: 'FIX'
}];
export function setDataSgOptions(data, mapList) {
  // data.zptStandard1 = mapList
  //   .map((v) => {
  //     return [v.title, data[v.map]];
  //   })
  //   .filter((v) => {
  //     return v[1];
  //   });
  mapList.forEach(function (v) {
    if (data[v.map]) {
      data[v.map].forEach(function (k) {
        data.zptStandard1.push([v.title, k]);
      });
    }
  });
}
export function getDataSgOptions(params, mapList) {
  if (params.zptStandard1.length > 0) {
    mapList.forEach(function (k) {
      params[k.field] = null;
      params[k.map] = null;
    });
    var maps = {};
    params.zptStandard1.forEach(function (v) {
      if (!maps[v[0]]) {
        maps[v[0]] = [v[1]];
      } else {
        maps[v[0]].push(v[1]);
      }
    });

    var _loop = function _loop() {
      var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          value = _Object$entries$_i[1];

      var index = mapList.findIndex(function (i) {
        return i.title === key;
      });

      if (index !== -1) {
        params[mapList[index].map] = value.join(',');
        params[mapList[index].field] = key;
      }
    };

    for (var _i = 0, _Object$entries = Object.entries(maps); _i < _Object$entries.length; _i++) {
      _loop();
    }
  } else {
    mapList.forEach(function (k) {
      params[k.field] = null;
      params[k.map] = null;
    });
  }

  params.zptStandard1 = '';
}
export default function getDiscountColumn(vm) {
  return [{
    title: '编号',
    field: 'zptCode',
    width: '120px',
    searchable: true,
    formSearch: true,
    disabled: function disabled(query) {
      return !query.zptType;
    }
  }, {
    title: '名称',
    field: 'zptName',
    width: '120px',
    searchable: true,
    formSearch: true,
    disabled: function disabled(query) {
      return !query.zptType;
    }
  }, {
    title: '次数/数量',
    field: 'zptNum'
  }, {
    title: '规格 ',
    field: 'order'
  }, {
    title: '单位',
    field: 'unit'
  }, {
    title: '类型',
    field: 'zptType',
    formSearch: true,
    searchable: true,
    searchList: [{
      title: '项目',
      field: 'PDU'
    }, {
      title: '产品',
      field: 'SAL'
    }, {
      title: '套餐',
      field: 'PTH'
    }],
    formatter: function formatter(val, list) {
      var row = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!row.ruleId) {
        return row.type;
      }

      var obj = {
        SAL: '产品',
        PDU: '项目',
        PTH: '套餐'
      };
      return obj[row.zptType];
    }
  }, {
    title: '活动',
    field: 'activityType',
    formSearch: true,
    searchable: true,
    searchList: activityTypeList,
    onChange: vm.activeChangeGetRule,
    formatter: function formatter(val, list) {
      var row = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!row.ruleId && vm.value && vm.value.length > 0) {
        var _item = activityTypeList.filter(function (v) {
          return v.field === vm.value[0];
        });

        return _item[0].title;
      }

      var item = activityTypeList.filter(function (v) {
        return v.field === row.activityType;
      });
      return item.length && item[0].title;
    }
  }, {
    title: '活动名',
    field: 'ruleName',
    formSearch: true,
    searchable: true,
    notColumn: true
  }, {
    title: '活动名',
    field: 'ruleId',
    searchList: vm.ruleList,
    formatter: function formatter(val, list) {
      var row = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!row.ruleId && vm.value && vm.value.length > 0) {
        var item = vm.typeList.filter(function (v) {
          return v.id === vm.value[1];
        });
        return item[0].name;
      }

      return row.name;
    }
  }, {
    title: '活动名ID',
    field: 'ruleId'
  }, {
    title: '时间',
    field: 'zptName',
    width: '150px',
    formatter: function formatter(val, list) {
      var row = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!row.ruleId && vm.value && vm.value.length > 0) {
        var item = vm.typeList.filter(function (v) {
          return v.id === vm.value[1];
        });
        return "".concat(item[0].beginTime, "-").concat(item[0].endTime);
      }

      if (row.ruleId) {
        return "".concat(row.beginTime, "-").concat(row.endTime);
      }

      return '';
    }
  }, {
    title: '促销价',
    field: 'disPrice',
    type: 'number',
    canEdit: function canEdit() {
      if (vm.value && vm.value && vm.value.length > 0) {
        return vm.value[0] === 1;
      }

      return false;
    }
  }, {
    title: '活动内容',
    field: 'ruleContext',
    width: '250px',
    formatter: function formatter(val, list) {
      var row = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      if (!row.ruleId && vm.value && vm.value.length > 0) {
        var item = vm.typeList.filter(function (v) {
          return v.id === vm.value[1];
        });
        return item[0].overList && item[0].overList.map(function (l) {
          return "\u6EE1".concat(l.over, "\u51CF").concat(l.sub);
        }).join(',');
      }

      if (row.ruleId) {
        return row.ruleContext;
      }

      return '';
    }
  }];
}
export function getProjectColumn() {
  return {
    columns1: [{
      title: '序号',
      field: 'orderNum',
      searchable: false,
      width: '40px',
      notUpdateTable: true,
      fixed: 'left'
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      searchable: true,
      width: '80px',
      searchList: this.symptomList,
      onChange: this.getsymList,
      fixed: 'left'
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      searchable: true,
      width: '80px',
      searchList: this.symptomList2,
      fixed: 'left'
    }, {
      title: '项目名称',
      field: 'zptName',
      searchable: true,
      width: '110px',
      fixed: 'left'
    }, {
      title: '项目编号',
      field: 'zptCode',
      searchable: true,
      width: '100px'
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '100px'
    }, // { title: "适应症一级", field: "zptSymptom",  width: "100px" },
    {
      title: '项目次数',
      field: 'zptNum',
      width: '60px'
    }, {
      title: '项目次数',
      notColumn: true,
      searchable: true,
      width: '40px',
      operate: 'numRange',
      field: 'minZptNum',
      field1: 'maxZptNum'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '60px',
      searchable: true,
      searchList: this.zptPdulabel2List
    }, {
      title: '推广标签',
      field: 'zptPdulabel',
      searchable: true,
      multiple: true,
      width: '80px',
      searchList: this.zptPdulabelList
    }, {
      title: '会员价',
      field: 'zptLocprice',
      width: '60px'
    }, {
      title: '会员价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptLocprice',
      field1: 'maxZptLocprice'
    }, {
      title: '会员单次价格',
      field: 'zptUprice',
      width: '60px'
    }, {
      title: '会员单次价格',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptUprice',
      field1: 'maxZptUprice'
    }, {
      title: '日常特价',
      field: 'zptPrice',
      width: '60px'
    }, {
      title: '日常特价',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'numRange',
      field: 'minZptPrice',
      field1: 'maxZptPrice'
    }, {
      title: '项目期限',
      field: 'zptSpercAmt',
      width: '60px'
    }, {
      title: '项目期限',
      notColumn: true,
      searchable: true,
      width: '90px',
      operate: 'numRange',
      field: 'minZptSpercAmt',
      field1: 'maxZptSpercAmt'
    }, {
      title: '项目分类',
      field: 'pdtTopType',
      showField: 'pdtTopName',
      searchable: true,
      formSearch: true,
      width: '90px',
      searchList: this.topType,
      onChange: this.getType
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      showField: 'pdtName1',
      searchable: true,
      width: '90px',
      searchList: this.type1,
      formSearch: true,
      onChange: this.getTypeTow
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      showField: 'pdtName2',
      searchable: true,
      width: '90px',
      searchList: this.type2
    }, {
      title: '项目科室',
      field: 'zptDptCode',
      width: '60px',
      searchable: true,
      searchList: this.departmentList,
      searchList1: this.departmentList,
      formatter: this.getColumnValueEditTable
    }, {
      title: '手工类别',
      field: 'zptStandard1',
      notColumn: true,
      searchable: true,
      width: '90px',
      operate: 'cascader',
      options: this.standardOptions,
      change: this.getCheckedNodesT,
      expandChange: this.expandChangeT
    }, {
      title: '主操作',
      field: 'sgZczName',
      width: '60px'
    }, {
      title: '配台',
      field: 'sgPtName',
      width: '60px'
    }, {
      title: '护理',
      field: 'sgHlName',
      width: '60px'
    }, {
      title: '脱毛',
      field: 'sgTmName',
      width: '60px'
    }, {
      title: '纹绣',
      field: 'sgWxName',
      width: '60px'
    }, {
      title: '计入成功率',
      field: 'zptIfsucrate',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '是否划扣',
      field: 'zptIfpaynext',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '可否改次数',
      field: 'zptCanChgTime',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '可否改价',
      field: 'zptCanChgPrice',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '是否无限赠送',
      field: 'canWxzs',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '赠送积分',
      field: 'zptInttype',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '状态',
      field: 'zptStatus',
      searchable: true,
      width: '70px',
      filter: this.$options.filters.statusFilter,
      searchList: this.statusOptions
    }, {
      title: '备注',
      field: 'zptRemark',
      width: '150px'
    }, {
      title: '使用耗材',
      field: 'hcs',
      width: '150px'
    }, {
      title: '创建时间',
      field: 'zptCztime',
      width: '150px'
    }],
    columns2: [{
      title: '序号',
      field: 'orderNum',
      searchable: false,
      width: '40px',
      notUpdateTable: true,
      fixed: 'left'
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      searchable: true,
      width: '80px',
      onChange: this.getsymList,
      searchList: this.symptomList,
      fixed: 'left'
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      searchable: true,
      width: '80px',
      searchList: this.symptomList2,
      fixed: 'left'
    }, {
      title: '项目名称',
      field: 'zptName',
      fixed: 'left',
      searchable: true,
      width: '80px'
    }, {
      title: '项目编号',
      field: 'zptCode',
      searchable: true,
      width: '80px'
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '80px'
    }, {
      title: '项目次数',
      field: 'zptNum',
      searchable: true,
      width: '60px'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '80px',
      searchable: true,
      searchList: this.zptPdulabel2List
    }, {
      title: '推广标签',
      field: 'zptPdulabel',
      searchable: true,
      multiple: true,
      width: '80px',
      searchList: this.zptPdulabelList
    }, {
      title: '促销价',
      field: 'zptDisPrice',
      searchable: true,
      width: '100px',
      type: 'input',
      canEdit: true
    }, {
      title: '促销开始时间',
      field: 'zptDisStartDate',
      searchable: true,
      width: '80px'
    }, {
      title: '促销截止时间',
      field: 'zptDisDate',
      searchable: true,
      width: '80px'
    }, // {title:'日常特价',field:'zptPrice',width:'120px'},
    {
      title: '项目期限',
      field: 'zptSpercAmt',
      searchable: true,
      width: '60px'
    }, {
      title: '项目系列',
      field: 'pdtName1',
      width: '80px'
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      notColumn: true,
      searchable: true,
      width: '80px',
      searchList: this.type1
    }, {
      title: '产品类别',
      field: 'pdtName2',
      width: '80px'
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      notColumn: true,
      searchable: true,
      width: '80px',
      searchList: this.type2
    }, {
      title: '项目科室',
      field: 'departmentName',
      width: '60px',
      searchable: true,
      searchList: this.departmentList,
      formatter: this.getColumnValueEditTable
    }, {
      title: '手工类别',
      field: 'zptStandard1',
      notColumn: true,
      searchable: true,
      width: '80px',
      operate: 'cascader',
      options: this.standardOptions,
      change: this.getCheckedNodesT,
      expandChange: this.expandChangeT
    }, {
      title: '主操作',
      field: 'sgZczName',
      width: '60px'
    }, {
      title: '配台',
      field: 'sgPtName',
      width: '60px'
    }, {
      title: '护理',
      field: 'sgHlName',
      width: '60px'
    }, {
      title: '脱毛',
      field: 'sgTmName',
      width: '60px'
    }, {
      title: '纹绣',
      field: 'sgWxName',
      width: '60px'
    }, // {
    //   title: '手工级别',
    //   field: 'zptStandard2',
    //   searchable: true,
    //   width: '100px',
    //   searchList: this.standardOptionsLevel,
    // },
    // {
    //   title: "工种提成",
    //   field: "zptEmpperc",
    //
    //   searchable: true,
    //   width: "80px",
    //   filter: this.$options.filters.percFilter,
    //   searchList: this.percSearchList
    // },
    {
      title: '计入成功率',
      field: 'zptIfsucrate',
      searchable: true,
      width: '60px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '是否划扣',
      field: 'zptIfpaynext',
      searchable: true,
      width: '60px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '可否改次数',
      field: 'zptCanChgTime',
      searchable: true,
      width: '60px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '可否改价',
      field: 'zptCanChgPrice',
      searchable: true,
      width: '60px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '是否无限赠送',
      field: 'canWxzs',
      searchable: true,
      width: '60px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '赠送积分',
      field: 'zptInttype',
      searchable: true,
      width: '60px',
      filter: this.$options.filters.boolFilter,
      searchList: this.yesOrNo
    }, {
      title: '状态',
      field: 'zptStatus',
      searchable: true,
      width: '80px',
      filter: this.$options.filters.statusFilter,
      searchList: this.statusOptions
    }, {
      title: '备注',
      field: 'zptRemark',
      width: '150px'
    }, {
      title: '使用耗材',
      field: 'hcs',
      width: '150px'
    }, {
      title: '创建时间',
      field: 'zptCztime',
      width: '150px'
    }],
    columns3: [{
      title: '项目名称',
      field: 'zptName',
      searchable: true,
      width: '120px',
      formSearch: true,
      notUpdateTable: true
    }, {
      title: '项目编号',
      field: 'zptCode',
      searchable: true,
      width: '150px',
      formSearch: true
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '120px',
      formSearch: true
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      formSearch: true,
      width: '120px',
      onChange: this.getsymList,
      searchList: this.symptomList
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      formSearch: true,
      width: '120px',
      searchList: this.symptomList2
    }, {
      title: '项目分类',
      field: 'pdtTopType',
      showField: 'pdtTopName',
      searchable: true,
      formSearch: true,
      width: '90px',
      searchList: this.topType,
      onChange: this.getType
    }, {
      title: '项目系列',
      field: 'zptPtype1',
      showField: 'pdtName1',
      searchable: true,
      width: '90px',
      searchList: this.type1,
      formSearch: true,
      onChange: this.getTypeTow
    }, {
      title: '产品类别',
      field: 'zptPtype2',
      showField: 'pdtName2',
      formSearch: true,
      searchable: true,
      width: '90px',
      searchList: this.type2
    }, {
      title: '状态',
      field: 'zptStatus',
      formSearch: true,
      width: '80px',
      searchList: this.statusOptions
    }, {
      title: '使用耗材',
      field: 'hcs',
      width: '150px'
    }],
    columns4: [{
      title: '物品编号',
      field: 'zptCode',
      searchable: true,
      width: '100px'
    }, {
      title: '物品系列',
      field: 'pdtName1',
      width: '80px'
    }, {
      title: '物品类别',
      field: 'pdtName2',
      width: '80px'
    }, {
      title: '价值标签',
      field: 'zptPdulabel2',
      notColumn: true,
      searchable: true,
      width: '150px',
      searchList: this.zptPdulabel2List,
      notUpdateTable: true
    }, {
      title: '零售价',
      field: 'minZptPrice',
      field1: 'maxZptPrice',
      operate: 'numRange',
      notColumn: true,
      searchable: true,
      width: '150px'
    }, {
      title: '物品系列',
      field: 'zptPtype1',
      operate: 'select',
      notColumn: true,
      searchable: true,
      searchList: [],
      onChange: this.getTypeTow
    }, //
    {
      title: '物品类别',
      field: 'zptPtype2',
      operate: 'select',
      notColumn: true,
      searchable: true,
      searchList: []
    }, //
    {
      title: '物品名称',
      field: 'zptName',
      searchable: true,
      width: '80px'
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '100px'
    }, // {title:'五笔码查询',field:'fucMap',searchable:true,width:'100px'},
    {
      title: '价值标签',
      field: 'zptPdulabel2',
      width: '80px'
    }, {
      title: '零售价',
      field: 'zptPrice',
      width: '80px'
    }, {
      title: '规格',
      field: 'zptOrder',
      width: '80px'
    }, // {title:'销售价',field:'zptPrice', width:'80px'},
    {
      title: '单位',
      field: 'unitName',
      width: '80px'
    }, // {title:'最小单位',field:'minUnitName', width:'80px'},
    // {title:'单位进制',field:'zptAddunit', width:'80px'},
    {
      title: '库存上限',
      field: 'zptInlimt',
      width: '80px'
    }, {
      title: '库存下限',
      field: 'zptOutlimt',
      width: '80px'
    }, // {title:'工种提成',field:'zptEmpperc', width:'80px',filter: this.$options.filters.percFilter},
    // {title:'是否同步',field:'ctfName', width:'80px'},
    {
      title: '适应症一级',
      field: 'zptPasymptom',
      width: '80px',
      onChange: this.getsymList,
      searchable: true,
      searchList: this.symptomList,
      formatter: this.getColumnValueEditTable
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      width: '80px',
      searchable: true,
      searchList: this.symptomList2,
      formatter: this.getColumnValueEditTable
    }, {
      title: '项目科室',
      field: 'zptDptCode',
      width: '80px',
      searchable: true,
      searchList: this.departmentListAll,
      formatter: this.getColumnValueEditTable
    }, // {title:'项目科室',field:'departmentName', width:'80px'},
    {
      title: '状态',
      field: 'zptStatus',
      width: '80px',
      searchable: true,
      searchList: this.statusUseStpList,
      filter: this.$options.filters.statusFilter
    }, // {title:'咨询成功率',field:'ctfName', width:'100px'},
    {
      title: '是否零售',
      field: 'zptIftbwz',
      width: '80px',
      searchable: true,
      searchList: this.yesOrNoList,
      filter: this.$options.filters.setFilter
    }, {
      title: '修改价格',
      field: 'zptCanChgPrice',
      width: '80px',
      searchable: true,
      searchList: this.yesOrNoList,
      filter: this.$options.filters.setFilter
    }, // {title:'修改次数',field:'zptCanChgTime', width:'80px',searchable: true,searchList: this.yesOrNoList,filter: this.$options.filters.setFilter},
    {
      title: '备注',
      field: 'zptRemark',
      width: '80px'
    }],
    columns5: [{
      title: '项目/产品名称',
      field: 'zptName',
      searchable: true,
      width: '120px',
      formSearch: true,
      notUpdateTable: true
    }, {
      title: '项目/产品编号',
      field: 'zptCode',
      searchable: true,
      width: '150px',
      formSearch: true
    }, {
      title: '拼音码',
      field: 'zptOldid',
      searchable: true,
      width: '120px',
      formSearch: true
    }, {
      title: '适应症一级',
      field: 'zptPasymptom',
      formSearch: true,
      width: '120px',
      onChange: this.getsymList,
      searchList: this.symptomList
    }, {
      title: '适应症二级',
      field: 'zptSymptom',
      formSearch: true,
      width: '120px',
      searchList: this.symptomList2
    }, {
      title: '项目/产品系列',
      field: 'zptPtype1',
      formSearch: true,
      width: '120px',
      searchList: this.type1
    }, {
      title: '项目/产品类别',
      field: 'zptPtype2',
      formSearch: true,
      width: '120px',
      searchList: this.type2
    }, {
      title: '状态',
      field: 'zptStatus',
      formSearch: true,
      width: '80px',
      searchList: this.statusOptions
    }]
  };
}