var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "comsume_print_box" } }, [
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c("span", { staticStyle: { flex: "2", "font-size": "5px" } }, [
          _vm._v("打印时间：" + _vm._s(_vm.printTime)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          "font-size": "16px",
          "text-align": "center",
          "font-weight": "500",
          "line-height": "28px",
        },
      },
      [_vm._v("\n      治疗单\n    ")]
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "line-height": "28px" } }, [
      _c("div", { staticStyle: { "font-size": "5px" } }, [
        _vm._v(
          "\n        卡号/姓名/年龄：" +
            _vm._s(_vm.customer.pidCtmcode) +
            "/" +
            _vm._s(_vm.customer.pidCtmname) +
            "/" +
            _vm._s(_vm.customer.ctmAge) +
            "\n      "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "font-size": "5px" } }, [
        _vm._v(" 开单日期：" + _vm._s(_vm.customer.pidSdatetime)),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "comsume_print_list",
        staticStyle: {
          "border-top": "2px solid #000",
          "border-bottom": "2px solid #000",
          padding: "5px 0 10px 0",
          "line-height": "28px",
          "font-size": "5px",
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.projectList, function (pro, index) {
          return _c("div", { key: pro.id }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _c("span", { staticStyle: { flex: "5" } }, [
                  _vm._v(_vm._s(index + 1) + "." + _vm._s(pro.ctpZptName)),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticStyle: { flex: "2", "text-align": "center" } },
                  [_vm._v(_vm._s(pro.ctpUsenum) + "次")]
                ),
              ]
            ),
            _vm._v(" "),
            pro.pduList
              ? _c("div", { staticStyle: { display: "flex" } }, [
                  _c("span", [_vm._v("耗材：")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { flex: "1" } },
                    _vm._l(
                      pro.pduList.filter(function (v) {
                        return v.checked
                      }),
                      function (hc, index2) {
                        return _c(
                          "div",
                          { key: index2, staticStyle: { display: "flex" } },
                          [
                            _c("span", { staticStyle: { flex: "1" } }, [
                              _vm._v(_vm._s(hc.ctpZptname)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "40px" } },
                              [_vm._v(_vm._s(hc.num))]
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            pro.ctpYpinfo
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "word-wrap": "break-word",
                      "word-break": "break-all",
                      "line-height": "15px",
                      "font-size": "5px",
                    },
                  },
                  [
                    _vm._v(
                      "\n          说明：" +
                        _vm._s(pro.ctpYpinfo) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "comsume_print_bottom",
        staticStyle: { "line-height": "28px" },
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
            },
          },
          [
            _c("span", { staticStyle: { flex: "2", "font-size": "5px" } }, [
              _vm._v("所属顾问:" + _vm._s(_vm.customer.ctmEmpcode1Name)),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { flex: "1", "font-size": "5px" } }, [
              _vm._v("操作员：" + _vm._s(_vm.userName)),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(1),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c("span", { staticStyle: { flex: "5" } }, [_vm._v("项目名称")]),
        _vm._v(" "),
        _c("span", { staticStyle: { flex: "2", "text-align": "center" } }, [
          _vm._v("治疗次数"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c("span", { staticStyle: { flex: "1", "font-size": "5px" } }, [
          _vm._v("主操作："),
        ]),
        _vm._v(" "),
        _c("span", { staticStyle: { flex: "1", "font-size": "5px" } }, [
          _vm._v("护理："),
        ]),
        _vm._v(" "),
        _c("span", { staticStyle: { flex: "1", "font-size": "5px" } }, [
          _vm._v("脱毛："),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }