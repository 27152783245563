import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import clip from '@/utils/clipboard'; // use clipboard directly

import MbPopover from "../components/mb-popover";
import EditItem from "./EditItem";
import { mapGetters } from 'vuex';
import { getLast } from '@/api/customer';
export default {
  name: 'EditTable',
  components: {
    Pagination: Pagination,
    MbPopover: MbPopover,
    EditItem: EditItem
  },
  props: {
    temp: {
      required: true,
      type: Object
    },
    preColumnSlot: {
      type: Boolean
    },
    checkbox: {
      type: Boolean
    },
    // 自定义序号函数
    indexMethod: {
      type: Function
    },
    arraySpanMethod: {
      type: Function
    },
    columns: {
      required: true,
      type: Array
    },
    //每页数量区间
    pageSizes: {
      type: Array,
      default: function _default() {
        [15, 30, 50, 100];
      }
    },
    list: {
      required: true
    },
    listQuery: {
      required: true,
      type: Object
    },
    // 是否需要序号
    showIndex: {
      type: Boolean,
      default: true
    },
    listLoading: {
      type: Boolean
    },
    rowClassName: {},
    total: {
      required: false,
      type: Number
    },
    // 是否开启分页
    pagination: {
      // required: true,
      type: Boolean
    },
    height: {
      // required: true,
      type: Number
    },
    checkItem: {
      type: Array
    },
    checkSelect: {
      type: Array
    },
    indexFixed: {
      type: Boolean
    },
    // 滚动到固定位置
    scrollTopLastLocation: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    topSelect: {
      type: Boolean
    },
    // 清除存储行缓存数据
    clearLocalStorage: {
      type: Boolean
    },
    // 存储行数据
    localStorageCurrentKey: {
      type: String
    },
    // 存储行数据的唯一键
    defaultRowKey: {
      type: String,
      default: 'id'
    },
    cancelClickClose: {
      type: Boolean
    },
    successComsume: {},
    totalColSpan: {
      type: Number
    },
    copyText: {
      type: Boolean,
      default: true
    },
    childrenCheckbox: {
      type: Boolean
    },
    autoSelect: {
      type: String
    },
    // 修改listquery是改变column中的值时 不更新列表
    updateCol: {
      type: Boolean
    },
    selectInit: {
      type: Function
    },
    antoRowStyle: {
      type: Boolean
    },
    cellStyle: {
      type: Object
    },
    cellClassName: {
      type: Function
    }
  },
  data: function data() {
    return {
      index: 0,
      num: 0,
      dom: '',
      scrollTop: 0,
      scrollLeft: 0,
      index2: 0,
      curCell: {},
      curCellChildren: {},
      updateTable: true,
      currentRow: undefined,
      visible: false,
      multipleSelection: [],
      multipleSelection2: [],
      checkItemT: this.checkItem,
      checkChange: false,
      rowStyle: {
        height: this.antoRowStyle ? 'auto' : '50px'
      }
    };
  },
  watch: {
    clearLocalStorage: function clearLocalStorage(val) {
      console.log(val);
      console.log(this.localStorageCurrentKey);

      if (val === true) {
        localStorage.removeItem("".concat(this.localStorageCurrentKey, "_currentRow"));
      }
    },
    columns: {
      handler: function handler(val) {
        // 不更新表
        console.log('updateCol', val);

        if (this.updateCol) {
          var hasNotUpdate = val.filter(function (v) {
            return v.notUpdateTable;
          });

          if (hasNotUpdate.length === 0) {
            this.fnUpdate();
          }
        }
      },
      deep: true
    },
    // list: {
    //   handler(val) {
    //     // 不更新表
    //     let hasNotUpdate = val.filter((v) => {
    //       return v.notUpdateTable;
    //     });
    //     if (hasNotUpdate.length === 0) {
    //       this.fnUpdate();
    //     }
    //   },
    //   deep: true,
    // },
    list: {
      handler: function handler(value) {
        var _this = this;

        this.$nextTick && this.$nextTick(function () {
          _this.setClick();
        });

        if (value.length > 0) {
          // this.$refs.table.setCurrentRow(this.list[0]);
          if (!this.checkChange) {
            this.fnSetCurrentRow(true);
            this.$nextTick(function () {
              if (_this.checkbox) {
                _this.$refs.table.clearSelection();

                if (_this.$refs.table.data.length > 0 && (_this.checkItemT || _this.autoSelect)) {
                  _this.$refs.table.data.forEach(function (item, index) {
                    if (item[_this.autoSelect]) {
                      _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                    } else if (_this.checkItemT) {
                      _this.checkItemT.forEach(function (item2) {
                        if (item.empCode == item2.empCode) {
                          _this.$refs.table.toggleRowSelection(_this.$refs.table.data[index]);
                        }
                      });
                    }
                  });
                }
              }

              var notScrollTop = value.filter(function (v) {
                return v.hasNotScrollTop;
              });

              if (_this.scrollTopLastLocation && notScrollTop.length !== 0) {
                _this.$refs.table && _this.$refs.table.bodyWrapper && (_this.$refs.table.bodyWrapper.scrollTop = _this.scrollTop);
                _this.$refs.table && _this.$refs.table.bodyWrapper && (_this.$refs.table.bodyWrapper.scrollLeft = _this.scrollLeft);
              }
            });
          } else {
            this.fnSetCurrentRow(false);
          }
        }
      },
      immediate: true
    },
    checkSelect: function checkSelect(val) {
      if (val.length > 0) {
        this.checkChange = true;
      }
    },
    height: function height() {
      this.$emit('update:height', window.innerHeight - 260);
    },
    temp: function temp(val) {
      if (this.localStorageCurrentKey && !val.id) {
        this.$refs.table.setCurrentRow();
      }
    },
    '$store.state.settings': {
      handler: function handler(val) {
        // console.log('val', val);
        this.rowStyle.height = val.tableHeight + 'px';
      },
      immediate: true,
      deep: true
    }
  },
  computed: _objectSpread({
    activeColumns: function activeColumns() {
      return this.columns.filter(function (column) {
        return column.visible && !column.notColumn;
      });
    }
  }, mapGetters(['tableLoading'])),
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;

    this.columns.forEach(function (item) {
      _this2.$set(item, 'visible', true);
    });
    this.$nextTick(function () {
      _this2.setClick();
    });
    var that = this;
    this.rowStyle.height = this.$store.state.settings.tableHeight + 'px';

    window.onresize = function () {
      return function () {
        that.$emit('update:height', window.innerHeight - 260);
      }();
    }; // 获取需要绑定的table


    this.dom = this.$refs.table.bodyWrapper;
    console.log('dom', this.dom);
    this.dom && this.dom.addEventListener('scroll', function () {
      // 滚动距离
      _this2.scrollLeft = _this2.dom.scrollLeft;
      var scrollDistance = _this2.dom.scrollHeight - _this2.dom.scrollTop - _this2.dom.clientHeight;

      if (scrollDistance <= 1) {
        //等于0证明已经到底，可以请求接口
        that.loadData();
      }
    });
  },
  methods: {
    loadData: function loadData() {
      this.$emit('getdata');
    },
    onInputTurnNumber: function onInputTurnNumber(num) {
      if ((num + '').replace(/[^\d^\.]+/g, '').length < 1) {
        return 0;
      }

      var strs = ('' + num).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || '';
      return strs;
    },
    fnSetCurrentRow: function fnSetCurrentRow(bool) {
      var _this3 = this;

      // 判断是否含有存储当前选择行
      if (!this.clearLocalStorage && this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
        // 获得存储的数据
        var currentRow = JSON.parse(localStorage.getItem("".concat(this.localStorageCurrentKey, "_currentRow"))); // 找到当前数据在查询出来数据的位置

        var data = [];

        if (currentRow && currentRow[this.defaultRowKey]) {
          data = this.list.filter(function (v) {
            return v[_this3.defaultRowKey] === currentRow[_this3.defaultRowKey];
          });
        } // 当缓存数据在表格数据里


        if (data.length > 0) {
          console.log('111');
          this.handleClick(data[0]); // 设置数据的高亮

          this.$refs.table && this.$refs.table.setCurrentRow(data[0]);
        } else {
          // 判断是否设置第一条数据高亮
          this.$refs.table.setCurrentRow();

          if (bool) {
            this.$refs.table && this.$refs.table.setCurrentRow(data[0]);
          }
        }
      } else {
        // 判断是否设置第一条数据高亮
        if (bool) {
          this.$refs.table && this.$refs.table.setCurrentRow(this.list[0]);
        }

        if (this.temp && !this.temp.id) {
          this.$refs.table && this.$refs.table.setCurrentRow();
        }
      }
    },
    selectPro: function selectPro(row) {
      this.$refs["closepopover-".concat(row.index + 1)][0].click();
    },
    fnUpdate: function fnUpdate() {
      var _this4 = this;

      this.updateTable = false;
      this.$nextTick(function () {
        _this4.updateTable = true;

        _this4.$nextTick(function () {
          _this4.fnSetCurrentRow(false);
        });
      });
    },
    children2Hide: function children2Hide(column, row) {
      column.select(this.multipleSelection2, row);
    },
    // 分页点击页码跳转
    getList: function getList() {
      this.listQuery.pageSize = this.listQuery.pageSize;
      this.$emit('pagination');
    },
    getCanEdit: function getCanEdit(column, row) {
      if (column.canEdit == true) {
        return true;
      }

      if (column.canEdit && column.canEdit(row)) {
        return true;
      }

      return false;
    },
    handleBlur: function handleBlur(event, row, column) {
      this.curCell = {};

      if (row && column && !this.cancelClickClose) {
        console.log('111==>');
        this.handleClick(row, column, 'blur');
      }
    },
    selectAll: function selectAll(selection) {
      // console.log(selection);
      if (selection.length > 1 && selection[0].onlySelect) {
        this.$refs.table.clearSelection();
      }

      this.$emit('allSelect', selection);
    },
    // 监听多选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val; // this.$emit('selected',this.multipleSelection.length);

      this.$emit('multipleSelection', val);
    },
    // 监听子级多选
    handleSelectionChange2: function handleSelectionChange2(val, column, row) {
      console.log('row', row);
      this.multipleSelection2 = JSON.parse(JSON.stringify(val));
      column.select(this.multipleSelection2, row);
    },
    handleClick: function handleClick(row, column, type) {
      if (this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
        localStorage.setItem("".concat(this.localStorageCurrentKey, "_currentRow"), JSON.stringify(row));
      } // 滚动到指定位置


      if (this.scrollTopLastLocation && column && type) {
        this.scrollTop = this.$refs.table.bodyWrapper.scrollTop; // this.scrollLeft = this.$refs.table.bodyWrapper.scrollLeft;
      }

      this.index = row.index;
      var curCell = {
        row: row.index,
        column: column ? column.index : column,
        field: column && (column.property || column.field)
      };
      this.$set(this, 'curCell', curCell);
      this.$emit('click', row, column, type);
    },
    handleChildrenRowClick: function handleChildrenRowClick(val, row, column) {
      column.select && column.select(val, row);
      this.$set(row, 'showPop', false);
    },
    handleClickChildrenCell: function handleClickChildrenCell(row, column) {
      this.index2 = row.index;
      this.curCellChildren = {
        row: row.index,
        column: column ? column.index : column
      }; // this.$emit('click',row, column, type);
    },
    handleDbclick: function handleDbclick(row) {
      this.index = row.index;
      this.$emit('dbclick', row);
    },
    handleCellDblClick: function handleCellDblClick(row, column, cell, event) {
      if (this.copyText) {
        console.log('window.getSelection().toString()', window.getSelection().focusNode.data);
        clip(window.getSelection().focusNode.data, event, column);
      }
    },
    select: function select(selection, row) {
      if (selection.length > 1 && row.onlySelect) {
        var del_row = selection.shift();
        this.$refs.table.toggleRowSelection(del_row, false);
      }

      this.$emit('selectItem', {
        selection: selection,
        row: row
      });
    },
    setCurrentRow: function setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },
    cellClass: function cellClass(row) {
      // console.log(row)
      if (this.topSelect) {
        if (row.columnIndex === 0) {
          return 'DisableSelection';
        } else {
          return 'DisableSelection';
        }
      } else {
        return 'table-header';
      }
    },
    selectFourEditCheck: function selectFourEditCheck(row, face, queList) {
      if (!queList || !row || !face || !row.preIdListQue) {
        return false;
      }

      var parentId = [];
      queList.forEach(function (v) {
        if (v.children) {
          v.children.forEach(function (v2) {
            if (row.preIdListQue.includes(v2.value)) {
              parentId.push(v.value);
            }
          });
        }
      }); //row.preIdListQue

      if (parentId.includes(face.value)) {
        return true;
      }

      return false;
    },
    selectTwoEditChange: function selectTwoEditChange(row, column, searchList) {
      var fd = row[column.field];

      if (searchList) {
        var show = '';
        searchList.forEach(function (k) {
          k.children.forEach(function (v) {
            if (fd.includes(v.value)) {
              show = show + v.label + ";";
            }
          });
        });
        row[column.fieldShow] = show;
      }
    },
    // 检测单元格必填字段
    checkColumns: function checkColumns(list2) {
      var list = list2 ? list2 : this.list;

      for (var i = 0; i < list.length; i++) {
        for (var j = 0; j < this.columns.length; j++) {
          // console.log(this.columns[j].field,list[i][this.columns[j].field],this.columns[j].required)
          if (!list[i][this.columns[j].field] && list[i][this.columns[j].field] !== 0 && this.columns[j].required) {
            this.curCell = {
              row: i,
              column: j
            };
            this.$emit('click', list[i]);
            var prex = this.columns[j].type == 'input' || this.columns[j].type == 'textarea' ? '请输入' : '请选择';
            this.$message({
              message: prex + this.columns[j].title,
              type: 'warning'
            });
            return false;
          }
        }
      }

      return true;
    },
    tableCellClassName: function tableCellClassName(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex - 1;
    },
    tableCellClassName2: function tableCellClassName2(_ref2) {
      var row = _ref2.row,
          column = _ref2.column,
          rowIndex = _ref2.rowIndex,
          columnIndex = _ref2.columnIndex;
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 设置空白区域点击事件
    setClick: function setClick() {
      console.log('this.$refs.table', this.$refs.table);

      if (this.$refs.table) {
        var click = function click(e) {
          var _con = $('td');

          if (!_con.is(e.target) && _con.has(e.target).length === 0) {
            that.handleBlur();
          }
        };

        var tbody = this.$refs.table.$el.querySelector('.el-table__body-wrapper'); // tbody.removeEventListener('click',click)

        var that = this;
        tbody.addEventListener('click', click);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentRow = val;
    },
    querySearchAsync: function querySearchAsync(str, cb) {
      if (str) {
        var list = this.getEmployeeTypeList('', true);
        var firStr = str.indexOf('Y');
        var arr = [];

        if (firStr == 0) {
          arr = list.filter(function (v) {
            return v.field.indexOf(str) > -1;
          }).map(function (v) {
            return {
              value: v.title,
              code: v.field
            };
          });
        } else {
          arr = list.filter(function (v) {
            return v.title.indexOf(str) > -1;
          }).map(function (v) {
            return {
              value: v.title,
              code: v.field
            };
          });
        }

        cb(arr);
      } else {
        cb([]);
      }
    },
    beforeMesShow: function beforeMesShow(row) {
      var _this5 = this;

      getLast({
        opType: 5,
        opTypeId: row.id
      }).then(function (res) {
        // console.log('res.data', res.data);
        if (res.data) {
          var _res$data = res.data,
              updateTime = _res$data.updateTime,
              opterName = _res$data.opterName,
              opter = _res$data.opter;

          _this5.$set(row, 'updateTime', updateTime);

          _this5.$set(row, 'opterName', opterName);

          _this5.$set(row, 'opter', opter);

          var item = _this5.getEmployeeTypeList('', true).filter(function (v) {
            return v.field === row.opter;
          });

          _this5.$set(row, 'opter2', item[0].title);
        } else {
          _this5.$set(row, 'updateTime', null);

          _this5.$set(row, 'opterName', null);

          _this5.$set(row, 'opter', null);
        }
      });
    }
  }
};