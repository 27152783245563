import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import VxeItemText from "./VxeItemText";
import { mapGetters } from 'vuex';
import EditItem from "./EditItem";
import MbPopover from "../components/mb-popover";
import clip from '@/utils/clipboard'; // use clipboard directly

import VxeSelect from '@/components/VxeComponent/select.vue';
export default {
  name: 'VxeAutoTable',
  components: {
    Pagination: Pagination,
    VxeItemText: VxeItemText,
    EditItem: EditItem,
    MbPopover: MbPopover,
    VxeSelect: VxeSelect
  },
  props: {
    list: {
      type: Array
    },
    columns: {
      type: Array
    },
    summaryMethod: {},
    height: {
      type: Number
    },
    listQuery: {
      type: Object
    },
    pagination: {
      type: Boolean
    },
    pageSizes: {
      type: Array
    },
    total: {
      type: Number
    },
    listLoading: {},
    clearLocalStorage: {
      type: Boolean
    },
    localStorageCurrentKey: {
      type: String
    },
    // 存储行数据的唯一键
    defaultRowKey: {
      type: String,
      default: 'id'
    },
    checkboxConfig: {
      type: Object,
      default: function _default() {}
    },
    // 是否展示选择框
    checkbox: {
      type: Boolean
    },
    childrenCheckbox: {
      type: Boolean
    },
    // 复选框判断
    selectInit: {
      type: Function
    },
    // 判断全选框是否展示
    allCheck: {
      type: Boolean
    },
    // 滚动到固定位置
    scrollTopLastLocation: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    changeListResetCheckbox: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    preColumnSlot: {
      type: Boolean
    },
    locationTop: {
      type: Boolean
    },
    // 当点击非编辑列之后，是否自动清除单元格的激活状态
    autoClear: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 设置第一条数据高亮
    setCurrentRowFirst: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    // 是否自适应宽度
    autoResize: {
      type: Boolean
    },
    // 虚拟滚动x y
    scroll: {
      type: Object,
      default: function _default() {
        return {
          x: 15,
          y: 25
        };
      }
    },
    rowClassName: {},
    showIndex: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    checkMethod: {
      type: Function
    },
    // 自动选中的列表
    autoSelectList: {
      type: Array
    },
    copyText: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    tableSlot: {
      type: Boolean
    },
    // 是否记录滚动条 横向滚动
    cacheOffsetLeft: {
      type: Boolean
    },
    // index列是否 slot
    slotHeader: {
      type: Boolean
    },
    spanMethod: {
      type: Function
    },
    footerMethod: {
      type: Function
    },
    mergeFooterItems: {
      type: Array
    },
    activeColumnsNotVisible: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      updateTable: true,
      scrollTop: 0,
      scrollLeft: 0,
      rowStyle: {
        height: this.$store.state.settings.tableHeight + 'px'
      }
    };
  },
  computed: _objectSpread({
    activeColumns: function activeColumns() {
      if (this.activeColumnsNotVisible) {
        return this.columns && this.columns.filter(function (column) {
          return !column.notColumn;
        });
      }

      return this.columns && this.columns.filter(function (column) {
        return column.visible && !column.notColumn;
      });
    }
  }, mapGetters(['tableLoading'])),
  mounted: function mounted() {
    var _this = this;

    this.columns.forEach(function (item) {
      _this.$set(item, 'visible', true);
    });
    this.rowStyle.height = this.$store.state.settings.tableHeight + 'px'; // .getElementsByClassName('vxe-table--fixed-left-wrapper')
  },
  watch: {
    clearLocalStorage: function clearLocalStorage(val) {
      if (val === true) {
        localStorage.removeItem("".concat(this.localStorageCurrentKey, "_currentRow"));
        this.$refs.table.setCurrentRow();
      }
    },
    columns: {
      handler: function handler(val) {
        if (val) {
          var hasNotUpdate = val.filter(function (v) {
            return v.notUpdateTable;
          });

          if (hasNotUpdate.length === 0) {
            this.fnUpdate();
          }
        }
      },
      deep: true
    },
    list: function list(value) {
      var _this2 = this;

      if (value.length > 0) {
        this.fnSetCurrentRow(true);
        if (!this.changeListResetCheckbox) return;
        this.$nextTick(function () {
          _this2.checkboxRow();

          var notScrollTop = value.filter(function (v) {
            return v.hasNotScrollTop;
          });

          if (_this2.scrollTopLastLocation && notScrollTop.length === 0) {
            _this2.scrollFun();
          }
        });
      }
    },
    '$store.state.settings': {
      handler: function handler(val) {
        // console.log('val', val);
        this.rowStyle.height = val.tableHeight + 'px';
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 获取选中的数据
    getSelectData: function getSelectData() {
      return this.$refs.table.getCheckboxRecords(this.list);
    },
    // 列表滚动事件
    loadData: function loadData(clear) {
      var _this3 = this;

      if (this.$refs.dcPopover) {
        this.$nextTick(function () {
          _this3.$refs.dcPopover.forEach(function (v, index) {
            _this3.$refs.dcPopover[index].updatePopper();
          });
        });
      }

      if (clear) {
        this.$refs.table.clearActived();
      } else {
        this.$emit('getdata');
      }
    },
    // 分页点击页码跳转
    getList: function getList() {
      this.$emit('pagination');
    },
    fnUpdate: function fnUpdate() {
      var _this4 = this;

      this.updateTable = false;
      this.$nextTick(function () {
        _this4.updateTable = true;

        _this4.$nextTick(function () {
          _this4.fnSetCurrentRow(false, true);

          if (_this4.scrollTopLastLocation || _this4.locationTop) {
            _this4.scrollFun();
          }
        });
      });
    },
    scrollFun: function scrollFun() {
      var _this5 = this;

      console.log('scrollTop', this.scrollTop);
      setTimeout(function () {
        _this5.$refs.table.scrollTo(_this5.scrollLeft, _this5.scrollTop);
      }, 300);
    },
    updateSelect: function updateSelect(scope, column, event, type) {
      if (column.inputTable) {
        return;
      }

      if (type === 'chiledTableSelect') {
        scope.row.showPop = false;
        !column.inputTable && column.select(event, scope.row);
      } else {
        this.$refs.table.clearActived();
        column.change && column.change(scope.row, column.field);
      }
    },
    handleClick: function handleClick(_ref) {
      var row = _ref.row,
          column = _ref.column,
          type = _ref.type;

      if (this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
        localStorage.setItem("".concat(this.localStorageCurrentKey, "_currentRow"), JSON.stringify(row));
      } // 滚动到指定位置
      // console.log('scrollTopLastLocation', this.scrollTopLastLocation);


      if ((this.scrollTopLastLocation || this.locationTop) && column && type) {
        var el = this.$refs.table.$el.querySelector('.vxe-table--body-wrapper');
        this.scrollTop = el.scrollTop;

        if (this.cacheOffsetLeft) {
          this.scrollLeft = el.scrollLeft;
        }
      }

      this.$emit('click', row, column, type);
    },
    handleCellDblClick: function handleCellDblClick(_ref2) {
      var row = _ref2.row,
          $event = _ref2.$event,
          column = _ref2.column;

      if (this.copyText) {
        // console.log('window.getSelection().toString()', window.getSelection().focusNode.data);
        clip(window.getSelection().focusNode.data, $event, column);
      }

      this.$emit('dbclick', row, column);
    },
    fnSetCurrentRow: function fnSetCurrentRow(bool, cancelClickUpdateList) {
      var _this6 = this;

      // 判断是否含有存储当前选择行
      if (!this.clearLocalStorage && this.localStorageCurrentKey && typeof this.localStorageCurrentKey === 'string') {
        // 获得存储的数据
        var currentRow = JSON.parse(localStorage.getItem("".concat(this.localStorageCurrentKey, "_currentRow"))); // 找到当前数据在查询出来数据的位置

        var data = [];

        if (currentRow && currentRow[this.defaultRowKey]) {
          data = this.list.filter(function (v) {
            return v[_this6.defaultRowKey] === currentRow[_this6.defaultRowKey];
          });
        } // 当缓存数据在表格数据里


        if (data.length > 0) {
          // 设置数据的高亮
          this.$refs.table.setCurrentRow(data[0]);

          if (cancelClickUpdateList) {
            return;
          } else {
            this.handleClick({
              row: data[0]
            });
          }
        } else {
          // 判断是否设置第一条数据高亮
          if (bool) {
            this.$refs.table.setCurrentRow();
          }
        }
      } else {
        // 判断是否设置第一条数据高亮
        if (bool) {
          this.$refs.table.setCurrentRow(this.list[0]);
        }

        if (this.temp && !this.temp.id) {
          this.$refs.table.setCurrentRow();
        }
      }

      if (!this.setCurrentRowFirst) {
        this.$refs.table.setCurrentRow();
      }
    },
    // 选择事件
    select: function select(_ref3) {
      var records = _ref3.records,
          row = _ref3.row;

      if (records.length > 1 && row.onlySelect) {
        var del_row = records.shift();
        this.$refs.table.toggleRowSelection(del_row, false);
      }

      this.$emit('selectItem', {
        selection: records,
        row: row
      });
      this.$emit('multipleSelection', records);
    },
    // 全选事件
    selectAll: function selectAll(_ref4) {
      var records = _ref4.records;
      console.log(records);

      if (records.length > 1 && records[0].onlySelect) {
        this.$refs.table.clearSelection();
      }

      this.$emit('allSelect', records);
    },
    // 检测单元格必填字段
    checkColumns: function checkColumns(list2) {
      var list = list2 ? list2 : this.list;

      for (var i = 0; i < list.length; i++) {
        for (var j = 0; j < this.columns.length; j++) {
          var value = list[i][this.columns[j].field];

          if (!value && list[i][this.columns[j].field] !== 0 && this.columns[j].required || this.columns[j].checkFun && this.columns[j].checkFun(value, list[i])) {
            var $table = this.$refs.table;
            $table.setActiveRow(list[i]);
            this.$emit('click', list[i]);
            var prex = this.columns[j].type !== 'select' ? '请输入' : '请选择';
            this.$message({
              message: this.columns[j].checkFailMessage ? this.columns[j].checkFailMessage(list[i]) : prex + this.columns[j].title,
              type: 'warning'
            });
            return false;
          }
        }
      }

      return true;
    },
    rowClassNameold: function rowClassNameold(_ref5) {
      var row = _ref5.row,
          rowIndex = _ref5.rowIndex;
      // console.log('rowClassNameold', rowClassNameold);
      row.index = rowIndex;
    },
    cellClass: function cellClass() {
      return 'table-header';
    },
    setCurrentRow: function setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },
    getCanEdit: function getCanEdit(column, row) {
      if (column.canEdit == true) {
        return true;
      }

      if (column.canEdit && column.canEdit(row, column)) {
        return true;
      }

      return false;
    },
    checkboxRow: function checkboxRow() {
      if (this.allCheck || this.checkbox) {
        console.log('checkboxRow');
        this.$refs.table.clearCheckboxRow();

        for (var i = 0; i < this.list.length; i++) {
          this.$refs.table.setCheckboxRow(this.list[i], this.allCheck ? this.selectInit && !this.selectInit(this.list[i]) : this.autoSelect ? true : this.checkMethod ? this.checkMethod(this.list[i], this.autoSelectList) : false);
        }
      }
    },
    toggleCheckAllboxRow: function toggleCheckAllboxRow() {
      // this.$refs.table.toggleAllCheckboxRow();
      for (var i = 0; i < this.list.length; i++) {
        this.$refs.table.toggleCheckboxRow(this.list[i]);
      }
    },
    clearCheckboxRow: function clearCheckboxRow() {
      this.$refs.table.clearCheckboxRow();
    }
  }
};