export var getCerifyStateList = function getCerifyStateList(row) {
  return [row, {
    title: '无需审核',
    field: '2'
  }, {
    title: '审核失败',
    field: '3'
  }, {
    title: '审核完成',
    field: '4'
  }];
};
export function statusFilter(status, type) {
  var map = {
    '0': {
      type: 'primary',
      title: '审核中'
    },
    '9': {
      type: 'primary',
      title: '审核中'
    },
    '1': {
      type: 'primary',
      title: '审核中'
    },
    '2': {
      type: 'success',
      title: '无需审核'
    },
    '3': {
      type: 'danger',
      title: '审核失败'
    },
    '4': {
      type: 'info',
      title: '审核完成'
    }
  };
  return map[status] ? map[status][type] : status;
}
export function statusFilter2(status, type) {
  var map = {
    givb: {
      type: 'primary',
      title: '打版'
    },
    give: {
      type: 'primary',
      title: '转赠'
    },
    givs: {
      type: 'primary',
      title: '赠送'
    }
  };
  return map[status] ? map[status][type] : status;
}
export var columns1 = [{
  title: '交易分院',
  field: 'earName',
  width: '80px'
}, {
  title: '交易分院',
  field: 'earId',
  width: '80px',
  notColumn: true,
  searchable: true,
  searchList: []
}, {
  title: '提交时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'startDate',
  field1: 'endDate'
}, {
  title: '审核时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'verifyStartDate',
  field1: 'verifyEndDate'
}, {
  title: '客户姓名',
  field: 'ctmName',
  searchable: true,
  width: '70px'
}, {
  title: '客户编号',
  field: 'ctpCtmcode',
  searchable: true,
  width: '80px'
}, {
  title: '提交时间',
  field: 'ctpTime',
  width: '140px'
}, {
  title: '项目/产品名称',
  field: 'zptCodeName',
  width: '100px'
}, {
  title: '套餐名称',
  field: 'ctpPtname',
  width: '90px'
}, {
  title: '适应症一级',
  field: 'pasymptom',
  width: '80px'
}, {
  title: '适应症二级',
  field: 'zptSymptom',
  width: '80px'
}, {
  title: '次数/数量',
  field: 'ctpZptNum',
  width: '80px'
}, {
  title: '治疗部位/面积',
  field: 'ctpSpare',
  width: '60px'
}, {
  title: '规格',
  field: 'zptOrder',
  width: '60px'
}, {
  title: '单位',
  field: 'unitName',
  width: '60px'
}, {
  title: '价格',
  field: 'ctpZptPrice',
  width: '60px'
}, // {title: '折扣', field: 'ctpZptDisaccount',    width: '80px'},
{
  title: '应付金额',
  field: 'ctpAccount',
  width: '60px'
}, {
  title: '推荐顾问',
  field: 'ctfJdEmpName',
  width: '80px'
}, {
  title: '助推/二级咨询',
  field: 'ctfZtEmpName',
  width: '60px'
}, // { title: '推广标签', field: 'zptPdulabel', width: '80px' },
{
  title: '价值标签',
  field: 'zptPdulabel2',
  width: '60px'
}, {
  title: '备注',
  field: 'ctpRemark',
  width: '100px'
}, {
  title: '审核说明',
  field: 'verifyRemark',
  width: '120px'
}, {
  title: '审核时间',
  field: 'verifyTime',
  width: '120px'
}, {
  title: '类型',
  fixed: 'right',
  field: 'ctpPtype4',
  width: '80px',
  filter: statusFilter2
}, {
  title: '审核状态',
  fixed: 'right',
  field: 'verifyState',
  width: '80px',
  filter: statusFilter
}, {
  title: '审核状态',
  field: 'verfyState',
  notColumn: true,
  width: '80px',
  searchList: getCerifyStateList({
    title: '审核中',
    field: '1'
  }),
  searchable: true,
  onchange: null
}];
export var columns2 = [{
  title: '交易分院',
  field: 'earId',
  searchable: true,
  formatter: null,
  width: '80px',
  searchList: []
}, {
  title: '提交时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'createStartTime',
  field1: 'createEndTime'
}, {
  title: '提交时间',
  field: 'createTime',
  width: '120px'
}, {
  title: '客户卡号',
  field: 'ctpCtmcode',
  searchable: true,
  width: '100px'
}, {
  title: '客户姓名',
  field: 'ctpCtmname',
  searchable: true,
  width: '80px'
}, {
  title: '电话',
  field: 'mobile',
  searchable: true,
  width: '100px'
}, {
  title: '项目/产品名称',
  field: 'zptName',
  width: '140px'
}, {
  title: '类型',
  field: 'verifyType',
  searchable: true,
  searchList: [],
  width: '90px',
  filter: null
}, {
  title: '次数',
  field: 'num',
  width: '60px'
}, {
  title: '金额',
  field: 'amount',
  width: '60px'
}, {
  title: '发生时间',
  field: 'time',
  width: '120px'
}, {
  title: '说明',
  field: 'remark',
  width: '120px'
}, {
  title: '提交人',
  field: 'opterCode',
  notColumn: true,
  searchable: true,
  width: '60px',
  searchList: []
}, {
  title: '提交人',
  field: 'opterName',
  searchable: false,
  width: '60px',
  searchList: []
}, {
  title: '审核人',
  field: 'verifyOpterName',
  searchable: false,
  width: '60px',
  searchList: []
}, {
  title: '审核人',
  field: 'verifyOpterCode',
  notColumn: true,
  searchable: true,
  width: '60px',
  searchList: []
}, {
  title: '审核时间',
  field: 'verifyTime',
  width: '80px'
}, {
  title: '审核时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'verifyStartTime',
  field1: 'verifyEndTime'
}, {
  title: '备注',
  field: 'verifyRemark',
  width: '120px'
}, {
  title: '审核状态',
  fixed: 'right',
  field: 'verifyState2',
  width: '100px',
  searchable: false,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '0'
  }),
  filter: statusFilter
}, {
  title: '审核状态',
  field: 'verifyState',
  searchable: true,
  notColumn: true,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '0'
  })
}];
export var columns5 = [{
  title: '交易分院',
  field: 'earId',
  notColumn: true,
  searchable: true,
  formatter: null,
  width: '80px',
  searchList: []
}, {
  title: '交易分院',
  field: 'earName',
  width: '80px'
}, {
  title: '提交时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'startDate',
  field1: 'endDate'
}, {
  title: '提交时间',
  field: 'cpyZptdate',
  width: '140px'
}, {
  title: '客户姓名',
  field: 'cpyCtmname',
  searchable: true,
  width: '140px'
}, {
  title: '客户卡号',
  field: 'cpyCtmcode',
  searchable: true,
  width: '140px'
}, {
  title: '药品名称',
  field: 'cpyZptName',
  width: '140px'
}, {
  title: '数量',
  field: 'cpyTal',
  width: '120px'
}, {
  title: '规格',
  field: 'cpyOrder',
  width: '120px'
}, {
  title: '单位',
  field: 'cpyUnitName',
  width: '120px'
}, {
  title: '已付金额',
  field: 'cpyPay',
  width: '120px'
}, // {title: '处方医生', field: 'cpyEmpcode1',  notColumn: true, searchable: true,   width: '120px', searchList:[]},
{
  title: '处方医生',
  field: 'cpyEmpcode1Name',
  searchable: false,
  width: '120px',
  searchList: []
}, {
  title: '审核药师',
  field: 'cpyYblstate2',
  searchable: false,
  width: '120px',
  searchList: []
}, {
  title: '审核药师',
  field: 'cpyYblstate2',
  notColumn: true,
  searchable: true,
  width: '120px',
  searchList: []
}, {
  title: '审核时间',
  field: 'cpyYblstate4',
  width: '80px'
}, {
  title: '审核时间',
  searchable: true,
  operate: 'dateRange',
  notColumn: true,
  field: 'verifyStartDate',
  field1: 'verifyEndDate'
}, {
  title: '备注',
  field: 'cpyRemark',
  width: '120px'
}, {
  title: '审核状态',
  fixed: 'right',
  field: 'cpyYblstate3',
  width: '100px',
  searchable: false,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '1'
  }),
  filter: statusFilter
}, {
  title: '审核状态',
  field: 'verfyState',
  searchable: true,
  notColumn: true,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '1'
  })
}];
export var columns6 = [{
  title: '交易分院',
  field: 'earId',
  searchable: true,
  formatter: null,
  width: '80px',
  searchList: []
}, {
  title: '提交时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'createStartTime',
  field1: 'createEndTime'
}, {
  title: '提交时间',
  field: 'createTime',
  width: '140px'
}, {
  title: '客户姓名',
  field: 'cpyCtmname',
  searchable: true,
  width: '140px'
}, {
  title: '客户卡号',
  field: 'cpyCtmcode',
  searchable: true,
  width: '140px'
}, {
  title: '药品名称',
  field: 'zptName',
  width: '140px'
}, {
  title: '次数',
  field: 'num',
  width: '120px'
}, {
  title: '规格',
  field: 'cpyOrder',
  width: '120px'
}, {
  title: '单位',
  field: 'cpyUnitName',
  width: '120px'
}, {
  title: '已付金额',
  field: 'amount',
  width: '120px'
}, {
  title: '处方医生',
  field: 'cpyEmpcode1Name',
  searchable: false,
  width: '120px',
  searchList: []
}, {
  title: '提交人',
  field: 'opterCode',
  notColumn: true,
  searchable: true,
  width: '120px',
  searchList: []
}, {
  title: '提交人',
  field: 'opterName',
  searchable: false,
  width: '120px',
  searchList: []
}, {
  title: '审核药师',
  field: 'verifyOpterName',
  searchable: false,
  width: '120px',
  searchList: []
}, {
  title: '审核人',
  field: 'verifyOpterCode',
  notColumn: true,
  searchable: true,
  width: '120px',
  searchList: []
}, {
  title: '审核时间',
  field: 'verifyTime',
  width: '80px'
}, {
  title: '审核时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'verifyStartTime',
  field1: 'verifyEndTime'
}, {
  title: '备注',
  field: 'verifyRemark',
  width: '120px'
}, {
  title: '审核状态',
  fixed: 'right',
  field: 'verifyState2',
  width: '100px',
  searchable: false,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '0'
  }),
  filter: statusFilter
}, {
  title: '审核状态',
  field: 'verifyState',
  searchable: true,
  notColumn: true,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '0'
  })
}];
export var columns7 = [{
  title: '交易分院',
  field: 'earId',
  // notColumn: true,
  searchable: true,
  width: '120px',
  searchList: [],
  formatter: null
}, {
  title: '营业日期',
  fixed: 'left',
  field: 'time',
  searchable: false,
  width: '95px'
}, // {title: '销售单号', field: 'salesNo',  searchable: true, width: '200px'},
// {
//   title: "收费单号",
//   fixed: "left",
//   field: "consumeNo",
//
//   searchable: true,
//   width: "150px",
// },
{
  title: '客户姓名',
  fixed: 'left',
  field: 'ctpCtmname',
  width: '80px'
}, {
  title: '客户卡号',
  field: 'ctpCtmcode',
  fixed: 'left',
  width: '120px'
}, {
  title: '提交时间',
  fixed: 'left',
  field: 'createTime',
  width: '95px'
}, // {
//   title: '业务类型',
//   fixed: 'left',
//   field: 'verifyType',
//
//   width: '120px',
// },
// { title: '项目科室', field: 'dptName', width: '120px' },
{
  title: '收款说明',
  field: 'remark',
  width: '120px'
}, // { title: '本单应收', field: 'payAmt', width: '120px' },
{
  title: '收款金额',
  field: 'amount',
  width: '100px'
}, {
  title: '使用积分',
  field: 'coupInt',
  width: '100px'
}, {
  title: '券额',
  field: 'coupAmt',
  width: '100px'
}, {
  title: '券类型',
  field: 'coupType',
  searchList: [],
  formatter: null,
  width: '120px'
}, {
  title: '审核说明',
  field: 'verifyRemark',
  width: '80px'
}, {
  title: ' 审核状态',
  field: 'verifyState',
  width: '80px',
  filter: statusFilter,
  fixed: 'right'
}, {
  title: '操作员',
  field: 'opterName',
  width: '80px'
} // { title: '备注', field: 'ctrRemark', width: '120px' },
];
export var columns8 = [{
  title: '交易分院',
  field: 'earId',
  // notColumn: true,
  searchable: true,
  width: '120px',
  searchList: [],
  formatter: null
}, {
  title: '成交时间',
  fixed: 'left',
  field: 'time',
  searchable: false,
  width: '95px'
}, // {title: '销售单号', field: 'salesNo',  searchable: true, width: '200px'},
// {
//   title: "收费单号",
//   fixed: "left",
//   field: "consumeNo",
//
//   searchable: true,
//   width: "150px",
// },
{
  title: '客户姓名',
  fixed: 'left',
  field: 'ctpCtmname',
  width: '80px'
}, {
  title: '客户卡号',
  field: 'ctpCtmcode',
  fixed: 'left',
  width: '120px'
}, {
  title: '提交时间',
  fixed: 'left',
  field: 'createTime',
  width: '95px'
}, {
  title: '项目/产品名称',
  field: 'zptName',
  fixed: 'left',
  type: 'text',
  width: '120px'
}, {
  title: '次数/数量',
  field: 'num',
  type: 'text',
  width: '100px'
}, {
  title: '(现金/卡)付',
  field: 'amount',
  type: 'text',
  width: '100px'
}, {
  title: '适应症一级',
  field: 'zptPasymptom',
  type: 'text',
  width: '100px'
}, {
  title: '适应症二级',
  field: 'zptSymptom',
  type: 'text',
  width: '100px'
}, {
  title: '平台',
  field: 'plate',
  type: 'text',
  width: '100px',
  formatter: function formatter(value, row) {
    return row.ctmPlantformList.map(function (v) {
      return v['plantform'];
    }).join(',');
  }
}, {
  title: '平台金额',
  field: 'zptSymptom',
  type: 'text',
  width: '100px',
  formatter: function formatter(value, row) {
    return row.ctmPlantformList.map(function (v) {
      return v['plantformAmount'];
    }).join(',');
  }
}, {
  title: '核消码',
  field: 'zptSymptom',
  type: 'text',
  width: '100px',
  formatter: function formatter(value, row) {
    return row.ctmPlantformList.map(function (v) {
      return v['hxm'];
    }).join(',');
  }
}, {
  title: '审核说明',
  field: 'verifyRemark',
  width: '80px'
}, {
  title: ' 审核状态',
  field: 'verifyState',
  width: '80px',
  filter: statusFilter,
  fixed: 'right'
}, {
  title: '操作员',
  field: 'opterName',
  width: '80px'
} // { title: '备注', field: 'ctrRemark', width: '120px' },
];
export var columns9 = [{
  title: '交易分院',
  field: 'earId',
  // notColumn: true,
  searchable: true,
  width: '60px',
  searchList: [],
  formatter: null,
  fixed: 'left'
}, {
  title: '客户姓名',
  fixed: 'left',
  field: 'ctpCtmname',
  width: '60px'
}, {
  title: '客户卡号',
  field: 'ctpCtmcode',
  fixed: 'left',
  width: '60px'
}, {
  title: '来院时间',
  fixed: 'left',
  field: 'ctfInTime',
  width: '80px'
}, {
  title: '提交时间',
  field: 'createTime',
  fixed: 'left'
}, {
  title: '来院目的',
  field: 'ctfLyMd'
}, // { title: '执行医生', field: 'ctfZxDoctor', width: '60px', searchList: [] },
// { title: '执行护理师', field: 'ctfZxHls', width: '60px', searchList: [] },
{
  title: '铺垫项目',
  field: 'ctfQtype1',
  width: '60px'
}, {
  title: '剩余项目/金额',
  field: 'ctfSyProject',
  type: 'text',
  width: '100px'
}, {
  title: '咨询沟通情况',
  field: 'ctfRemark',
  type: 'text',
  width: '180px'
}, {
  title: '美容师沟通情况',
  field: 'ctfMrsRemark',
  type: 'text',
  width: '180px'
}, {
  title: '审核说明',
  field: 'verifyRemark',
  width: '80px'
}, {
  title: ' 审核状态',
  field: 'verifyState',
  width: '80px',
  filter: statusFilter,
  fixed: 'right'
}, {
  title: '操作员',
  field: 'opterName',
  width: '80px'
} // { title: '备注', field: 'ctrRemark', width: '120px' },
];
export var columns20 = [{
  title: '客户卡号',
  field: 'ctpCtmcode',
  searchable: true,
  width: '100px'
}, {
  title: '客户姓名',
  field: 'ctpCtmname',
  searchable: true,
  width: '80px'
}, {
  title: '交易分院',
  field: 'payEarName',
  width: '80px'
}, {
  title: '项目/产品名称',
  field: 'zptName',
  width: '80px'
}, {
  title: '成交时间',
  field: 'time',
  width: '120px'
}, {
  title: '剩余次数',
  field: 'num',
  width: '80px'
}, {
  title: '(现金/卡/存款)余额',
  field: 'amount',
  width: '120px'
}, {
  title: '截止时间',
  field: 'ctpFnsdate',
  width: '120px'
}, {
  title: '提交分院',
  field: 'earId',
  searchable: true,
  formatter: null,
  width: '80px',
  searchList: []
}, {
  title: '提交时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'createStartTime',
  field1: 'createEndTime'
}, {
  title: '提交时间',
  field: 'createTime',
  width: '120px'
}, {
  title: '提交人',
  field: 'opterName',
  searchable: false,
  width: '80px'
}, {
  title: '审核时间',
  field: 'verifyTime',
  width: '80px'
}, {
  title: '审核时间',
  searchable: true,
  notColumn: true,
  operate: 'dateRange',
  field: 'verifyStartTime',
  field1: 'verifyEndTime'
}, {
  title: '备注',
  field: 'verifyRemark',
  width: '120px'
}, {
  title: '审核状态',
  field: 'verifyState',
  searchable: true,
  searchList: getCerifyStateList({
    title: '审核中',
    field: '0'
  }),
  filter: statusFilter
}];