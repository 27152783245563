//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    exportItem: {
      type: Object
    }
  },
  data: function data() {
    return {};
  }
};