import request from '@/utils/request'; // 用户登录

export function login(data) {
  return request({
    url: '/manager/user/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/vue-element-admin/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  });
} // 修改密码

export function changePassword(data) {
  return request({
    url: '/manager/user/changePassword',
    method: 'post',
    data: data
  });
}