/*
 * @Author: yangchao
 * @Date: 2021-08-24 10:39:40
 * @LastEditors: yangchao
 * @LastEditTime: 2021-08-27 09:13:29
 * @Description: 运营报表Route
 */
import Layout from '@/layout';
var courtRoute = {
  path: '/court',
  component: Layout,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/operate/court');
    },
    name: 'Court',
    hidden: true,
    meta: {
      title: '分院报表',
      icon: 'clipboard',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'age',
    component: function component() {
      return import('@/views/operate/age');
    },
    name: 'Age',
    hidden: true,
    meta: {
      title: '年龄分析报表',
      icon: 'clipboard',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'source',
    component: function component() {
      return import('@/views/operate/source');
    },
    name: 'OpeSource',
    hidden: true,
    meta: {
      title: '来源分析报表',
      icon: 'clipboard',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'sourceEar',
    component: function component() {
      return import('@/views/operate/sourceEar');
    },
    name: 'OpeSource',
    hidden: true,
    meta: {
      title: '来源-分院分析报表',
      icon: 'clipboard',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'consult',
    component: function component() {
      return import('@/views/operate/consult');
    },
    name: 'Consult',
    hidden: true,
    meta: {
      title: '咨询师分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'beautician',
    component: function component() {
      return import('@/views/operate/beautician');
    },
    name: 'Beautician',
    hidden: true,
    meta: {
      title: '美容师分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'nurse',
    component: function component() {
      return import('@/views/operate/nurse');
    },
    name: 'Nurse',
    hidden: true,
    meta: {
      title: '护理师分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'consultGz',
    component: function component() {
      return import('@/views/operate/consultGz');
    },
    name: 'ConsultGz',
    hidden: true,
    meta: {
      title: '咨询师分析报表-公账',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'beauticianGz',
    component: function component() {
      return import('@/views/operate/beauticianGz');
    },
    name: 'BeauticianGz',
    hidden: true,
    meta: {
      title: '美容师分析报表-公账',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'nurseGz',
    component: function component() {
      return import('@/views/operate/nurseGz');
    },
    name: 'NurseGz',
    hidden: true,
    meta: {
      title: '护理师分析报表-公账',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'exper',
    component: function component() {
      return import('@/views/operate/exper');
    },
    name: 'Exper',
    hidden: true,
    meta: {
      title: '项目体验分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'oneExper',
    component: function component() {
      return import('@/views/operate/oneExper');
    },
    name: 'oneExper',
    hidden: true,
    meta: {
      title: '一级项目体验分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'secondExper',
    component: function component() {
      return import('@/views/operate/secondExper');
    },
    name: 'secondExper',
    hidden: true,
    meta: {
      title: '二级项目体验分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'worth',
    component: function component() {
      return import('@/views/operate/worth');
    },
    name: 'Worth',
    hidden: true,
    meta: {
      title: '项目价值分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'moult',
    component: function component() {
      return import('@/views/operate/moult');
    },
    name: 'Moult',
    hidden: true,
    meta: {
      title: '脱毛客户分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'oneMoult',
    component: function component() {
      return import('@/views/operate/oneMoult');
    },
    name: 'oneMoult',
    hidden: true,
    meta: {
      title: '一级脱毛客户分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'secondMoult',
    component: function component() {
      return import('@/views/operate/secondMoult');
    },
    name: 'secondMoult',
    hidden: true,
    meta: {
      title: '二级脱毛客户分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'threeMoult',
    component: function component() {
      return import('@/views/operate/threeMoult');
    },
    name: 'threeMoult',
    hidden: true,
    meta: {
      title: '三级脱毛客户分析报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'project',
    component: function component() {
      return import('@/views/operate/project');
    },
    name: 'ProjectOpe',
    hidden: true,
    meta: {
      title: '项目报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'secondProject',
    component: function component() {
      return import('@/views/operate/secondProject');
    },
    name: 'secondProject',
    hidden: true,
    meta: {
      title: '二级项目报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'threeProject',
    component: function component() {
      return import('@/views/operate/project');
    },
    name: 'threeProject',
    hidden: true,
    meta: {
      title: '三级项目报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'secondSource',
    component: function component() {
      return import('@/views/operate/secondSource');
    },
    name: 'secondSource',
    hidden: true,
    meta: {
      title: '来源情况报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'earList',
    component: function component() {
      return import('@/views/operate/earOprate');
    },
    name: 'EarList',
    hidden: true,
    meta: {
      title: '一级项目->分院报表',
      keepAlive: true,
      roles: ['admin']
    }
  }, {
    path: 'earTwoList',
    component: function component() {
      return import('@/views/operate/earTwoOprate');
    },
    name: 'EarTwoList',
    hidden: true,
    meta: {
      title: '二级项目->分院报表',
      keepAlive: true,
      roles: ['admin']
    }
  }]
};
export default courtRoute;