import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { customerList } from '@/api/customer';
export default {
  props: {
    temp: {
      type: Object
    },
    customer: {
      type: Object
    },
    departmentList: {
      type: Array
    },
    payTypeList: {
      type: Array
    },
    paymentList: {
      type: Array
    },
    employees: {
      type: Array
    },
    height: {},
    approvalInfo: {
      type: Object
    }
  },
  watch: {},
  data: function data() {
    return {
      rules: {
        remark: [{
          required: true,
          trigger: 'blur',
          message: '审批备注不能为空'
        }]
      },
      itemParams: {},
      setCard: true,
      advisers: '',
      diagnosis: '',
      cardTypeSelect: 'select',
      listQuery: {
        pageNum: 1,
        pageSize: 10
      },
      ctmStoamt: 0
    };
  },
  created: function created() {
    var _this = this;

    this.itemParams = JSON.parse(this.temp.requestParams);

    if (this.itemParams.advisers.length > 0) {
      this.itemParams.advisers.forEach(function (item) {
        _this.advisers += item.empName + ',';
      });
    }

    if (this.itemParams.diagnosis.length > 0) {
      this.itemParams.diagnosis.forEach(function (item) {
        _this.diagnosis += item.empName + ',';
      });
    }

    this.getPay();
  },
  methods: {
    getPay: function getPay() {
      var _this2 = this;

      this.listQuery.ctmCode = this.temp.ctpCtmcode;
      customerList(this.listQuery).then(function (res) {
        if (res.data && res.data.list.length > 0) {
          console.log('0', res.data.list[0]);
          var ctmStoamt = res.data.list[0].ctmStoamt;
          console.log('ctmStoamt', ctmStoamt);
          _this2.ctmStoamt = ctmStoamt ? ctmStoamt : 0;
        }
      });
    }
  }
};