var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      style: { height: _vm.height + "px", overflow: "scroll" },
    },
    [
      _c("VxeTable", {
        ref: "table",
        attrs: {
          temp: _vm.customer,
          list: _vm.list,
          height: _vm.height - 40,
          "list-query": _vm.listQuery,
          columns: _vm.columns,
        },
        on: { click: _vm.handleClick },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottomBtn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", plain: "", type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible1 = true
                },
              },
            },
            [_vm._v("项目")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", plain: "", type: "success" },
              on: { click: _vm.setcat2 },
            },
            [_vm._v("套餐")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", plain: "", type: "success" },
              on: { click: _vm.setcat },
            },
            [_vm._v("零购产品")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", plain: "", type: "success" },
              on: { click: _vm.showYj },
            },
            [_vm._v("业绩分配")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", plain: "", type: "success" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible5 = true
                },
              },
            },
            [_vm._v("库存查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: {
                size: "mini",
                plain: "",
                type: "danger",
                disabled: !_vm.temp,
              },
              on: { click: _vm.handleDel },
            },
            [_vm._v("\n      删除\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleDone },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "产品列表",
                visible: _vm.dialogVisible1,
                "append-to-body": "",
                top: "50px",
                "close-on-click-modal": false,
                center: "",
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("cp-table", {
                ref: "dialog1",
                attrs: { height: _vm.innerHeight - 150 },
                on: { done: _vm.handleSelect1 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible2
        ? _c("el-dialog", {
            directives: [
              { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
            ],
            attrs: {
              title: "套餐",
              visible: _vm.dialogVisible2,
              "append-to-body": "",
              top: "50px",
              "close-on-click-modal": false,
              center: "",
              width: "60%",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVisible2 = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible3
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "零购产品",
                visible: _vm.dialogVisible3,
                "append-to-body": "",
                top: "50px",
                "close-on-click-modal": false,
                center: "",
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible3 = $event
                },
              },
            },
            [
              _c("lg-table", {
                ref: "dialog1",
                attrs: { height: _vm.innerHeight - 120 },
                on: { done: _vm.handleSelect3 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible5
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "产品库存列表",
                visible: _vm.dialogVisible5,
                "append-to-body": "",
                top: "50px",
                "close-on-click-modal": false,
                center: "",
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible5 = $event
                },
              },
            },
            [
              _c("kc-table", {
                ref: "dialog1",
                attrs: { height: _vm.innerHeight - 120 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible7
        ? [
            _c("YjDialog", {
              ref: "parfomanceSet",
              attrs: {
                operate: "add",
                customer: _vm.customer,
                ProjectItem: _vm.projectItem,
                advisersT: _vm.advisersT,
                diagnosisT: _vm.diagnosisT,
              },
              on: {
                close: function ($event) {
                  _vm.dialogVisible7 = false
                },
                setItemProject: _vm.setItemProject,
                setPay: _vm.getPay,
                empChangeSuccess: function ($event) {
                  _vm.dialogVisible7 = false
                },
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible
        ? [
            _c("sh-dialog", {
              ref: "exchangeAddOrEdit",
              attrs: {
                operate: "add",
                pidCode: _vm.pidCode,
                project: _vm.projectList,
                callInfo: _vm.callInfo,
                customer: _vm.customer,
                checkType: "shType",
              },
              on: {
                close: function ($event) {
                  _vm.dialogVisible = false
                },
                done: _vm.handleDone1,
                empChangeSuccess: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }