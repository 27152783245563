var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-container fixed_table",
        style: { height: _vm.height + "px", overflow: "scroll" },
      },
      [
        _c(
          "el-container",
          [
            _c(
              "el-header",
              { attrs: { height: "auto" } },
              [
                _c("base-header", {
                  attrs: {
                    actions: _vm.actions,
                    temp: _vm.customer,
                    "list-query": _vm.listQuery,
                    selectColumns: _vm.columns,
                    columns: _vm.columns,
                  },
                  on: { action: _vm.handleAction, search: _vm.handleRefresh },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                _c("base-table", {
                  ref: "table",
                  attrs: {
                    index: "",
                    temp: _vm.customer,
                    list: _vm.list,
                    total: _vm.total,
                    height: _vm.height - 145,
                    "list-query": _vm.listQuery,
                    columns: _vm.columns,
                    pagination: "",
                  },
                  on: {
                    click: _vm.handleClick,
                    dblclick: _vm.handleDblclick,
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bottomBtn" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.handleSure },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }