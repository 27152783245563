//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import EditItem from './EditItem';
import { isArray } from '@/utils/validate';
export default {
  name: 'VxeItemText',
  components: {},
  props: {
    column: {
      type: Object
    },
    row: {
      type: Object
    },
    summaryMethod: {}
  },
  methods: {
    cellMousIn: function cellMousIn(column, row) {
      if (column.cellMousIn) {
        column.cellMousIn(column, row);
      }
    }
  },
  data: function data() {
    return {
      myIsArray: isArray
    };
  }
};