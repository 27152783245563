var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      style: { height: _vm.height + "px", "margin-top": "10px" },
    },
    [
      _c(
        "el-form",
        {
          attrs: {
            inline: true,
            model: _vm.customer,
            size: "mini",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "1) 选择项目行号", prop: "ctfCtmcode" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "90px" },
                model: {
                  value: _vm.customer.ctfCtmcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "ctfCtmcode", $$v)
                  },
                  expression: "customer.ctfCtmcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "2) 该次划扣次数", prop: "ctfCtmname" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "90px" },
                model: {
                  value: _vm.customer.ctfCtmname,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "ctfCtmname", $$v)
                  },
                  expression: "customer.ctfCtmname",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "3) 该项目下次隔",
                prop: "ctfCtmname",
                "label-width": "110px",
              },
            },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 10, label: "描述文字" },
                model: {
                  value: _vm.customer.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "num", $$v)
                  },
                  expression: "customer.num",
                },
              }),
              _vm._v("\n      天跟进\n      "),
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.customer.checked,
                    callback: function ($$v) {
                      _vm.$set(_vm.customer, "checked", $$v)
                    },
                    expression: "customer.checked",
                  },
                },
                [_vm._v("不做跟进")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "跟进人员:", "label-width": "75px" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100px" },
                  attrs: { filterable: "" },
                  model: {
                    value: _vm.customer.ctfOpter,
                    callback: function ($$v) {
                      _vm.$set(_vm.customer, "ctfOpter", $$v)
                    },
                    expression: "customer.ctfOpter",
                  },
                },
                _vm._l(_vm.ctfZdDocList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.display_name, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "说明:", prop: "idCard" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "40vw" },
                attrs: { type: "textarea", rows: 3 },
                model: {
                  value: _vm.customer.idCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "idCard", $$v)
                  },
                  expression: "customer.idCard",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { prop: "idCard", "label-width": "90px" },
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.customer.sms,
                    callback: function ($$v) {
                      _vm.$set(_vm.customer, "sms", $$v)
                    },
                    expression: "customer.sms",
                  },
                },
                [_vm._v("术后短信定制(定制后由服务器发送)")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-table", {
        ref: "table",
        attrs: {
          temp: _vm.customer,
          list: _vm.list,
          height: _vm.height / 2 - 50,
          "list-query": _vm.listQuery,
          columns: _vm.columns,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottomBtn" },
        [
          _vm.customer.sms
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", plain: "" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("增加短信")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.customer.sms
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { size: "mini", plain: "", type: "danger" },
                  on: { click: _vm.handleDel },
                },
                [_vm._v("\n      删除短信\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { size: "mini", plain: "", type: "success" },
            },
            [_vm._v("\n      确定\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { size: "mini", plain: "", type: "warning" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v("\n      取消\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }