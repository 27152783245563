import "core-js/modules/web.dom.iterable";
import _objectSpread from "/root/.jenkins/workspace/zsyl.ymh5.beta/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { billDelete } from '@/api/stock';
import BaseHeader from "../components/BaseHeader.vue";
import BaseTable from "../components/BaseTable.vue";
import DjTable from "./dj-table";
import { mapGetters } from 'vuex';
import { billPage, billItem, billRecovery } from '@/api/cash';
import bus from "../components/bus";
import moment from 'moment';
export default {
  name: 'KhTable',
  components: {
    BaseHeader: BaseHeader,
    BaseTable: BaseTable,
    DjTable: DjTable
  },
  props: {
    height: {
      required: true
    },
    ctmCode: {
      type: String
    },
    earId: {
      required: true,
      type: String
    },
    checkItem: {
      type: Object
    },
    approvalInfo: {
      type: Object
    }
  },
  filters: {
    statusFilter: function statusFilter(status, type) {
      var map = {
        USE: {
          type: 'primary',
          title: '有效'
        },
        STP: {
          type: 'warning',
          title: '作废'
        }
      };
      return map[status] ? map[status][type] : status;
    }
  },
  computed: _objectSpread({}, mapGetters(['employees', 'rightWidth', 'empCode', 'eareList'])),
  watch: {},
  data: function data() {
    return {
      customer: {},
      activeName: '1',
      rules: {
        cpyRmamt: [{
          required: true,
          message: '请输入还款金额',
          trigger: 'blur'
        }],
        cpyCardamt1: [{
          required: true,
          message: '请输入卡1金额',
          trigger: 'blur'
        }],
        cpyCardamt2: [{
          required: true,
          message: '请输入卡2金额',
          trigger: 'blur'
        }]
      },
      actions: [{
        title: '刷新',
        icon: 'el-icon-refresh',
        type: ''
      }, {
        title: '作废',
        icon: 'el-icon-delete',
        type: 'danger',
        enable: this.getEnable
      }, {
        title: '开票',
        icon: 'el-icon-coin',
        type: 'primary'
      } // {title:'恢复',icon:'el-icon-trophy', type:'success', enable: this.getEnable2},
      ],
      curCell: {},
      list1: [],
      list2: [],
      form: {
        cpyRmamt: 0,
        cpyCardamt1: 0,
        cpyCardamt2: 0
      },
      customer2: {},
      listLoading: false,
      dialogVisible1: false,
      dialogVisible2: false,
      payAllDisabled: false,
      total: 0,
      total2: 0,
      temp: {},
      rvTypes: [],
      columns1: [],
      columns2: [],
      filter: false,
      payTypeList: [{
        title: '现金支付',
        field: 'A'
      }, {
        title: '刷卡支付',
        field: 'B'
      }],
      //  statusList: [
      //   {field: 'USE', title: '有效'},
      //   {field: 'STP', title: '作废'},
      // ],
      innerHeight: 540,
      listQuery: {
        pageNum: 1,
        pageSize: 15
      },
      listQuery2: {
        pageNum: 1,
        pageSize: 100
      }
    };
  },
  created: function created() {
    var _this = this;

    if (this.checkItem) {
      this.actions = [{
        title: '提交审核',
        icon: 'el-icon-check',
        type: 'danger',
        enable: function enable() {
          return _this.customer.verifyState == '4';
        }
      }];
    }

    this.innerHeight = $(window).height();
    this.columns1 = [{
      title: '起始日期',
      field: 'ctbDateBegin',
      field1: 'ctbDateEnd',
      operate: 'dateRange',
      notColumn: true,
      searchable: true,
      width: '120px'
    }, {
      title: '分院',
      field: 'earId',
      searchable: true,
      width: '140px',
      notColumn: true,
      searchList: this.eareListFilter()
    }, {
      title: '开票日期',
      field: 'ctbDate',
      searchable: false,
      width: '120px'
    }, {
      title: '开票单号',
      field: 'ctbCode',
      searchable: true,
      width: '120px'
    }, {
      title: '客户卡号',
      field: 'ctbCtmcode',
      width: '120px',
      searchable: true
    }, {
      title: '客户姓名',
      field: 'ctbCtmname',
      notColumn: true,
      width: '80px',
      searchable: true
    }, {
      title: '客户姓名',
      field: 'ctbCtmname',
      width: '80px',
      searchable: false
    }, {
      title: '发票号码',
      field: 'ctbCtmname',
      width: '120px'
    }, {
      title: '发票代码',
      field: 'ctbTaxcode',
      width: '120px'
    }, {
      title: '发票抬头',
      field: 'ctbInfo',
      width: '80px'
    }, {
      title: '开票金额',
      field: 'ctbAmt',
      width: '80px'
    }, // {title:'单据状态',field:'ctbStatus',width:'80px',searchable:true,searchList: this.statusList, filter:this.$options.filters.statusFilter},
    {
      title: '说明',
      field: 'ctbRemark',
      width: '120px'
    }];
    this.columns2 = [// {title:'账单状态',field:'acStatus',width:'80px',filter:this.$options.filters.statusFilter},
    {
      title: '营业日期',
      field: 'cpyDate',
      searchable: true,
      width: '95px'
    }, {
      title: '销售单号',
      field: 'cpyPageid',
      width: '80px'
    }, // {title:'住院号',field:'cpyStayno',width:'180px'},
    {
      title: '发票号码',
      field: 'ctbTaxid',
      width: '120px'
    }, {
      title: '项目/产品名称',
      field: 'cpyZptname',
      width: '120px'
    }, {
      title: '规格',
      field: 'cpyOrder',
      width: '120px'
    }, {
      title: '单位',
      field: 'cpyUnit',
      width: '120px'
    }, {
      title: '数量',
      field: 'cpyNum',
      width: '120px'
    }, {
      title: '收款金额',
      field: 'cpyPayamt',
      width: '120px'
    }, {
      title: '其中现金',
      field: 'cpyRmamt',
      width: '120px'
    }, {
      title: '其中刷卡',
      field: 'cpyCardamt',
      width: '120px'
    }, {
      title: '存款支付',
      field: 'cpyStoamt',
      searchable: true,
      width: '120px'
    }, {
      title: '消耗金额',
      field: 'cpyUseMonAmt',
      searchable: true,
      width: '120px'
    }, {
      title: '开票金额',
      field: 'cpyTaxamt',
      width: '120px'
    }];
    this.getlist1();
  },
  mounted: function mounted() {
    var that = this;
    bus.$on('tips', function (msg) {
      console.log('接收到关闭消息', msg);

      if (msg == false) {
        console.log('关闭当前弹窗');
        that.dialogVisible1 = false;
        that.getlist1();
      }
    });
  },
  methods: {
    handleAction: function handleAction(index) {
      console.log('this.checkItem', this.checkItem);

      if (this.checkItem) {
        this.$emit('sureCheck');
      } else {
        switch (index) {
          case 0:
            this.handleRefresh();
            break;

          case 1:
            this.handleDel();
            break;

          case 2:
            console.log('开票');
            this.dialogVisible1 = true;
            break;

          case 3:
            this.handleShitf();
            break;
        }
      }
    },
    handleShitf: function handleShitf() {
      var _this2 = this;

      this.$confirm('确定要恢复该票据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // this.customer.dacStatus	= 'FNS'
        billRecovery({
          ctbCodes: _this2.customer.ctbCode
        }).then(function (res) {
          if (res.code == 200) {
            _this2.$message({
              type: 'success',
              message: '恢复成功!',
              onClose: function onClose() {
                _this2.getlist1();

                _this2.getlist2();
              }
            });
          }
        });
      });
    },
    handleDel: function handleDel() {
      var _this3 = this;

      this.$confirm('该发票确定作废吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log('当前选中code', _this3.customer.ctbCode);
        billDelete({
          ctbCodes: _this3.customer.ctbCode
        }).then(function (response) {
          _this3.listLoading = false;

          if (response.code == 200) {
            _this3.$message({
              type: 'success',
              message: '发票作废成功!',
              onClose: function onClose() {
                _this3.getlist1();

                _this3.getlist2();
              }
            });
          }
        });
      }).catch(function () {});
    },
    setClear: function setClear() {
      this.$refs.form.resetFields();
    },
    handleCancel: function handleCancel() {
      this.dialogVisible2 = false;
    },
    getEnable: function getEnable() {
      return this.customer.ctbStatus === 'STP';
    },
    getEnable2: function getEnable2() {
      return this.customer.ctbStatus === 'USE';
    },
    handleSearch1: function handleSearch1() {
      this.getlist1();
    },
    handleSearch2: function handleSearch2() {
      this.getlist2();
    },
    getlist1: function getlist1() {
      var _this4 = this;

      this.list1Loading = true;
      this.listQuery.ctbDateBegin = this.listQuery.ctbDateBegin ? moment(this.listQuery.ctbDateBegin).format('YYYY-MM-DD 00:00:00') : null;
      this.listQuery.ctbDateEnd = this.listQuery.ctbDateEnd ? moment(this.listQuery.ctbDateEnd).format('YYYY-MM-DD 23:59:59') : null;
      this.listQuery.ctbStatus = 'USE';
      billPage(this.listQuery).then(function (response) {
        console.log('response', response);
        _this4.list1Loading = false;

        if (response.data && response.data.list) {
          var list1 = response.data.list;

          if (list1.length > 0) {
            list1.forEach(function (item) {
              if (item.ctbStatus == 'USE') {
                item.dacDisacc2 = 0;
              } else {
                item.dacDisacc2 = item.dacDisacc - item.dacPaytol;
              }
            });
          }

          _this4.list1 = list1;
          _this4.total = response.data.total;

          if (_this4.list1.length > 0) {
            _this4.customer = JSON.parse(JSON.stringify(_this4.list1[0]));
          }
        }
      });
    },
    getlist2: function getlist2() {
      var _this5 = this;

      this.list1Loading = true;
      this.listQuery2.ctbCode = this.customer.ctbCode || this.checkItem.ctbCode; //this.listQuery2.cpyId = this.customer.dacCtmcode || JSON.parse(this.checkItem.requestParams).id

      console.log('当前选中的id', this.listQuery2.ctbCode); // if (this.customer.dacCtmcode) {

      billItem(this.listQuery2).then(function (response) {
        console.log('开票记录明细', response);
        _this5.list1Loading = false;

        if (response.data) {
          var list2 = response.data;
          _this5.list2 = list2;
          _this5.total2 = response.data.length;
          if (_this5.list2.length > 0) _this5.customer2 = JSON.parse(JSON.stringify(_this5.list2[0]));
        }
      });
    },
    handleClear: function handleClear() {
      this.listQuery = {
        ctmName: undefined,
        limit: 10,
        empStatus: '',
        ope: undefined,
        orderBy: undefined,
        pageNum: 1,
        pageSize: 100,
        parentEarId: undefined,
        start: 0,
        tel: undefined
      };
    },
    handleFilter: function handleFilter() {
      this.filter = !this.filter;
    },
    handleSure: function handleSure() {
      this.$emit('select', this.customer);
    },
    handleRefresh: function handleRefresh() {
      this.listQuery.pageNum = 1;
      this.list1 = [];
      this.list2 = [];
      this.getlist1();
    },
    handleClick: function handleClick(row) {
      this.customer = row;
      this.customer2 = {};
      this.getlist2();
    },
    handleClick2: function handleClick2(row) {
      this.customer2 = row;
    },
    handleDblclick: function handleDblclick(row) {
      this.customer = row;
      this.dialogVisible1 = true;
    }
  }
};