import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var UserInfo = 'userInfo';
var Common = 'common';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function setUserInfo(userInfo) {
  return Cookies.set(UserInfo, userInfo);
}
export function getUserInfo() {
  return Cookies.get(UserInfo);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function removeUserInfo() {
  return Cookies.remove(UserInfo);
}
export function getEarId() {
  var data = JSON.parse(getUserInfo());
  var earId = data.earId;
  return earId;
}