/*
 * @Author: yangchao
 * @Date: 2021-10-25 09:06:50
 * @LastEditors: yangchao
 * @LastEditTime: 2021-12-01 11:19:18
 * @Description:
 */
import request from '@/utils/request'; // 刷新间隔，有效消费金额，累计消费年限

export function getBaseConf(query) {
  return request({
    url: '/manager/rfmconf/getBaseConf',
    method: 'get',
    params: query
  });
} // 获取+更新 刷新间隔，有效消费金额，累计消费年限

export function UpdateBaseConf(query) {
  return request({
    url: '/manager/rfmconf/UpdateBaseConf',
    method: 'get',
    params: query
  });
} // 评分等级

export function rfmconfGetList(query) {
  return request({
    url: '/manager/rfmconf/getList',
    method: 'get',
    params: query
  });
} // 评分等级 修改or新增

export function addOrUpdate(data) {
  return request({
    url: '/manager/rfmconf/addOrUpdate',
    method: 'post',
    data: data
  });
} // 客户等级设置

export function getCusGradeConf(query) {
  return request({
    url: '/manager/rfmconf/getCusGradeConf',
    method: 'get',
    params: query
  });
} // 客户等级设置 改or新增

export function updateCusGradeConf(data) {
  return request({
    url: '/manager/rfmconf/updateCusGradeConf',
    method: 'post',
    data: data
  });
} // RFM表格的详细情况

export function getRfmDetailConf(query) {
  return request({
    url: '/manager/rfmconf/getRfmDetailConf',
    method: 'get',
    params: query
  });
} // RFM表格的详细情况 改or新增

export function updateRfmDetailConf(data) {
  return request({
    url: '/manager/rfmconf/updateRfmDetailConf',
    method: 'post',
    data: data
  });
} // 客户分群

export function getRfmCusType(query) {
  return request({
    url: '/manager/rfmconf/getRfmCusType',
    method: 'get',
    params: query
  });
} // 客户分群 改or新增

export function updateRfmCusType(data) {
  return request({
    url: '/manager/rfmconf/updateRfmCusType',
    method: 'post',
    data: data
  });
} // 客户分群删除

export function delRfmCusType(query) {
  return request({
    url: '/manager/rfmconf/delRfmCusType',
    method: 'get',
    params: query
  });
} // 其他的都是共用这

export function configDelete(query) {
  return request({
    url: '/manager/rfmconf/delete',
    method: 'get',
    params: query
  });
} // 其他的都是共用这

export function getCusRfmList(data) {
  return request({
    url: '/manager/rfm/getCusRfmList',
    method: 'post',
    data: data
  });
} // 手动重算

export function initRfm() {
  return request({
    url: '/manager/rfm/initRfm?code=init',
    method: 'get'
  });
} // 价值导出

export function getCusRfmListExport(data) {
  return request({
    url: '/manager/rfm/getCusRfmListExport',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 会员等级

export function getCusGrade() {
  return request({
    url: '/manager/rfmconf/getCusGrade',
    method: 'get'
  });
} // 会员等级-编辑

export function updateOrAddCusGrade(data) {
  return request({
    url: '/manager/rfmconf/updateOrAddCusGrade',
    method: 'post',
    params: data
  });
}
/**
 * @description  {客户存量分析}
 * @param {String} earId 分院
 * @param {String}
 * getAge:获取我的rfm计算值记录
 *   Bl: 获取客户留存分布,
 *   Hyd: 获取客户活跃度,
 *   M1: 获取客户累计消费金额,
 *   M2: 获取客户年均消费金额,
 *   R1: 获取客户近一年消费频次,
 *   R2: 获取客户累计有效消费品项,
 *
 * */

export function rfmAnalyse(data, urlItemStr) {
  var params = urlItemStr === 'getAge' ? urlItemStr : 'getCus' + urlItemStr;
  return request({
    url: "/manager/rfm/analyse/".concat(params),
    method: 'post',
    data: data
  });
}
export function rfmAnalyseNewold(data, urlItemStr) {
  // let params = urlItemStr === 'getAge' ? urlItemStr : 'getCus' + urlItemStr;
  return request({
    url: "/manager/rfm/analyse/newold/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //渠道分析（所有客户） ->渠道 VS 年龄段分析

export function rfmEpt(data, urlItemStr) {
  // let params = urlItemStr === 'getAge' ? urlItemStr : 'getCus' + urlItemStr;
  return request({
    url: "/manager/rfm/ept/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //年龄交叉

export function agevs(data, urlItemStr) {
  // let params = urlItemStr === 'getAge' ? urlItemStr : 'getCus' + urlItemStr;
  return request({
    url: "/manager/rfm/analyse/agevs/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //年龄交叉

export function getTaskList(query) {
  return request({
    url: "/manager/rfm/getTaskList",
    method: 'get',
    params: query
  });
} //年龄交叉

export function consumerEpt(data, urlItemStr) {
  return request({
    url: "/manager/rfm/ept/consumer/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //多维度交叉分析

export function dwdAnalyse(data, urlItemStr) {
  return request({
    url: "/manager/rfm/analyse/dwd/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //工作观察

export function ywdcAnalyse(data, urlItemStr) {
  return request({
    url: "/manager/rfm/analyse/ywdc/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //RFM分级报表

export function rfmmode(data, urlItemStr) {
  return request({
    url: "/manager/rfm/analyse/rfmmode/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} // 重点项目

export function importEpt(data, urlItemStr) {
  return request({
    url: "/manager/rfm/ept/import/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} // 等级vs指标

export function rfmmodeAnalyse(data, urlItemStr) {
  return request({
    url: "/manager/rfm/analyse/rfmmode/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} // RFM客户分级

export function getgrade(data) {
  return request({
    url: "/manager/rfm/analyse/rfmmode/getgrade",
    method: 'post',
    data: data
  });
} // 人群筛选

export function peopleAnalyse(data, urlItemStr) {
  return request({
    url: "/manager/rfm/analyse/people/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} // 员工

export function rfmmodeEmp(data, urlItemStr) {
  return request({
    url: "/manager/rfm/analyse/rfmmode/".concat(urlItemStr),
    method: 'post',
    data: data
  });
}
export function rfmcusclEmp(data) {
  return request({
    url: '/manager/rfm/analyse/rfmcuscl/getcl',
    method: 'post',
    data: data
  });
}
export function getclmoneyEmp(data) {
  return request({
    url: '/manager/rfm/analyse/rfmcuscl/getclmoney',
    method: 'post',
    data: data
  });
}
export function gethigh(data) {
  return request({
    url: '/manager/rfm/analyse/rfmmode/gethigh',
    method: 'post',
    data: data
  });
}
export function gethyd(data) {
  return request({
    url: '/manager/rfm/analyse/rfmmode/gethyd',
    method: 'post',
    data: data
  });
}
export function getcurrfmEmp(data) {
  return request({
    url: '/manager/rfm/analyse/rfmcuscl/getcurrfm',
    method: 'post',
    data: data
  });
}
export function getcurrfmEmpExport(data) {
  return request({
    url: '/manager/rfm/analyse/rfmcuscl/getcurrfm/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
}
export function getcuremprfm(data) {
  return request({
    url: '/manager/rfm/analyse/rfmcuscl/getcuremprfm',
    method: 'post',
    data: data
  });
}
export function getcuremprfmExport(data) {
  return request({
    url: '/manager/rfm/analyse/rfmcuscl/getcuremprfm/export',
    method: 'post',
    responseType: 'blob',
    data: data
  });
} // 品类品项

export function eptCate(data, urlItemStr) {
  return request({
    url: "/manager/rfm/ept/cate/".concat(urlItemStr),
    method: 'post',
    data: data
  });
} //map message

export function getEmpRel(query) {
  return request({
    url: "/manager/rfm/getEmpRel",
    method: 'get',
    params: query
  });
}