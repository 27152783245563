//
//
//
//
//
//
//
export default {
  name: 'CheckItem',
  props: {
    column: {
      type: Object
    }
  },
  render: function render(h) {
    var _this = this;

    var column = this.column;
    return h('el-checkbox', {
      props: {
        value: column.visible
      },
      on: {
        change: function change(value) {
          _this.$set(column, 'visible', value); // column.visible = value

        }
      }
    }, column.title);
  }
};