var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      style: {
        height: _vm.height + "px",
        "margin-top": "10px",
        "padding-bottom": "60px",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "mini",
          attrs: {
            rules: _vm.rules,
            inline: true,
            "show-message": false,
            model: _vm.temp,
            size: "mini",
            "label-width": "110px",
            "label-position": "right",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户卡号:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.ctpCtmcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "ctpCtmcode", $$v)
                  },
                  expression: "customer.ctpCtmcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.ctpCtmName,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "ctpCtmName", $$v)
                  },
                  expression: "customer.ctpCtmName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "项目名称:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.zptCodeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "zptCodeName", $$v)
                  },
                  expression: "customer.zptCodeName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "项目次数:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.ctpZptNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "ctpZptNum", $$v)
                  },
                  expression: "customer.ctpZptNum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "项目应付金额:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.cpyAccount,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "cpyAccount", $$v)
                  },
                  expression: "customer.cpyAccount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "剩余次数:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                attrs: { disabled: "" },
                model: {
                  value: _vm.customer.ctpSubnum,
                  callback: function ($$v) {
                    _vm.$set(_vm.customer, "ctpSubnum", $$v)
                  },
                  expression: "customer.ctpSubnum",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "转赠客户卡号:", prop: "ctmCode" } },
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                model: {
                  value: _vm.temp.ctmCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "ctmCode", $$v)
                  },
                  expression: "temp.ctmCode",
                },
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v("选择")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "转赠客户姓名:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "110px" },
                model: {
                  value: _vm.temp.giftCtmName,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "giftCtmName", $$v)
                  },
                  expression: "temp.giftCtmName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "转赠客户次数:", prop: "num" } },
            [
              _c("el-input", {
                staticStyle: { width: "110px" },
                model: {
                  value: _vm.temp.num,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "num", $$v)
                  },
                  expression: "temp.num",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "交易日期日期:", prop: "businessDate" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "120px" },
                attrs: {
                  type: "date",
                  format: "yyyy-MM-dd",
                  "value-format": "yyyy-MM-dd",
                  clearable: false,
                },
                model: {
                  value: _vm.temp.businessDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "businessDate", $$v)
                  },
                  expression: "temp.businessDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注:" } },
            [
              _c("el-input", {
                ref: "mobile",
                staticStyle: { width: "360px" },
                attrs: { rows: 2, type: "textarea" },
                model: {
                  value: _vm.temp.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.temp, "remark", $$v)
                  },
                  expression: "temp.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottomBtn" },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.handleDone },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "客户资料",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                "append-to-body": "",
                top: "100px",
                width: "70%",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("kh-table", {
                ref: "customer",
                attrs: {
                  "ear-id": _vm.earId,
                  "ctf-zd-doc-list": _vm.ctfZdDocList,
                  height: _vm.innerHeight - 250,
                },
                on: { select: _vm.handleSelect },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }