/*
 * @Author: yangchao
 * @Date: 2020-08-11 09:19:11
 * @LastEditors: yangchao
 * @LastEditTime: 2021-11-30 10:24:24
 * @Description:
 */
import Vue from 'vue';
import Clipboard from 'clipboard';

function clipboardSuccess(copyContent) {
  Vue.prototype.$message({
    message: "\u590D\u5236".concat(copyContent, "\u6210\u529F\uFF01"),
    type: 'success',
    duration: 1500
  });
}

function clipboardError() {
  Vue.prototype.$message({
    message: "\u590D\u5236".concat(copyContent, "\u5931\u8D25\uFF01"),
    type: 'error'
  });
}

export default function handleClipboard(_text, event, column) {
  var clipboard = new Clipboard(event.target, {
    text: function text() {
      return _text;
    }
  });
  clipboard.on('success', function () {
    clipboardSuccess(column.label || column.title);
    clipboard.destroy();
  });
  clipboard.on('error', function () {
    clipboardError(column.label || column.titl);
    clipboard.destroy();
  });
  clipboard.onClick(event);
}