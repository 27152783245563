import request from '@/utils/request'; // 客户消费单分页

export function pidnoPage(data) {
  return request({
    url: '/manager/comsume/pidnoPage',
    method: 'post',
    data: data
  });
} // 查询现有选购项目

export function pageVo(data) {
  return request({
    url: '/manager/ctmZpt/pageVo',
    method: 'post',
    data: data
  });
} // 消费项目历史表

export function ctmzptDPage(data) {
  return request({
    url: '/manager/ctmZpt/ctmzptDPage',
    method: 'post',
    data: data
  });
} // 科室消费--保存正在消费单

export function ctpComsume(data) {
  return request({
    url: '/manager/comsume/ctpComsume',
    method: 'post',
    data: data
  });
} // 科室消费--取消消费

export function cancalComsume(data) {
  return request({
    url: '/manager/comsume/cancalComsume',
    method: 'post',
    params: data
  });
} // 科室消费--划扣

export function ctpDeduction(data) {
  return request({
    url: '/manager/comsume/ctpDeduction',
    method: 'post',
    data: data
  });
} // 科室消费--消费历史修改

export function ctmzptdUpdate(data, query) {
  return request({
    url: "/manager/ctmZpt/ctmzptdUpdate?".concat(query),
    method: 'post',
    data: data
  });
} // 科室消费--消单

export function canback(data) {
  return request({
    url: '/manager/comsume/canback',
    method: 'post',
    params: data
  });
} // 科室消费--退单

export function reback(data) {
  return request({
    url: '/manager/comsume/reback',
    method: 'post',
    params: data
  });
} // 部门下拉

export function departmentList(data) {
  return request({
    url: '/manager/department/list',
    method: 'post',
    params: data
  });
} // 退划扣审核

export function comsumeRtdDeductionVerify(data) {
  return request({
    url: '/manager/comsume/rtdDeductionVerify',
    method: 'post',
    data: data
  });
} // 退划扣审核

export function comsumeGetRtdDeductionAmt(data) {
  return request({
    url: '/manager/comsume/getRtdDeductionAmt',
    method: 'get',
    params: data
  });
} // 退划扣审核

export function queryRtdDeductionList(data) {
  return request({
    url: '/manager/ctmPay/queryRtdDeductionList',
    method: 'post',
    data: data
  });
} // 耗材列表

export function getCtmzptDeptList(data) {
  return request({
    url: '/manager/ctmZpt/getCtmzptDeptList',
    method: 'post',
    data: data
  });
} // 更新耗材列表

export function ctmzptDeptUpdate(data) {
  return request({
    url: '/manager/ctmZpt/ctmzptDeptUpdate',
    method: 'post',
    data: data
  });
}
/**
 *
 * 新划扣
 */
// 咨询师保存消费项目接口

export function comsumeCtpComsume(data) {
  return request({
    url: '/manager/comsume/ctpComsume',
    method: 'post',
    data: data
  });
} // 前台登记 医生护士接口

export function saveReceptionInfo(data) {
  return request({
    url: '/manager/ctmZpt/saveReceptionInfo',
    method: 'post',
    data: data
  });
} // 库房出库接口

export function consumeStockOut(data) {
  return request({
    url: '/manager/comsume/consumeStockOut',
    method: 'post',
    data: data
  });
} // 补划扣

export function patchConfirmDeduction(data) {
  return request({
    url: '/manager/comsume/patchConfirmDeduction',
    method: 'post',
    data: data
  });
} // 划扣人->客户列表->项目->院长确认

export function bossConfirmDeduction(data) {
  return request({
    url: '/manager/comsume/bossConfirmDeduction',
    method: 'post',
    data: data
  });
} // 判断是否有出库

export function getDeptOutList(data) {
  return request({
    url: '/manager/empout/getDeptOutList',
    method: 'get',
    params: data
  });
} // 检测项目耗材设置

export function checkDeptStock(data) {
  return request({
    url: '/manager/ctmZpt/checkDeptStock',
    method: 'post',
    params: data
  });
}