/*
 * @Author: yangchao
 * @Date: 2020-08-11 09:19:11
 * @LastEditors: yangchao
 * @LastEditTime: 2021-11-22 10:23:33
 * @Description:
 */
var getters = {
  globalLoading: function globalLoading(state) {
    return state.app.globalLoading;
  },
  tableLoading: function tableLoading(state) {
    return state.app.tableLoading;
  },
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  empSelectSaveStatus: function empSelectSaveStatus(state) {
    return state.app.empSelectSaveStatus;
  },
  searchStatus: function searchStatus(state) {
    return state.settings.searchStatus;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  userName: function userName(state) {
    return state.user.userName;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  password: function password(state) {
    return state.user.password;
  },
  earId: function earId(state) {
    return state.common.earId;
  },
  empCode: function empCode(state) {
    return state.user.empCode;
  },
  eareName: function eareName(state) {
    return state.user.eareName;
  },
  roleId: function roleId(state) {
    return state.user.roleId;
  },
  roleName: function roleName(state) {
    return state.user.roleName;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  jump: function jump(state) {
    return state.user.jump;
  },
  posName: function posName(state) {
    return state.user.posName;
  },
  employees: function employees(state) {
    return state.common.employees;
  },
  //职员列表
  vipLevelList: function vipLevelList(state) {
    return state.common.vipLevelList;
  },
  //会员等级
  employeesAll: function employeesAll(state) {
    return state.common.employeesAll;
  },
  //职员列表-所有分院
  caltypeList: function caltypeList(state) {
    return state.common.caltypeList;
  },
  //信息来源
  customerLevel: function customerLevel(state) {
    return state.common.customerLevel;
  },
  //客户等级
  customerImportant: function customerImportant(state) {
    return state.common.customerImportant;
  },
  //客户类型
  customerLevelManual: function customerLevelManual(state) {
    return state.common.customerLevelManual;
  },
  //客户等级
  symptomListChi: function symptomListChi(state) {
    return state.common.symptomListChi;
  },
  //适应症二级
  symptomList: function symptomList(state) {
    return state.common.symptomList;
  },
  //适应症一级
  simpleCalType: function simpleCalType(state) {
    return state.common.simpleCalType;
  },
  //信息来源-二级
  services: function services(state) {
    return state.common.services;
  },
  //指定服务
  departmentList: function departmentList(state) {
    return state.common.departmentList;
  },
  //科室列表
  departmentListAll: function departmentListAll(state) {
    return state.common.departmentListAll;
  },
  //科室列表
  activeList: function activeList(state) {
    return state.common.activeList;
  },
  //激活列表
  departmentEmployList: function departmentEmployList(state) {
    return state.common.departmentEmployList;
  },
  //科室列表
  paymentList: function paymentList(state) {
    return state.common.paymentList;
  },
  //付款方式列表
  eareList: function eareList(state) {
    return state.common.eareList;
  },
  //分院列表
  intensList: function intensList(state) {
    return state.common.intensList;
  },
  rvTypes: function rvTypes(state) {
    return state.common.rvTypes;
  },
  //回访类型列表
  storageList: function storageList(state) {
    return state.common.storageList;
  },
  //仓库列表
  vendorList: function vendorList(state) {
    return state.common.vendorList;
  },
  //供应商列表
  waiterList: function waiterList(state) {
    return state.common.waiterList;
  },
  positionList: function positionList(state) {
    return state.common.positionList;
  },
  //职位列表
  fucTypeList: function fucTypeList(state) {
    return state.common.fucTypeList;
  },
  //预约类型
  zptPdulabelList: function zptPdulabelList(state) {
    return state.common.zptPdulabelList;
  },
  //推广标签
  zptPdulabel2List: function zptPdulabel2List(state) {
    return state.common.zptPdulabel2List;
  },
  //价值标签
  rightWidth: function rightWidth(state) {
    return state.settings.rightWidth;
  },
  openSearch: function openSearch(state) {
    return state.settings.openSearch;
  },
  lockScreen: function lockScreen(state) {
    return state.settings.lockScreen;
  },
  showSettings: function showSettings(state) {
    return state.settings.showSettings;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  permission_AddRoutes: function permission_AddRoutes(state) {
    return state.permission.addRoutes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  preProjectList: function preProjectList(state) {
    return state.common.preProjectList;
  }
};
export default getters;