var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      style: { height: _vm.height + "px", overflow: "hidden" },
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-header",
            { attrs: { height: "auto" } },
            [
              _c("base-header", {
                attrs: {
                  actions: _vm.actions,
                  temp: _vm.temp,
                  "list-query": _vm.listQuery,
                  selectColumns: _vm.columns,
                  columns: _vm.columns,
                },
                on: { action: _vm.handleAction, search: _vm.handleSearch },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("search-form", {
            attrs: { columns: _vm.columns, listQuery: _vm.listQuery },
            on: { search: _vm.handleSearch },
          }),
          _vm._v(" "),
          _c(
            "el-main",
            [
              _c("VxeTable", {
                ref: "table",
                attrs: {
                  checkbox: "",
                  showIndex: false,
                  temp: _vm.temp,
                  checkType: "lgCheck",
                  list: _vm.list,
                  total: _vm.total,
                  height: _vm.height - 230,
                  "list-query": _vm.listQuery,
                  columns: _vm.columns,
                  pagination: "",
                  selectList: _vm.selectList,
                },
                on: {
                  pagination: _vm.getList,
                  click: _vm.handleClick,
                  selectItem: _vm.selectItem,
                  selected: _vm.handleSelected,
                  allSelect: _vm.allSelect,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }