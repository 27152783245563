var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-container" },
    [
      _c(
        "el-container",
        [
          !_vm.approvalInfo
            ? _c(
                "el-header",
                { attrs: { height: "auto" } },
                [
                  _c("base-header", {
                    attrs: {
                      actions: _vm.actions,
                      temp: _vm.customer,
                      "list-query": _vm.listQuery,
                      selectColumns: _vm.columns1,
                      columns: _vm.columns1,
                      excel: true,
                      exportMessage: _vm.exportMessage,
                    },
                    on: { action: _vm.handleAction, search: _vm.handleRefresh },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-main",
            [
              _vm.approvalInfo
                ? [
                    _c(
                      "el-form",
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 19 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "审核备注:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { type: "textarea", rows: 3 },
                                      model: {
                                        value: _vm.approvalInfo.remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.approvalInfo,
                                            "remark",
                                            $$v
                                          )
                                        },
                                        expression: "approvalInfo.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-top": "60px",
                                      "margin-left": "20px",
                                    },
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      disabled: _vm.customer.verifyState == "4",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("sureCheckXm")
                                      },
                                    },
                                  },
                                  [_vm._v("审核通过")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "60px" },
                                    attrs: {
                                      size: "mini",
                                      type: "danger",
                                      disabled: _vm.customer.verifyState == "4",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("cancelCheckXm")
                                      },
                                    },
                                  },
                                  [_vm._v("审核驳回")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.checkItem
                ? _c("base-table", {
                    ref: "table1",
                    attrs: {
                      index: "",
                      temp: _vm.customer,
                      list: _vm.list1,
                      total: _vm.total,
                      height: (_vm.height - 140) / 2,
                      "list-query": _vm.listQuery,
                      columns: _vm.columns1,
                      pagination: "",
                    },
                    on: {
                      click: _vm.handleClick,
                      dblclick: _vm.handleDblclick,
                      pagination: _vm.handleSearch1,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-button", { attrs: { type: "text", size: "medium" } }, [
                _vm._v("还款记录列表（鼠标双击进行修改）"),
              ]),
              _vm._v(" "),
              !_vm.checkItem
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.printAgain(null)
                        },
                      },
                    },
                    [_vm._v("重印")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "danger",
                    icon: "el-icon-delete",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDel()
                    },
                  },
                },
                [_vm._v("删除")]
              ),
              _vm._v(" "),
              _c("base-table", {
                ref: "table2",
                attrs: {
                  index: "",
                  temp: _vm.customer2,
                  list: _vm.list2,
                  total: _vm.total2,
                  height: (_vm.height - 140) / 2,
                  "list-query": _vm.listQuery2,
                  columns: _vm.columns2,
                  pagination: "",
                },
                on: { click: _vm.handleClick2, pagination: _vm.handleSearch2 },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dialogVisible1
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: _vm.customer.dacCode
                  ? "编辑日常收支账单"
                  : "增加日常收支账单",
                visible: _vm.dialogVisible1,
                "close-on-click-modal": false,
                top: "80px",
                width: "600px",
                center: "",
                "destroy-on-close": "",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("wf-form", {
                ref: "dialog1",
                attrs: {
                  temp: _vm.customer,
                  "ctf-zd-doc-list": _vm.employees,
                  height: _vm.innerHeight - 240,
                },
                on: {
                  cancel: function ($event) {
                    _vm.dialogVisible1 = false
                  },
                  done: _vm.handleDone,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisible2
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "还款",
                visible: _vm.dialogVisible2,
                "close-on-click-modal": false,
                top: "180px",
                width: "52%",
                center: "",
                "destroy-on-close": "",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
                close: _vm.setClear,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { height: "260px", padding: "20px" },
                  attrs: {
                    inline: true,
                    rules: _vm.rules,
                    "label-position": "right",
                    model: _vm.form,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "现金还款", prop: "cpyRmamt" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  required: "",
                                  type: "number",
                                  min: "0",
                                },
                                model: {
                                  value: _vm.form.cpyRmamt,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cpyRmamt", $$v)
                                  },
                                  expression: "form.cpyRmamt",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "卡1还款", prop: "cpyCardamt1" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  required: "",
                                  type: "number",
                                  min: "0",
                                },
                                model: {
                                  value: _vm.form.cpyCardamt1,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cpyCardamt1", $$v)
                                  },
                                  expression: "form.cpyCardamt1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "卡2还款", prop: "cpyCardamt2" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "80%" },
                                attrs: {
                                  required: "",
                                  type: "number",
                                  min: "0",
                                },
                                model: {
                                  value: _vm.form.cpyCardamt2,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cpyCardamt2", $$v)
                                  },
                                  expression: "form.cpyCardamt2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bottomBtn" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.print,
                        callback: function ($$v) {
                          _vm.print = $$v
                        },
                        expression: "print",
                      },
                    },
                    [_vm._v("是否打印")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleDone2("form")
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dialogVisibleQk
        ? _c(
            "el-dialog",
            {
              directives: [
                { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
              ],
              attrs: {
                title: "应收欠款明细",
                visible: _vm.dialogVisibleQk,
                "close-on-click-modal": false,
                top: "80px",
                width: "70%",
                center: "",
                "destroy-on-close": "",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisibleQk = $event
                },
              },
            },
            [_c("qk-table", { ref: "qkTable" })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }