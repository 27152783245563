//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    temp: {
      type: Object
    },
    customer: {
      type: Object
    },
    departmentList: {
      type: Array
    },
    employees: {
      type: Array
    },
    payTypeList: {
      type: Array
    },
    paymentList: {
      type: Array
    },
    height: {},
    approvalInfo: {
      type: Object
    }
  },
  watch: {},
  data: function data() {
    return {
      rules: {},
      itemParams: {},
      setCard: true,
      advisers: '',
      diagnosis: '',
      cardTypeSelect: 'select'
    };
  },
  created: function created() {
    this.itemParams = JSON.parse(this.temp.requestParams);
  },
  methods: {}
};