import "core-js/modules/es6.regexp.to-string";

/**
 * Created by PanJiaChen on 16/11/18.
 */
var toString = Object.prototype.toString;
/**
 * @param {string} path
 * @returns {Boolean}
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
/**
 * @param {string} url
 * @returns {Boolean}
 */

export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
/**
 * @param {string} email
 * @returns {Boolean}
 */

export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }

  return false;
}
/**
 * @param {Array} arg
 * @returns {Boolean}
 */

export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }

  return Array.isArray(arg);
} // 判断是否是正确的手机号

export var isPhoneNumber = function isPhoneNumber() {
  var tel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var reg = /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  return reg.test(tel);
}; // 判断是否是合法的身份证信息

export var isIDCard = function isIDCard() {
  var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var id15 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var id18 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
  return id15.test(id) || id18.test(id);
};
export function isFunction(flg) {
  var s = toString.call(flg);
  var t = '[object Function]';
  return s === t || s === '[object AsyncFunction]';
}
export function isBoolean(bool) {
  return typeof bool === 'boolean';
}